import TextPage from "../../../components/TextPage"
import React, { useEffect } from "react";
import { content } from '../../../constants/PrivacyPolicyData'

const PrivacyPolicy = (props) => {
    // let urlStrings = window.location.href.split("/");
    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://consent.cookiebot.com/c0728bbf-2551-4cda-a732-9a1e4cf0d70b/cd.js";
        script.async = true;
        document.getElementById('wrapperCookies').appendChild(script);
      
        return () => {
            if(document.getElementById('wrapperCookies') !== null && document.getElementById('wrapperCookies').childElementCount > 0){
                document.getElementById('wrapperCookies').removeChild(script);
            }
        }
      }, []);

    return (
        // <TextPage content={urlStrings.includes('advisors') ? contentAdvisor : contentClient} />
        <TextPage content={content} />
    )
}

export default PrivacyPolicy