import React from 'react';
import Footer from '../components/footer/Footer'
import SimpleSuperAdminNavbar from '../components/navbar/SuperAdminNavbar2'
import '../styles/superAdmin_sidebar.scss'
import bg from '../assets/img/bg/bannerSuperAdmin.png'

const SimpleSuperAdminLayout = (props) => {
  const {children, categories, userEmail, check403Error} = props;
    return (
  <>
      <div className='simpleSuperAdmin' style={{ backgroundImage: `url(${bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: 'inherit' }}>
    <SimpleSuperAdminNavbar userEmail={userEmail} check403Error={check403Error}/>
    {children}
    <Footer categories = {categories}/>
    </div>
  </>
    )
}
export {SimpleSuperAdminLayout}