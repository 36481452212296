import React from "react"
import "keen-slider/keen-slider.min.css";
import { Button } from "react-bootstrap";

import { isMobile, isMobileOnly } from 'react-device-detect'

const BackgroundText = (props) => {
    const { backgroundImg, titleFirstLine, titleSecondLine, subtitle, list, setLoginModalShow, role, agreement, desc, setHideSignUp } = props
    const isAffiliate = role === 'affiliate';

    return (
        <>
            <div className="navigation-wrapper">
                <div className="keen-slider">
                    <div className={`keen-slider__slide number-slide`} style={{ borderRadius: 0, background: '#04122e', display: 'contents' }}>
                        <div className="slideContent" style={{ height: 'fit-content' }}>
                            <div className="backroundImage" style={isMobileOnly ? {} : styles.bg}>
                                <img src={backgroundImg} alt="ico" style={isMobileOnly ? { aspectRatio: '2.4', height: 'auto' } : { minHeight: '100%', objectFit: 'cover' }} />
                            </div>

                            <div className="bottomItems" style={isMobileOnly ? styles.bottomItemsMobile : styles.bottomItems(subtitle, agreement, desc)}>
                                <div className="imageTitle" style={styles.imageTitle(desc)}>{titleFirstLine}{!isMobileOnly ? <br /> : ' '}{titleSecondLine}</div>
                                {!list ?
                                    <div className="mb-4" style={styles.imageSubTitle(subtitle, list)}>{subtitle}</div>
                                    :
                                    <ul className="imageSubTitle">
                                        {list.map((item, id) => {
                                            return (
                                                <li className="mb-4" key={id} style={styles.imageSubTitle(subtitle, list)}> {item}</li>
                                            )
                                        })}
                                    </ul>
                                }
                                {!desc ?
                                    <Button className="buttonGreen mr-5" style={isMobileOnly ? styles.greenBtnMobile : styles.greenBtn(isAffiliate)} onClick={() => { setHideSignUp(false); setLoginModalShow(true) }}>{!isAffiliate ? 'Join us now' : 'Apply Now'}</Button>
                                    :
                                    <div style={styles.desc}>{desc}</div>
                                }
                                {
                                    isAffiliate && agreement ?
                                        <div style={styles.agreement} ><a href='/affiliate-pdf' style={{ color: '#D8D8D8', textDecoration: 'none' }}>{agreement}</a></div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default BackgroundText

const styles = {
    bg: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%'
    },
    bottomItems: (subtitle, agreement, desc) => ({
        marginTop: subtitle ? agreement ? '20.7vh' : '19.55vh' : desc ? '41px' : '38px',
        marginLeft: subtitle ? '15.3%' : desc ? '0' : '15.3%',
        position: 'relative',
        top: '0',
        left: '0',
        paddingBottom: agreement ? '2.8vh' : desc ? '6vh' : '4vh'
    }),
    bottomItemsMobile: {
        top: '0',
        position: "relative",
        marginLeft: '19px',
        left: '0',
        marginRight: '19px',
        marginTop: '20px',
        padding: '0px',
        width: 'auto'
    },
    imageTitle: (desc) => ({
        fontSize: isMobileOnly ? "32px" : '36px',
        lineHeight: isMobileOnly ? "45px" : "56px",
        marginBottom: isMobileOnly ? "18px" : '16px',
        textAlign: desc || isMobileOnly ? 'center' : 'left'
    }),
    greenBtnMobile: {
        width: '-webkit-fill-available',
        marginLeft: "32px",
        marginRight: "32px",
        fontSize: "20px",
        lineHeight: "20px",
        padding: "17px 0.5rem"
    },
    greenBtn: (isAffiliate) => ({
        fontSize: "18px",
        lineHeight: "20px",
        padding: !isAffiliate ? '1rem 1.8rem' : '1rem 3.43rem'
    }),
    imageSubTitle: (subtitle, list) => ({
        fontSize: subtitle && !isMobileOnly ? '24px' : '20px',
        lineHeight: subtitle ? '24px' : '36px',
        textAlign: isMobileOnly && !list ? 'center' : 'left'
    }),
    agreement: {
        fontSize: '16px',
        lineHeight: '24px',
        color: '#D8D8D8',
        marginTop: '12px',
        textAlign: 'left'
    },
    desc: {
        textAlign: "center",
        fontSize: '16px',
        lineHeight: '34px',
        padding: isMobile ? '0 1rem' : '0 23vw',
        fontWeight: '400'
    }

};
