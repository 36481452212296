import React from "react";
import { Button } from "react-bootstrap";
import "../styles/thankYouScreen.scss";
import "../styles/default.css";
import * as myAppConfig from "../constants/AppConstants";
import { useHistory } from "react-router-dom";
import * as utils from "../Utilities/Utils";

const ThankYouScreen = (props) => {
  const history = useHistory();
  const subtitle = props.advisorName
    ? parse(props.data.subtitle, props.advisorName)
    : "Your feedback is valueable to us! We appreciate your review & honesty";

  function parse(str) {
    var args = [].slice.call(arguments, 1),
      i = 0;

    return str.replace(/%s/g, () => args[i++]);
  }

  const redirectToHomePage = () => {
    if (
      props.data === myAppConfig.FINAL_THANK_YOU_MESSAGES.CONVERSATION_ENDED
    ) {
      utils.add_rating_and_review(false, props.advisorId);
    }
    sessionStorage.clear();
    if (props.advisorName !== "") {
      history.replace("/");
      window.location.pathname = "/";
    } else {
      window.location.pathname = "/";
    }
  };

  const redirectToOtherPage = () => {
    if (props.data === myAppConfig.FINAL_THANK_YOU_MESSAGES.FEEDBACK) {
      redirectToHomePage();
    } else if (
      props.data === myAppConfig.FINAL_THANK_YOU_MESSAGES.CONVERSATION_ENDED
    ) {
      history.replace("/rate-advisor");
    } else if (props.data === myAppConfig.FINAL_THANK_YOU_MESSAGES.TIME_IS_UP) {
    } else if (
      props.data === myAppConfig.FINAL_THANK_YOU_MESSAGES.CONNECTION_ISSUE
    ) {
    }
  };

  return (
    <div
      className="thank-you-container popup-container mb-5 pb-5"
      //style={{ display: showThankYouPopup ? "block" : "none" }}
    >
      <div className="modal-thankyou-body-custom" style={{ display: "block" }}>
        <img
          src={require("../assets/icons/" + props.data.icon).default}
          className="img-changed"
          alt="icon changed"
        />
        <p className="thank-you-title">{props.data.title}</p>
        <p className="thank-you-subtitle">{subtitle}</p>

        <div className="btns-container">
          <Button
            className="btn-thankyou btnDarkBlue "
            style={{ display: props.data.btnNo === null ? "none" : "block" }}
            onClick={() => redirectToHomePage()}
          >
            {props.data.btnNo}
          </Button>
          <Button
            className={
              "btn-thankyou btnGreen " +
              (props.data.btnNo === null ? "single-option" : "")
            }
            onClick={() => redirectToOtherPage()}
          >
            {props.data.btnYes}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ThankYouScreen;
