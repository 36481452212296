import React, { useState, useEffect } from "react";
import { Row, Col, Spinner, Button } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import * as Icon from "react-feather";
import "../../styles/callComponent.scss";
import * as myAppConfig from "../../constants/AppConstants";
import icon_logo from "../../assets/img/user.png";
//import { ProgressBarLine } from "react-progressbar-line";
import * as utils from "../../Utilities/Utils";

const CallComponent = (props) => {
  const userType = localStorage.getItem("userType");
  const isClient = Number(userType) === myAppConfig.USER_TYPES.CLIENT;

  if (props.deviceStatus && props.deviceStatus === "closed") {
    clearInterval(countUpInterval);
  }
  const [isMicOn, setIsMicOn] = useState(true);

  const otherTalkerCategory = utils.getCategoriesFromList(
    props.otherTalkerCategory
  );

  const showLoader =
    (isClient && !props.otherJoined) ||
    (!isClient && props.deviceStatus === "");

  /*const [startTimer, setStartTimer] = useState(false);
  const conversationSeconds = 3 * 60;
  const [hours, setHours] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);*/

  var timer = 0;
  const [elapsedTime, setElapsedTime] = useState("0:00:00");
  const [freeMinutesExpired, setFreeMinutesExpired] = useState(false);

  var countUpInterval;

  const startCountingUp = () => {
    console.log("start counting up");
    countUpInterval = setInterval(countUp, 1000);
  };

  const countUp = () => {
    timer = timer + 1;
    setElapsedTime(utils.secondsToHms(timer));

    if (
      sessionStorage.getItem("free-minutes") !== undefined &&
      sessionStorage.getItem("free-minutes") > 0 &&
      sessionStorage.getItem("free-minutes") * 60 === timer
    ) {
      setFreeMinutesExpired(true);
    }
  };

  if (props.call !== undefined) {
    bindVolumeIndicators(props.call);
  }

  useEffect(() => {
    if (props.otherJoined && props.otherJoined === true) {
      startCountingUp();
    }
  }, [props.otherJoined]);

  function bindVolumeIndicators(call) {
    call.on("volume", function (inputVolume, outputVolume) {
      if (isMicOn) {
        call.mute(false);
      } else {
        call.mute(true);
        call.mute();
      }
    });
  }

  return (
    <div className="callContainer">
      <div
        className="free-minutes-tooltip"
        style={{ display: freeMinutesExpired ? "" : "none" }}
      >
        {isClient ? (
          <p>
            Your {sessionStorage.getItem("free-minutes")} free minutes has
            ended!
          </p>
        ) : (
          <p>{props.otherTalkerName + "'s free minutes has ended!"}</p>
        )}
        <Icon.XCircle
          className="close-img"
          onClick={() => setFreeMinutesExpired(false)}
        />
      </div>

      <div className="call-header">
        <Row className="call-top">
          <Col lg={4} style={{ width: "35%" }} />
          <Col lg={4} className="call-header-title" style={{ width: "30%" }}>
            Call
          </Col>

          {showLoader ? null : (
            <Col
              lg={4}
              className="end"
              style={{ width: "35%" }}
              onClick={() => props.showModalEndSession()}
            >
              <p>End session</p>
            </Col>
          )}
        </Row>
        <img
          src={
            props.otherTalkerLogoImage === ""
              ? icon_logo
              : myAppConfig.imagesApiURL + props.otherTalkerLogoImage
          }
          alt="other talker logo"
          className="user-logo"
        />

        <div>
          <p className="name">{props.otherTalkerName}</p>
          <p className="category">{otherTalkerCategory}</p>
        </div>

        <div className="bottom-container">
          {showLoader && isClient ? (
            <div
              className={
                "spinner-container " +
                (isMobile ? "spinner-container-mobile" : "")
              }
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <div className="infoText">
                <p className="infoTitle">Starting Call...</p>
                <p>We're connecting you now, please wait a few seconds</p>
              </div>
              <Button onClick={() => props.onCancelCall()}>Cancel</Button>
              <hr />
            </div>
          ) : (
            <>
              <p className="connecting">
                {props.deviceStatus === "open" ? "" : props.deviceStatus}
              </p>
              <div
                className={
                  props.deviceStatus === "open"
                    ? "d-flex justify-content-end "
                    : ""
                }
                style={{
                  display: props.deviceStatus === "open" ? "" : "none",
                }}
              >
                {/*<ProgressBarLine
                  value={
                    startTimer && isClient
                      ? (1 -
                          (hours * 3600 + minutes * 60 + seconds) /
                            conversationSeconds) *
                        100
                      : 0
                  }
                  min={0}
                  max={100}
                  strokeWidth={2.26}
                  trailWidth={2.26}
                  styles={{
                    path: {
                      stroke: "white",
                    },
                    trail: {
                      stroke: "#233558",
                    },
                  }}
                />*/}
                <div className="time">{elapsedTime}</div>
              </div>
            </>
          )}
        </div>
      </div>

      <div
        className="call-bottom"
        style={{ display: showLoader ? "none" : "" }}
      >
        <div
          className={"btns " + (isMicOn ? "microphone-on" : "microphone-off")}
          onClick={() => {
            setIsMicOn(!isMicOn);
          }}
        ></div>
        <div className="volume btns" style={{ display: "none" }} />
      </div>
    </div>
  );
};

export default CallComponent;
