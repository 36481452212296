import React from 'react'
import {Col} from 'react-bootstrap';
import { imagesApiURL } from '../../../../constants/AppConstants';
import styles from '../../../../styles/wizard.module.scss';

const CategoryCard = (props) => {

    const getCategoryBackgroundUrl = (background_image, index, areaSelected) => {
        let url = imagesApiURL + background_image;
        let value = "url(" + url + ")";
        let rgbTint = "rgba(29, 161, 242, 0.6)";
        let valueSelected = "linear-gradient(" + rgbTint + ", " + rgbTint + "),url(" + url + ")";
        console.log(index === areaSelected ? valueSelected : value);
        return index === areaSelected ? valueSelected : value;
    }

    return (
        <Col key={props.id} className="mb-4">

            <div
                onClick={() => props.onClick()}
                className={styles.category_item}
                style={{ backgroundImage: getCategoryBackgroundUrl(props.background_image, props.id, props.areaSelected), backgroundRepeat: "no-repeat" }}>
                <img src={imagesApiURL + props.icon} alt="categ_icon" className={styles.categ_icon}/>
                <div className={styles.title_categ}>{props.title}</div>
            </div>

        </Col>
    )
}

export default CategoryCard
