import editAccountImage from '../../assets/icons/edit_account_icon.png';
import { Card } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

const AccountSettingsForm = (props) => {
    const {
        setShowEditPassword
    } = props

    return (
        <div className='account pb-3' >
            <h4 className="pb-3 pt-5">Password settings</h4>
            <Card className='password'>
                {isMobile ?
                    <div className='your'>Your password</div>
                    : null
                }
                <div className='value'>Change password</div>
                <div className='edit-profile' onClick={() => setShowEditPassword(true)} style={{ top: '32px' }} > <img alt='' src={editAccountImage} /> </div>
            </Card>
        </div>
    )
}

export default AccountSettingsForm