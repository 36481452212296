import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { imagesApiURL } from "../../constants/AppConstants";
import "../../styles/accountManageActionModal.scss";
import cardLogo from '../../assets/icons/card_logo.png'
import clockLogo from '../../assets/icons/clock_logo.png'
import privateLogo from '../../assets/icons/private_logo.png'
import { isMobileOnly } from "react-device-detect";

const AddFirstCardModal = (props) => {
    return props.actionType !== "" ? (
        <Modal
            show={props.show}
            onHide={() => props.setShow(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="account-manage-action-modal first-card-modal"
            backdrop='static'
            keyboard={false}
            dialogClassName={isMobileOnly ? "modal-fullscreen modal-backdrop" : ""}
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className='paymentMethods-container'>
                <img className='advisor-image' src={imagesApiURL + props.advisorImage} />
                <div className="title">Thank you for choosing <br /> {props.advisorName}</div>
                <div className="subtitle">As a new client and before you start with the session, you'll need to add a payment method. </div>

                <div className='card secure-info'>
                    <div className='d-flex mb-2'>
                        <img src={cardLogo} alt='card logo' className='logo' />
                        <div className='section-title'>Secure Payment</div>
                    </div>
                    <div className='section-info pb-3 mb-3'>Trusted by millions of customers. Our Policy will make sure your transactions are secure.</div>
                    <div className='d-flex mb-2'>
                        <img src={privateLogo} alt='card logo' className='logo' />
                        <div className='section-title'>Private, Safe, Secure</div>
                    </div>
                    <div className='section-info pb-3 mb-3'>Your conversations and psychic readings are always 100% anounymous and confidential.</div>
                    <div className='d-flex mb-2'>
                        <img src={clockLogo} alt='card logo' className='logo' />
                        <div className='section-title'>Anytime, Anywhere</div>
                    </div>
                    <div className='section-info'>We’re here it’s convenient for you 24/7 via phone, chat or app</div>
                </div>
                <Button className='button btnGreen' onClick={props.onProceed}>Proceed</Button>
            </Modal.Body>
        </Modal>
    ) : null;
};

export default AddFirstCardModal;
