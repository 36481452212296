
import {Modal} from 'react-bootstrap'

const ProfileUpdated = (props) =>{
    const { showProfileUpdatedMessage, setShowProfileUpdatedMessage, title, description, newUrl } = props
    return (
        <Modal
        show={showProfileUpdatedMessage}
            onHide={() => { setShowProfileUpdatedMessage(false); if (newUrl) { window.location.href = newUrl; } else { window.location.reload() } }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="loginModal successUpdate"
            backdrop='static'
            keyboard={false}
        >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
                <div className="registerMessage title">{title}</div>
                <div className="registerMessage">{description}</div>)
        
        </Modal.Body>
        </Modal>
    )
}

export default ProfileUpdated