import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Card,
  Spinner
} from "react-bootstrap";
import axios from "axios";
import "../../../styles/superAdminDashboard.scss";
import RevenueCard from "../../../components/RevenueCard";
import * as myAppConfig from "../../../constants/AppConstants";
import * as utils from "../../../Utilities/Utils";
import ExpertCard from "./ExpertCard";
import PeriodDropDown from "../../../components/PeriodDropDown";

const dropDownValues = [
  "Last 24 hours",
  "Last 30 days",
  "Last 90 days",
  "Custom",
];

const Dashboard = (props) => {
  const { homePageDetails, setHomePageDetails, check403Error } = props;

  const [advisorsRevenue, setAdvisorsRevenue] = useState();
  const [dropDownTitle, setDropDownTitle] = useState(dropDownValues[0]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dashboardData, setDashboardData] = useState();
  const [dataLoading, setDataLoading] = useState(false);
  const maxToDisplay = 5;

  const [tempStartDate, setTempStartDate] = useState(null);//new Date());
  const [tempEndDate, setTempEndDate] = useState(null);

  const selectedInterval = dropDownValues.indexOf(dropDownTitle);
  const intervalDays = selectedInterval === 0 ? 1 : selectedInterval === 1 ? 30 : selectedInterval === 2 ? 90 : 0;
  const [data2Array, setData2Array] = useState([]);

  useEffect(() => {
    if (!dataLoading) {
      setDataLoading(true);
      let params;
      let interval = {
        interval: intervalDays
      }
      if (selectedInterval === 3 && tempStartDate && tempEndDate) {
        let fromTo = {
          from: startDate,
          to: endDate
        }
        params = Object.assign(interval, fromTo);
      }
      else {
        params = interval;
      }
      if ((selectedInterval < 3) || (selectedInterval === 3 && tempStartDate && tempEndDate)) {
        setData2Array([]);
        setDashboardData([]);

        axios
          .get(myAppConfig.GET_DASHBOARD_GRAPHS, {
            params: params,
            headers: { token: localStorage.getItem("userToken") },
          })
          .then((response) => {
            if (response.data.status === "success") {
              setDashboardData(utils.getDashboardGraphsData(response));
            }

          }).catch((err) => check403Error(err));

        axios
          .get(myAppConfig.GET_ADVISOR_DASHBOARD, {
            params: params,
            headers: { token: localStorage.getItem("userToken") },
          })
          .then((response) => {
            setDataLoading(false);
            if (response.data.status === "success") {
                const hpData = {
                  categories: response.data.categories,
                  specializations: response.data.specializations,
                  languages: response.data.languages,
                  skillsAndMethods: response.data.skillsAndMethods
                };
                localStorage.setItem("homePageDetails", JSON.stringify(hpData))
              if (!homePageDetails) {
                setHomePageDetails(response.data);
              }
              if (!advisorsRevenue) {
                setAdvisorsRevenue(response.data.advisors_revenue);
              }
            }
          })
          .catch((err) => check403Error(err));
      }
      else {
        setDataLoading(false);
      }
    }
  }, [dropDownTitle, startDate, endDate, check403Error, advisorsRevenue, setHomePageDetails, selectedInterval, intervalDays, homePageDetails]);


  return (
    <div className="dashboard-main-container">
      <Container fluid className="dashboard-container p-0">
        <div className="clientHomePageDIV">
          <p className="clientHomePageTitle">{(selectedInterval === 0 ? 'Daily' : selectedInterval === 1 ? 'Monthly' : selectedInterval === 2 ? 'Last 3 Months' : 'Custom Period') + ' Revenue'}</p>
          {!dataLoading ?
            <PeriodDropDown
              dropDownValues={dropDownValues}
              dropDownTitle={dropDownTitle}
              setDropDownTitle={setDropDownTitle}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              tempStartDate={tempStartDate}
              setTempStartDate={setTempStartDate}
              tempEndDate={tempEndDate}
              setTempEndDate={setTempEndDate} /> : null}
        </div>
        {dataLoading ?
          <div style={{ textAlign: 'center', marginBottom: '46px' }}>
            <Spinner animation='border' style={{ color: 'white' }} />
          </div> :
          <>
            <br /><br />
            <Row xs={1} md={3} className="g-4">
              {dashboardData ? dashboardData.map((_item, idx) => (
                <Col key={idx}>
                  <RevenueCard item={_item} interval={selectedInterval} tempEndDate={tempEndDate} tempStartDate={tempStartDate} data2Array={data2Array} setData2Array={setData2Array} id={idx} />
                </Col>
              )) : null}
            </Row>
          </>}

        {advisorsRevenue ?
          <>
            <Row className="advisors-revenue-header">
              <p>Top 5 - Advisors Revenue</p>
            </Row>

            <Row className="experts-list-container mx-0">
              <Card>
                <div className="card-container">
                  <p className="expert-title">Experts List</p>

                  {advisorsRevenue ? advisorsRevenue.map((advisor, idx) =>
                    idx < maxToDisplay ? (
                      <ExpertCard key={idx} advisor_revenue={advisor} />
                    ) : null
                  ) : null}
                </div>

                <div
                  className="show-more-advisors"
                  onClick={() => document.querySelector("#advisors-link").click()}
                >
                  <p>Show more</p>
                </div>
              </Card>
            </Row>
          </> : null}
      </Container>
    </div>
  );
};

export default Dashboard;
