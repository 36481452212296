import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Card, Row, Col } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import "../../../styles/ratingAndReviews.scss";
import { imagesApiURL } from "../../../constants/AppConstants";
import RatingAndReviewCell from "../../../components/RatingAndReviewCell";
import { isMobileOnly } from "react-device-detect";

export default function RatingAndReviews(props) {
  const maxToDisplay = 6;
  const [noToDisplay, setNoToDisplay] = useState(maxToDisplay);
  let history = useHistory();

  return (
    <div className="rating-and-reviews-container">
      <Container fluid style={isMobileOnly ? { width: "91.5%" } : { width: "88.88%" }} className="main-container p-0">
        <div className="back" onClick={history.goBack}>
          <Card.Img
            src={imagesApiURL + "images/custom/back.png"}
            className="back-image"
          />{" "}
          Back
        </div>

        <Card className="card-container">
          <div className="card-margins">
            <Row>
              <Col lg={6}>
                <p className="reviews-title">Rating & Reviews</p>
              </Col>
              <Col lg={6}>
                <div className="rating-images">
                  <ReactStars
                    edit={false}
                    size={14}
                    value={props.reviews.score_average.toFixed(1)}
                    isHalf={true}
                    color="#233558"
                    activeColor="#19AAA1"
                  />
                  <p className="rating-amount-note custom-text-style-14-20">
                    {props.reviews.score_average.toFixed(1)} (
                    {props.reviews.rating_and_reviews.length})
                  </p>
                  <div style={{ marginLeft: 42, alignSelf: "center" }}>
                    <p
                      className="rating-amount-note custom-text-style-14-20"
                      style={{ color: "#19AAA1" }}
                    >
                      {props.reviews.number_of_reviews}
                    </p>
                    <p className="rating-amount-note custom-text-style-14-20">
                      readings
                    </p>
                  </div>
                </div>
              </Col>
            </Row>

            <div>
              {props.reviews.rating_and_reviews.map((review, idx) =>
                idx < noToDisplay ? (
                  <RatingAndReviewCell review={review} check403Error={props.check403Error} />
                ) : null
              )}

              <div
                className="show-more-reviews"
                style={{
                  display:
                    props.reviews.rating_and_reviews.length > noToDisplay
                      ? "flex"
                      : "none",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setNoToDisplay(props.reviews.rating_and_reviews.length)
                }
              >
                <p>Show more</p>
              </div>
            </div>
          </div>
        </Card>
      </Container>
    </div>
  );
}
