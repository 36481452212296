import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Popup from "react-popup";
import "../../styles/custom.popup.css";

import * as myAppConfig from "../../constants/AppConstants";

import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"

import { ClientRouter } from "../../routing";
import { AdminRouter } from "../../routing";
import { SuperAdminRouter } from "../../routing";
import FAQ from "./general/FAQ";
import BecomeAPsychic from "./general/BecomeAPsychic";
import AboutAdvia from "./general/AboutAdvia";
import ForgotPassword from "./client/ForgotPassword";

import { GeneralLayout } from "../../layouts/GeneralLayout";
import BecomeAnAffiliate from "./general/BecomeAnAffiliate";
import TermsAndConditions from "./general/TermsAndConditions";
import AffiliatePDF from "./general/AffiliatePDF";
import PrivacyPolicy from "./general/PrivacyPolicy";

function HomePage() {
  const [userToken, setUserToken] = useState(localStorage.getItem("userToken"));
  const [userType, setUserType] = useState(localStorage.getItem("userType"));
  const query = new URLSearchParams(window.location.search).get("is_activated");
  const query2 = new URLSearchParams(window.location.search).get("logged_out");
  const [loginModalShow, setLoginModalShow] = useState(
    query === "true" || query2 === "true"
  );
  const [homePageDetails, setHomePageDetails] = useState(JSON.parse(localStorage.getItem('homePageDetails')));
  const [hideSignUp, setHideSignUp] = useState(true);

  const check403Error = (errorMessage) => {
    if (
      errorMessage &&
      errorMessage.response &&
      errorMessage.response.status && (
        parseInt(errorMessage.response.status) === 403 ||
        parseInt(errorMessage.response.status) === 404 ||
        parseInt(errorMessage.response.status) === 401)
    ) {
      if (parseInt(errorMessage.response.status) === 404 || parseInt(errorMessage.response.status) === 401) {
        console.log("clear all storage - 404 error");
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/?logged_out=true";
      } else {
        //403
        if (errorMessage.response.data.status === "error") {
          Popup.create({
            title: "Error",
            content:
              errorMessage.response.data.message !== ""
                ? errorMessage.response.data.message
                : "An error has occured!",
            buttons: {},
          });
        }
      }
    }
  };


  function RouteWrapper({
    component: Component,
    ...rest
  }) {
    return (
      <Route {...rest} render={(props) => {
        const layoutProps = {
          children: props,
          setLoginModalShow: setLoginModalShow,
          userToken: userToken,
          categories: homePageDetails?.categories ?? [],
          setUserToken: setUserToken,
          setUserType: setUserType,
          loginModalShow: loginModalShow,
          check403Error: check403Error,
          hideSignUp: hideSignUp,
          setHideSignUp: setHideSignUp,
          userType: userType,
          userEmail: localStorage.getItem("userEmail")
        }

        return (
          <GeneralLayout {...layoutProps}>
            <Component setHomePageDetails={setHomePageDetails} homePageDetails={homePageDetails} categories={homePageDetails?.categories ?? []} setLoginModalShow={setLoginModalShow} setHideSignUp={setHideSignUp} check403Error={check403Error} />
          </GeneralLayout>
        )
      }
      } />
    );
  }

  return (
    <>
      <Popup />
      <Router>
        <Switch>
          {(myAppConfig.VERSION_FOR_PRODUCTION && !userToken) ||
            (myAppConfig.VERSION_FOR_PRODUCTION && parseInt(userType) === myAppConfig.USER_TYPES.CLIENT)
            ?
            <RouteWrapper
              exact
              path="/"
              component={BecomeAPsychic}
            />
            :
            <RouteWrapper
              exact
              path="/become-a-psychic"
              component={BecomeAPsychic}
            />
          }
          <RouteWrapper
            exact
            path="/become-an-affiliate"
            component={BecomeAnAffiliate}
          />
          <RouteWrapper
            exact
            path='/about-advia'
            component={AboutAdvia}
          />
          <RouteWrapper
            exact
            path='/faq'
            component={FAQ}
          />
          {myAppConfig.VERSION_FOR_PRODUCTION ?
            <RouteWrapper
              exact
              path="/forgot-password"
              component={ForgotPassword}
            />
            : null}
          <RouteWrapper
            path="/terms-and-conditions"
            component={TermsAndConditions}
          />
          <RouteWrapper
            exact
            path="/privacy-policy"
            component={PrivacyPolicy}
          />
          <RouteWrapper
            exact
            path="/affiliate-pdf"
            component={AffiliatePDF}
          />
          <Route path='/'>
            {parseInt(userType) === myAppConfig.USER_TYPES.ADVISOR ? (
              <AdminRouter
                check403Error={check403Error}
                userToken={userToken}
                setLoginModalShow={setLoginModalShow}
                setUserToken={setUserToken}
                setUserType={setUserType}
                loginModalShow={loginModalShow}
                hideSignUp={hideSignUp}
                setHideSignUp={setHideSignUp}
                userType={userType}
                homePageDetails={homePageDetails}
                setHomePageDetails={setHomePageDetails}
              />
            ) : parseInt(userType) === myAppConfig.USER_TYPES.SUPERADMIN ? (
              <SuperAdminRouter
                check403Error={check403Error}
                homePageDetails={homePageDetails}
                setHomePageDetails={setHomePageDetails}
              />
            ) :
              !myAppConfig.VERSION_FOR_PRODUCTION ? (
                <ClientRouter
                  check403Error={check403Error}
                  homePageDetails={homePageDetails}
                  loginModalShow={loginModalShow}
                  setLoginModalShow={setLoginModalShow}
                  userToken={userToken}
                  setUserToken={setUserToken}
                  setUserType={setUserType}
                  setHomePageDetails={setHomePageDetails}
                />
              )
                : <Redirect to='/' />}
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default HomePage;