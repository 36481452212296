import { useState } from "react";
import { Row, Col, InputGroup, FormControl, Card } from "react-bootstrap";
import { minChatPrice, minCallPrice, minVideoPrice } from '../../constants/AppConstants'
import PromotionsModal from "../modals/PromotionsModal";

const Promotions = (props) => {
  const {
    freeMin,
    setFreeMin,
    freeMinStatus,
    setFreeMinStatus,
    saleOffer,
    setSaleOffer,
    saleOfferStatus,
    setSaleOfferStatus,
    saveUserDetails,
    chatPrice,
    callPrice,
    videoPrice
  } = props;

  const [invalidSaleOffer, setInvalidSaleOffer] = useState(false);
  const [invalidFreeMinutes, setInvalidFreeMinutes] = useState(false);
  const [invalidSaleOffer1, setInvalidSaleOffer1] = useState(false);
  const [invalidSaleOffer2, setInvalidSaleOffer2] = useState(false);
  const [invalidSaleOffer3, setInvalidSaleOffer3] = useState(false);
  const [invalidSaleOffer4, setInvalidSaleOffer4] = useState(false);
  const [invalidFreeMinutes1, setInvalidFreeMinutes1] = useState(false);
  const [showPromotionsModal, setShowPromotionsModal] = useState(false);
  const [wantToSelect, setWantToSelect] = useState(0);
  const maxFreeMins = 5;
  const maxDiscount = 50;

  async function changeFreeMinStatus() {
    if (saleOfferStatus === 1 && (1 - freeMinStatus) === 1) {
      setWantToSelect(0);
      setShowPromotionsModal(true);
      return;
    }
    if (saveUserDetails) {
      const formData = new FormData();
      formData.append("freeminutes_status", 1 - freeMinStatus);
      await saveUserDetails(formData);
    }
    setFreeMinStatus(1 - freeMinStatus);
  }

  async function changeFreeMin(ev) {
    let integerNumber = /^[0]|([1-9][0-9]*)$/;
    if (ev.target.value && !integerNumber.test(ev.target.value)) {
      setInvalidFreeMinutes(true);
    } else {
      setInvalidFreeMinutes(false);
      if (ev.target.value && ev.target.value > maxFreeMins) {
        setInvalidFreeMinutes1(true)
      }
      else {
        setInvalidFreeMinutes1(false);
      setFreeMin(ev.target.value);
      }
    }
  }

  async function changeSaleOfferStatus() {
    if (freeMinStatus === 1 && (1 - saleOfferStatus) === 1) {
      setWantToSelect(1);
      setShowPromotionsModal(true);
      return;
    }
    if (saveUserDetails) {
      const formData = new FormData();
      formData.append("saleoffer_status", 1 - saleOfferStatus);
      await saveUserDetails(formData);
    }
    setSaleOfferStatus(1 - saleOfferStatus);
  }

  async function changeSaleOffer(ev) {
    let integerNumber = /^[0]|([1-9][0-9]*)$/;
    if (ev.target.value && !integerNumber.test(ev.target.value)) {
      setInvalidSaleOffer(true);
    } else {
      setInvalidSaleOffer(false);
      if (ev.target.value && ev.target.value > maxDiscount) {
        setInvalidSaleOffer1(true)
      }
      else {
        setInvalidSaleOffer1(false);
        if (ev.target.value) {
          if (callPrice && (((100 - ev.target.value) / 100) * callPrice < minCallPrice)) {
            setInvalidSaleOffer2(true)
          }
          else {
            setInvalidSaleOffer2(false)
          }
          if (chatPrice && (((100 - ev.target.value) / 100) * chatPrice < minChatPrice)) {
            setInvalidSaleOffer3(true)
          }
          else {
            setInvalidSaleOffer3(false)
          }
          if (videoPrice && (((100 - ev.target.value) / 100) * videoPrice < minVideoPrice)) {
            setInvalidSaleOffer4(true)
          }
          else {
            setInvalidSaleOffer4(false)
          }
        }
        else {
          setInvalidSaleOffer2(false);
          setInvalidSaleOffer3(false);
          setInvalidSaleOffer4(false);
        }
      setSaleOffer(ev.target.value);
      }
    }
  }
  const saveData = async e => {
        const formData = new FormData();
    formData.append("saleoffer_value", saleOffer ? saleOffer : 0);
    formData.append("freeminutes_value", freeMin ? freeMin : 0);
        await saveUserDetails(formData);
      }

  return (
    <>
      <PromotionsModal wantToSelect={wantToSelect} show={showPromotionsModal} setShow={setShowPromotionsModal} saveUserDetails={saveUserDetails} setSaleOfferStatus={setSaleOfferStatus} setFreeMinStatus={setFreeMinStatus} />
    <Card className="card-custom custom-padding promotions">
      <p className="custom-text-style-24-34">Promotions</p>
      <p className="custom-text-style-16-22">
        We recommend to provide sale option to your retain clients to increase
        their future sessions
      </p>
      <Row>
        <Col sm={5} style={{ width: "40%" }}>
          <p className="text-style">Free Minutes</p>
        </Col>
        <Col sm={3} style={{ width: "30%" }}>
          <InputGroup>
            <FormControl
              className={(invalidFreeMinutes || invalidFreeMinutes1) ? "invalid" : ""}
              defaultValue={freeMin}
              aria-label="Username"
              aria-describedby="basic-addon1"
              onKeyUp={setFreeMin ? changeFreeMin : null}
              disabled={setFreeMin ? false : true}
              onBlur={saveUserDetails ? saveData : null}
            />
          </InputGroup>
        </Col>
        <Col
          sm={4}
          className="switch-container-direction"
          style={{ width: "30%" }}
        >
          <div className="toogle-on-off">
            <label className="switch">
              <input
                type="checkbox"
                id="togBtn"
                checked={freeMinStatus === 0 ? true : false}
                  onChange={setFreeMinStatus ? changeFreeMinStatus : null}
                disabled={setFreeMinStatus ? false : true}
              />
              <div className="slider round" style={setFreeMinStatus ? {} : { cursor: 'initial' }}></div>
            </label>
          </div>
        </Col>
      </Row>

      <Row className="custom-margin">
        <Col sm={5} style={{ width: "40%" }}>
          <p className="text-style">Sale Offer</p>
          <p className="sm-info-text">*Up to 30%</p>
        </Col>
        <Col sm={3} style={{ width: "30%" }}>
          <InputGroup>
            <FormControl
              className={(invalidSaleOffer || invalidSaleOffer1 || invalidSaleOffer2 || invalidSaleOffer3 || invalidSaleOffer4) ? "invalid" : ""}
              defaultValue={saleOffer}
              aria-label="Username"
              aria-describedby="basic-addon1"
              onKeyUp={setSaleOffer ? changeSaleOffer : null}
              disabled={setSaleOffer ? false : true}
              onBlur={saveUserDetails ? saveData : null}
            /><span>%</span>
          </InputGroup>
        </Col>
        <Col
          sm={4}
          className="switch-container-direction"
          style={{ width: "30%" }}
        >
          <div className="toogle-on-off">
            <label className="switch">
              <input
                type="checkbox"
                id="togBtn"
                checked={saleOfferStatus === 0 ? true : false}
                onChange={setSaleOfferStatus ? changeSaleOfferStatus : null}
                disabled={setSaleOfferStatus ? false : true}
              />
              <div className="slider round" style={setSaleOfferStatus ? {} : { cursor: 'initial' }}></div>
            </label>
          </div>
        </Col>
      </Row>
      <Row className='invalid mb-0'>
        {invalidFreeMinutes ? '* Invalid free minutes format. Required format example: 3.' : invalidSaleOffer ? 'Invalid sale offer format. Required format example: 10.' : ''}
        {(invalidFreeMinutes || invalidSaleOffer) ? <br /> : null}
        {invalidFreeMinutes1 ? ('* If you want to set the free minutes promotion, you can offer a maximum of ' + maxFreeMins + ' free minutes') : ''}
        {invalidFreeMinutes1 ? <br /> : null}
        {invalidSaleOffer1 ? ('* If you want to set the sale offer promotion, you can enter a maximum discount of ' + maxDiscount + '%') : ''}
        {invalidSaleOffer1 ? <br /> : null}
        {invalidSaleOffer2 ? ('* Should you set this discount, your call price will be less than the minimum call price') : ''}
        {invalidSaleOffer2 ? <br /> : null}
        {invalidSaleOffer3 ? ('* Should you set this discount, your chat price will be less than the minimum chat price') : ''}
        {invalidSaleOffer3 ? <br /> : null}
        {invalidSaleOffer4 ? ('* Should you set this discount, your video price will be less than the minimum video price') : ''}
      </Row>
    </Card>
    </>
  );
};
export default Promotions;
