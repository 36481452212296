
import { Card } from "react-bootstrap"
import { imagesApiURL } from "../../constants/AppConstants"
import uploadPhoto from '../../assets/icons/upload_photo_icon.png';
import ImageCropper from "../ImageCropper";
import { useState } from "react";
import { isIOS } from "react-device-detect";

const LogoImage = (props) => {

  const { image, setImage, saveUserDetails } = props;
  const [showCropper, setShowCropper] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  const imageChange = async e => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file)

      setImageSrc(imageDataUrl);
      if (!isIOS) {
      setShowCropper(true);
      }
      else {
        setImage({
          preview: URL.createObjectURL(file),
          raw: file
        });
        if (saveUserDetails) {
          const formData = new FormData();
          formData.append("logo_image", file);
          await saveUserDetails(formData)
        }
      }
    }
  };
  return (
    <>
      {!isIOS ?
      <ImageCropper show={showCropper} setShow={setShowCropper} imageSrc={imageSrc} setImage={setImage} saveUserDetails={saveUserDetails ? saveUserDetails : null} />
        : null}
      <Card className="card-custom" style={{ width: '100%' }}>
        {!image ? <div /> :
          image.preview ?
            <Card.Img src={image.preview} style={{ height: '100%' }} /> :
            <Card.Img src={imagesApiURL + image} style={{ height: '100%' }} />
        }
        <div style={{ height: '0px' }}>
          <label htmlFor="upload-button-img">
            <Card.Img src={uploadPhoto} className="overlay-img" />
          </label>
          <input
            type="file"
            id="upload-button-img"
            style={{ display: "none" }}
            onChange={imageChange}
          />
        </div>
      </Card>
    </>
  )
}

export default LogoImage