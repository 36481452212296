import React from "react";
import "../styles/emptyScreen.scss";
import "../styles/default.css";
import imgIllustration from "../assets/img/empty_illustration.png";
import * as myAppConfig from "../constants/AppConstants";

const EmptyScreen = (props) => {
  const onButtonClick = () => {
    if (props.data === myAppConfig.EMPTY_POPUP_DETAILS.CHAT) {
    } else if (props.data === myAppConfig.EMPTY_POPUP_DETAILS.SAVED) {
      window.location.href = "/category/";
    }
  };
  return (
    <div className="empty-screen-container">
      <img src={imgIllustration} className="img-illustration" alt='illustration' />

      <p className="description">{props.data.description}</p>

      <div className="btn-explore" onClick={() => onButtonClick()}>
        {props.data.btnText}
      </div>
    </div>
  );
};

export default EmptyScreen;
