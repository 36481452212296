import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import * as myAppConfig from "../../../constants/AppConstants";
import "../../../styles/callScreen.scss";
import EndSessionModal from "../../../components/modals/EndSessionModal";
import ThankYouScreen from "../../../components/ThankYouScreen";
import CallComponent from "../../../components/chat/CallComponent";
import AccountManageActionModal from "../../../components/modals/AccountManageActionModal";
import * as utils from "../../../Utilities/Utils";
import * as gaEcommerce from "../../../Utilities/GAEcommerce";
import { useTimer } from "reactjs-countdown-hook";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { MobileChatComponentLayout } from "../../../layouts/MobileChatComponentLayout";
import Div100vh from "react-div-100vh";
import { Col } from "react-bootstrap";
import ClientSessionsNotes from "../../../components/chat/ClientSessionsNotes";
//import ReactGA from "react-ga";
import Popup from "react-popup";

const { Device } = require("twilio-client");
const { isSupported } = require("twilio-video");

const CallScreen = (props) => {
  const { check403Error } = props;

  const userType = parseInt(localStorage.getItem("userType"));
  const isClient = userType === myAppConfig.USER_TYPES.CLIENT;
  const isMounted = React.useRef(true);
  const device = new Device();
  let history = useHistory();

  const [token, setToken] = useState("");
  var callSid = "";

  const otherTalkerInfo = sessionStorage.getItem("otherTalker")
    ? JSON.parse(sessionStorage.getItem("otherTalker"))
    : [];
  const otherTalkerId = otherTalkerInfo["id"] ? otherTalkerInfo["id"] : "";
  const otherTalkerName = otherTalkerInfo["name"]
    ? otherTalkerInfo["name"]
    : "";
  const otherTalkerLogoImage = otherTalkerInfo["logo_image"]
    ? otherTalkerInfo["logo_image"]
    : "";
  const otherTalkerCategory = otherTalkerInfo["category"]
    ? otherTalkerInfo["category"]
    : "";

  const friendly_name = otherTalkerInfo["friendly_name"]
    ? otherTalkerInfo["friendly_name"]
    : "";

  const [callInfo, setCallInfo] = useState({
    uniqueName: "",
    friendlyName: "",
    advisorfriendlyName: "",
  });

  const [deviceInfo, setDeviceInfo] = useState({
    identity: "",
    status: "",
    ready: false,
  });

  const [call, setCall] = useState();

  const [showEndSession, setShowEndSession] = useState(false);
  const [isConversationDone, setConversationDone] = useState(false);
  const [isSessionEnded, setSessionEnded] = useState(true);

  const [showAccountManageModal, setShowAccountActionModal] = useState(false);
  const [accountActionType, setAccountActionType] = useState("");

  const [otherJoined, setOtherJoined] = useState(false);

  //Advisor sessions & notes
  const [notes, setNotes] = useState("");
  const [clientSessions, setClientSessions] = useState(null);
  const [showClientSessions, setShowClientSessions] = useState(false);

  var timerForCheckAdvisorResponse;
  var timerForContinueWithConversation;
  var timerForNotResponding;
  var timerForActiveConversation;

  const [isNotResponding, setIsNotResponding] = useState(false);

  const [outsideTarget, setOutSideTarget] = useState(null);
  const currentScreenConversation = useRef();
  const handleOutsideClick = (event) => {
    if (
      currentScreenConversation &&
      currentScreenConversation.current !== null &&
      !currentScreenConversation.current.contains(event.target)
    ) {
      if (
        document.querySelector(".modal") === null //&& event.target.pathname !== undefined
      ) {
        console.log("handleOutsideClick");
        setOutSideTarget(event.target);
        setShowEndSession(true);
      }
    }
  };

  const [isTimerForContinueConversation, setIsTimerForContinueConversation] =
    useState(false);

  const conversationResponseSeconds = 60;
  useTimer(conversationResponseSeconds, clearWaitingTimeForAdvisorResponse);

  function clearWaitingTimeForAdvisorResponse() {
    if (isClient) {
      let callInvitation = sessionStorage.getItem("call-invitation");

      if (utils.invitationResponse(callInvitation)) {
        setIsTimerForContinueConversation(true);

        setAccountActionType(
          utils.getAdvisorIsNotRespondingMessage(otherTalkerName)
        );
        setShowAccountActionModal(true);
      }
    }
  }

  useEffect(() => {
    if (isTimerForContinueConversation) {
      timerForContinueWithConversation = window.setTimeout(
        () => onCancelCall(),
        60 * 1000
      );
    } else {
      clearTimeout(timerForContinueWithConversation);
    }

    return () => clearTimeout(timerForContinueWithConversation);
  }, [isTimerForContinueConversation]);

  useEffect(() => {
    if (isNotResponding) {
      timerForNotResponding = setTimeout(() => onCancelCall(), 60 * 1000);
    } else {
      clearTimeout(timerForNotResponding);
    }

    return () => clearTimeout(timerForNotResponding);
  }, [isNotResponding]);

  useEffect(() => {
    async function createConversation() {
      console.log("create conversation ");
      sessionStorage.setItem("call-invitation", undefined);
      sessionStorage.setItem(myAppConfig.isConversationCancelled, false);

      let convFormData = utils.getCreateConversationParameters(
        otherTalkerId,
        myAppConfig.CONVERSATION_TYPE.VOICE.id
      );

      console.log(
        "calling create conversation for call: " +
          myAppConfig.CONVERSATION_TYPE.VOICE.id
      );
      axios
        .post(myAppConfig.CREATE_CONVERSATION, convFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: localStorage.getItem("userToken"),
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            if (response.data.payUrl) {
              utils.redirectToAddCard(
                response.data,
                myAppConfig.CONVERSATION_TYPE.VOICE.name
              );
            } else {
              console.log("conversation_id: " + response.data.conversation_id);
              const uniqueName = response.data.conversation_id;
              const friendlyName = response.data.channelfriendlyName;
              const advisorfriendlyName = response.data.advisorfriendlyName;

              setCallInfo({
                uniqueName: uniqueName,
                friendlyName: friendlyName,
                advisorfriendlyName: advisorfriendlyName,
              });

              const fetchTasks = utils.getChatToken(
                myAppConfig.CONVERSATION_TYPE.VOICE.name
              );

              Promise.all([fetchTasks])
                .then(function (res) {
                  const response = res[0];
                  if (response.data.status === "success") {
                    setToken(response.data.token);
                  } else {
                    console.log(response.data);
                  }
                })
                .catch((error) => {
                  console.log("error on fetchTasks: " + error);
                });
            }
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log("error create conv");
          check403Error(error);
        });
    }
    if (isMounted.current) {
      if (isClient) {
        if (isSupported) {
          createConversation();
        } else {
          alert("This browser is not supported by Twilio Voice");
          onCancelCall();
        }
      } else {
        checkConversationStatus();

        getClientNotes();
        getSessionsList();
      }
    }

    return () => {
      isMounted.current = false;
      console.log("return isMounted: " + isMounted.current);
      clearTimeout(timerForActiveConversation);
      utils.setCurrentConversationAccepted("");
    };
  }, []);

  useEffect(() => {
    async function setupDeviceForCall() {
      if (token !== "") {
        deviceSetupToken(device);

        device.on("incoming", (connection) => {
          // immediately accepts incoming connection
          console.log("incoming: ", connection.status());

          connection.accept();
          setDeviceInfo({ ...deviceInfo, status: connection.status() });

          setCall(connection);
        });

        device.on("cancel", (connection) => {
          console.log("cancel s: ", connection.status());
        });

        device.on("ready", (_device) => {
          console.log("device ready");
          setDeviceInfo({ ...deviceInfo, status: "device ready", ready: true });

          if (isClient) checkCallInvitationResponse();
          else connectToCall();
        });

        device.on("connect", (connection) => {
          console.log("on device connect: ", connection.status());
          setDeviceInfo({ ...deviceInfo, status: connection.status() });

          if (isClient) {
            if (connection.parameters.CallSid) {
              callSid = connection.parameters.CallSid;
            }

            sessionStorage.setItem("free-minutes", 0);
            startOrEndConversation(true, callInfo.uniqueName);
          } else {
            document.addEventListener("mousedown", handleOutsideClick);
            setOtherJoined(true);
            checkActiveConversation(utils.getCurrentConversationAccepted());
          }
        });

        device.on("disconnect", (connection) => {
          console.log("device on disconnect");
          clearTimeout(timerForActiveConversation);
          utils.setCurrentConversationAccepted("");
          console.log("on device disconnect: ", connection.status());
          setDeviceInfo({ ...deviceInfo, status: connection.status() });

          if (isClient) {
            startOrEndConversation(false, callInfo.uniqueName);

            //setConversationDone(true);
            setShowEndSession(false);
          } else {
            document.removeEventListener("mousedown", handleOutsideClick);

            if (outsideTarget === null) {
              history.replace("/");

              //INCOMING
              if (connection.sendHangup === false) {
                Popup.create({
                  title: "End session",
                  content: otherTalkerName + " has left the voice call",
                });
              }
            } else outsideTarget.click();
          }

          device.destroy();
          device.disconnectAll();
          console.log("device destroyed and disconnected");
        });
      }
    }

    if (token !== "") {
      setupDeviceForCall();
    }
  }, [token]);

  const checkCallInvitationResponse = () => {
    if (isClient) {
      let isConversationCancelled = sessionStorage.getItem(
        myAppConfig.isConversationCancelled
      );
      console.log("isConversationCancelled: ", isConversationCancelled);
      if (
        isConversationCancelled === true ||
        isConversationCancelled === "true"
      ) {
        return;
      }

      let invitation = sessionStorage.getItem("call-invitation");

      if (utils.invitationResponse(invitation)) {
        console.log("Waiting call invitation response: " + invitation);
        clearTimeout(timerForCheckAdvisorResponse);
        timerForCheckAdvisorResponse = setTimeout(
          () => checkCallInvitationResponse(),
          1000
        );
      } else if (
        Number(invitation) ===
        Number(myAppConfig.NOTIFICATION_TYPE.accept_request)
      ) {
        setIsNotResponding(false);
        setShowEndSession(false);
        setShowAccountActionModal(false);
        setIsTimerForContinueConversation(false);

        console.log("Accepted call invitation: " + invitation);
        //connectToCall();
      } else if (
        Number(invitation) ===
        Number(myAppConfig.NOTIFICATION_TYPE.reject_request)
      ) {
        let message = utils.displayErrorMessage(
          "Sorry",
          utils.getAdvisorIsBusyMessage(otherTalkerName)
        );
        setAccountActionType(message);
        setShowAccountActionModal(true);
      } else {
        console.log("here I am");
      }
    }
  };

  function deviceSetupToken(_device) {
    if (token !== "") {
      console.log("deviceSetupToken ");
      device.setup(token);
      device.audio.incoming(false);
      device.audio.outgoing(false);
      device.audio.disconnect(false);
    }
  }

  async function connectToCall() {
    console.log("Attempting to call: " + friendly_name); //callInfo.advisorfriendlyName);

    if (friendly_name !== ""){
      const call = await device.connect({ phone: friendly_name }); //callInfo.advisorfriendlyName });
      // add listeners to the Call
      // "accepted" means the call has finished connecting and the state is now "open"
      call.addListener("accept", updateUIAcceptedCall);
      call.addListener("disconnect", updateUIDisconnectedCall);
    }
  }

  function updateUIAcceptedCall(call) {
    console.log("Call in progress ...");
    setDeviceInfo({ ...deviceInfo, status: "Call in progress ..." });

    setCall(call);
  }

  function updateUIDisconnectedCall() {
    console.log("Call disconnected.");
    setDeviceInfo({ ...deviceInfo, status: "Call disconnected." });
  }

  function disconnectFromCall() {
    if (call !== undefined) {
      call.disconnect();
    } else {
      history.goBack();
    }
    if (token !== "") {
      device.setup(token);
      device.destroy();
      device.disconnectAll();
      console.log("device disconnected");
    }
  }

  const showModalEndSession = () => {
    setOutSideTarget(null);
    setShowEndSession(true);
  };

  const btnEndSessionClick = () => {
    if (isNotResponding) {
      onCancelCall();
    } else {
      disconnectFromCall();
    }
  };

  const btnContinueConversation = () => {
    setShowEndSession(false);
  };

  const startOrEndConversation = (isStart, conv_id) => {
    if (isStart) {
      setSessionEnded(false);
    } else {
      setSessionEnded(true);
    }

    let formData = utils.getStartOrEndConversationParameters(
      isStart,
      conv_id,
      myAppConfig.CONVERSATION_TYPE.VOICE.id
    );
    formData.append("call_sid", callSid);

    const fetchStartEndConv = utils.callStartOrEndConversation(
      isStart,
      formData
    );

    Promise.all([fetchStartEndConv])
      .then(function (res) {
        const response = res[0];
        if (response.data.status === "success") {
          if (isStart) {
            checkActiveConversation(conv_id);
            sessionStorage.setItem("free-minutes", response.data.free_minutes);
            console.log("free minutes ", response.data.free_minutes);
            setOtherJoined(true);
          } else {
            if (response.data.end_Details) {
              gaEcommerce.gaAddTransaction(response.data.end_Details);
            }
          }
          console.log("success " + (isStart ? " started conv" : " ended conv"));
        } else {
          console.log(response.data);
        }

        if (!isStart) {
          setConversationDone(true);
          setShowEndSession(false);
        }
      })
      .catch((error) => {
        console.log("error on fetchStartEndConv: " + error);
        check403Error(error);

        if (!isStart) {
          setConversationDone(true);
          setShowEndSession(false);
        }
      });
  };

  const onAccountActionBtnClick = () => {
    const showHomepage = isTimerForContinueConversation;
    setIsTimerForContinueConversation(false);
    setShowAccountActionModal(false);

    if (token !== "") {
      device.setup(token);
      device.disconnectAll();
      console.log("device disconnected");
    }

    if (!showHomepage) {
      history.replace("/");
    }
  };

  const handleEndSession = () => {
    if (!isSessionEnded) {
      startOrEndConversation(
        false,
        isClient ? callInfo.uniqueName : utils.getCurrentConversationAccepted()
      );
    }
  };

  useEffect(() => {
    const unlockStory = (e) => {
      if (!isSessionEnded) {
        handleEndSession();
        e.preventDefault();
        e.returnValue = "Are you sure you want to leave this page?";
      }
    };

    // Add event listener to run code before window closes
    window.addEventListener("beforeunload", unlockStory);
    window.addEventListener("pagehide", unlockStory);

    return () => {
      window.removeEventListener("beforeunload", unlockStory);
      window.removeEventListener("pagehide", unlockStory);
    };
  }, [isSessionEnded]);

  function onCancelCall() {
    sessionStorage.setItem(myAppConfig.isConversationCancelled, true);
    console.log("cancel the call conv_id:", callInfo.uniqueName);

    if (callInfo.uniqueName !== "") {
      const fetchCancelConversationTask = utils.cancelConversation(
        callInfo.uniqueName
      );
      Promise.all([fetchCancelConversationTask])
        .then(function (res) {
          disconnectFromCall();
        })
        .catch((error) => {
          disconnectFromCall();
        });
    } else {
      disconnectFromCall();
    }

    if (!isClient) {
      utils.setCurrentConversationAccepted("");
    }

    clearTimeout(timerForActiveConversation);
    clearTimeout(timerForCheckAdvisorResponse);
  }

  const checkActiveConversation = (conversation_id) => {
    if (window.location.pathname === "/call") {
      console.log("checkActiveConversation", conversation_id);
      axios
        .get(myAppConfig.CHECK_ACTIVE_CONVERSATION, {
          params: {
            conversation_id: conversation_id,
            from: isClient ? 2 : 1, //1.advisor //2 client
          },
          headers: {
            token: localStorage.getItem("userToken"),
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            console.log("response get active conversation");
            timerForActiveConversation = setTimeout(
              () => checkActiveConversation(conversation_id),
              myAppConfig.check_active_conversation_time
            );
          } else {
            clearTimeout(timerForActiveConversation);

            let message = utils.displayErrorMessage(
              "Error",
              response.data.message !== ""
                ? response.data.message
                : "An error has occured!"
            );
            setAccountActionType(message);
            setShowAccountActionModal(true);
          }
        })
        .catch((err) => {
          console.log("aici errror");
        });
    } else {
      clearTimeout(timerForActiveConversation);
    }
  };

  function getClientNotes() {
    const fetchTasks = utils.getClientNotes(otherTalkerId);

    Promise.all([fetchTasks])
      .then(function (res) {
        setNotes(res);
      })
      .catch((error) => {
        console.log("error on fetchTasks: " + error);
      });
  }

  function getSessionsList() {
    const fetchTasks = utils.getSessionsList(otherTalkerId);

    Promise.all([fetchTasks])
      .then(function (res) {
        if (res.length > 0) {
          setClientSessions(res[0]);
        }
      })
      .catch((error) => {
        console.log("error on fetchTasks: " + error);
      });
  }

  const setClientSessionsList = () => {
    setShowClientSessions(!showClientSessions);
  };

  function checkConversationStatus() {
    if (parseInt(utils.getCurrentConversationAccepted()) > -1) {
      const fetchTasks = utils.checkConversationStatus(
        utils.getCurrentConversationAccepted(),
        myAppConfig.CONVERSATION_TYPE.VOICE.id
      );

      Promise.all([fetchTasks])
        .then(function (res) {
          if (res.length > 0) {
            if (res[0] === false) {
              if (document.querySelector(".modal") === null) {
                let message = utils.displayErrorMessage(
                  "Sorry",
                  utils.getCancelConversationMessage(otherTalkerName)
                );
                setAccountActionType(message);
                setShowAccountActionModal(true);
              }
            } else {
              const fetchTasks = utils.getChatToken(
                myAppConfig.CONVERSATION_TYPE.VOICE.name
              );

              Promise.all([fetchTasks])
                .then(function (res) {
                  const response = res[0];
                  if (response.data.status === "success") {
                    setToken(response.data.token);
                  } else {
                    console.log(response.data);
                  }
                })
                .catch((error) => {
                  console.log("error on fetchTasks: " + error);
                  check403Error(error);
                });
            }
          }
        })
        .catch((error) => {
          console.log("error on fetchTasks: " + error);
        });
    } else {
      window.location.href = "/";
    }
  }

  const renderCallComponent = () => {
    return (
      <CallComponent
        deviceStatus={deviceInfo.status}
        otherTalkerName={otherTalkerName}
        otherTalkerLogoImage={otherTalkerLogoImage}
        otherTalkerCategory={otherTalkerCategory}
        showModalEndSession={showModalEndSession}
        otherJoined={otherJoined}
        call={call}
        onCancelCall={onCancelCall}
      />
    );
  };

  return (
    <>
      <AccountManageActionModal
        show={showAccountManageModal}
        setShowAccountActionModal={() => onAccountActionBtnClick()}
        actionType={accountActionType}
        onAccountAction={onAccountActionBtnClick}
        btnText={isTimerForContinueConversation ? "Continue" : "Ok"}
        hideClose={true}
      />

      <EndSessionModal
        show={showEndSession}
        setShowEndSession={() => setShowEndSession(false)}
        btnEndSessionClick={() => btnEndSessionClick()}
        isNotResponding={isNotResponding}
        btnContinueConversation={() => btnContinueConversation()}
      />
      {isConversationDone ? (
        <div>
          <ThankYouScreen
            data={myAppConfig.FINAL_THANK_YOU_MESSAGES.CONVERSATION_ENDED}
            advisorName={localStorage.getItem("userFullName")}
            advisorId={otherTalkerId}
          />
        </div>
      ) : isMobile ? (
        <Div100vh>
          <MobileChatComponentLayout show={true}>
            {renderCallComponent()}
          </MobileChatComponentLayout>
        </Div100vh>
      ) : (
        <div className="callScreen mb-5 pb-5" ref={currentScreenConversation}>
          <Col
            lg={8}
            style={{
              display: "flex",
              justifyContent: isClient ? "center" : "flex-end",
              height: "100%",
              paddingRight: isClient ? "0" : "2%",
            }}
            className="mb-5"
          >
            <div className="call-component-container">
              {renderCallComponent()}
            </div>
          </Col>
          {isClient ? null : (
            <ClientSessionsNotes
              showClientSessions={showClientSessions}
              setClientSessionsList={setClientSessionsList}
              currentConversationIndex={0}
              clientId={otherTalkerId}
              notes={notes}
              setNotes={setNotes}
              clientSessions={clientSessions}
              check403Error={check403Error}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CallScreen;
