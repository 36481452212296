import React, { useState, useEffect } from "react"
import { Row, Col, Container, DropdownButton, Dropdown, Form, FormControl, Table, Card, Spinner } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import '../../../styles/archive.scss';
import '../../../styles/_layouts.scss';
import axios from 'axios'
import * as myAppConfig from "../../../constants/AppConstants";

import PropTypes from 'prop-types';
import icon_dashboard_dropdown_arrow from "../../../assets/icons/arrows/dashboard_dropdown_arrow.png";

import activeAdvisorIcon from '../../../assets/icons/active_advisor_icon.png'
// import blockedAdvisorIcon from '../../../assets/icons/blocked_advisor_icon.png'
import AccountManageActionModal from "../../../components/modals/AccountManageActionModal";

import { isMobileOnly } from "react-device-detect";
import leftArrow from '../../../assets/icons/arrows/arrow_left_icon.png'
import firstArrow from '../../../assets/icons/arrows/arrow_first_icon.png'

const sortByDropDownValues = [
    { id: -1, title: "Sort by" },
    { id: 0, title: "All" },
    { id: 1, title: "New Clients" },
    { id: 2, title: "Return clients" },
    { id: 3, title: "Revenue: Low to High" },
    { id: 4, title: "Revenue: High to Low" }
];

export default function Archive({ check403Error }) {
    const [dataLoading, setDataLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState();

    const [showAccountManageModal, setShowAccountActionModal] = useState(false);
    const [accountActionType, setAccountActionType] = useState("");
    const [sortIndex, setSortIndex] = useState(1);
    const [searchText, setSearchText] = useState();
    const [users, setUsers] = useState();
    const [dropDownTitle, setDropDownTitle] = useState(
        sortByDropDownValues[0].title
    );
    const [shouldReload, setShouldReload] = useState(false);

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [numberOfEntries, setNumberOfEntries] = useState(0);
    const numberOfPages = Math.ceil(numberOfEntries / itemsPerPage);
    const from = (currentPage - 1) * itemsPerPage + 1;
    const to = Math.min(numberOfEntries, currentPage * itemsPerPage)

    const parseData = (data) => {
        var values = [];
        const keys = Object.keys(data);
        keys.forEach(key => {
            values.push(data[key]);
        });
        return values;
    }

    useEffect(() => {
        const style = document.querySelector(
            ".advisor-drop-down-container > .dropdown-toggle"
        ).style;
        style.setProperty(
            "--arrowURL",
            "url(" + icon_dashboard_dropdown_arrow + ")"
        );
        if (!dataLoading) {
            if (searchText === undefined || shouldReload) {
                setDataLoading(true);
            }
            axios
                .get(myAppConfig.GET_LIST_OF_REGISTERED_CLIENTS, {
                    params: {
                        sort_by: sortIndex,
                        search: searchText ? searchText : "",
                        items_per_page: itemsPerPage,
                        current_page: currentPage

                    },
                    headers: {
                        token: localStorage.getItem("userToken"),
                    },
                })
                .then((response) => {
                    if (searchText === undefined || shouldReload) {
                        setDataLoading(false);
                        setShouldReload(false);
                    }
                    //debugger;
                    if (response.data.status === "success") {
                        console.log(response.data);
                        setUsers(response.data.registered_users);
                        setNumberOfEntries(response.data.totalRows);
                    }
                })
                .catch((err) => {
                    //debugger;
                    check403Error(err);
                });
        }
    }, [sortIndex, searchText, check403Error, currentPage, itemsPerPage]);

    const handleSelect = (index) => {
        if (!isNaN(index)) {
            const indexNo = Number(index);
            if (
                indexNo < sortByDropDownValues.length &&
                dropDownTitle !== sortByDropDownValues[indexNo].title
            ) {
                setDropDownTitle(sortByDropDownValues[indexNo].title);
                setSortIndex(sortByDropDownValues[indexNo].id);
                setShouldReload(true);
                setCurrentPage(1);
            }
        }
    };

    const onChangeSearchText = (ev) => {
        let value = ev.target.value;
        setCurrentPage(1);
        setSearchText(value);
    };

    async function block(credentials) {
        axios.post(myAppConfig.BLOCK_CLIENT, credentials, {
            headers: { 'Content-Type': 'multipart/form-data', 'token': localStorage.getItem('userToken') }
        })
            .then(response => {
                if (response.data.status === 'success') {
                    console.log(response.data);
                    window.location.reload();
                }
                else {
                    console.log(response.data);
                }
            }).catch(err => check403Error(err));
    }

    const blockClient = async () => {
        let clientFormData = new FormData();
        clientFormData.append('client_id', selectedUser);
        await block(clientFormData);
    }

    async function unblock(credentials) {
        axios.post(myAppConfig.UNBLOCK_CLIENT, credentials, {
            headers: { 'Content-Type': 'multipart/form-data', 'token': localStorage.getItem('userToken') }
        })
            .then(response => {
                if (response.data.status === 'success') {
                    console.log(response.data);
                    window.location.reload();
                }
                else {
                    console.log(response.data);
                }
            }).catch(err => check403Error(err));
    }

    const unblockClient = async () => {
        let clientFormData = new FormData();
        clientFormData.append('client_id', selectedUser);
        await unblock(clientFormData);
    }

    const onAccountActionBtnClick = async (e) => {
        e.preventDefault();
        if (accountActionType === myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.REACTIVATE_ACCOUNT) {
            unblockClient();
        } else {
            blockClient();
        }
        setShowAccountActionModal(false);
    };

    const accountManageAction = (actionType) => {

        setAccountActionType(actionType);
        setShowAccountActionModal(true);
    };

    const changeCurrentPage = (page) => {
        setCurrentPage(page);
        setShouldReload(true);
    }


    return (
        <>
            <AccountManageActionModal
                show={showAccountManageModal}
                setShowAccountActionModal={() => setShowAccountActionModal(false)}
                actionType={accountActionType}
                onAccountAction={onAccountActionBtnClick}
            />

            <div className="adminProfile py-lg-5 py-md-5 py-sm-3 py-3" id="adminProfilePage">
                <Container fluid style={isMobileOnly ? { width: "91.5%" } : { width: "88.88%" }} className='p-0'>
                    <Row>
                        <Col lg={6} className='px-0'>
                            <p className="title">Registered users</p>
                        </Col>
                        <Col lg={6} className='px-0' style={dataLoading ? { visibility: 'hidden' } : {}}>
                            <Row>
                                <Col lg={6}>
                                    <DropdownButton
                                        className="advisor-drop-down-container advisors-dropdown row"
                                        alignRight
                                        title={dropDownTitle}
                                        id="advisors-dropdown-menu-align-right"
                                        onSelect={handleSelect}
                                    >
                                        {sortByDropDownValues.map((_item, idx) => (
                                            idx > 0 ?
                                                <Dropdown.Item
                                                    key={_item.id}
                                                    className={
                                                        dropDownTitle === _item.title
                                                            ? "active"
                                                            : ""
                                                    }
                                                    eventKey={idx}
                                                >
                                                    {_item.title}
                                                </Dropdown.Item>
                                                : null
                                        ))}
                                    </DropdownButton>
                                </Col>
                                <Col lg={6}>
                                    <Form className="d-flex">
                                        <FormControl
                                            type="search"
                                            placeholder="Search for client..."
                                            className="searchInput"
                                            aria-label="Search"
                                            value={searchText}
                                            onChange={onChangeSearchText}
                                        />
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <br />
                    {dataLoading ?
                        <div style={{ textAlign: 'center' }}>
                            <Spinner animation='border' style={{ color: 'white' }} />
                        </div> :
                        <>
                            <Row className="registered-users">
                                <Table responsive className="registered-users-table">
                                    <thead>
                                        <tr>
                                            <th>Full Name</th>
                                            <th>Client ID</th>
                                            <th>Last Update</th>
                                            <th>Sessions</th>
                                            <th>Income to Date</th>
                                            <th>Rating</th>
                                            <th>Activity</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users && users.length > 0 && users.map ? users.map((user, idx) =>
                                            <tr>
                                                <td><Card.Img className="active-status" src={activeAdvisorIcon} />{user.full_name}</td>
                                                <td>{user.client_id}</td>
                                                <td>{user.last_update ? user.last_update : "-"}</td>
                                                <td>{user.numberOfsessions}</td>
                                                <td>${user.income ? user.income : '0'}</td>
                                                <td>{user.rating ? user.rating.toFixed(2) : '0'}/5</td>
                                                <td><button type="button" className="active-btn" onClick={() => { accountManageAction(myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.FREEZE_ACCOUNT); setSelectedUser(3453223) }}>Active</button></td>
                                                <td><button type="button" className="review-btn" onClick={() =>
                                                    (window.location.href =
                                                    "/station?" +
                                                    (user.last_conversation_type ===
                                                    myAppConfig.CONVERSATION_TYPE.CHAT.id
                                                        ? myAppConfig.CONVERSATION_TYPE.CHAT.name
                                                        : myAppConfig.CONVERSATION_TYPE.VOICE.id
                                                        ? myAppConfig.CONVERSATION_TYPE.VOICE.name
                                                        : myAppConfig.CONVERSATION_TYPE.VIDEO.name) +
                                                    "/" +
                                                    user.last_conversation_id)
                                                }>Review</button></td>
                                            </tr>
                                        ) : null}
                                    </tbody>
                                </Table>
                            </Row>
                            <div className='archivePagination mt-4 mb-4'>
                                <div>
                                    {'Showing ' + from + ' to ' + to + ' of ' + numberOfEntries + ' entries'}
                                </div>
                                <div className='d-flex'>
                                    <div className='itemsPerPage me-4'>
                                        <span className='me-2'>Items per page: </span>
                                        <select name="items" id="items" onChange={(e) => { setCurrentPage(1); setItemsPerPage(parseInt(e.target.value)); setShouldReload(true); }} defaultValue={itemsPerPage}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">50</option>
                                        </select>
                                    </div>
                                    <div className='pages'>
                                        <div className={'pageNumber first ' + (currentPage === 1 ? 'disabled' : '')} onClick={() => changeCurrentPage(1)}><img alt='arrow' src={firstArrow} /></div>
                                        <div className={'pageNumber prev ' + (currentPage === 1 ? 'disabled' : '')} onClick={() => changeCurrentPage(currentPage - 1)}><img alt='arrow' src={leftArrow} /></div>
                                        {currentPage > 1 ?
                                            <>
                                                {currentPage > 2 ?
                                                    <div className='pageNumber dots'>...</div>
                                                    : null
                                                }
                                                <div className='pageNumber' onClick={() => changeCurrentPage(currentPage - 1)}>{currentPage - 1}</div>
                                            </>
                                            : null}
                                        <div className='pageNumber active'>{currentPage}</div>
                                        {currentPage < numberOfPages ?
                                            <>
                                                <div className='pageNumber' onClick={() => changeCurrentPage(currentPage + 1)}>{currentPage + 1}</div>
                                                {numberOfPages > currentPage + 1 ?
                                                    <div className='pageNumber dots'>...</div>
                                                    : null
                                                }
                                            </>
                                            : null}
                                        <div className={'pageNumber next ' + (currentPage === numberOfPages ? 'disabled' : '')} onClick={() => changeCurrentPage(currentPage + 1)}><img alt='arrow' src={leftArrow} /></div>
                                        <div className={'pageNumber last ' + (currentPage === numberOfPages ? 'disabled' : '')} onClick={() => changeCurrentPage(numberOfPages)}><img alt='arrow' src={firstArrow} /></div>
                                    </div>
                                </div>
                            </div>
                        </>}
                </Container>
            </div>
        </>
    )
}
Archive.propTypes = {
    check403Error: PropTypes.func
}
