import React from 'react'
import { Row, Col } from 'react-bootstrap';
import '../../styles/categories.scss';
import { imagesApiURL } from '../../constants/AppConstants';
import { isMobileOnly } from 'react-device-detect';
import CategoriesMobileSlider from './CategoriesMobileSlider';

function Categories(props) {

    const { categories } = props;

    const categories_items = categories.map((category, idx) =>
        <Col lg={3} key={category.id} className="mb-4">
            <a href={"/category/" + category.title} style={{ textDecoration: 'none' }} >
                <div className="categ_item push-on-hover" style={{ backgroundImage: "url(" + imagesApiURL + category.background_image + ")" }}>
                    <img src={imagesApiURL + category.icon} alt="categ_icon" />
                    <div className="title_categ">{category.title}</div>
                </div>
            </a>
        </Col>
    );

    return (
        <div className={isMobileOnly ? "pt-0 advisors_wrapper" : "pt-5 pb-5 advisors_wrapper"} style={isMobileOnly ? { overflow: "hidden", paddingBottom: '40px' } : {}}>
            <h2 className="component_title mb-5">Categories</h2>
            {!isMobileOnly ?
                <Row>
                    {categories_items}
                </Row>
                :
                <CategoriesMobileSlider categories={categories} />
            }
        </div>
    )
}

export default Categories
