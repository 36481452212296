import React, { useState, useEffect } from "react";
import { Row, Card, Form, Button, Col } from 'react-bootstrap';
import styles from '../../../styles/clients.module.scss';
import { imagesApiURL } from "../../../constants/AppConstants";
import { useHistory } from "react-router-dom";
import noImage from '../../../assets/img/no_image.png';
import axios from "axios";
import { isDesktop, isIOS, isAndroid } from "react-device-detect";
import * as Icon from 'react-feather'
import ProfileUpdated from "../../../components/advisorProfile/ProfileUpdated";
import * as myAppConfig from '../../../constants/AppConstants'
import ImageCropper from "../../../components/ImageCropper";
import UploadingModal from "../../../components/modals/UploadingModal";

export default function SuperAdminProfile(props) {

    const { check403Error } = props;
    let history = useHistory();
    const [image, setImage] = useState(noImage);
    const [name, setName] = useState('');
    const email = localStorage.getItem('userEmail');
    const password = localStorage.getItem('userPassword');
    const [showProfileUpdatedMessage, setShowProfileUpdatedMessage] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [showPass, setShowPass] = useState(false);

    const [showCropper, setShowCropper] = useState(false);
    const [imageSrc, setImageSrc] = useState("");

    useEffect(() => {
        if (!dataLoading) {
            setDataLoading(true);
            axios.get(myAppConfig.GET_MY_PROFILE, {
                headers: { 'Content-Type': 'multipart/form-data', 'token': localStorage.getItem('userToken') }
            }).then(response => {
                if (response.data.status === 'success' && response.data.profile_details.length !== 0) {
                    let profileDetails = response.data.profile_details[0];
                    setName(profileDetails.full_name ? profileDetails.full_name : response.data.user[0].name);
                    if (profileDetails.logo_image) setImage(imagesApiURL + profileDetails.logo_image);
                }
                else {
                    console.log(response.data);
                }
            }).catch(err => check403Error(err));
        }
    }, [dataLoading, check403Error]);

    function changeName(ev) {
        setName(ev.target.value);
    }

    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    const fileChange = async e => {
        if (e.target.files.length) {
            const file = e.target.files[0];
            let imageDataUrl = await readFile(file)

            setImageSrc(imageDataUrl);
            if (!isIOS) {
            setShowCropper(true);
            }
            else {
                setImage({
                    preview: URL.createObjectURL(file),
                    raw: file
                });
            }
        }
    };

    const [loaded, setLoaded] = useState(null);
    async function saveUserDetails(credentials) {
        setLoaded(null);
        axios
            .post(myAppConfig.UPDATE_PROFILE_SUPER_ADMIN, credentials, {
                headers: { 'Content-Type': 'multipart/form-data', 'token': localStorage.getItem('userToken') },
                onUploadProgress: ProgressEvent => {
                    setLoaded(Math.round(
                        (ProgressEvent.loaded * 100) / ProgressEvent.total
                    ))
                }
            })
            .then(response => {
                if (response.data.status === 'success') {
                    console.log(response.data);
                    if (response.data.logo_image) {
                        localStorage.setItem('userLogoImg', response.data.logo_image)
                    }
                    setShowProfileUpdatedMessage(true);
                }
                else {
                    console.log(response.data);
                }
                // }
                // )
            }).catch(err => check403Error(err));
    }

    const onFormSubmit = async e => {
        e.preventDefault()
        var detailsFormData = new FormData();
        detailsFormData.append('full_name', name);
        if (image && image.raw) detailsFormData.append('logo_image', image.raw);
        detailsFormData.append('device_type', isDesktop ? 'DT' : isIOS ? 'iOS' : isAndroid ? 'Android' : 'Mobile Web');
        await saveUserDetails(detailsFormData);
    }

    return (
        <>
            {!isIOS ?
            <ImageCropper show={showCropper} setShow={setShowCropper} imageSrc={imageSrc} setImage={setImage} />
                : null}
            <ProfileUpdated
                showProfileUpdatedMessage={showProfileUpdatedMessage}
                setShowProfileUpdatedMessage={setShowProfileUpdatedMessage}
                title='Profile updated'
                description="You've successfully updated your profile." />
            <UploadingModal show={loaded && (loaded < 100)} />
            <div className={"userProfile " + styles.clientsContainer}>
                <div
                    className={styles.back}
                    onClick={() => history.goBack()}>
                    <Card.Img
                        src={imagesApiURL + "images/custom/back.png"}
                        className={styles.back_image}
                    />
                    <p className={styles.back_p}>Back</p>
                </div>
                <div className={styles.clientPageContent} style={{ width: '73%' }}>
                    <Row>
                        <div className="top_header">
                            <h1 className="text-center">Internal Profile</h1>
                        </div>
                    </Row>

                    <div className="form_side mt-5 pt-4">
                        <Row className="m-0">
                            <Col className="leftSide col-lg-3 col-md-3 col-sm-12 col-12">
                                <div>
                                    <label htmlFor="upload-button">
                                        {image.preview ? (
                                            <>
                                                <div className="uploadPhoto">
                                                    <img src={image.preview} alt="default" className="w-100 h-100" style={{ width: '200px' }} />
                                                </div>
                                                <div className="upload text-center mt-3">
                                                    Upload a photo<br />(optional)
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="uploadPhoto">
                                                    <img src={image} alt="default" style={{ width: '200px' }} />
                                                </div>
                                                <div className="upload text-center mt-3">
                                                    Upload a photo<br />(optional)
                                                </div>
                                            </>
                                        )}
                                    </label>
                                    <input
                                        type="file"
                                        id="upload-button"
                                        style={{ display: "none" }}
                                        onChange={fileChange}
                                    />
                                </div>
                            </Col>
                            <Col className='col-lg-9 col-md-9 col-sm-12 col-12'>
                                <Form className='ms-0 mb-5 pb-5' style={{ maxWidth: '100%', textAlign: "end" }} onSubmit={onFormSubmit}>
                                    <Form.Group className="mb-3 pb-2" controlId="formName" style={{ textAlign: "start" }}>
                                        <Form.Label>Full name</Form.Label>
                                        <Form.Control type="text" className="custom_input superAdmin" value={name} onChange={changeName} />
                                    </Form.Group>
                                    <Form.Group className="mb-3 pb-2" controlId="formEmail" style={{ textAlign: "start" }}>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="text" className="custom_input superAdmin" value={email} disabled />
                                    </Form.Group>
                                    <Form.Group className="mb-3 pb-2" controlId="formEmail" style={{ textAlign: "start", position: 'relative' }}>
                                        <Form.Label>Password</Form.Label>
                                        {showPass ?
                                            <Icon.EyeOff className='eyeIcon' onClick={() => setShowPass(false)} /> :
                                            <Icon.Eye className='eyeIcon' onClick={() => setShowPass(true)} />}
                                        <Form.Control type={showPass ? "text" : "password"} className="custom_input superAdmin" value={password} disabled />
                                    </Form.Group>
                                    <Button type="submit" className="saveButton my-5">Save changes</Button>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
}