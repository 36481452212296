export const content = `
<div>
<p style="text-align: center;" class='title terms'><u>Advia Privacy Policy</u></p>
<p><strong>Effective Date: 1st November 2021</strong></p>
<p>The Advia website at <a href='https://advia.io'>https://advia.io</a> domain (the &ldquo;<strong>Website</strong>&rdquo;), Advia mobile and web applications (&ldquo;<strong>Application</strong>&rdquo;) software system (the &ldquo;<strong>System</strong>&rdquo;) and related services (&ldquo;<strong>Services</strong>&rdquo;) (the Website, Application, System, and Services shall be referred to collectively as the &ldquo;<strong>Platform</strong>&rdquo;) are operated by Advia Technologies Ltd. (&ldquo;<strong>Advia</strong>&rdquo; or &ldquo;<strong>Us</strong>&rdquo; or &ldquo;<strong>We</strong>&rdquo;). This document (this &ldquo;<strong>Policy</strong>&rdquo;) describes how We treat Personal Data (as defined below) of Advisors (as such term defined in the Terms) and End Users, individuals using the Services and consuming Advisor Services through the Platform (End Users and Advisors, collectively, &ldquo;<strong>Users</strong>&rdquo;) processed through the Platform. This policy is an integral part of each of the Users Terms of Use and Advisors Terms of Use (the &ldquo;<strong>Terms</strong>&rdquo;) and is incorporated therein. Capitalized terms used in this Policy but not defined otherwise herein shall have the meaning assigned to them in the applicable Terms.</p>
<p><strong>Important:</strong></p>
<ul>
    <li><strong>Using the Platform or any part thereof means that you have read and understood this Policy</strong></li>
    <li><strong>Where specific consent is required under applicable law, we will request this consent separately.</strong></li>
</ul>

<ol class='has-nested'>
    <li><strong><u>What Personal Data is Processed and for What Purposes.</u></strong>
        <p style="margin-left: 0.75rem;">This section explains what kind of Personal Data is being processed through our Platform, for what purposes this Personal Data is being processed, and what are our legal grounds for processing such data. Generally, we collect personal data that you provide while using the Platform (like when you fill forms or operate functions), and some data you provide passively such as data regarding your website or application usage (for example log in and out times, app screens you visited etc.). The purposes of use are generally for the operation of the Platform services, transactional communications with you, internal operational needs of the Company, and if you agreed to it – marketing communications.</p>
        <ol>
            <li>Advia Technologies Ltd. is the controller of your personal data.</li>
            <li>The Platform collects Personal Data from Users at several different points within the Platform. Some Personal Data is provided voluntarily by Users, some of it is required in order to be able to use the Platform, and some of it is collected through the use of the Platform passively through various technological means integrated in the Platform. Below you can find further details on the types of Personal Data we collect in each instance and the purposes for which We will be using this Personal Data.</li>
            <li>Information the Users provide about themselves through the Platform</li>
            <table>
                <tr>
                    <th>User Type</th>
                    <th>Instance in which Personal Data about this User is Provided/Processed</th>
                    <th>Categories of Personal Data of the User that are processed</th>
                    <th>Purposes of processing</th>
                    <th>Legal basis for processing (under GDPR)</th>
                </tr>
                <tr>
                    <td>Advisors</td>
                    <td>When you register as an Advisor and open an account through our Website or the Application</td>
                    <td>First and last name, email address, phone number, password of your choice, Advisor’s alias and photo presented on the Platform, area of expertise, skills and professional background.</td>
                    <td>
                        <ol>
                            <li>So we can identify you when you log in and provide you with personalized services,</li> 
                            <li>List in our search and directory services any users who provide services through one of our Platforms</li>
                            <li>Help you change your password if you forget it;</li>
                            <li>Communicate with you in regards to your use of the Platform;</li> 
                            <li>Provide you with the services offered by the Platform;</li>
                            <li>Manage your subscription and process payments to you</li>
                            <li>Contact you regarding customer service and customer surveys</li>
                            <li>Inform you of changes made to our Platforms or legal documents</li>
                            <li>Improve our Platform</li>
                            <li>Inform you of products, services, promotions, offers, and competitions which you may find interesting provided you have not opted out of receiving such information or, where required, we have your express consent</li>
                        </ol>
                    </td>  
                    <td>
                        (i)-(viii) - Performance of Contract or entering into a contract<br/><br/>
                        (ix) - Our legitimate business interests<br/><br/>
                        (x) - Your consent
                    </td>
                </tr>
                <tr>
                    <td>Advisors</td>
                    <td>When you log in to and use the Platform</td>
                    <td>IP address, country from which you are accessing the Platform (state, city, latitude and longitude), type of operating system of the device you are using, type of browser you are using, time of login</td>
                    <td>
                        <ol>
                            <li>Manage access to the Platform and allow use of the Platform to registered members</li> 
                            <li>Assess your needs and interests in our Platform and its features in order to better tailor our offers and/or advertising</li>
                            <li>Improve our Platforms</li>
                            <li>Conduct research to understand Platform User trends and patterns so we can develop and support existing and ongoing marketing strategies for the Platform</li> 
                            <li>Detecting, preventing, or otherwise addressing fraud, security or technical issues</li>
                        </ol>
                    </td>  
                    <td>
                        <ol>
                            <li>Our legitimate business interest</li> 
                            <li>Performance of contract or entering into a contract</li>
                        </ol>
                    </td>
                </tr>
                <tr>
                    <td>Advisors</td>
                    <td>Information that we receive about you from End Users</td>
                    <td>Advisor’s rating and End User’s review of the call and the Advisor Services provided.</td>
                    <td>
                        <ol>
                            <li>Present Advisor’s rating and reviews made by End Users on the Application</li> 
                            <li>Improve our Platform</li>
                        </ol>
                    </td>  
                    <td>Our legitimate business interests</td>
                </tr>
                <tr>
                    <td>End Users</td>
                    <td>When you register to the Platform, create your account and starting a first session with any Advisor – mandatory information required for the registration.</td>
                    <td>First and last name, date of birth, email address, gender, password of your choice and preferred payment method.</td>
                    <td>
                        <ol>
                            <li>Identify you when you log in and provide you with personalized services,</li> 
                            <li>Help you change your password if you forget it</li>
                            <li>Communicate with you in regards to your use of the Platform </li>
                            <li>Provide you with the services offered by the Platform</li>
                            <li>Manage your subscription and process your payments</li>
                            <li>Respond to inquiries that you may have</li>
                            <li>Contact you regarding customer service</li>
                            <li>Inform you of changes made to our Platforms or legal documents</li>
                            <li>Contact you regarding customer surveys</li>
                            <li>Manage our Platform's safety</li>
                            <li>Manage any possible disputes with you</li>
                            <li>Inform you of products, services, promotions, offers, and competitions which you may find interesting provided you have not opted out of receiving such information or, where required, we have your express consent</li>
                        </ol>
                    </td>  
                    <td>
                        (i)-(viii) - Performance of Contract or entering into a contract<br/><br/>
                        (ix)-(xi) - Our legitimate business interests<br/><br/>
                        (xii) - Your consent
                    </td>
                </tr>
                <tr>
                    <td>End Users</td>
                    <td>When you register to the Platform and create your account – voluntary information.</td>
                    <td>Image of your choice, time of birth, mobile phone number</td>
                    <td>
                        <ol>
                            <li>Personalize your account</li> 
                            <li>To enable chat and video phone calls with the Advisors</li>
                        </ol>
                    </td>  
                    <td>Consent</td>
                </tr>
                <tr>
                    <td>Advisors and End User</td>
                    <td>When you log in and use the Platform</td>
                    <td>
                    Login and logout time <br/><br/>
                    IP address,<br/> <br/>
                    Location from which you are accessing the application (city, country latitude, longitude) <br/><br/>
                    Type of operating system, browser, mobile device of the device you are using
                    </td>
                    <td>
                        <ol>
                            <li>Manage access to the Platform and allow use of the Platform to registered members</li> 
                            <li>Assess your needs and interests in our products and services in order to better tailor our offers and/or advertising</li>
                            <li>Improve our Platforms</li>
                            <li>Conduct research</li>
                            <li>Understand general customer trends and patterns so we can develop and support existing and ongoing marketing strategies for our products and services</li>
                            <li>Detecting, preventing, or otherwise addressing fraud, security or technical issues</li>
                        </ol>
                    </td>  
                    <td>Our legitimate business interests</td>
                </tr>
                <tr>
                    <td>Advisors and End User</td>
                    <td>When you interact with an End User or an Advisor (as applicable) via designated chat tool as made available on the Platform. </td>
                    <td>Text messages exchanged between the End Users or Advisors (as applicable) via designated chat tool as made available on the Platform.</td>
                    <td>
                        <ol>
                            <li>Monitor the interaction between Users to ensure compliance with the provisions of the Agreement.</li> 
                            <li>Investigate Disputes, requests for Satisfaction Guarantee Refund and/or any other requests made by Users. </li>
                        </ol>
                    </td>  
                    <td>Our legitimate business interests</td>
                </tr>
                <tr>
                    <td>Advisors and End User</td>
                    <td>When you subscribe to receive our newsletters and marketing information.</td>
                    <td>Email address</td>
                    <td>
                        <ol>
                            <li>If you signified your consent by checking the applicable box, we will use this personal data to add you to our mailing list for newsletters and other marketing information</li> 
                            <li>Invite you to promotional activities, games or promotional events</li>
                            <li>Conduct research</li>
                            <li>Monitor your interaction with our messages</li>
                            <li>Understand general customer trends and patterns so we can develop and support existing and ongoing marketing strategies for our products and services</li>
                        </ol>
                    </td>  
                    <td>
                        (i)-(ii) - Consent<br/><br/>
                        (iii)-(v) - Our legitimate business interests                
                    </td>
                </tr>
                <tr>
                    <td>End Users and Advisors</td>
                    <td>When you fill out our surveys and feedback forms whether through the Application or by email</td>
                    <td>First and last name, email address and the feedback you choose to provide us.</td>
                    <td>
                        <ol>
                            <li>Assess your needs and interests in our products and services in order to better tailor our offers and/or advertising</li> 
                            <li>Improve our Platforms</li>
                            <li>Organize or carry out promotional activities, games or promotional events.</li>
                            <li>Conduct research</li>
                            <li>Understand general customer trends and patterns so we can develop and support existing and ongoing marketing strategies for our products and services</li>
                        </ol>
                    </td>  
                    <td>Our legitimate business interest</td>
                </tr>
                <tr>
                    <td>End Users</td>
                    <td>When you open a support ticket</td>
                    <td>Full name, email address and a description of your issue</td>
                    <td>
                        <ol>
                            <li>To provide you with the service and support you require and process any requests, inquiries or complaints</li> 
                            <li>Improve our Platform</li>
                            <li>Conduct research</li>
                            <li>Understand general customer trends and patterns so we can develop and support existing and ongoing marketing strategies for our products and services</li>
                        </ol>
                    </td>  
                    <td>
                        (i) - Performance of Contract or entering into a contract<br/><br/>
                        (ii)-(iv) – Our legitimate business interests
                    </td>
                </tr>
            </table>
            
            <li><strong>In addition to the above uses of your personal data, we will also process your personal data for the following purposes:</strong></li>
            <li>We use all of the above personal data to operate, maintain, and provide to you the Platform features and functionality and to provide personalized services as requested by you.</li>
            <li>To prevent, detect and fight fraud or other illegal or unauthorized activities</li>
            <li>To ensure legal compliance – from our side (to legal requirements that apply to us and to our obligations under the Terms of Use) and from your side (Compliance with laws applicable to you and with the Terms of Use)</li>
            <li>We will not use your Personal Data for any personal profiling and automated decision-making regarding you based on such profiling. </li>
            <li>We limit access by our employees to your information only to those who were specifically authorized by the Company to access your information, as part of their job. </li>
            <li>We use anonymized, aggregate data, in order to gain insight on how you and other users use the Platform and try and improve it, as well as to plan our marketing and advertisements.</li>
            <li>All of the above processing is based on our legitimate business interest (GDPR Article 6(1)(f), except for point 1.6 which is partially based on our legitimate business interest (concerning compliance with terms of use) and partially is processing that is necessary to comply with legal obligations that apply to us (GDPR Article 6(1)(c).</li>
            <li>Please note that you have no legal obligation under any applicable law to provide us with any of the above information, and any information that you provide is subject to your will to provide it. However, some of the information we collect is necessary for the facilitation of your use of the Platform, and if you choose not to provide it, you understand that you will not be able to use the Platform or use it properly.</li>
        </ol>
    </li>
    <li><strong><u>How we share Personal Data with Third Parties</u></strong>
        <p style="margin-left: 0.75rem;">This section describes with which third parties we will be sharing your personal data, for the purpose of facilitating the Platform and for being able to operate it and the services, and also protect or legitimate business interests such as facilitate our marketing operations, prevent fraud or misuse, defend ourselves against claims and more. You should also know that we are not going to sell your personal data to any third party. </p>
        <ol>
            <li>No Sale to Third Parties. We do not sell any of your personal data to any third party.</li>
            <li>Service Providers: we employ other companies and people to perform tasks on our behalf and need to share your information with them in order to provide the services to us. The categories of third party recipients of the personal data from us are as follows: cloud hosting services, email campaign automation, marketing automation, social media advertisement (on our behalf, not for transfer to third parties advertisement or marketing purposes), CRM platform, customer support management tools, analytics tools providers, web page building tools, cloud communications platform and call center support services. Our service providers do not have any right to use your personal information collected from our Platform beyond what is necessary for the purpose of facilitating our provision of the Website.</li>
            <li>We may also share information in order to (a) ensure compliance with any applicable law or regulation and (b) detect, prevent or otherwise address fraud or security issues; (c) in case of change in ownership of Advia by way of merger and/or acquisition, in which case all personal data processed by us will be transferred to the possession of our successor in interest.</li>
            <li>Advia may process Personal Data and disclose Personal Data to third parties if required to do so by applicable law or in the good faith belief that such action is necessary to (a) conform to the edicts of the law or comply with legal process involving Advia, but only to the extent necessary; (b) protect and defend the rights or property of Advia or other Users of the Platform; or (c) act in urgent circumstances to protect the personal safety of Advia employees or agents, other Users or members of the public.</li>
            <li>Other than as set forth above or as required by applicable law, We do not share any Personal Data with third parties.</li>
            <li>Anonymous and aggregated information may be shared with other third parties for the purpose of improving our services provided through the Platform, conducting business analysis, business intelligence or other business purposes.</li>
        </ol>
    </li>
    <li><strong><u>Data Transfers and Global Processing under GDPR</u></strong>
        <p style="margin-left: 0.75rem;">This section explains for what reasons, and under what circumstances, your personal data may be transferred to countries other than the country in which you are located. Transfer means also accessing and processing your personal data from a country other than yours. When, and if, your personal data is transferred over borders it shall be always done in accordance with the provisions of applicable law and we shall apply certain safeguards and ensure that an adequate level of protection is awarded to your personal data.</p>
        <ol class='has-nested'>
            <li><u>Why is Personal Data Transferred?</u>
                <ol>
                    <li>As we operate globally, and the Platform Users are from all over the world, we may on occasion allow any information collected through the Platform, as detailed above, to be accessed and/or processed and/or transferred from/to countries other than Your own location.  We may do this where data is accessed/processed:
                        <ol>
                            <li>By employees and contractors of Advia located in Israel for operational, administrative and compliance purposes;</li>
                            <li>By our service providers, for the purposes we specified above under the chapter &ldquo;How We Share Personal Data with Third Parties&rdquo;; currently our service providers are processing the data in US, Israel and EU.</li>
                        </ol>
                    </li>
                    <li><u>Our Safeguards in performing such transfers across boundaries:</u>
                        <ol>
                            <li>Adequacy. Any transfers between the EEA and Israel are transfers on the basis of an adequacy decision made by the European commission and under applicable law.</li>
                            <li>Model Clauses. With some of our processors processing EEA personal data outside of the EEA, we use standard contractual clauses that are binding standards of processing of personal data committed to contractually by third parties processing information for us and on our behalf, such standard clauses are approved by the European Commission (&ldquo;Model Clauses&rdquo;). Nonetheless, we use reasonable judgment to ensure that an adequate level of protection is awarded to the personal data, and where applicable we use additional safeguards to ensure such level of protection.</li>
                            <li>Consent. In the absence of an adequacy decision, Model Clauses, or other approved certification or safeguards or transfer mechanisms provided by applicable law, we shall require Your consent to facilitate the transfer of your personal information.</li>
                        </ol>
                    </li>
                </ol>
            </li>
        </ol>
    </li>
    <li><strong><u>How long do we hold your Personal Data?</u></strong>
        <p style="margin-left: 0.75rem;">This section describes our policy concerning the period of time we shall retain your personal data, which is generally for as long as you are a registered user, and the circumstances under which we might be required to retain such personal data for a longer period. Please also note that deleting your account does not mean you were unsubscribed from our mailing lists and you will need to opt out specifically by an opt out link provided in each email.</p>
        <ol>
            <li>We retain the Personal Data that we collect from and about You through the Platform for as long as You are a registered user, You have not requested to opt out of our mailing list, or requested deletion of your Personal Data, or subject to our retention policies as may be in place from time to time (which you may request more information on, as described in the section &ldquo;Data Subject Rights&rdquo;).</li>
            <li>Deleting your profile or account from the Platform does not mean that you also opt out of our mailing lists, and You need to opt out specifically in order for your email to be removed from our mailing lists. Each communication that will be sent to you has an opt out link; if for any reason you cannot find it, please contact us at <a href="mailto:support@advia.io">support@advia.io</a></li>
            <li>We may retain and use Personal Data as necessary to comply with our legal and regulatory obligations, to resolve disputes and to enforce our agreements, and we may retain and use anonymous and aggregated information for performance reporting and analytic purposes and for product and service improvement without limitation.</li>
        </ol>
    </li>
    <li><strong><u>Our Cookie Policy</u></strong><strong><u>s</u></strong>
        <p style="margin-left: 0.75rem;">This section describes what are cookies (small text file that is stored in your web browser that allows us or a third party (such as third-party service providers) to recognize you) how we use cookies, and the categories and types of cookies used by us. We shall advise you of concerning each cookie used what’s its source, purpose, lifetime and in what way you can opt out from using this cookie. We also explain what additional tracking technologies are used by us. </p>
        <ol>
            <li>A cookie is a small text file that is stored in your web browser that allows us or a third party (such as third-party service providers) to recognize you. Cookies can either be session cookies or persistent cookies. A session cookie expires automatically when you close your browser. A persistent cookie will remain until it expires, or you delete your cookies.</li>
            <li>Cookies that we may use can generally be categorized as follows:
                <!--<script id="CookieDeclaration" src="https://consent.cookiebot.com/c0728bbf-2551-4cda-a732-9a1e4cf0d70b/cd.js" type="text/javascript"></script>-->
                <div id="wrapperCookies"></div>
            </li>
            <li>We may use additional tracking technologies to help understand user activities and preferences. For example, we use web beacons (also known as clear gifs, pixel tags or web bugs) to track user activities and communicate with cookies. You cannot opt out of web beacons used in webpages, but you can limit their use by opting out of the cookies they interact with. You can opt out of web beacons used in emails by setting your email client to render emails in text mode only. </li>
            <li>We may set cookies that monitor links that we send to you to our website (if you have consented to receiving emails from us). These cookies are used to track visitors to our Website sourced from these emails.  To avoid these types of cookies, please follow the explanation below on how to change your browser cookies settings. </li>
            <li>In addition, we may use a tracking technology (pixels) in emails to understand how often our emails are opened and clicked on by our customers. If you do not wish this tracking to be effected, please change your email software or service (such as outlook, Gmail etc.) settings to not automatically download images (to the extent it is not already your default). In some instances, depending on your email or browser settings, cookies in an email may be automatically accepted (for example, when you have added an email address to your address book or safe senders list). Please refer to your email browser or device instructions for more information on this.</li>
            <li>You can change your browser's settings to delete cookies that have already been set and to not accept new cookies. To learn more about how to do this, visit the help pages of your browser. Some useful information can also be found here: <a href='https://www.allaboutcookies.org/'>https://www.allaboutcookies.org/</a>. Please note, however, that if you delete cookies or do not accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.</li>
        </ol>
    </li>
    <li><strong><u>Data Subject Rights in Relation to Personal Data</u></strong>
        <p style="margin-left: 0.75rem;">In this section detailed are the rights you might have in relation to the personal data processed by us including, but not limited to the right to be informed how your personal data is processed, the right to access such data, restrict its processing, object processing and in certain circumstances ask for its deletion. These rights, their extent, restrictions, and limitation are all depending on the laws of the country you are located in which apply to you, and we urge you to get familiar with these laws. In addition, in this section we will explain how you can exercise these rights and how we shall treat such request. Note that when approaching us with the request concerning your rights we will initially verify your identity. </p>
        <ol>
            <li>You may have the rights prescribed below in relation to personal data processed by us, subject to the specific laws applicable to your personal data, certain limitations and restrictions as set forth in applicable law, and depending on the lawful basis upon which the processing of your personal data is based. If you are an European Economic Area data subject, all of the following rights may apply to you.
                <ol>
                    <li>The right to be informed about how your personal data is being processed;</li>
                    <li>The right of access to your personal data processed by us, which includes the right to obtain confirmation as to whether or not personal data concerning you are being processed, and, where that is the case, access to the personal data and the purposes of the processing, categories of personal data concerned, recipients or categories of recipient to whom the personal data have been or will be disclosed, where possible, the envisaged period for which the personal data will be stored, the existence of the right to request rectification or erasure of personal data or restriction of processing of personal data or to object to such processing; the right to lodge a complaint with a supervisory authority, where the personal data are not collected from the data subject, any available information as to their source, the existence of automated decision-making, including profiling; the appropriate safeguards relating to the transfer of your personal data outside the EEA.</li>
                    <li>The right to request the correction of inaccurate personal data we hold about you;</li>
                    <li>Under some circumstances, the right to request that we delete your data;</li>
                    <li>The right to restrict processing your personal data;</li>
                    <li>The right to object to processing activities, if the processing is based on our legitimate interest;</li>
                    <li>The right to withdraw consent for processing at any time, if the processing is based on consent (please note that withdrawal of consent does not affect the lawfulness of processing of your personal data based on your consent prior to such withdrawal);</li>
                    <li>The right to object to processing of personal data for direct marketing purposes at any time;</li>
                    <li>In some circumstances, the right to request that we transfer or port elements of your data either to you or another service provider – if the processing is based on your consent, and is made by automated means.</li>
                    <li>The right to file a complaint with your Supervisory Authority (in the EEA, as prescribed by GDPR).</li>
                </ol>
            </li>
            <li>You may edit your profile information, including deleting most of the data in the profile, any time you wish. Please note that in order to delete other personal data that is not editable through the Website and/or Application, you need to contact Company as described in section ‎14 hereunder.</li>
            <li>You may exercise these rights to the extent these rights apply to you by emailing Advia to: <a href="mailto:support@advia.io">support@advia.io</a> or use any of the contact information listed below. We will undertake to respond to your request within the applicable time frame prescribed by applicable law. Although we will make reasonable efforts to accommodate your requests, in some circumstances we may deem your request unfounded or not eligible under applicable law. In such instances we reserve the right to refuse your request. We shall require, as pre-requisite to fulfilling any request, to verify your identity which we may do by asking you to provide certain information or identification to ensure that all data subjects’ privacy is protected.</li>
        </ol>
    </li>
    <li><strong><u>Security.</u></strong>
        <p style="margin-left: 0.75rem">Although we shall always use leading security practices to preserve the integrity of all your personal data, you should be aware that we cannot guarantee that our systems are fully immune from an unauthorized access. </p>
        <p style="margin-left: 0.75rem">The security of your personal data is extremely important to us. We use industry leading security practices to preserve the integrity of all your personal data. We hold an information security policy, and we monitor industry standards to periodically update our practices. We obligate our vendors and service providers to adhere to minimum standards of security and have practices in place to monitor their compliance. Such measures and procedures reduce the risk of security breaches, but they do not provide absolute security. Therefore, Advia cannot guarantee that the Platform is fully immune to unauthorized access to the information stored therein and to other information security.</p>
    </li>
    <li><strong><u>Age Restrtiction.</u></strong>
        <p style="margin-left: 0.75rem">We are not knowingly collecting personal data concerning minors who are under the age of 18 without appropriate consent. Nevertheless, in case we were provided with such personal data we shall seek appropriate consent or delete it. </p>
        <p style="margin-left: 0.75rem">Advia does not knowingly collect personal data from minors who are under the age of 18 through the Platform without appropriate consent. If a parent or guardian becomes aware that his or her child has provided us with personally identifiable information via the Platform without his/her consent, then he or she should contact Advia at the information described below. If we become aware that a child under the age of 18 has provided us with personally identifiable information, we will delete such information from our files unless we have a satisfactory assurance of a parental consent.</p>
    </li>
    <li><strong><u>Notification of Changes</u></strong>
        <p style="margin-left: 0.75rem">We shall post changed changes on this page and other places we deem appropriate so that Users are always aware of the updated Policy.</p>
        <p style="margin-left: 0.75rem">If this Privacy Policy is changed, Advia will post those changes on this page and other places we deem appropriate so that Users are always aware of the updated Policy, and will notify our Users of the changes.</p>
    </li>
    <li><strong><u>Contact Information</u></strong> 
        <p style="margin-left: 0.75rem">For any queries concerning this privacy policy you should contact us at <a href="mailto:support@advia.io">support@advia.io</a></p>
        <p style="margin-left: 0.75rem">If Users have any questions regarding this Privacy Policy, or the exercise of any of their rights, or any other questions, suggestions or complaints about our use of your personal data and your privacy please contact data protection officer at <a href="mailto:support@advia.io">support@advia.io</a></p>
    </li>
</ol>
<p>&nbsp;</p>
<p style="margin-left: 1.5rem; text-align: center;"><strong>****</strong></p>
</div>
`

// remove the title and the spacing after <p>These Terms of Use for Users ...
export const contentNoTitle = `
<div>
<p><strong>Effective Date: 1st November 2021</strong></p>
<p>The Advia website at <a href='https://advia.io'>https://advia.io</a> domain (the &ldquo;<strong>Website</strong>&rdquo;), Advia mobile and web applications (&ldquo;<strong>Application</strong>&rdquo;) software system (the &ldquo;<strong>System</strong>&rdquo;) and related services (&ldquo;<strong>Services</strong>&rdquo;) (the Website, Application, System, and Services shall be referred to collectively as the &ldquo;<strong>Platform</strong>&rdquo;) are operated by Advia Technologies Ltd. (&ldquo;<strong>Advia</strong>&rdquo; or &ldquo;<strong>Us</strong>&rdquo; or &ldquo;<strong>We</strong>&rdquo;). This document (this &ldquo;<strong>Policy</strong>&rdquo;) describes how We treat Personal Data (as defined below) of Advisors (as such term defined in the Terms) and End Users, individuals using the Services and consuming Advisor Services through the Platform (End Users and Advisors, collectively, &ldquo;<strong>Users</strong>&rdquo;) processed through the Platform. This policy is an integral part of each of the Users Terms of Use and Advisors Terms of Use (the &ldquo;<strong>Terms</strong>&rdquo;) and is incorporated therein. Capitalized terms used in this Policy but not defined otherwise herein shall have the meaning assigned to them in the applicable Terms.</p>
<p><strong>Important:</strong></p>
<ul>
    <li><strong>Using the Platform or any part thereof means that you have read and understood this Policy</strong></li>
    <li><strong>Where specific consent is required under applicable law, we will request this consent separately.</strong></li>
</ul>

<ol class='has-nested'>
    <li><strong><u>What Personal Data is Processed and for What Purposes.</u></strong>
        <p style="margin-left: 0.75rem;">This section explains what kind of Personal Data is being processed through our Platform, for what purposes this Personal Data is being processed, and what are our legal grounds for processing such data. Generally, we collect personal data that you provide while using the Platform (like when you fill forms or operate functions), and some data you provide passively such as data regarding your website or application usage (for example log in and out times, app screens you visited etc.). The purposes of use are generally for the operation of the Platform services, transactional communications with you, internal operational needs of the Company, and if you agreed to it – marketing communications.</p>
        <ol>
            <li>Advia Technologies Ltd. is the controller of your personal data.</li>
            <li>The Platform collects Personal Data from Users at several different points within the Platform. Some Personal Data is provided voluntarily by Users, some of it is required in order to be able to use the Platform, and some of it is collected through the use of the Platform passively through various technological means integrated in the Platform. Below you can find further details on the types of Personal Data we collect in each instance and the purposes for which We will be using this Personal Data.</li>
            <li>Information the Users provide about themselves through the Platform</li>
            <table>
                <tr>
                    <th>User Type</th>
                    <th>Instance in which Personal Data about this User is Provided/Processed</th>
                    <th>Categories of Personal Data of the User that are processed</th>
                    <th>Purposes of processing</th>
                    <th>Legal basis for processing (under GDPR)</th>
                </tr>
                <tr>
                    <td>Advisors</td>
                    <td>When you register as an Advisor and open an account through our Website or the Application</td>
                    <td>First and last name, email address, phone number, password of your choice, Advisor’s alias and photo presented on the Platform, area of expertise, skills and professional background.</td>
                    <td>
                        <ol>
                            <li>So we can identify you when you log in and provide you with personalized services,</li> 
                            <li>List in our search and directory services any users who provide services through one of our Platforms</li>
                            <li>Help you change your password if you forget it;</li>
                            <li>Communicate with you in regards to your use of the Platform;</li> 
                            <li>Provide you with the services offered by the Platform;</li>
                            <li>Manage your subscription and process payments to you</li>
                            <li>Contact you regarding customer service and customer surveys</li>
                            <li>Inform you of changes made to our Platforms or legal documents</li>
                            <li>Improve our Platform</li>
                            <li>Inform you of products, services, promotions, offers, and competitions which you may find interesting provided you have not opted out of receiving such information or, where required, we have your express consent</li>
                        </ol>
                    </td>  
                    <td>
                        (i)-(viii) - Performance of Contract or entering into a contract<br/><br/>
                        (ix) - Our legitimate business interests<br/><br/>
                        (x) - Your consent
                    </td>
                </tr>
                <tr>
                    <td>Advisors</td>
                    <td>When you log in to and use the Platform</td>
                    <td>IP address, country from which you are accessing the Platform (state, city, latitude and longitude), type of operating system of the device you are using, type of browser you are using, time of login</td>
                    <td>
                        <ol>
                            <li>Manage access to the Platform and allow use of the Platform to registered members</li> 
                            <li>Assess your needs and interests in our Platform and its features in order to better tailor our offers and/or advertising</li>
                            <li>Improve our Platforms</li>
                            <li>Conduct research to understand Platform User trends and patterns so we can develop and support existing and ongoing marketing strategies for the Platform</li> 
                            <li>Detecting, preventing, or otherwise addressing fraud, security or technical issues</li>
                        </ol>
                    </td>  
                    <td>
                        <ol>
                            <li>Our legitimate business interest</li> 
                            <li>Performance of contract or entering into a contract</li>
                        </ol>
                    </td>
                </tr>
                <tr>
                    <td>Advisors</td>
                    <td>Information that we receive about you from End Users</td>
                    <td>Advisor’s rating and End User’s review of the call and the Advisor Services provided.</td>
                    <td>
                        <ol>
                            <li>Present Advisor’s rating and reviews made by End Users on the Application</li> 
                            <li>Improve our Platform</li>
                        </ol>
                    </td>  
                    <td>Our legitimate business interests</td>
                </tr>
                <tr>
                    <td>End Users</td>
                    <td>When you register to the Platform, create your account and starting a first session with any Advisor – mandatory information required for the registration.</td>
                    <td>First and last name, date of birth, email address, gender, password of your choice and preferred payment method.</td>
                    <td>
                        <ol>
                            <li>Identify you when you log in and provide you with personalized services,</li> 
                            <li>Help you change your password if you forget it</li>
                            <li>Communicate with you in regards to your use of the Platform </li>
                            <li>Provide you with the services offered by the Platform</li>
                            <li>Manage your subscription and process your payments</li>
                            <li>Respond to inquiries that you may have</li>
                            <li>Contact you regarding customer service</li>
                            <li>Inform you of changes made to our Platforms or legal documents</li>
                            <li>Contact you regarding customer surveys</li>
                            <li>Manage our Platform's safety</li>
                            <li>Manage any possible disputes with you</li>
                            <li>Inform you of products, services, promotions, offers, and competitions which you may find interesting provided you have not opted out of receiving such information or, where required, we have your express consent</li>
                        </ol>
                    </td>  
                    <td>
                        (i)-(viii) - Performance of Contract or entering into a contract<br/><br/>
                        (ix)-(xi) - Our legitimate business interests<br/><br/>
                        (xii) - Your consent
                    </td>
                </tr>
                <tr>
                    <td>End Users</td>
                    <td>When you register to the Platform and create your account – voluntary information.</td>
                    <td>Image of your choice, time of birth, mobile phone number</td>
                    <td>
                        <ol>
                            <li>Personalize your account</li> 
                            <li>To enable chat and video phone calls with the Advisors</li>
                        </ol>
                    </td>  
                    <td>Consent</td>
                </tr>
                <tr>
                    <td>Advisors and End User</td>
                    <td>When you log in and use the Platform</td>
                    <td>
                    Login and logout time <br/><br/>
                    IP address,<br/> <br/>
                    Location from which you are accessing the application (city, country latitude, longitude) <br/><br/>
                    Type of operating system, browser, mobile device of the device you are using
                    </td>
                    <td>
                        <ol>
                            <li>Manage access to the Platform and allow use of the Platform to registered members</li> 
                            <li>Assess your needs and interests in our products and services in order to better tailor our offers and/or advertising</li>
                            <li>Improve our Platforms</li>
                            <li>Conduct research</li>
                            <li>Understand general customer trends and patterns so we can develop and support existing and ongoing marketing strategies for our products and services</li>
                            <li>Detecting, preventing, or otherwise addressing fraud, security or technical issues</li>
                        </ol>
                    </td>  
                    <td>Our legitimate business interests</td>
                </tr>
                <tr>
                    <td>Advisors and End User</td>
                    <td>When you interact with an End User or an Advisor (as applicable) via designated chat tool as made available on the Platform. </td>
                    <td>Text messages exchanged between the End Users or Advisors (as applicable) via designated chat tool as made available on the Platform.</td>
                    <td>
                        <ol>
                            <li>Monitor the interaction between Users to ensure compliance with the provisions of the Agreement.</li> 
                            <li>Investigate Disputes, requests for Satisfaction Guarantee Refund and/or any other requests made by Users. </li>
                        </ol>
                    </td>  
                    <td>Our legitimate business interests</td>
                </tr>
                <tr>
                    <td>Advisors and End User</td>
                    <td>When you subscribe to receive our newsletters and marketing information.</td>
                    <td>Email address</td>
                    <td>
                        <ol>
                            <li>If you signified your consent by checking the applicable box, we will use this personal data to add you to our mailing list for newsletters and other marketing information</li> 
                            <li>Invite you to promotional activities, games or promotional events</li>
                            <li>Conduct research</li>
                            <li>Monitor your interaction with our messages</li>
                            <li>Understand general customer trends and patterns so we can develop and support existing and ongoing marketing strategies for our products and services</li>
                        </ol>
                    </td>  
                    <td>
                        (i)-(ii) - Consent<br/><br/>
                        (iii)-(v) - Our legitimate business interests                
                    </td>
                </tr>
                <tr>
                    <td>End Users and Advisors</td>
                    <td>When you fill out our surveys and feedback forms whether through the Application or by email</td>
                    <td>First and last name, email address and the feedback you choose to provide us.</td>
                    <td>
                        <ol>
                            <li>Assess your needs and interests in our products and services in order to better tailor our offers and/or advertising</li> 
                            <li>Improve our Platforms</li>
                            <li>Organize or carry out promotional activities, games or promotional events.</li>
                            <li>Conduct research</li>
                            <li>Understand general customer trends and patterns so we can develop and support existing and ongoing marketing strategies for our products and services</li>
                        </ol>
                    </td>  
                    <td>Our legitimate business interest</td>
                </tr>
                <tr>
                    <td>End Users</td>
                    <td>When you open a support ticket</td>
                    <td>Full name, email address and a description of your issue</td>
                    <td>
                        <ol>
                            <li>To provide you with the service and support you require and process any requests, inquiries or complaints</li> 
                            <li>Improve our Platform</li>
                            <li>Conduct research</li>
                            <li>Understand general customer trends and patterns so we can develop and support existing and ongoing marketing strategies for our products and services</li>
                        </ol>
                    </td>  
                    <td>
                        (i) - Performance of Contract or entering into a contract<br/><br/>
                        (ii)-(iv) – Our legitimate business interests
                    </td>
                </tr>
            </table>
            
            <li><strong>In addition to the above uses of your personal data, we will also process your personal data for the following purposes:</strong></li>
            <li>We use all of the above personal data to operate, maintain, and provide to you the Platform features and functionality and to provide personalized services as requested by you.</li>
            <li>To prevent, detect and fight fraud or other illegal or unauthorized activities</li>
            <li>To ensure legal compliance – from our side (to legal requirements that apply to us and to our obligations under the Terms of Use) and from your side (Compliance with laws applicable to you and with the Terms of Use)</li>
            <li>We will not use your Personal Data for any personal profiling and automated decision-making regarding you based on such profiling. </li>
            <li>We limit access by our employees to your information only to those who were specifically authorized by the Company to access your information, as part of their job. </li>
            <li>We use anonymized, aggregate data, in order to gain insight on how you and other users use the Platform and try and improve it, as well as to plan our marketing and advertisements.</li>
            <li>All of the above processing is based on our legitimate business interest (GDPR Article 6(1)(f), except for point 1.6 which is partially based on our legitimate business interest (concerning compliance with terms of use) and partially is processing that is necessary to comply with legal obligations that apply to us (GDPR Article 6(1)(c).</li>
            <li>Please note that you have no legal obligation under any applicable law to provide us with any of the above information, and any information that you provide is subject to your will to provide it. However, some of the information we collect is necessary for the facilitation of your use of the Platform, and if you choose not to provide it, you understand that you will not be able to use the Platform or use it properly.</li>
        </ol>
    </li>
    <li><strong><u>How we share Personal Data with Third Parties</u></strong>
        <p style="margin-left: 0.75rem;">This section describes with which third parties we will be sharing your personal data, for the purpose of facilitating the Platform and for being able to operate it and the services, and also protect or legitimate business interests such as facilitate our marketing operations, prevent fraud or misuse, defend ourselves against claims and more. You should also know that we are not going to sell your personal data to any third party. </p>
        <ol>
            <li>No Sale to Third Parties. We do not sell any of your personal data to any third party.</li>
            <li>Service Providers: we employ other companies and people to perform tasks on our behalf and need to share your information with them in order to provide the services to us. The categories of third party recipients of the personal data from us are as follows: cloud hosting services, email campaign automation, marketing automation, social media advertisement (on our behalf, not for transfer to third parties advertisement or marketing purposes), CRM platform, customer support management tools, analytics tools providers, web page building tools, cloud communications platform and call center support services. Our service providers do not have any right to use your personal information collected from our Platform beyond what is necessary for the purpose of facilitating our provision of the Website.</li>
            <li>We may also share information in order to (a) ensure compliance with any applicable law or regulation and (b) detect, prevent or otherwise address fraud or security issues; (c) in case of change in ownership of Advia by way of merger and/or acquisition, in which case all personal data processed by us will be transferred to the possession of our successor in interest.</li>
            <li>Advia may process Personal Data and disclose Personal Data to third parties if required to do so by applicable law or in the good faith belief that such action is necessary to (a) conform to the edicts of the law or comply with legal process involving Advia, but only to the extent necessary; (b) protect and defend the rights or property of Advia or other Users of the Platform; or (c) act in urgent circumstances to protect the personal safety of Advia employees or agents, other Users or members of the public.</li>
            <li>Other than as set forth above or as required by applicable law, We do not share any Personal Data with third parties.</li>
            <li>Anonymous and aggregated information may be shared with other third parties for the purpose of improving our services provided through the Platform, conducting business analysis, business intelligence or other business purposes.</li>
        </ol>
    </li>
    <li><strong><u>Data Transfers and Global Processing under GDPR</u></strong>
        <p style="margin-left: 0.75rem;">This section explains for what reasons, and under what circumstances, your personal data may be transferred to countries other than the country in which you are located. Transfer means also accessing and processing your personal data from a country other than yours. When, and if, your personal data is transferred over borders it shall be always done in accordance with the provisions of applicable law and we shall apply certain safeguards and ensure that an adequate level of protection is awarded to your personal data.</p>
        <ol class='has-nested'>
            <li><u>Why is Personal Data Transferred?</u>
                <ol>
                    <li>As we operate globally, and the Platform Users are from all over the world, we may on occasion allow any information collected through the Platform, as detailed above, to be accessed and/or processed and/or transferred from/to countries other than Your own location.  We may do this where data is accessed/processed:
                        <ol>
                            <li>By employees and contractors of Advia located in Israel for operational, administrative and compliance purposes;</li>
                            <li>By our service providers, for the purposes we specified above under the chapter &ldquo;How We Share Personal Data with Third Parties&rdquo;; currently our service providers are processing the data in US, Israel and EU.</li>
                        </ol>
                    </li>
                    <li><u>Our Safeguards in performing such transfers across boundaries:</u>
                        <ol>
                            <li>Adequacy. Any transfers between the EEA and Israel are transfers on the basis of an adequacy decision made by the European commission and under applicable law.</li>
                            <li>Model Clauses. With some of our processors processing EEA personal data outside of the EEA, we use standard contractual clauses that are binding standards of processing of personal data committed to contractually by third parties processing information for us and on our behalf, such standard clauses are approved by the European Commission (&ldquo;Model Clauses&rdquo;). Nonetheless, we use reasonable judgment to ensure that an adequate level of protection is awarded to the personal data, and where applicable we use additional safeguards to ensure such level of protection.</li>
                            <li>Consent. In the absence of an adequacy decision, Model Clauses, or other approved certification or safeguards or transfer mechanisms provided by applicable law, we shall require Your consent to facilitate the transfer of your personal information.</li>
                        </ol>
                    </li>
                </ol>
            </li>
        </ol>
    </li>
    <li><strong><u>How long do we hold your Personal Data?</u></strong>
        <p style="margin-left: 0.75rem;">This section describes our policy concerning the period of time we shall retain your personal data, which is generally for as long as you are a registered user, and the circumstances under which we might be required to retain such personal data for a longer period. Please also note that deleting your account does not mean you were unsubscribed from our mailing lists and you will need to opt out specifically by an opt out link provided in each email.</p>
        <ol>
            <li>We retain the Personal Data that we collect from and about You through the Platform for as long as You are a registered user, You have not requested to opt out of our mailing list, or requested deletion of your Personal Data, or subject to our retention policies as may be in place from time to time (which you may request more information on, as described in the section &ldquo;Data Subject Rights&rdquo;).</li>
            <li>Deleting your profile or account from the Platform does not mean that you also opt out of our mailing lists, and You need to opt out specifically in order for your email to be removed from our mailing lists. Each communication that will be sent to you has an opt out link; if for any reason you cannot find it, please contact us at <a href="mailto:support@advia.io">support@advia.io</a></li>
            <li>We may retain and use Personal Data as necessary to comply with our legal and regulatory obligations, to resolve disputes and to enforce our agreements, and we may retain and use anonymous and aggregated information for performance reporting and analytic purposes and for product and service improvement without limitation.</li>
        </ol>
    </li>
    <li><strong><u>Our Cookie Policy</u></strong><strong><u>s</u></strong>
        <p style="margin-left: 0.75rem;">This section describes what are cookies (small text file that is stored in your web browser that allows us or a third party (such as third-party service providers) to recognize you) how we use cookies, and the categories and types of cookies used by us. We shall advise you of concerning each cookie used what’s its source, purpose, lifetime and in what way you can opt out from using this cookie. We also explain what additional tracking technologies are used by us. </p>
        <ol>
            <li>A cookie is a small text file that is stored in your web browser that allows us or a third party (such as third-party service providers) to recognize you. Cookies can either be session cookies or persistent cookies. A session cookie expires automatically when you close your browser. A persistent cookie will remain until it expires, or you delete your cookies.</li>
            <li>Cookies that we may use can generally be categorized as follows:
                <!--<script id="CookieDeclaration" src="https://consent.cookiebot.com/c0728bbf-2551-4cda-a732-9a1e4cf0d70b/cd.js" type="text/javascript"></script>-->
                <div id="wrapperCookies"></div>
            </li>
            <li>We may use additional tracking technologies to help understand user activities and preferences. For example, we use web beacons (also known as clear gifs, pixel tags or web bugs) to track user activities and communicate with cookies. You cannot opt out of web beacons used in webpages, but you can limit their use by opting out of the cookies they interact with. You can opt out of web beacons used in emails by setting your email client to render emails in text mode only. </li>
            <li>We may set cookies that monitor links that we send to you to our website (if you have consented to receiving emails from us). These cookies are used to track visitors to our Website sourced from these emails.  To avoid these types of cookies, please follow the explanation below on how to change your browser cookies settings. </li>
            <li>In addition, we may use a tracking technology (pixels) in emails to understand how often our emails are opened and clicked on by our customers. If you do not wish this tracking to be effected, please change your email software or service (such as outlook, Gmail etc.) settings to not automatically download images (to the extent it is not already your default). In some instances, depending on your email or browser settings, cookies in an email may be automatically accepted (for example, when you have added an email address to your address book or safe senders list). Please refer to your email browser or device instructions for more information on this.</li>
            <li>You can change your browser's settings to delete cookies that have already been set and to not accept new cookies. To learn more about how to do this, visit the help pages of your browser. Some useful information can also be found here: <a href='https://www.allaboutcookies.org/'>https://www.allaboutcookies.org/</a>. Please note, however, that if you delete cookies or do not accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.</li>
        </ol>
    </li>
    <li><strong><u>Data Subject Rights in Relation to Personal Data</u></strong>
        <p style="margin-left: 0.75rem;">In this section detailed are the rights you might have in relation to the personal data processed by us including, but not limited to the right to be informed how your personal data is processed, the right to access such data, restrict its processing, object processing and in certain circumstances ask for its deletion. These rights, their extent, restrictions, and limitation are all depending on the laws of the country you are located in which apply to you, and we urge you to get familiar with these laws. In addition, in this section we will explain how you can exercise these rights and how we shall treat such request. Note that when approaching us with the request concerning your rights we will initially verify your identity. </p>
        <ol>
            <li>You may have the rights prescribed below in relation to personal data processed by us, subject to the specific laws applicable to your personal data, certain limitations and restrictions as set forth in applicable law, and depending on the lawful basis upon which the processing of your personal data is based. If you are an European Economic Area data subject, all of the following rights may apply to you.
                <ol>
                    <li>The right to be informed about how your personal data is being processed;</li>
                    <li>The right of access to your personal data processed by us, which includes the right to obtain confirmation as to whether or not personal data concerning you are being processed, and, where that is the case, access to the personal data and the purposes of the processing, categories of personal data concerned, recipients or categories of recipient to whom the personal data have been or will be disclosed, where possible, the envisaged period for which the personal data will be stored, the existence of the right to request rectification or erasure of personal data or restriction of processing of personal data or to object to such processing; the right to lodge a complaint with a supervisory authority, where the personal data are not collected from the data subject, any available information as to their source, the existence of automated decision-making, including profiling; the appropriate safeguards relating to the transfer of your personal data outside the EEA.</li>
                    <li>The right to request the correction of inaccurate personal data we hold about you;</li>
                    <li>Under some circumstances, the right to request that we delete your data;</li>
                    <li>The right to restrict processing your personal data;</li>
                    <li>The right to object to processing activities, if the processing is based on our legitimate interest;</li>
                    <li>The right to withdraw consent for processing at any time, if the processing is based on consent (please note that withdrawal of consent does not affect the lawfulness of processing of your personal data based on your consent prior to such withdrawal);</li>
                    <li>The right to object to processing of personal data for direct marketing purposes at any time;</li>
                    <li>In some circumstances, the right to request that we transfer or port elements of your data either to you or another service provider – if the processing is based on your consent, and is made by automated means.</li>
                    <li>The right to file a complaint with your Supervisory Authority (in the EEA, as prescribed by GDPR).</li>
                </ol>
            </li>
            <li>You may edit your profile information, including deleting most of the data in the profile, any time you wish. Please note that in order to delete other personal data that is not editable through the Website and/or Application, you need to contact Company as described in section ‎14 hereunder.</li>
            <li>You may exercise these rights to the extent these rights apply to you by emailing Advia to: <a href="mailto:support@advia.io">support@advia.io</a> or use any of the contact information listed below. We will undertake to respond to your request within the applicable time frame prescribed by applicable law. Although we will make reasonable efforts to accommodate your requests, in some circumstances we may deem your request unfounded or not eligible under applicable law. In such instances we reserve the right to refuse your request. We shall require, as pre-requisite to fulfilling any request, to verify your identity which we may do by asking you to provide certain information or identification to ensure that all data subjects’ privacy is protected.</li>
        </ol>
    </li>
    <li><strong><u>Security.</u></strong>
        <p style="margin-left: 0.75rem">Although we shall always use leading security practices to preserve the integrity of all your personal data, you should be aware that we cannot guarantee that our systems are fully immune from an unauthorized access. </p>
        <p style="margin-left: 0.75rem">The security of your personal data is extremely important to us. We use industry leading security practices to preserve the integrity of all your personal data. We hold an information security policy, and we monitor industry standards to periodically update our practices. We obligate our vendors and service providers to adhere to minimum standards of security and have practices in place to monitor their compliance. Such measures and procedures reduce the risk of security breaches, but they do not provide absolute security. Therefore, Advia cannot guarantee that the Platform is fully immune to unauthorized access to the information stored therein and to other information security.</p>
    </li>
    <li><strong><u>Age Restrtiction.</u></strong>
        <p style="margin-left: 0.75rem">We are not knowingly collecting personal data concerning minors who are under the age of 18 without appropriate consent. Nevertheless, in case we were provided with such personal data we shall seek appropriate consent or delete it. </p>
        <p style="margin-left: 0.75rem">Advia does not knowingly collect personal data from minors who are under the age of 18 through the Platform without appropriate consent. If a parent or guardian becomes aware that his or her child has provided us with personally identifiable information via the Platform without his/her consent, then he or she should contact Advia at the information described below. If we become aware that a child under the age of 18 has provided us with personally identifiable information, we will delete such information from our files unless we have a satisfactory assurance of a parental consent.</p>
    </li>
    <li><strong><u>Notification of Changes</u></strong>
        <p style="margin-left: 0.75rem">We shall post changed changes on this page and other places we deem appropriate so that Users are always aware of the updated Policy.</p>
        <p style="margin-left: 0.75rem">If this Privacy Policy is changed, Advia will post those changes on this page and other places we deem appropriate so that Users are always aware of the updated Policy, and will notify our Users of the changes.</p>
    </li>
    <li><strong><u>Contact Information</u></strong> 
        <p style="margin-left: 0.75rem">For any queries concerning this privacy policy you should contact us at <a href="mailto:support@advia.io">support@advia.io</a></p>
        <p style="margin-left: 0.75rem">If Users have any questions regarding this Privacy Policy, or the exercise of any of their rights, or any other questions, suggestions or complaints about our use of your personal data and your privacy please contact data protection officer at <a href="mailto:support@advia.io">support@advia.io</a></p>
    </li>
</ol>
<p>&nbsp;</p>
<p style="margin-left: 1.5rem; text-align: center;"><strong>****</strong></p>
</div>
`