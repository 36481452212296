import React from 'react'
import Card from '../Card';
import {Row} from 'react-bootstrap';
import advisorImage from '../../assets/img/advisor.png';
import '../../styles/card.scss';
import { imagesApiURL } from '../../constants/AppConstants';
import love from '../../assets/icons/categories/heart.png';
import * as Icon from "react-feather";
import { isMobileOnly } from 'react-device-detect';
import AdvisorsMobileSlider from './AdvisorsMobileSlider';
import * as myAppConfig from '../../constants/AppConstants'

const LoveRelationshipAdvisors = (props) => {

    const { advisors, advisorsUserReviews, setAdvisorsUsersReviews } = props

    const minUsersReviewing = 2;
    const maxUsersReviewing = 10;
    const cards = advisors.filter(card => (card.full_name !== null)).map((card, idx) => {
        let usersReviewing;
        let filtering = advisorsUserReviews.filter((advisor) => advisor.advisorId === card.id);
        if (filtering.length === 0) {
            usersReviewing = Math.floor(Math.random() * (maxUsersReviewing - minUsersReviewing + 1)) + minUsersReviewing;
        const advisorReviews = {
            advisorId: card.id,
            numberOfRewiews: usersReviewing
        }
            setAdvisorsUsersReviews(advisorsUserReviews.concat([advisorReviews]))
        }
        else {
            usersReviewing = filtering[0].numberOfRewiews
        }
        let audioCall = 0;
        let chat = 0;
        let videoCall = 0;
        let mins = [];
        let minprice = 0;
        if (card.profile_session_pricings) {
            card.profile_session_pricings.map((price, idx) => {
                const p = price.price;
                if (p > 0) {
                    mins.push(p);
                    switch (price.session_method_type) {
                        case 1:
                            audioCall = 1;
                            break;
                        case 2:
                            chat = 1;
                            break;
                        case 3:
                            videoCall = 1;
                            break;
                        default:
                            break;
                    }
                }
                minprice = Math.min(...mins);
                return null;
            })
        }
        return (
            <Card 
            key={card.id} 
            cardId={card.id}
            image={card.logo_image ? imagesApiURL + card.logo_image : advisorImage} 
                name={card.full_name ? card.full_name : (card.first_name + ' ' + card.last_name)}
            categ={card.category[0] ? card.category[0].title : ''}
            stars={card.score_average ? card.score_average : 0}
            reviewsNo={card.number_of_reviews ? card.number_of_reviews : 0}
                price={'$' + minprice + '/min'}
                usersReviewing={usersReviewing}
                free={(card.freeminutes_status === 1 && card.freeminutes_value) ? ('First ' + card.freeminutes_value + ' min FREE') : (card.saleoffer_status === 1 && card.saleoffer_value) ? (card.saleoffer_value + '% sale offer') : ''}
            audio={audioCall}
            video={videoCall}
            chat={chat}
            readings={card.number_of_readings ? card.number_of_readings : 0}
                status={(Number(card.status_account) === myAppConfig.ACCOUNT_STATUS.FREEZE.id) ? 2 : (card.offline_online ? card.status ? card.status : 0 : 2)}
            />
        )
    }
    );


    return (
        <div className={isMobileOnly ? "pt-0 advisors_wrapper" : "pt-5 pb-5 advisors_wrapper"} style={isMobileOnly ? { overflow: "hidden", paddingBottom: '1rem' } : {}}>
            <h2 className="component_title mb-5">Best advisors in <img src={love} alt="categ_icon" />  <span className="category_title">Love & Relationship</span> <a href='/category/Love & Relationship' className='view_all'>View All <Icon.ChevronRight width={14} /></a></h2>
            {!isMobileOnly ?
        <Row>
            {cards}
        </Row>
                :
                <AdvisorsMobileSlider advisors={advisors} />
            }
    </div>
    )
}

export default LoveRelationshipAdvisors
