import parse from "html-react-parser";

const TextPage = (props) => {
    const { content, pdf } = props;
    return (
        <div style={styles.wrapper(pdf)}>
            {parse(content)}
        </div>
    )
}

export default TextPage

const styles = {
    wrapper: (pdf) => ({
        margin: '15vh 7% 5vh',
        color: 'white',
        fontSize: pdf ? '18px' : '14px',
        lineHeight: pdf ? '34px' : '20px',
        textAlign: 'justify'
    })
}