import React, { useState, useCallback, useRef, useEffect } from 'react'
import '../styles/imageCropper.scss'
import { Modal, Button } from 'react-bootstrap'
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { isMobileOnly } from 'react-device-detect';

const ImageCropper = (props) => {
    const { show, setShow, imageSrc, setImage, saveUserDetails } = props
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({ unit: "%", width: 30, height: 30 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [showCrop, setShowCrop] = useState(false);

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    const saveImage = (canvas, crop) => {
        if (!crop || !canvas) {
            return;
        }

        canvas.toBlob(
            async (blob) => {
                setImage({
                    preview: URL.createObjectURL(blob),
                    raw: blob
                });
                if (saveUserDetails) {
                    const formData = new FormData();
                    formData.append("logo_image", blob);
                    await saveUserDetails(formData)
                }
            },
            'image/png',
            1
        );
    }

    const reset = () => {
        setCrop({ unit: "%", width: 30, height: 30 });
        setCompletedCrop(null);
        setShowCrop(false);
    }

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
    }, [completedCrop]);

    return (
        <Modal
            show={show}
            onHide={() => { setShow(false); reset(); }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="editModal cropModal"
            dialogClassName={isMobileOnly ? "modal-fullscreen modal-backdrop" : ""}
            backdrop='static'
            keyboard={false}
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="title">Crop Image</div>
                <div className='image-cropper' style={showCrop ? { display: 'none' } : {}}>
                    <ReactCrop
                        src={imageSrc}
                        onImageLoaded={onLoad}
                        crop={crop}
                        onChange={(c) => setCrop(c)}
                        onComplete={(c) => setCompletedCrop(c)}
                    />
                    <Button onClick={() => setShowCrop(true)} className='mb-4'>Crop Image</Button>
                </div>
                <div style={!showCrop ? { display: 'none' } : { display: 'flex', flexDirection: 'column' }}>
                    <p>This is your result:</p>
                    <canvas
                        ref={previewCanvasRef}
                        style={
                            {
                                width: Math.round(completedCrop?.width ?? 0),
                                height: Math.round(completedCrop?.height ?? 0)
                            }}
                    />
                    <Button onClick={() => { saveImage(previewCanvasRef.current, completedCrop); setShow(false); reset(); }} className='mb-4'>Save Cropped Image</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ImageCropper

