import { Col, Row, Card } from "react-bootstrap";
import { imagesApiURL } from "../../constants/AppConstants";
import * as myAppConfig from "../../constants/AppConstants";

const SessionHistoryForm = (props) => {
  const { sessionsHistory } = props;

  return (
    <div className="session-history pb-3">
      <h4 className="pb-3 pt-5">Sessions history</h4>

      <div className="session-container-all">
        {sessionsHistory === undefined || sessionsHistory === null ? null : (
          <>
            {Object.keys(sessionsHistory).map((key, i) => (
              <Row className="session-container" key={i}>
                <div
                  className="session-top-header"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Col lg={4} className="line">
                    <hr />
                  </Col>
                  <Col lg={4} className="month">
                    {key}
                  </Col>
                  <Col lg={4} className="line">
                    <hr />
                  </Col>
                </div>

                {sessionsHistory[key].items.map((item, index) => (
                  <Card className="session-user-info" key={index}>
                    <Row>
                      <Col lg={9} sm={6} style={{ width: "70%" }}>
                        <div className="user-info">
                          <img
                            src={imagesApiURL + item.advisor_logo}
                            alt="userImage"
                            className="user-image"
                          />

                          <div>
                            <p className="user-name">{item.advisor_name}</p>
                            <p className="chat-info">
                              {item.conversation_type ===
                              myAppConfig.CONVERSATION_TYPE.CHAT.id
                                ? "Chat"
                                : myAppConfig.CONVERSATION_TYPE.VOICE.id
                                ? "Call"
                                : "Video"}{" "}
                              <Card.Img className="session-dot" />
                              {item.difference}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col
                        lg={3}
                        sm={6}
                        className="session-amount"
                        style={{ width: "30%" }}
                      >
                        <p>${item.total_amount.toFixed(2)}</p>
                      </Col>
                    </Row>
                  </Card>
                ))}

                <Row className="total-amount">
                  <p>Total: ${sessionsHistory[key].total.toFixed(2)}</p>
                </Row>
              </Row>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default SessionHistoryForm;
