import React from 'react'
import { Button, Row } from 'react-bootstrap';
import styles from '../../../../styles/wizard.module.scss';


const TITLE_MATCH_VALUE = "Amazing! Our Wizard has found the perfect match for you!";
const SUBTITLE_MATCH_VALUE = "Our Wizard AI has analyzed your answers and is ready to provide you with the best results possible";
const VIEW_ADVISORS = "View my advisors";
const FOOTER_VALUE = "©2021 Advia. All rights reserved";

const Last_step = (props) => {

    return (
        <div>
            <div className={styles.containerCenterWizard}>
                <Row>
                    <p className={styles.labelTitleWizard}>{TITLE_MATCH_VALUE}</p>
                </Row>
                <Row>
                    <p className={styles.labelSubtitleWizard}>{SUBTITLE_MATCH_VALUE}</p>
                </Row>
                <Row className={styles.hideForMobile}>
                    <Button
                        variant="primary"
                        type="submit"
                        className={styles.buttonFindWizard}
                        onClick={() => props.onClick()}
                    >
                        {VIEW_ADVISORS}
                    </Button>
                </Row>
            </div>
            <Row className={styles.footerDiv}>
                <p className={styles.modalFooterText}>{FOOTER_VALUE}</p>
                <div className={styles.visibleForMobile}>
                    <Button
                        variant="primary"
                        type="submit"
                        className={styles.buttonFindWizardMobile}
                        onClick={() => props.onClick()}
                    >
                        {VIEW_ADVISORS}
                    </Button>
                </div>
            </Row>
        </div>);
}

export default Last_step