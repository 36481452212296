import React, { useEffect, useState } from "react";
import { Row, Dropdown, DropdownButton, Form, FormControl, Table, Card, Spinner } from 'react-bootstrap';
import axios from 'axios';
import styles from '../../../styles/clients.module.scss';
import '../../../styles/_layouts.scss';
import * as myAppConfig from "../../../constants/AppConstants";
import { imagesApiURL } from '../../../constants/AppConstants';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import icon_dashboard_dropdown_arrow from "../../../assets/icons/arrows/dashboard_dropdown_arrow.png";

const NO_DATA_FOUND = "No data found.";
const FULL_NAME = "Full Name";
const CLIENT_ID = "Client ID";
const LAST_UPDATE = "Last Update";
const SESSIONS = "Sessions";
const INCOME_TO_DATE = "Income to Date";
const TYPE_OF_SESSION = "Type of Session";

const REVIEW = "Review";
const CURRENCY_SYMBOL = "$";

export default function Clients({ check403Error }) {

  const sortByDropDownValues = [
    { id: 0, title: "All" },
    { id: 1, title: "Revenue: Low to High" },
    { id: 2, title: "Revenue: High to Low" },
    { id: 3, title: "Reviews: Low to High" },
    { id: 4, title: "Reviews: High to Low" },
    { id: 5, title: "Last Update: Near to Far" },
    { id: 6, title: "Last Update: Far to Near" },
    { id: 7, title: "New Registered Users" },
  ];

  const [dropDownItemSelected, setDropDownItemSelected] = useState(sortByDropDownValues[0]);
  const [searchText, setSearchText] = useState();
  const [clientsArray, setClientsArray] = useState();

  const [dataLoading, setDataLoading] = useState(false);
  const [shouldReload, setShouldReload] = useState(false);

  const handleSelect = (index) => {
    if (!isNaN(index)) {
      const indexNo = Number(index);
      if (indexNo < sortByDropDownValues.length && dropDownItemSelected.id !== sortByDropDownValues[indexNo].id) {
        setDropDownItemSelected(sortByDropDownValues[indexNo]);
        setShouldReload(true)
      }
    }
  }

  const onChangeSearchText = (ev) => {
    let value = ev.target.value;
    setSearchText(value);
  }

  const getTypeOfSession = (typeOfSession) => {
    if (typeOfSession === myAppConfig.CONVERSATION_TYPE.CHAT.id){
      return myAppConfig.CONVERSATION_TYPE.CHAT.name;
    }else if(typeOfSession === myAppConfig.CONVERSATION_TYPE.VOICE.id){
      return myAppConfig.CONVERSATION_TYPE.VOICE.name;
    }else if(typeOfSession === myAppConfig.CONVERSATION_TYPE.VIDEO.id){
      return myAppConfig.CONVERSATION_TYPE.VIDEO.name;
    }else return "";
  }

  useEffect(() => {
    const style = document.querySelector(
      ".clients-dropdown > .dropdown-toggle"
    ).style;
    style.setProperty(
      "--arrowURL",
      "url(" + icon_dashboard_dropdown_arrow + ")"
    );
    let isMounted = true;

    const fetchData = async () => {

      axios
        .get(myAppConfig.GET_LIST_OF_CLIENTS,
          {
            "params": {
              "sort_by": dropDownItemSelected.id,
              "search": searchText ? searchText : ""
            },
            "headers": {
              'token': localStorage.getItem('userToken')
            }
          })
        .then(response => {
          if (searchText === undefined || shouldReload) {
            setDataLoading(false);
            setShouldReload(false);
          }
          if (response.data.status === 'success') {
            var array = [];

            response.data.list_of_clients.forEach(element => {
              var client = {
                id: element.id,
                name: element.first_name + " " + element.last_name,
                clientId: element.id,
                isActive: element.user !== null && element.user !== undefined && element.user.length > 0 ? element.user[0].is_activated : 0,
                sessionsNo: element.sessions,
                lastUpdate: element.last_update,
                incomeToDate: element.income_to_date,
                wallet: element.wallet,
                typeOfSession: element.typeOfSession
              };
              array.push(client);
            });

            if (isMounted) {
              setClientsArray(array);
            }

          } else {
            let message = response.data.message ? response.data.message : NO_DATA_FOUND;
            alert("Error: " + message);
          }
        }).catch(err => check403Error(err));
    }
    if (!dataLoading) {
      if (searchText === undefined || shouldReload) {
        setDataLoading(true);
      }
      fetchData();
    }
    return () => { isMounted = false };
  }, [check403Error, dropDownItemSelected, searchText]);

  return (
    <div className={styles.clientsContainer + ' px-0'}>
      <Row>
        <div className={"col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"}>
          <p className={styles.title}>Registered Clients</p>
        </div>
        <div className={"col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"}>
          <Row style={dataLoading ? { display: 'none' } : {}}>
            <div className={"col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"}>
              <DropdownButton
                className={styles.clients_drop_down + " row clients-dropdown"}
                alignRight
                title={"Sort by: " + dropDownItemSelected.title}
                id={styles.dropdown_menu_align_left}
                onSelect={handleSelect}>
                {sortByDropDownValues.map((_item, idx) =>
                  <Dropdown.Item
                    key={_item.id}
                    className={dropDownItemSelected.id === _item.id ? styles.drop_down_item_selected : styles.drop_down_item}
                    eventKey={idx}>
                    {_item.title}
                  </Dropdown.Item>
                )}
              </DropdownButton>

            </div>
            <div className={"col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 " + styles.clientSearchContainer}>
              <Form >
                <FormControl
                  value={searchText}
                  onChange={onChangeSearchText}
                  type="search"
                  placeholder="Search for client..."
                  className={"searchInput " + styles.searchClients + " " + styles.ellipsis}
                  aria-label="Search"
                />
              </Form>
            </div>
          </Row>
        </div>
      </Row>
      {
        dataLoading ?
          <div style={{ textAlign: 'center' }}>
            <Spinner animation='border' style={{ color: 'white', marginTop: '80px', marginBottom: '80px' }} />
          </div>
          :
          <Row className={styles.clients_table + ' mx-0'}>
            <Table responsive className={styles.registered_clients_table}>
              <thead>
                <tr>
                  <th className={styles.containerHeader}>{FULL_NAME}</th>
                  <th className={styles.containerHeader}>{CLIENT_ID}</th>
                  <th className={styles.containerHeader}>{LAST_UPDATE}</th>
                  <th className={styles.containerHeader}>{SESSIONS}</th>
                  <th className={styles.containerHeader}>{INCOME_TO_DATE}</th>
                  <th className={styles.containerHeader}>{TYPE_OF_SESSION}</th>
                  <th className={styles.containerHeader}></th>
                </tr>
              </thead>
              <tbody>
                {clientsArray !== null && clientsArray !== undefined && clientsArray.length > 0 ? clientsArray.map((client, _idx) => (
                  <tr key={_idx} className={_idx < (clientsArray.length - 1) ? styles.customSeparator : styles.noSeparator}>
                    <td className={styles.container}>
                      <div className={styles.nameContainter}>
                        <Card.Img
                          className={styles.dot}
                          src={imagesApiURL + (client.isActive ? 'images/custom/active-client.png' : 'images/custom/inactive-client.png')} />
                        <p className={client.name.trim() ? styles.clientLabel : styles.clientLabelEmpty}>{client.name.trim() ? client.name : 'Empty'}</p>
                      </div>
                    </td>
                    <td className={styles.container}>{client.id}</td>
                    <td className={styles.container}>{client.lastUpdate}</td>
                    <td className={styles.container}>{client.sessionsNo}</td>
                    <td className={styles.container}>{CURRENCY_SYMBOL + client.incomeToDate}</td>
                    <td className={styles.container}>{getTypeOfSession(client.typeOfSession)}</td>
                    <td className={styles.container} style={{ textAlign: 'end' }}>
                      <Link to={{ pathname: "/client-page", state: client.id }} className={styles.reviewButton + " " + styles.ellipsis} >{REVIEW}</Link>
                    </td>
                  </tr>
                )) : ""
                }
              </tbody>
            </Table>
          </Row>
      }
    </div>
  );

}

Clients.propTypes = {
  check403Error: PropTypes.func.isRequired
}
