import { Link } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { imagesApiURL } from "../../constants/AppConstants";
import * as myAppConfig from "../../constants/AppConstants";
import icon_logo from "../../assets/img/user.png";

const Sessions = (props) => {
  const { sessions } = props;

  return (
    <Card
      className={
        "card-custom custom-padding height-auto custom-sessions " +
        (sessions.length > 0 ? "" : "empty")
      }
    >
      <Row>
        <Col lg={6} xs={6}>
          <p className="custom-text-style-24-34">Sessions</p>
        </Col>
        <Col lg={6} xs={6}>
          <div className="rating-images">
            <p
              className="rating-amount-note custom-text-style-14-20"
              style={{ color: "#19AAA1" }}
            >
              {sessions.length}
            </p>
            <p className="rating-amount-note custom-text-style-14-20">
              Sessions
            </p>
          </div>
        </Col>
      </Row>
      {sessions.length > 0 ? (
        <>
          {sessions
            ? sessions.map((session, id) => {
                return (
                  <>
                    <Row className="session">
                      <Col className="rating-left d-flex col-6">
                        <Card.Img src={session.clientLogo === "" || session.clientLogo === null || session.clientLogo === undefined ? icon_logo : imagesApiURL + session.clientLogo} />
                        <div className="d-flex flex-column justify-content-center">
                          <p className="review-name custom-text-style-16-22 text-white">
                            {session.clientName}
                          </p>
                          {/* <p className="review-ago custom-text-style-16-22 mb-0 text-white">{session.session[0].last_message}</p> */}
                          <p className="review-ago custom-text-style-16-22 mb-0 text-white">
                            <span style={{ textTransform: "capitalize" }}>
                              {session.conversation_type ===
                              myAppConfig.CONVERSATION_TYPE.CHAT.id
                                ? myAppConfig.CONVERSATION_TYPE.CHAT.name
                                : myAppConfig.CONVERSATION_TYPE.VOICE.id
                                ? myAppConfig.CONVERSATION_TYPE.VOICE.name
                                : myAppConfig.CONVERSATION_TYPE.VIDEO.name}
                            </span>
                            &nbsp;session
                          </p>
                        </div>
                      </Col>
                      <Col className="rating-right col-6">
                        <button
                          type="button"
                          className="custom-button-text mb-4 mt-4"
                          style={{ padding: "10px 18px" }}
                          onClick={() =>
                            (window.location.href =
                              "/station?" +
                              (session.conversation_type ===
                              myAppConfig.CONVERSATION_TYPE.CHAT.id
                                ? myAppConfig.CONVERSATION_TYPE.CHAT.name
                                : myAppConfig.CONVERSATION_TYPE.VOICE.id
                                ? myAppConfig.CONVERSATION_TYPE.VOICE.name
                                : myAppConfig.CONVERSATION_TYPE.VIDEO.name) +
                              "/" +
                              session.id)
                          }
                        >
                          Review
                        </button>
                      </Col>
                    </Row>
                    {id !== sessions.length - 1 ? (
                      <hr />
                    ) : sessions.length > 2 ? (
                      <hr />
                    ) : null}
                  </>
                );
              })
            : null}
          {sessions.length > 2 ? (
            <Link
              to={{ pathname: "/sessions", state: sessions }}
              className="custom-button-text"
              style={{ textDecoration: "none" }}
            >
              Show More
            </Link>
          ) : null}
        </>
      ) : (
        <div
          style={{
            fontSize: "16px",
            lineHeight: "22px",
            color: "white",
            marginTop: "62px",
            minHeight: "80px",
          }}
        >
          No sessions yet
        </div>
      )}
    </Card>
  );
};

export default Sessions;
