import becomePsychic_banner from '../../../assets/img/bg/becomePsychic_banner.png'
import becomePsychic_banner1 from '../../../assets/img/bg/becomePsychic_banner1.png'
import BackgroundText from "../../../components/becomePsychic/BackgroundText"
import AboutUs from "../../../components/becomePsychic/AboutUs"
import WhyAdvia from "../../../components/becomePsychic/WhyAdvia"

const BecomeAnAffiliate = (props) => {
    const { setLoginModalShow, setHideSignUp } = props
    return (
        <>
            <BackgroundText setHideSignUp={setHideSignUp} setLoginModalShow={setLoginModalShow} backgroundImg={becomePsychic_banner} titleFirstLine="Become an affiliate partner with Advia" subtitle="The next generation online psychic readers platform" role='affiliate' agreement='*By signing up you are agreeing to the terms of the Advia Affiliate Agreement program' />
            <AboutUs role='affiliate' />
            <BackgroundText setLoginModalShow={setLoginModalShow} backgroundImg={becomePsychic_banner1} titleFirstLine="Advia affiliate program" desc='Advia is going to revolutionised the online psychic reading industry. The affiliate fee is 120$ for every new customer that is coming from your traffic. Customers can use our services 24/7 and, can conduct their session with an advisor through either chat, phone or, video call. All our advisors were handpicked by our proffesional and experienced success team. We provide and strive to give the highest quality of service and demand our advisors to comply with these standards. We offer our customers a satisfaction guarentee policy' role='affiliate' />
            <WhyAdvia role='affiliate' setLoginModalShow={setLoginModalShow} setHideSignUp={setHideSignUp} />
        </>
    )
}

export default BecomeAnAffiliate