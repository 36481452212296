import React, { useEffect, useState } from "react";
import { Row, Button, Card, Form } from "react-bootstrap";
import styles from "../../../styles/clients.module.scss";
import { imagesApiURL } from "../../../constants/AppConstants";
import { useHistory } from "react-router-dom";

import axios from "axios";
import * as myAppConfig from "../../../constants/AppConstants";

import icon_clock from "../../../assets/icons/hour_icon.png";
import icon_calendar from "../../../assets/icons/calendar_icon.png";

import icon_gender_female from "../../../assets/icons/gender/female.png";
import icon_gender_male from "../../../assets/icons/gender/male.png";
import icon_gender_other from "../../../assets/icons/gender/other.png";
import AccountManageActionModal from "../../../components/modals/AccountManageActionModal";
import user from '../../../assets/img/user.png'

const FREEZE_ACCOUNT = "Freeze account";
const REACTIVATE_ACCOUNT = "Reactivate";
const BACK = "Back";

const CLIENT_ID = "Client ID ";
const LAST_UPDATE = "Last update: ";
const FIRST_NAME = "First name";
const LAST_NAME = "Last name";
const GENDER = "Gender";
const DATE_OF_BIRTH = "Date of Birth";
const TIME_OF_BIRTH = "Time of Birth";
const INCOME_TO_DATE = "Income to Date";
const WALLET = "Wallet";
const CREDITS = "Credits";
const TOTAL_CREDIT = "Total Remain credits: $";
const ADD_CREDIT = "Add Credit";

const GENDER_FEMALE = { id: 1, title: "Female" };
const GENDER_MALE = { id: 2, title: "Male" };
const GENDER_OTHER = { id: 0, title: "Other" };

const CURRENCY_SYMBOL = "$";

export default function Client(props) {
  const { check403Error } = props;

  let history = useHistory();

  const [clientId, setClientId] = useState(-1);
  const [userId, setUserId] = useState(-1);
  const [lastUpdate, setLastUpdate] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [image, setImage] = useState("");
  const [statusAccount, setStatusAccount] = useState(
    myAppConfig.ACCOUNT_STATUS.ACTIVE.id
  );
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [timeOfBirth, setTimeOfBirth] = useState("");
  const [gender, setGender] = useState(0);
  const [incomeToDate, setIncomeToDate] = useState(0);
  const [totalRemainCredit, setTotalRemainCredit] = useState(0);
  const [wallet, setWallet] = useState(0);
  const [credit, setCredit] = useState(0);

  const [userEmail, setUserEmail] = useState("");
  const [showAccountManageModal, setShowAccountActionModal] = useState(false);
  const [accountActionType, setAccountActionType] = useState("");

  useEffect(() => {
    const fetchData = async (id) => {
      axios
        .get(myAppConfig.GET_CLIENT_PROFILE, {
          params: {
            client_id: id,
          },
          headers: {
            token: localStorage.getItem("userToken"),
          },
        })
        .then((response) => {
          if (response.data !== null && response.data !== undefined) {
            let data = response.data;
            if (
              data.status === "success" &&
              data.profile_details !== null &&
              data.profile_details !== undefined &&
              data.profile_details.length > 0
            ) {
              let profileDetails = data.profile_details[0];
              setLastUpdate(profileDetails.last_update);
              setFirstName(profileDetails.first_name);
              setLastName(profileDetails.last_name);
              setImage(profileDetails.logo_image);
              setDateOfBirth(profileDetails.date_of_birth);
              setTimeOfBirth(profileDetails.time_of_birth);
              setGender(profileDetails.gender);
              setIncomeToDate(profileDetails.income_to_date);
              setTotalRemainCredit(profileDetails.total_remain_credit);
              setWallet(profileDetails.wallet);
              setStatusAccount(profileDetails.status_account);
              setUserId(profileDetails.user_id);
              if (profileDetails.user && profileDetails.user[0] && profileDetails.user[0].email) {
              setUserEmail(profileDetails.user[0].email);
              }
            } else {
              let message =
                data.message !== null && data.message !== undefined
                  ? data.message
                  : "";
              alert("Error: " + message);
            }
          }
        })
        .catch((err) => check403Error(err));
    };

    if (props !== null && props !== undefined && props.userId !== null) {
      let clientId = props.userId;
      setClientId(clientId);
      fetchData(clientId);
    }
  }, [check403Error, props]);

  const accountManageAction = (actionType) => {
    console.log("daa");
    setAccountActionType(actionType);
    setShowAccountActionModal(true);
  };

  const onAccountActionBtnClick = () => {
    if (
      accountActionType ===
      myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.FREEZE_ACCOUNT
    ) {
      updateAccountStatus(true);
    } else if (
      accountActionType ===
      myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.REACTIVATE_ACCOUNT
    ) {
      updateAccountStatus(false);
    }
    setShowAccountActionModal(false);
  };

  const updateAccountStatus = async () => {
    let list_of_profiles = "";

    list_of_profiles += userId + ",";
    list_of_profiles += clientId + ",";
    list_of_profiles +=
      statusAccount === myAppConfig.ACCOUNT_STATUS.FREEZE.id
        ? myAppConfig.ACCOUNT_STATUS.ACTIVE.id
        : myAppConfig.ACCOUNT_STATUS.FREEZE.id;

    var formData = new FormData();
    formData.append("list_of_profiles", list_of_profiles);

    axios
      .post(myAppConfig.FREEZE_REACTIVATE_ACCOUNT, formData, {
        headers: {
          token: localStorage.getItem("userToken"),
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          let newStatusAccountValue =
            statusAccount === myAppConfig.ACCOUNT_STATUS.FREEZE.id
              ? myAppConfig.ACCOUNT_STATUS.ACTIVE.id
              : myAppConfig.ACCOUNT_STATUS.FREEZE.id;
          setStatusAccount(newStatusAccountValue);
        }
      })
      .catch((err) => {
        check403Error(err);
      });
  };

  const addCredit = () => {};

  const handleBack = () => {
    history.goBack();
  };

  const getGenderIcon = (clientGender) => {
    var iconName;
    switch (clientGender) {
      case GENDER_FEMALE.id:
        iconName = icon_gender_female;
        break;
      case GENDER_MALE.id:
        iconName = icon_gender_male;
        break;
      default:
        iconName = icon_gender_other;
        break;
    }
    return iconName;
  };
  const getGenderTitle = (clientGender) => {
    var genderValue;
    switch (clientGender) {
      case GENDER_FEMALE.id:
        genderValue = GENDER_FEMALE.title;
        break;
      case GENDER_MALE.id:
        genderValue = GENDER_MALE.title;
        break;
      default:
        genderValue = GENDER_OTHER.title;
        break;
    }
    return genderValue;
  };

  function changeInputValue(ev) {
    let value = ev.target.value;
    if (!isNaN(Number(value))) {
      setCredit(value);
    }
  }

  return (
    <div className={styles.clientsContainer}>
      <AccountManageActionModal
        show={showAccountManageModal}
        setShowAccountActionModal={() => setShowAccountActionModal(false)}
        actionType={accountActionType}
        onAccountAction={onAccountActionBtnClick}
      />
      <div className={styles.back} onClick={handleBack}>
        <Card.Img
          src={imagesApiURL + "images/custom/back.png"}
          className={styles.back_image}
        />
        <p className={styles.back_p}>{BACK}</p>
      </div>
      <div className={styles.clientPageContent}>
        <Row style={{ background: "50px" }}>
          <div className={"col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"}>
            <p className={styles.title}>Client Profile Page</p>
          </div>
          <div className={"col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"}>
            <Button
              variant="primary"
              className={styles.button}
              onClick={() =>
                accountManageAction(
                  statusAccount === myAppConfig.ACCOUNT_STATUS.FREEZE.id
                    ? myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.REACTIVATE_ACCOUNT
                    : myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.FREEZE_ACCOUNT
                )
              }
            >
              {statusAccount === myAppConfig.ACCOUNT_STATUS.FREEZE.id
                ? REACTIVATE_ACCOUNT
                : FREEZE_ACCOUNT}
            </Button>
          </div>
        </Row>
        <Row className={styles.containerHolder}>
          <div className={"col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"}></div>
          <div className={"col-5 col-sm-5 col-md-5 col-lg-5 col-xl-5"}>
            <p className={styles.clientIdP}> {CLIENT_ID + clientId}</p>
          </div>
          <div className={"col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"}>
            <p className={styles.clientLastUpdateP}>
              {" "}
              {LAST_UPDATE + lastUpdate}
            </p>
          </div>
        </Row>
        <div
          className={styles.clientContentData + " " + styles.containerHolder}
        >
          <div
            className={
              "col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 " +
              styles.clientProfileImageContainer
            }
          >
            <Card.Img
              src={image ? imagesApiURL + image : user}
              className={styles.clientProfileImage}
              alt={"client_profile_image"}
            />
          </div>
          <div className={"col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"}>
            <Row>
              <div className={"col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 text-center text-md-start"}>
                <p className={styles.clientLabelP}>{FIRST_NAME}</p>
                <p className={styles.clientContentP}>{firstName}</p>
              </div>

              <div className={"col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8 text-center text-md-start"}>
                <p className={styles.clientLabelP}>{LAST_NAME}</p>
                <p className={styles.clientContentP}>{lastName}</p>
              </div>
            </Row>
            <Row className={styles.containerHolder}>
              <div className={"col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 text-center text-md-start"}>
                <p className={styles.clientLabelP}>{GENDER}</p>
                <div className={styles.client_gender_container}>
                  <img
                    src={getGenderIcon(gender)}
                    className={styles.client_gender_icon}
                    alt={"gender_icon"}
                  />
                  <p className={styles.clientContentP}>
                    {getGenderTitle(gender)}
                  </p>
                </div>
              </div>
              <div className={"col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8 text-center text-md-start"}>
                <p className={styles.clientLabelP}>Email Address</p>
                <p className={styles.clientContentP}>{userEmail}</p>
              </div>
            </Row>


            <Row className={styles.containerHolder}>
              <div className={"col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 text-center text-md-start"}>
                <p className={styles.clientLabelP}>{DATE_OF_BIRTH}</p>
                <div className={styles.client_time_container}>
                  <img
                    src={icon_calendar}
                    className={styles.client_icon_calendar}
                    alt={"calendar_icon"}
                  />
                  <p className={styles.clientContentP}>{dateOfBirth}</p>
                </div>
              </div>

              <div className={"col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8 text-center text-md-start"}>
                <p className={styles.clientLabelP}>{TIME_OF_BIRTH}</p>
                <div className={styles.client_time_container}>
                  <img
                    src={icon_clock}
                    className={styles.client_icon_clock}
                    alt={"clock_icon"}
                  />
                  <p className={styles.clientContentP}>{timeOfBirth}</p>
                </div>
              </div>
            </Row>

            <Row className={styles.containerHolder}>
              <div className={"col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 text-center text-md-start"}>
                <p className={styles.clientLabelP}>{INCOME_TO_DATE}</p>
                <p className={styles.clientContentP}>
                  {CURRENCY_SYMBOL + incomeToDate}
                </p>
              </div>

              <div className={"col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8 text-center text-md-start"}>
                <p className={styles.clientLabelP}>{WALLET}</p>
                <p className={styles.clientContentP}>
                  {CURRENCY_SYMBOL + wallet}
                </p>
              </div>
            </Row>

            <hr className={styles.client_separator}></hr>

            <Row style={{ marginTop: "32px" }}>
              <div className={"col col-sm-12 col-md-12 col-lg-12 col-xl-12"}>
                <p className={styles.clientLabelP}>{CREDITS}</p>

                <p className={styles.clientContentGreyP}>
                  {TOTAL_CREDIT + totalRemainCredit}
                </p>
              </div>
            </Row>
            <Row style={{ marginTop: "26px", marginBottom: "53px" }}>
              <div className={"col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"}>
                <div className={styles.currencyHolder}>
                  <p className={styles.spanCurrency}>{CURRENCY_SYMBOL}</p>
                  <Form.Control
                    type="text"
                    className={styles.customInput}
                    value={credit}
                    onChange={changeInputValue}
                  />
                </div>
              </div>
              <div className={"col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"}>
                <Button
                  variant="primary"
                  className={styles.buttonAddCredit}
                  onClick={addCredit}
                >
                  {ADD_CREDIT}
                </Button>
              </div>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}