import React from "react";
import { useHistory } from "react-router-dom";
import Popup from "react-popup";
import "../../../styles/custom.popup.css";
import ChatComponent from "../../../components/chat/ChatComponent";
import CallComponent from "../../../components/chat/CallComponent";
import VideoChatComponent from "../../../components/chat/VideoChatComponent";

import "../../../styles/chat.scss";
import "../../../styles/callScreen.scss";
import * as myAppConfig from "../../../constants/AppConstants";

const PaymentResponse = (props) => {
  let history = useHistory();

  const conversationTypeName = sessionStorage.getItem("session-pay-screen");

  const otherTalkerInfo = sessionStorage.getItem("otherTalker")
    ? JSON.parse(sessionStorage.getItem("otherTalker"))
    : [];
  const otherTalkerName = otherTalkerInfo["name"]
    ? otherTalkerInfo["name"]
    : "";
  const otherTalkerLogoImage = otherTalkerInfo["logo_image"]
    ? otherTalkerInfo["logo_image"]
    : "";
  const otherTalkerCategory = otherTalkerInfo["category"]
    ? otherTalkerInfo["category"]
    : "";

  //if (props.carouselSlidesData && props.carouselSlidesData.length > 0) {
  const params = new URLSearchParams(window.location.search);
  if (params.has("success") && sessionStorage.getItem("session-pay") !== null) {
    if (params.get("success") === "true") {
      let current_session = sessionStorage.getItem("session-pay");
      if (
        params.get("type") === "1" &&
        current_session === params.get("session")
      ) {
        removeSessionSavedData();
        history.push(
          conversationTypeName === myAppConfig.CONVERSATION_TYPE.CHAT.name
            ? "/chat"
            : conversationTypeName === myAppConfig.CONVERSATION_TYPE.VOICE
              ? "/call"
              : conversationTypeName === myAppConfig.CONVERSATION_TYPE.VIDEO
                ? "/video-chat"
                : "/payment-methods"
        );
      }
    } else {
      removeSessionSavedData();

      Popup.create({
        title: "Error",
        content: "An error has occured while trying to add the credit card!",
        buttons: {},
      });

      history.push("/");
    }
    //}
  }

  function removeSessionSavedData() {
    sessionStorage.removeItem("session-pay");
    sessionStorage.removeItem("session-pay-screen");
  }

  function onCancel() {
    console.log("cancel the chat");
    removeSessionSavedData();
    history.push("/");
  }

  //type
  //session
  //success

  return (
    <>
      <Popup />

      {conversationTypeName !== undefined ? (
        <>
          {conversationTypeName === myAppConfig.CONVERSATION_TYPE.CHAT.name ? (
            <div className="chatScreen mb-5 pb-5">
              <div className="chat-component-container">
                <ChatComponent
                  otherTalkerName={otherTalkerName}
                  otherTalkerLogoImage={otherTalkerLogoImage}
                  otherTalkerCategory={otherTalkerCategory}
                  showLoader={true}
                  onCancelChat={onCancel}
                />
              </div>
            </div>
          ) : conversationTypeName ===
            myAppConfig.CONVERSATION_TYPE.VOICE.name ? (
            <div className="callScreen mb-5 pb-5">
              <div className="call-component-container">
                <CallComponent
                  deviceStatus={""}
                  otherTalkerName={otherTalkerName}
                  otherTalkerLogoImage={otherTalkerLogoImage}
                  otherTalkerCategory={otherTalkerCategory}
                  showModalEndSession={onCancel}
                  otherJoined={false}
                  onCancelCall={onCancel}
                />
              </div>
            </div>
          ) : conversationTypeName ===
            myAppConfig.CONVERSATION_TYPE.VOICE.name ? (
            <div className="video-chat-screen mb-5 pb-5">
              <div className="video-chat-component-container">
                <VideoChatComponent
                  otherTalkerName={otherTalkerName}
                  otherTalkerLogoImage={otherTalkerLogoImage}
                  otherTalkerCategory={otherTalkerCategory}
                  showModalEndSession={onCancel}
                  onCancelVideo={onCancel}
                />
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default PaymentResponse;
