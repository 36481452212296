import React, { useState } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { ClientLayout } from '../layouts/';
import CompleteProfile from "../views/pages/client/CompleteProfile";
import UserProfile from '../views/pages/client/UserProfile';
import ClientHomePage from "../views/pages/client/ClientHomePage";
import CategoryPage from "../views/pages/client/CategoryPage";
import AdvisorProfile from "../views/pages/client/AdvisorProfile";
import ForgotPassword from "../views/pages/client/ForgotPassword";
import RateAdvisor from "../views/pages/client/RateAdvisor";
import SavedAdvisors from "../views/pages/client/SavedAdvisors";
import { SimpleClientLayout } from "../layouts/SimpleClientLayout";
import { ChatLayout } from "../layouts/ChatLayout";
import { WizzardLayout } from "../layouts/WizzardLayout";
import WizardAI from "../views/pages/client/Wizard/Wizard_ai";
import * as myAppConfig from "../constants/AppConstants";
import ChatScreen from "../views/pages/client/ChatScreen";
import AllChats from "../views/pages/chat/AllChats";
import PaymentResponse from "../views/pages/client/PaymentResponse";
import CallScreen from "../views/pages/client/CallScreen";
import VideoChat from "../views/pages/client/VideoChat";
import { isMobile } from "react-device-detect";
import PaymentMethods from "../views/pages/client/PaymentMethods";

const ClientRouter = (props) => {
  let showCompleteProfile = localStorage.getItem(myAppConfig.LOCAL_STORAGE_VALUES.SHOW_COMPLETE_PROFILE.Title) === myAppConfig.LOCAL_STORAGE_VALUES.SHOW_COMPLETE_PROFILE.Values.YES;
  const { homePageDetails, setHomePageDetails, setLoginModalShow, userToken, setUserToken, setUserType, loginModalShow, check403Error, carouselSlidesData } = props;
  const [hasSimpleNavbar, setHasSimpleNavbar] = useState(true);
  const [hasSimpleFooter, setHasSimpleFooter] = useState(true);

  function RouteWrapper({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) => {
          const layoutProps = {
            children: props,
            setLoginModalShow: setLoginModalShow,
            userToken: userToken,
            categories: homePageDetails?.categories ?? [],
            setUserToken: setUserToken,
            setUserType: setUserType,
            loginModalShow: loginModalShow,
            check403Error: check403Error,
          };

          return (
            <ClientLayout {...layoutProps}>
              <Component
                homePageDetails={homePageDetails}
                setHomePageDetails={setHomePageDetails}
                check403Error={check403Error}
                carouselSlidesData={carouselSlidesData}
                setLoginModalShow={setLoginModalShow}
              />
            </ClientLayout>
          );
        }}
      />
    );
  }

  function SimpleRouteWrapper({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) => {
          const layoutProps = {
            children: props,
            setLoginModalShow: setLoginModalShow,
            userToken: userToken,
            categories: homePageDetails?.categories ?? [],
            setUserToken: setUserToken,
            setUserType: setUserType,
            loginModalShow: loginModalShow,
            hasSimpleNavbar: hasSimpleNavbar,
            check403Error: check403Error,
            hasSimpleFooter: hasSimpleFooter,
          };

          return (
            <SimpleClientLayout {...layoutProps}>
              <Component
                homePageDetails={homePageDetails}
                setHasSimpleNavbar={setHasSimpleNavbar}
                check403Error={check403Error}
                setHasSimpleFooter={setHasSimpleFooter}
              />
            </SimpleClientLayout>
          );
        }}
      />
    );
  }

  function ChatRouteWrapper({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) => {
          const layoutProps = {
            children: props,
          };

          return (
            <ChatLayout {...layoutProps}>
              <Component
                homePageDetails={homePageDetails}
                setHasSimpleNavbar={setHasSimpleNavbar}
                check403Error={check403Error}
                setHasSimpleFooter={setHasSimpleFooter}
              />
            </ChatLayout>
          );
        }}
      />
    );
  }

  function WizzardRouteWrapper({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) => {
          const layoutProps = {
            children: props,
            setLoginModalShow: setLoginModalShow,
            userToken: userToken,
            categories: homePageDetails?.categories ?? [],
            setUserToken: setUserToken,
            setUserType: setUserType,
            loginModalShow: loginModalShow,
            check403Error: check403Error,
          };

          return (
            <WizzardLayout {...layoutProps}>
              <Component
                homePageDetails={homePageDetails}
                setLoginModalShow={setLoginModalShow}
                check403Error={check403Error}
              />
            </WizzardLayout>
          );
        }}
      />
    );
  }

  return (
    showCompleteProfile && userToken !== null ?
      <RouteWrapper
        component={CompleteProfile} />
      :
      <>
        <Switch>
          <RouteWrapper exact path="/" component={ClientHomePage} />
          <RouteWrapper
            exact
            path="/forgot-password"
            component={ForgotPassword}
          />
          <RouteWrapper
            exact
            path="/profile-settings"
            component={UserProfile}
          />
          <SimpleRouteWrapper
            exact
            path="/saved-advisors"
            component={SavedAdvisors}
          />
          <RouteWrapper path='/payment-methods' component={PaymentMethods} />
          <RouteWrapper path="/category" component={CategoryPage} />
          <RouteWrapper path="/advisor-profile" component={AdvisorProfile} />
          <SimpleRouteWrapper path="/rate-advisor" component={RateAdvisor} />
          <WizzardRouteWrapper exact path="/wizard" component={WizardAI} />
          <RouteWrapper
            exact
            path="/wizard-results/:id"
            component={CategoryPage}
          />
          {isMobile ? (
            <ChatRouteWrapper exact path="/chat" component={ChatScreen} />
          ) : (
            <SimpleRouteWrapper exact path="/chat" component={ChatScreen} />
          )}
          <RouteWrapper exact path="/all-chats" component={AllChats} />
          <RouteWrapper
            path="/payment-response"
            component={PaymentResponse}
          />
          <SimpleRouteWrapper exact path="/call" component={CallScreen} />
          <SimpleRouteWrapper exact path="/video-chat" component={VideoChat} />
          <Redirect to="/" />
        </Switch>
      </>
  );
};

export default ClientRouter;
