import React from "react";
import { Button, Form, FormControl, Dropdown } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import pattern from '../../assets/img/bg/mobile_search_pattern.png'
import { Link } from "react-router-dom";
import { imagesApiURL } from "../../constants/AppConstants";

import searchIcon from '../../assets/icons/search_icon.png'

const MobileSearch = (props) => {
    const { showMobileSearch, setShowMobileSearch, search, showResults, setShowResults, searchResultAdvisors, setSearchResultAdvisors, searchResultCategories, setSearchResultCategories } = props

    return (
        <Modal dialogClassName="modal-fullscreen modal-backdrop mobileSearch"
            show={showMobileSearch}
            onHide={() => setShowMobileSearch(false)}
            backdrop='static'
            keyboard={false}
        >
            <img src={pattern} className='pattern' alt='pattern' />
            <Modal.Body>
                <div className="d-flex">
                    <Form>
                        <FormControl
                            type="search"
                            placeholder={"Search for Advisor..."}
                            className="searchInput"
                            aria-label="Search"
                            onChange={search}
                            onFocus={() => setShowResults(true)}
                            onBlur={() => setTimeout(() => { setShowResults(false); setShowMobileSearch(false); setSearchResultAdvisors([]); setSearchResultCategories([]) }, 700)}
                        />
                    </Form>
                    <Button className="modal-cancel-button-custom"
                        onClick={() => setShowMobileSearch(false)}
                    >Cancel</Button>
                </div>
                <Dropdown.Menu show={showResults && (searchResultAdvisors.length > 0 || searchResultCategories.length > 0)}
                    className="dashboard-dropdown" alignRight>
                    {searchResultAdvisors.length > 0 ?
                        <>
                            <div className='title'>Advisors</div>
                            {searchResultAdvisors.map((_item, idx) => (
                                <Dropdown.Item key={idx} as={Link} to={'/advisor-profile/' + _item.full_name} className="dashboard-dropdown-menu-item dashboard-dropdown-menu-item-unselected-color" eventKey={idx}><img alt='advisor logo' src={imagesApiURL + _item.logo_image} />{_item.full_name}</Dropdown.Item>
                            ))}
                        </>
                        :
                        null
                    }
                    {searchResultCategories.length > 0 ?
                        <>
                            <div className='title' style={searchResultAdvisors.length > 0 ? { marginTop: '52px' } : {}}>Other results</div>
                            {searchResultCategories.map((_item, idx) => (
                                <Dropdown.Item key={idx} as={Link} to={'/category/' + _item.title + '/?search=true'} className="dashboard-dropdown-menu-item dashboard-dropdown-menu-item-unselected-color" eventKey={idx}><img alt='search logo' src={searchIcon} />{_item.title}</Dropdown.Item>
                            ))}
                        </>
                        :
                        null
                    }
                </Dropdown.Menu>
            </Modal.Body>
        </Modal>
    );
}

export default MobileSearch