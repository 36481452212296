import React from "react";
import "../../styles/clientSessionsNotes.scss";
import { Col, Card } from "react-bootstrap";
import SessionsInfoComponent from "./SessionsInfoComponent";
import * as utils from "../../Utilities/Utils";

const ClientSessionsNotes = (props) => {
  return (
    <Col
      lg={props.colLg === undefined ? 4 : props.colLg}
      style={{ height: "100%", paddingRight: "3rem" }} //
      className="mb-5"
    >
      {props.currentConversationIndex > -1 ? (
        <div className="sessions-container">
          <div className="arrow-container">
            <div
              className={
                "session-arrow " +
                (props.showClientSessions ? "arrow-close" : "arrow-open")
              }
              onClick={() => {
                props.setClientSessionsList();
              }}
            />
          </div>

          {props.showClientSessions ? (
            <>
              <div style={{ width: "100%" }}>
                <Card style={{ height: "65%" }}>
                  {props.clientSessions !== null &&
                  props.clientSessions !== undefined ? (
                    <SessionsInfoComponent
                      conversation={props.clientSessions}
                    />
                  ) : null}
                </Card>
                <div className="take-a-note-container">
                  <div className="take-a-note">
                    <p>Take a note</p>
                  </div>
                  <div className="notes-container">
                    <textarea
                      type="text"
                      placeholder="Take a note.."
                      onChange={(event) => props.setNotes(event.target.value)}
                      value={props.notes}
                    />
                    <div
                      className="done"
                      onClick={() =>
                        utils.updateClientNotes(
                          props.clientId,
                          props.notes,
                          props.currentConversationIndex,
                          props.check403Error
                        )
                      }
                    >
                      Done
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="div-no-bg"></div>
          )}
        </div>
      ) : null}
    </Col>
  );
};

export default ClientSessionsNotes;
