
import { Modal, Form, Button } from "react-bootstrap";
import { isMobileOnly } from "react-device-detect";

const EditBackground = (props) => {
    const {
        showEditBackground, setShowEditBackground,
        onBackgroundEdited, background,
        setTempBackground } = props

    function changeBackground(ev){
        setTempBackground(ev.target.value);
    }
    return (
        <Modal
        show={showEditBackground}
        onHide={() => setShowEditBackground(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="editModal"
            dialogClassName={isMobileOnly ? "modal-fullscreen modal-backdrop" : ""}
            backdrop='static'
            keyboard={false}
        >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <div className="title">Edit Background</div>
        <Form className="px-lg-5 px-md-5 px-sm-3 px-3" onSubmit={onBackgroundEdited}>

            <Form.Group controlId="formBackground">
                <Form.Label>Detail about your background so that other customers can be impressed by your experience</Form.Label>
                <Form.Control name="background_description" as="textarea" style={{resize: 'none'}} rows={11} className="custom_input" onChange={changeBackground} defaultValue={background}/>
            </Form.Group>

            <Button type="submit" className="saveButton mb-5">Save</Button>
        </Form>
            </Modal.Body>
        </Modal> 
    )
}
export default EditBackground