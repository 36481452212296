import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "../../styles/notificationsPopup.scss";
import "../../styles/accountManageActionModal.scss";
import "../../styles/endSession.scss";

const NotificationsPopup = (props) => {
  //debugger;

  var info = "";
  if (props.notificationInfo) {
    info = JSON.parse(props.notificationInfo.data);
  }

  return props.notificationInfo ? (
    <Modal
      show={props.show}
      onHide={() => props.setShowNewNotificationModal()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="end-session-action-modal"
      backdrop='static'
      keyboard={false}
    >
      <Modal.Body>
        <div className="title">New conversation request</div>

        <div className="px-5">
          <div className="subtitle">{info.message}</div>

          <div className="btns-container">
            <Button
              type="submit"
              className="button btnDarkBlue"
              style={{marginRight: "10px", marginLeft: "0px"}}
              onClick={() =>
                props.onAcceptRejectNotification(
                  false,
                  info.conversation_id,
                  props.notificationInfo
                )
              }
            >
              Reject
            </Button>

            <Button
              type="submit"
              className="button btnGreen"
              style={{marginRight: "0px", marginLeft: "10px"}}
              onClick={() =>
                props.onAcceptRejectNotification(
                  true,
                  info.conversation_id,
                  props.notificationInfo
                )
              }
            >
              Accept
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  ) : null;
};

export default NotificationsPopup;
