import PromotionalIcon from '../../assets/icons/promotional_icon.png'
import TwentyFourIcon from '../../assets/icons/24_7_icon.png'
import TrustedIcon from '../../assets/icons/trusted_icon.png'
import CompetitiveIcon from '../../assets/icons/competitive_icon.png'
import EasyPaymentIcon from '../../assets/icons/easy_payment_icon.png'
import ReportingIcon from '../../assets/icons/reporting_icon.png'
import QuickIcon from '../../assets/icons/quick_affiliate_icon.png'
import CompetitiveIconAffiliate from '../../assets/icons/competitive_affiliate_icon.png'
import { isMobile } from 'react-device-detect'


const AboutUs = (props) => {
    const { role } = props;
    const isAffiliate = role === 'affiliate';
    const specialities = isAffiliate ?
        [
            {
                icon: QuickIcon,
                text: 'Quick setup account​',
                mobileOrder: 1
            },
            {
                icon: TwentyFourIcon,
                text: '24/7 available psychic advisors​',
                mobileOrder: 4
            },
            {
                icon: TrustedIcon,
                text: 'Trusted psychic readers',
                mobileOrder: 3
            },
            {
                icon: CompetitiveIconAffiliate,
                text: 'Competitive affiliate fees​',
                mobileOrder: 2
            },
            {
                icon: EasyPaymentIcon,
                text: 'Easy and quick payment solution​',
                mobileOrder: 5
            },
            {
                icon: ReportingIcon,
                text: 'Reporting Analytics dashboard​',
                mobileOrder: 6
            },

        ]
        :
        [
            {
                icon: PromotionalIcon,
                text: 'Promotional tools​',
                mobileOrder: 1
            },
            {
                icon: TwentyFourIcon,
                text: '24/7 available psychic advisors​',
                mobileOrder: 4
            },
            {
                icon: TrustedIcon,
                text: 'Trusted psychic readers',
                mobileOrder: 3
            },
            {
                icon: CompetitiveIcon,
                text: 'Competitive commissions​',
                mobileOrder: 2
            },
            {
                icon: EasyPaymentIcon,
                text: 'Easy and quick payment solution​',
                mobileOrder: 5
            },
            {
                icon: ReportingIcon,
                text: 'Reporting analytics dashboard​',
                mobileOrder: 6
            },
        ]
    return (
        <div style={isMobile ? { margin: '0 8.2vw' } : !isAffiliate ? { margin: '0 23vw' } : { margin: '0 21.7vw' }}>
            {!isAffiliate ?
                <>
                    <p style={styles.title}>About Us</p>
                    <p style={styles.desc}>Advia was founded by a group of experienced professionals from the online psychic advisor’s industry.<br />
                        Our innovative platform bridges clients and psychic advisors from various fields.
                        The goal is to empower advisors by connecting them to the right customer, providing actionable insights and promotional tools to improve results.
                        We truly understand the real needs of both sides and strive to provide
                        several missing significant features that can make a real difference.
                    </p>
                </>
                : null}
            <div className={!isAffiliate ? 'd-flex flex-wrap pb-3' : 'd-flex flex-wrap mt-5 pt-4 pb-3'} style={{ padding: '0 6%' }}>
                {specialities.map((special, id) => {
                    return (
                        <div key={id} className='col-lg-4 col-md-4 col-sm-12 col-12 d-flex mb-5 pb-2' style={isMobile ? { paddingLeft: '12vw', paddingRight: '6vw', order: special.mobileOrder } : {}}>
                            <img src={special.icon} style={!isAffiliate ? styles.itemImage : styles.itemImageAffiliate} alt='speciality icon' />
                            <div style={styles.itemText}>{special.text}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default AboutUs;

const styles = {
    title: {
        fontSize: '32px',
        lineHeight: '34px',
        textAlign: 'center',
        color: 'white',
        marginTop: '46px',
        marginBottom: '20px',
        fontWeight: '600'
    },
    desc: {
        fontSize: "16px",
        lineHeight: "34px",
        textAlign: 'center',
        color: 'white',
        marginBottom: '44px'
    },
    itemImage: {
        marginRight: '20px',
        width: '44px',
        height: '44px'
    },
    itemImageAffiliate: {
        marginRight: '20px',
        width: '56px',
        height: '56px'
    },
    itemText: {
        fontSize: '16px',
        color: '#E6E6E6',
        lineHeight: '22px',
        fontWeight: '600'
    }
}