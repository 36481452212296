import bg from "../../../assets/img/bg/banner.png";
import { isDesktop, isMobile } from "react-device-detect";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "../../../styles/paymentMethods.scss";

import cardLogo from '../../../assets/icons/card_logo.png'
import clockLogo from '../../../assets/icons/clock_logo.png'
import privateLogo from '../../../assets/icons/private_logo.png'

import * as utils from "../../../Utilities/Utils";

import { useEffect, useState } from "react";
import PaymentMethodsModal from "../../../components/modals/PaymentMethodsModal";

import secure_bg from '../../../assets/img/bg/secure_payment_bg.png'
import axios from "axios";
import * as myAppConfig from '../../../constants/AppConstants'
import ProfileUpdated from "../../../components/advisorProfile/ProfileUpdated";

const PaymentMethods = (props) => {

    const [dataLoading, setDataLoading] = useState(false);
    const [cardsList, setCardsList] = useState();
    const [showDefaultCardSuccess, setShowDefaultCardSuccess] = useState(false);
    const [showCardRemovedSuccess, setShowCardRemovedSuccess] = useState(false);

    useEffect(() => {
        if (!dataLoading) {
            setDataLoading(true);
            axios.get(myAppConfig.GET_CARDS_LIST, {
                headers: { 'token': localStorage.getItem('userToken') }
            }).then(response => {
                setDataLoading(false);
                if (response.data.status === 'success') {
                    setCardsList(response.data.list_of_cards)
                }
            }).catch(err => props.check403Error(err));
        }
    }, [])

    async function addCard() {
        axios
            .get(myAppConfig.ADD_CARD, {
                headers: { 'token': localStorage.getItem('userToken') }
            })
            .then(response => {
                if (response.data.status === 'success') {
                    if (response.data.payUrl) {
                        utils.redirectToAddCard(
                            response.data,
                            'payment-methods'
                        );
                    }
                }
                else {
                    console.log(response.data)
                }
            }
            ).catch(err => props.check403Error(err));
    }

    async function changeDefaultCard(credentials) {
        axios
            .post(myAppConfig.SET_CARD_AS_DEFAULT, credentials, {
                headers: { 'Content-Type': 'multipart/form-data', 'token': localStorage.getItem('userToken') }
            })
            .then(response => {
                if (response.data.status === 'success') {
                    setShowChangeDefaultModal(false);
                    setShowDefaultCardSuccess(true);
                }
                else {
                    console.log(response.data)
                }
            }
            ).catch(err => props.check403Error(err));
    }

    const addANewCard = async (e) => {
        e.preventDefault();
        await addCard();
    }

    const setDefaultCard = async (id) => {
        var myFormData = new FormData();
        myFormData.append('card_id', id);
        await changeDefaultCard(myFormData);
    }

    async function deleteCard(credentials) {
        axios
            .post(myAppConfig.DELETE_CARD, credentials, {
                headers: { 'Content-Type': 'multipart/form-data', 'token': localStorage.getItem('userToken') }
            })
            .then(response => {
                if (response.data.status === 'success') {
                    setShowCardRemovedSuccess(true);
                }
                else {
                    console.log(response.data)
                }
            }
            ).catch(err => props.check403Error(err));
    }

    const removeCard = async (id) => {
        var myFormData = new FormData();
        myFormData.append('card_id', id);
        await deleteCard(myFormData);
    }

    const [showChangeDefaultModal, setShowChangeDefaultModal] = useState(false);

    const singleCard = (cardInfo) =>
        <Card className="card-custom custom-padding payment-method">
            <div className='d-flex justify-content-between p-4'>
                <div className='card-details d-flex'>
                    <div className='card-number'>
                        {'**** ' + cardInfo.ExtShvaParams_CardNumber5}
                    </div>
                    <div className='card-number expiration-date ms-3'>
                        {cardInfo.CardValidityMonth + '/' + cardInfo.CardValidityYear}
                    </div>
                </div>
                <Button onClick={(ev) => { ev.preventDefault(); removeCard(cardInfo.id) }}>Remove Card</Button>
            </div>
            {parseInt(cardInfo.default_card) === 1 ?
                <>
                    <hr />
                    <div className='default card-number text-center w-100 py-4'>
                        Current default payment method
                    </div>
                </>
                : null}
        </Card>

    return (
        <>
            <ProfileUpdated
                title='Default card updated'
                description="You've successfully updated your default card."
                showProfileUpdatedMessage={showDefaultCardSuccess}
                setShowProfileUpdatedMessage={setShowDefaultCardSuccess}
            />
            <ProfileUpdated
                title='Card removed successfully'
                description="You've successfully removed the card."
                showProfileUpdatedMessage={showCardRemovedSuccess}
                setShowProfileUpdatedMessage={setShowCardRemovedSuccess}
            />
            <PaymentMethodsModal
                show={showChangeDefaultModal}
                setShow={setShowChangeDefaultModal}
                title='Change Default Payment Method'
                onCardSelected={setDefaultCard}
                cardsList={cardsList?.filter((card) => card.ExtShvaParams_CardNumber5 !== null).map((card) => {
                    return (
                        {
                            label: `
                        <div className='card-details d-flex justify-content-between'>
                            <div className='card-number'>
                                ${'**** ' + card.ExtShvaParams_CardNumber5}
                            </div>
                            <div className='card-number expiration-date ms-3'>
                                ${card.CardValidityMonth + '/' + card.CardValidityYear}
                            </div>
                        </div>`,
                            value: card.id
                        })
                }) ?? []}
            />
            <div>
                <img src={bg} style={{ position: 'absolute', top: '0', width: '100%', zIndex: '-1', objectFit: 'cover' }} alt='bg' />
                <Container
                    fluid
                    className='paymentMethods-container p-0'
                >
                    <Row style={!isMobile ? { marginTop: '140px', marginBottom: '120px', justifyContent: 'space-between' } : { marginTop: '24px', marginBottom: '16px' }}>
                        <Col col={7} lg={7} md={7} sm={12} xs={12} className="d-flex flex-column leftSide mb-4 mb-lg-0">
                            <div className='title'>Payments method</div>
                            <div style={isDesktop ? { width: '80%' } : {}}>
                                {cardsList ? cardsList?.filter((card) => card.ExtShvaParams_CardNumber5 !== null).map((card) => singleCard(card)) : null}
                                {cardsList && cardsList?.filter((card) => card.ExtShvaParams_CardNumber5 !== null).length > 1 ?
                                    <Button className='change-default mb-4 py-3 w-100' onClick={() => setShowChangeDefaultModal(true)}>Change Default Payment Method</Button>
                                    : null
                                }
                                <Button className='add-card mb-4 py-3 w-100' onClick={addANewCard}>Add a new Card</Button>
                            </div>
                        </Col>
                        <Col col={5} lg={5} md={5} sm={12} xs={12} className="d-flex flex-column rightSide mb-4 mb-lg-0 pe-md-0 ps-md-5">
                            <Card className='secure-info'>
                                <img src={secure_bg} alt='background' />
                                <div className='d-flex mb-2'>
                                    <img src={cardLogo} alt='card logo' className='logo' />
                                    <div className='section-title'>Secure Payment</div>
                                </div>
                                <div className='section-info pb-3 mb-3'>Trusted by millions of customers. Our Policy will make sure your transactions are secure.</div>
                                <div className='d-flex mb-2'>
                                    <img src={privateLogo} alt='card logo' className='logo' />
                                    <div className='section-title'>Private, Safe, Secure</div>
                                </div>
                                <div className='section-info pb-3 mb-3'>Your conversations and psychic readings are always 100% anounymous and confidential.</div>
                                <div className='d-flex mb-2'>
                                    <img src={clockLogo} alt='card logo' className='logo' />
                                    <div className='section-title'>Anytime, Anywhere</div>
                                </div>
                                <div className='section-info'>We’re here it’s convenient for you 24/7 via phone, chat or app</div>

                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div >
        </>
    )
}

export default PaymentMethods