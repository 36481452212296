import React from 'react';
import Footer from '../components/footer/Footer'
import GeneralNavbar from '../components/navbar/GeneralNavbar';
import Login from '../views/pages/Login';

const GeneralLayout = (props) => {
  const { children, setLoginModalShow, setUserToken, userType, setUserType, loginModalShow, categories, check403Error, hideSignUp, setHideSignUp, userToken, userEmail } = props;

  return (
    <>
      <GeneralNavbar categories={categories} check403Error={check403Error} setLoginModalShow={setLoginModalShow} userToken={userToken} userType={userType} userEmail={userEmail} />
      <Login setUserToken={setUserToken} setLoginModalShow={setLoginModalShow} setUserType={setUserType} loginModalShow={loginModalShow} check403Error={check403Error} hideSignUp={hideSignUp} hideLogin={!hideSignUp} setHideSignUp={setHideSignUp} />
      {children}
      <Footer categories={categories} />
    </>
  )
}

export { GeneralLayout }

