import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "../../styles/advisorIsAvailable.scss";
import * as myAppConfig from "../../constants/AppConstants";

const AdvisorIsAvailableModal = (props) => {
  var info = "";
  if (props.notifyMeInfo) {
    info = JSON.parse(props.notifyMeInfo.data);
  }

  return props.notifyMeInfo ? (
    <Modal
      show={props.show}
      onHide={() => props.setShowNotifyMe(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="end-session-action-modal"
      backdrop='static'
      keyboard={false}
    >
      <Modal.Body>
        {props.notifyMeInfo.advisor_logo_image !== null &&
        props.notifyMeInfo.advisor_logo_image !== "" ? (
          <img
            src={
              myAppConfig.imagesApiURL + props.notifyMeInfo.advisor_logo_image
            }
            alt="advisor talker logo"
            className="modal-user-image"
          />
        ) : null}
        <div className="title">{info.message}</div>

        <div className="px-5">
          <div className="subtitle">
            <p>
              {" "}
              We wanted to notify you that your advsior is available. Would you
              like to start a session with him now?
            </p>
          </div>

          <div className="btns-container">
            <Button
              type="submit"
              className="button btnDarkBlue"
              style={{ marginRight: "10px", marginLeft: "0px" }}
              onClick={() => props.onNewSession(false, props.notifyMeInfo)}
            >
              No
            </Button>

            <Button
              type="submit"
              className="button btnGreen"
              style={{ marginRight: "0px", marginLeft: "10px" }}
              onClick={() => props.onNewSession(true, props.notifyMeInfo)}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  ) : null;
};
export default AdvisorIsAvailableModal;
