import React from 'react';
import { Navbar, Row, Col, Button } from 'react-bootstrap';
import logo from '../../assets/icons/logo.png';
import styles from './../../styles/wizard.module.scss';
import PropTypes from 'prop-types';
// import helpIcon from './../../assets/icons/help_icon.png';
import ClientDropDown from './dropDowns/ClientDropDown';

// const NEED_HELP = "Need Help?";

function WizzardNavbar({ setLoginModalShow, userIsLoggedIn, check403Error }) {

  const handleLogin = () => {
    // console.log("Login btn clicked");
    setLoginModalShow(true);
  }

  return (
    <Navbar expand="lg" className="navbar-dark">
      <Row className={styles.containerTopWizard} style={{ width: "100%" }}>
        <Col className={"col-xs-4 col-md-4 col-lg-4"} >
          {/* <Button className={styles.buttonNeedHelp} >{NEED_HELP}</Button>
          <Button className={styles.buttonNeedHelpMobile}
            style={{ backgroundImage: "url(" + helpIcon + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
          </Button> */}
        </Col>
        <Col className={"col-xs-4 col-md-4 col-lg-4 " + styles.containerImage}>
          <img src={logo} alt="logo" className={styles.imgLogoWizard} onClick={() => window.location.pathname = '/'} />
        </Col>
        <Col className={"col-xs-4 col-md-4 col-lg-4 d-flex justify-content-end"} >
          {userIsLoggedIn ?
            <ClientDropDown check403Error={check403Error} />
            :
            <Button className={styles.buttonSignIn} onClick={handleLogin}></Button>
          }
        </Col>
      </Row>
    </Navbar>
  );
}


export default WizzardNavbar;

WizzardNavbar.propTypes = {
  userIsLoggedIn: PropTypes.bool,
  setLoginModalShow: PropTypes.func,
  check403Error: PropTypes.func
}
