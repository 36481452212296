import React, { useEffect, useState } from "react";
import { Col, Card } from "react-bootstrap";
import { Label } from "reactstrap";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  Tooltip,
  //XAxis,
  //YAxis,
} from "recharts";
import "../styles/revenueCard.scss";
import icon_increase_arrow from "../assets/icons/arrows/increaseArrow.png";
import icon_decrease_arrow from "../assets/icons/arrows/decreaseArrow.png";

const maxNumberOfHours = 24;
const maxNumberOfDays = 30;
const RevenueCard = (props) => {
  const {
    id,
    item,
    interval,
    tempStartDate,
    tempEndDate,
    data2Array,
    setData2Array,
    hideType,
  } = props;
  const [data2, setData2] = useState(data2Array[id] ?? null);

  /*const arrayLength =
    item.transactions && item.transactions.length > 0
      ? interval === 0
        ? Math.min(maxNumberOfHours, item.transactions.length)
        : Math.min(maxNumberOfDays, item.transactions.length)
      : maxNumberOfHours;
  const array =
    item.transactions && item.transactions.length > 1
      ? getRandom(item.transactions, arrayLength)//.sort((a, b) => a.created_at ? (new Date(a.created_at) - new Date(b.created_at)) : (new Date(a.created_date) - new Date(b.created_date)))
      : item.transactions && item.transactions.length === 1
      ? new Array(2).fill(null).map(() => item.transactions[0])
      : new Array(arrayLength).fill(null).map(() => 0);*/

  var newArray = [];
  if (item.transactions === undefined || item.transactions.length === 0) {
    const nested_object = {};
    nested_object["total"] = 0;
    nested_object["created_at"] = "";
    newArray.push(nested_object);
  } else {
    Object.entries(item.transactions).map(function (entry) {
      const nested_object = {};
      nested_object["created_at"] = entry[0];
      nested_object["total"] = entry[1];
      newArray.push(nested_object);
    });
  }
  if (newArray.length > 1) {
    newArray = newArray.sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at)
    );
  }
  const array = newArray;

  /*function getRandom(arr, n) {
    var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }*/

  useEffect(() => {
    if (interval < 3 || (interval === 3 && tempStartDate && tempEndDate)) {
      const myArray = array.map((item, id) => {
        return {
          uv: item.total,
          date: item.created_at ?? item.created_date ?? "",
        };
      });

      setData2Array((data2Array) => [...data2Array, myArray]);
      setData2(myArray);
    }
  }, [interval, setData2Array]);

  function CustomTooltip({ payload, label, active }) {
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            margin: "0",
            padding: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            border: "1px solid rgb(204, 204, 204)",
            whiteSpace: "nowrap",
            height: "fit-content",
          }}
        >
          {/*<p className="label mb-2">{`Device type: ${payload[0].payload.name}`}</p>*/}
          <p className="label mb-2">
            {id === 0 || id > 3
              ? `Amount: $${payload[0].value.toFixed(2)}`
              : id === 3
              ? `Number of sessions: ${payload[0].value}`
              : `Number of clients: ${payload[0].value}`}
          </p>
          <p className="label mb-0">{`Date: ${payload[0].payload.date}`}</p>
        </div>
      );
    }
    return null;
  }

  return data2 ? (
    <Card className="card-custom card-revenue">
      <div className="">
        <div className="card-title-container custom-card-div-margin-left-22">
          <p className="cardTitle">{item.title}</p>

          <div className="percentage-margin-right-31">
            <img
              className="arrow-image"
              src={
                item.percentagePositive
                  ? icon_increase_arrow
                  : icon_decrease_arrow
              }
              alt=""
            />
            <Label
              className={
                item.percentagePositive
                  ? "cardPercentagePositive"
                  : "cardPercentageNegative"
              }
            >
              {item.percentage} %
            </Label>
          </div>
        </div>
        <Label className="cardAmount custom-card-div-margin-left-22">
          {(id === 0 || id > 3 ? "$" : "") +
            Number(Number(item.amount).toFixed(2))}
        </Label>
        {!hideType ? (
          <div className="custom-amount-container">
            <Col lg={3}>
              <div className="custom-column-display">
                <Label className="cardColumnAmount">DT</Label>
                <Label className="cardColumnAmount">{item.dtAmount}</Label>
              </div>
            </Col>

            <Col lg={3}>
              <div className="custom-column-display">
                <Label className="cardColumnAmount">Mobile Web</Label>
                <Label className="cardColumnAmount">
                  {item.mobileWebAmount}
                </Label>
              </div>
            </Col>

            <Col lg={3}>
              <div className="custom-column-display">
                <Label className="cardColumnAmount">iOS</Label>
                <Label className="cardColumnAmount">{item.iOSAmount}</Label>
              </div>
            </Col>

            <Col lg={3}>
              <div className="custom-column-display">
                <Label className="cardColumnAmount">Android</Label>
                <Label className="cardColumnAmount">{item.androidAmount}</Label>
              </div>
            </Col>
          </div>
        ) : null}

        <div className="chart-container">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={data2}
              margin={{ top: 5, right: 0, left: 0, bottom: 0 }}
              fontSize={12}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={"#2AD2C7"} stopOpacity={0.3} />
                  <stop offset="95%" stopColor={"#2BD0C9"} stopOpacity={0} />
                </linearGradient>
              </defs>
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="linear"
                strokeWidth={2}
                dataKey="uv"
                fillOpacity={1}
                fill="url(#colorUv)"
                stroke="#2BD0C9"
              />

              {/*<XAxis dataKey={"date"} type="category" dy={1} />*/}
              {/*<YAxis dataKey={"uv"} type="number" dx={1} />*/}
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </Card>
  ) : null;
};

export default RevenueCard;
