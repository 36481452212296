import React from 'react'
import { Col, Row } from 'react-bootstrap';
import ReactStars from "react-rating-stars-component";
import * as Icon from "react-feather";
import '../styles/card.scss';
import * as myAppConfig from "../constants/AppConstants";
import { isIOS, isSafari } from 'react-device-detect';

const handleAdvisorTap = (name) => {
    if (name !== undefined) {
        window.location.pathname = "/advisor-profile/" + name;
    }
}

const userLogged = localStorage.getItem('userToken');
const have3Mins = ((parseInt(localStorage.getItem('has3mins')) === 1) || !userLogged) ? 1 : 0;

const Card = (props) => {
    return (
        <Col sm={6} md={6} lg={3} className="mb-4 card-space col-12">
            <div className={"card push-on-hover advisor " + ((isSafari || isIOS) ? "safariIos" : "")} onClick={() => handleAdvisorTap(props.name)}>
                <div className="header_image">
                    <img src={props.image} alt="user" />
                </div>

                <div className="infos">
                    <Row className="m-0 pe-0">
                        <Col className="col-7">
                            <div className="advisor_name pl-3 pt-3">{props.name}</div>
                            <div className="advisor_categ">{props.categ}</div>
                            <div className="stars d-flex align-items-center">
                                <ReactStars edit={false} size={16.8} value={parseInt(props.stars.toFixed(1))} isHalf={true} color='#233558' activeColor="#19AAA1" />
                                <div className="score ml-3">{props.stars.toFixed(1)} ({props.reviewsNo})</div>
                            </div>
                            <div className="price mt-3">from <span>{props.price}</span></div>

                        </Col>
                        <Col className='col-5 ps-0'>
                            <div className="user_actions d-flex justify-content-end">
                                {props.audio === 1 ? <div className="audio_call d-flex justify-content-center align-items-center"><Icon.Phone width={13.3} height={13.3} /></div> : <div style={{ visibility: "hidden" }} className="audio_call d-flex justify-content-center align-items-center"><Icon.Phone width={13.3} height={13.3} /></div>}
                                {props.video === 1 ? <div className="vdeo_call d-flex justify-content-center align-items-center"><Icon.Video width={13.3} height={13.3} /></div> : ""}
                                {props.chat === 1 ? <div className="message d-flex justify-content-center align-items-center"><Icon.MessageSquare width={13.3} height={13.3} /></div> : ""}
                            </div>

                            <div className="readings"><span>{props.readings}</span> readings</div>

                            <div className="status">
                                {props.status === myAppConfig.ADVISOR_STATUS_STATES.busy.id ? <div className="busy">{myAppConfig.ADVISOR_STATUS_STATES.busy.title}</div> : ''}
                                {props.status === myAppConfig.ADVISOR_STATUS_STATES.available.id ? <div className="available">{myAppConfig.ADVISOR_STATUS_STATES.available.title}</div> : ''}
                                {props.status === myAppConfig.ADVISOR_STATUS_STATES.away.id ? <div className="away">{myAppConfig.ADVISOR_STATUS_STATES.away.title}</div> : ''}
                            </div>
                        </Col>
                    </Row>
                    <Row className="footer_text">
                        {have3Mins === 1 || props.free ?
                            <Col className='col-7'>
                                <div className="bookText p-3 pe-0 ">{props.usersReviewing} users reviewing now</div>
                            </Col> : <Col>
                                <div className="bookText p-3 text-center" style={{ marginTop: '3px' }}>{props.usersReviewing} users reviewing now</div>
                            </Col>}
                        {have3Mins === 1 ?
                            <Col className="p-0 col-5">
                                <div className="free_time text-center pt-3 pb-3">First 3 min FREE</div>
                            </Col>
                            :
                            props.free ?
                                <Col className="p-0 col-5">
                                    <div className="free_time text-center pt-3 pb-3">{props.free}</div>
                                </Col> : ''
                        }
                    </Row>
                </div>
            </div>
        </Col>
    )
}

export default Card
