import React, { useState } from "react";
import { Button, Image } from "react-bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/periodDropdown.scss";
import moment from "moment";

import icon_dashboard_dropdown_arrow from "../assets/icons/arrows/dashboard_dropdown_arrow.png";

const PaymentPeriodDropDownDays = (props) => {
    const { startDate, setStartDate, endDate, setEndDate } = props;
    const [showCalendar, setShowCalendar] = useState(false);

    const [tempStartDate, setTempStartDate] = useState(startDate);
    const [tempEndDate, setTempEndDate] = useState(endDate);

    const onChange = (dates) => {
        const [start, end] = dates;
        if (startDate === null) {
            setStartDate(start);
        }
        setTempStartDate(start);
        setTempEndDate(end);
    };

    const onClearChanges = () => {
        setTempStartDate(null);
        setTempEndDate(null);
    };

    const reset = () => {
        if (tempStartDate === null) {
            setTempStartDate(startDate)
        }
        if (tempEndDate === null) {
            setTempEndDate(endDate);
        }
    }

    const onApplyChanges = () => {
        if (tempEndDate !== null) {
            setEndDate(tempEndDate)
        }
        if (tempStartDate !== null) {
            setStartDate(tempStartDate);
            setShowCalendar(false);
        }
    };

    return (
        <>
            <div id='paymentOverlay' style={showCalendar ? { display: 'block' } : { display: 'none' }} onClick={() => setShowCalendar(false)} />
            <div className="period-drop-down-container payment">
                {showCalendar ? (
                    <div className="picker-date-container">
                        <DatePicker
                            onChange={onChange}
                            peekNextMonth
                            dateFormat="yyyy-MM-dd"
                            startDate={tempStartDate}
                            endDate={tempEndDate}
                            selectsRange
                            inline
                            onClickOutside={reset}
                        />

                        <div className="apply-changes" onClick={() => onApplyChanges()}>Apply changes</div>

                        <div className="clear-changes" onClick={() => onClearChanges()}>
                            <p>Clear</p>
                        </div>
                    </div>
                ) : null}

                <Button
                    className="dashboard-dropdown period-dropdown"
                    alignRight
                    id="dropdown-menu-align-right"
                    onClick={() => setShowCalendar(true)}
                >
                    {new Date(startDate).getMonth() !== new Date(endDate).getMonth() ?
                        moment(startDate).format('D MMMM yyyy') + ' - ' + moment(endDate).format('D MMMM yyyy')
                        : moment(startDate).format('D') + ' - ' + moment(endDate).format('D MMMM yyyy')}
                    <Image className='ms-3' src={icon_dashboard_dropdown_arrow} />
                </Button>
            </div>
        </>
    );
};

export default PaymentPeriodDropDownDays;
