import React, { useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { AdminLayout } from "../layouts/";
import AdminProfile from "../views/pages/admin/AdminProfile";
import AdminHomePage from "../views/pages/admin/AdminHomePage";
import Archive from "../views/pages/admin/Archive";
import Payments from "../views/pages/admin/Payments";
import RatingAndReviews from "../views/pages/admin/RatingAndReviews";
import ChatScreen from "../views/pages/client/ChatScreen";
import * as myAppConfig from '../constants/AppConstants'
import CompleteProfile from "../views/pages/admin/CompleteProfile";
import AllChats from "../views/pages/chat/AllChats";
import Sessions from "../views/pages/admin/Sessions";
import { GeneralLayout } from "../layouts/GeneralLayout";
import CallScreen from "../views/pages/client/CallScreen";
import CategoryPage from "../views/pages/client/CategoryPage";
import VideoChat from "../views/pages/client/VideoChat";
import BestPractices from "../views/pages/admin/BestPractices";

const AdminRouter = (props) => {
  const { homePageDetails, setHomePageDetails, check403Error, userType, userToken, setLoginModalShow, setUserToken, setUserType, loginModalShow, hideSignUp, setHideSignUp } = props;
  let showCompleteProfile = localStorage.getItem(myAppConfig.LOCAL_STORAGE_VALUES.SHOW_COMPLETE_PROFILE.Title) === myAppConfig.LOCAL_STORAGE_VALUES.SHOW_COMPLETE_PROFILE.Values.YES;

  function RouteWrapper({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) => {
          const layoutProps = {
            children: props,
            categories: homePageDetails?.categories ?? [],
            userEmail: localStorage.getItem("userEmail"),
            check403Error: check403Error
          };

          return (
            <AdminLayout {...layoutProps}>
              <Component setHomePageDetails={setHomePageDetails} homePageDetails={homePageDetails} check403Error={check403Error} />
            </AdminLayout>
          );
        }}
      />
    );
  }

  function GeneralRouteWrapper({
    component: Component,
    ...rest
  }) {
    return (
      <Route {...rest} render={(props) => {
        const layoutProps = {
          children: props,
          setLoginModalShow: setLoginModalShow,
          userToken: userToken,
          categories: homePageDetails?.categories ?? [],
          setUserToken: setUserToken,
          setUserType: setUserType,
          loginModalShow: loginModalShow,
          check403Error: check403Error,
          hideSignUp: hideSignUp,
          setHideSignUp: setHideSignUp,
          userType: userType,
          userEmail: localStorage.getItem("userEmail")
        }

        return (
          <GeneralLayout {...layoutProps}>
            <Component homePageDetails={homePageDetails} setHomePageDetails={setHomePageDetails} categories={homePageDetails?.categories ?? []} setLoginModalShow={setLoginModalShow} setHideSignUp={setHideSignUp} check403Error={check403Error} />
          </GeneralLayout>
        )
      }
      } />
    );
  }

  function RouteWrapperReviews({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) => {
          const layoutProps = {
            children: props,
            categories: homePageDetails?.categories ?? [],
            userEmail: localStorage.getItem("userEmail"),
            check403Error: check403Error
          };
          return (
            <AdminLayout {...layoutProps}>
              <Component homePageDetails={homePageDetails} reviews={props.location.state} check403Error={check403Error} />
            </AdminLayout>
          );
        }}
      />
    );
  }


  return (
      (showCompleteProfile && userToken !== null) || myAppConfig.VERSION_FOR_PRODUCTION ?
      <Switch>
        <GeneralRouteWrapper
          exact
          path="/"
          component={CompleteProfile} />
        <Redirect to='/' />
      </Switch>
        :
        <Switch>
          <RouteWrapper exact path="/" component={AdminHomePage} />
          <RouteWrapper
            exact
            path="/profile-settings"
            component={AdminProfile}
          />
          <RouteWrapper exact path="/registered-users" component={Archive} />
          <RouteWrapper exact path="/payments" component={Payments} />
          <RouteWrapperReviews
            exact
            path="/rating-and-reviews"
            component={(props) => <RatingAndReviews {...props} />}
          />
          <RouteWrapperReviews
            exact
            path="/sessions"
            component={(props) => <Sessions {...props} />}
          />
          <RouteWrapper
            path="/category"
            component={CategoryPage}
          />
          <RouteWrapper
            exact
            path="/chat"
            component={ChatScreen} />
          <RouteWrapper exact path="/station" component={AllChats} />
          <RouteWrapper
              exact
              path="/call"
              component={CallScreen} />
          <RouteWrapper
              exact
              path="/video-chat"
              component={VideoChat} />
        <Route exact path='/best-practices' component={BestPractices} />
          <Redirect to='/' />
        </Switch>
  );
};

export default AdminRouter;
