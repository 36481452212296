import React, { useState } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { Dashboard, Advisors, Archive, Clients, Client, Settings } from "../views/pages/superAdmin"
import { SuperAdminLayout, SimpleSuperAdminLayout } from '../layouts/';
import SuperAdminProfile from "../views/pages/superAdmin/SuperAdminProfile";
import CategoryPage from '../views/pages/client/CategoryPage'
import ChangePassword from '../views/pages/superAdmin/ChangePassword'
import AdvisorProfile from '../views/pages/superAdmin/AdvisorProfile'
import RatingAndReviews from "../views/pages/admin/RatingAndReviews";
import Projection from "../views/pages/superAdmin/Projection";

const SuperAdminRouter = (props) => {
  const { homePageDetails, setHomePageDetails, check403Error } = props;

  function RouteWrapper({
    component: Component,
    ...rest
  }) {
    return (
      <Route {...rest} render={(props) => {
        const layoutProps = {
          children: props,
          categories: homePageDetails?.categories ?? [],
          userEmail: localStorage.getItem('userEmail'),
          homePageDetails: homePageDetails,
          check403Error: check403Error
        }
        return (
          <SuperAdminLayout {...layoutProps}>
            <Component homePageDetails={homePageDetails} check403Error={check403Error} setHomePageDetails={setHomePageDetails} />
          </SuperAdminLayout>
        )
      }
      } />
    );
  }
  function SimpleRouteWrapper({
    component: Component,
    ...rest
  }) {
    return (
      <Route {...rest} render={(props) => {
        const layoutProps = {
          children: props,
          categories: homePageDetails?.categories ?? [],
          userEmail: localStorage.getItem('userEmail'),
          check403Error: check403Error
        }
        return (
          <SimpleSuperAdminLayout {...layoutProps}>
            <Component homePageDetails={homePageDetails} check403Error={check403Error} userId={props.location.state} advisorId={props.location.state} />
          </SimpleSuperAdminLayout>
        )
      }
      } />
    );
  }
  function RouteWrapperReviews({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) => {
          const layoutProps = {
            children: props,
            categories: homePageDetails?.categories ?? [],
            userEmail: localStorage.getItem("userEmail"),
            check403Error: check403Error
          };
          return (
            <SuperAdminLayout {...layoutProps}>
              <Component homePageDetails={homePageDetails} reviews={props.location.state} check403Error={check403Error} />
            </SuperAdminLayout>
          );
        }}
      />
    );
  }
  return (
      <Switch>
        <RouteWrapper
          exact
          path="/"
          component={Dashboard}
        />
        <RouteWrapper
          exact
          path="/advisors"
          component={Advisors}
        />
        <SimpleRouteWrapper
          exact
          path="/profile-settings"
          component={SuperAdminProfile}
        />
        <RouteWrapper
          exact
          path="/archive"
          component={Archive}
        />
        <RouteWrapper
          exact
          path="/clients"
          component={Clients}
        />
        <RouteWrapper
          exact
          path="/settings"
          component={Settings}
        />
        <SimpleRouteWrapper
          path="/category"
          component={CategoryPage}
        />
        <SimpleRouteWrapper
          exact
          path="/change-password"
          component={ChangePassword}
        />
        <SimpleRouteWrapper
          path="/advisor-profile"
          component={AdvisorProfile}
        />
        <SimpleRouteWrapper
          path="/advisor-projection"
          component={Projection}
        />
        <SimpleRouteWrapper
          path="/client-page"
          component={Client}
        />
        <RouteWrapperReviews
          exact
          path="/rating-and-reviews"
          component={RatingAndReviews}
        />
        <Redirect to='/' />
      </Switch>
  )

}

export default SuperAdminRouter
