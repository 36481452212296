import React from "react";
import { Button, Row, Table, Form } from "react-bootstrap";
import "../../../styles/newAdvisorRequestCard.scss";

const NewAdvisorRequestCard = (props) => {
  return (
    <div>
      <Row className="new-adv-request-card-row-container">
        <Table responsive>
          <thead>
            <tr>
              <th style={{ width: "25%" }}>Advisor Full Name</th>
              <th style={{ width: "25%" }}>Email</th>
              <th style={{ width: "20%" }}>Phone No.</th>
              <th style={{ width: "20%" }}>Category</th>
              <th style={{ width: "10%" }}>
                <Form.Check
                  className="new-adv-checkbox"
                  type="checkbox"
                  checked={props.isChecked}
                  onChange={() => props.onClick()}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ width: "25%" }}>
                <p>{props.data.full_name}</p>
              </td>
              <td style={{ width: "25%" }}>
                <p>{props.data.email}</p>
              </td>
              <td style={{ width: "20%" }}>
                <p>{props.data.phone_number}</p>
              </td>
              <td style={{ width: "20%" }}>
                <p>
                  {props.data.category.length > 0
                    ? props.data.category.name
                    : ""}
                </p>
              </td>
              <td style={{ width: "10%" }}>
                {props.data.full_name === null ? (
                  <p />
                ) : (
                  <Button
                    className="viewButton"
                    onClick={() =>
                      (window.location.pathname =
                      "/advisor-profile/" + props.data.full_name)
                    }
                  >
                    View
                  </Button>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </Row>
      <hr />
    </div>
  );
};

export default NewAdvisorRequestCard;
