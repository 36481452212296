import React from 'react';
import { Row, Col, Container, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css'
import '../../../styles/homePage.scss';
import RevenueCard from '../../../components/RevenueCard';
import '../../../styles/profile.scss';
import PeriodDropDown from '../../../components/PeriodDropDown';
import { isMobileOnly } from 'react-device-detect';
import SessionAndPricing from '../../../components/advisorProfile/SessionAndPricing';
import Promotions from '../../../components/advisorProfile/Promotions'
import { useState, useEffect } from 'react';
import axios from 'axios';
import * as myAppConfig from '../../../constants/AppConstants'
import * as utils from "../../../Utilities/Utils";

const dropDownValues = [
  "Last 24 hours",
  "Last 30 days",
  "Last 90 days",
  "Custom",
];

const AdminHomePage = (props) => {

  const { homePageDetails, setHomePageDetails, check403Error } = props;

  const profilePrices = JSON.parse(localStorage.getItem('profilePrices'));
  const profilePromotions = JSON.parse(localStorage.getItem('profilePromotions'));

  const [dropDownTitle, setDropDownTitle] = useState(dropDownValues[0]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [dashboardData, setDashboardData] = useState();
  const [dataLoading, setDataLoading] = useState(false);


  const [tempStartDate, setTempStartDate] = useState(null);//new Date());
  const [tempEndDate, setTempEndDate] = useState(null);

  const selectedInterval = dropDownValues.indexOf(dropDownTitle);
  const intervalDays = selectedInterval === 0 ? 1 : selectedInterval === 1 ? 30 : selectedInterval === 2 ? 90 : 0;

  const [data2Array, setData2Array] = useState([]);
  useEffect(() => {
    if (!dataLoading) {
      setDataLoading(true);
      let params;
      let interval = {
        interval: intervalDays,
        advisor_id: localStorage.getItem("profileId")
      }
      if (selectedInterval === 3 && tempStartDate && tempEndDate) {
        let fromTo = {
          from: startDate,
          to: endDate
        }
        params = Object.assign(interval, fromTo);
      }
      else {
        params = interval;
      }
      if ((selectedInterval < 3) || (selectedInterval === 3 && tempStartDate && tempEndDate)) {
        setData2Array([]);
        setDashboardData([]);
        axios
          .get(myAppConfig.GET_DASHBOARD_GRAPHS, {
            params: params,
            headers: { token: localStorage.getItem("userToken") },
          })
          .then((response) => {
            if (response.data.status === "success") {
              setDashboardData(utils.getDashboardGraphsData(response));
            }

          }).catch((err) => check403Error(err));

        axios
          .get(myAppConfig.GET_ADVISOR_DASHBOARD, {
            params: params,
            headers: { token: localStorage.getItem("userToken") },
          })
          .then((response) => {
            setDataLoading(false);
            if (response.data.status === "success") {
              const hpData = {
                categories: response.data.categories,
                specializations: response.data.specializations,
                languages: response.data.languages,
                skillsAndMethods: response.data.skillsAndMethods
              };
              localStorage.setItem("homePageDetails", JSON.stringify(hpData))
              if (!homePageDetails) {
                setHomePageDetails(hpData);
              }
            }
          })
          .catch((err) => check403Error(err));
      }
      else {
        setDataLoading(false);
      }
    }
  }, [dropDownTitle, startDate, endDate, check403Error, setHomePageDetails, selectedInterval, intervalDays, homePageDetails]);

  return (
    <>
      <div id="adminProfilePage">
        <Container fluid style={isMobileOnly ? { width: "91.5%" } : { width: "88.88%" }} className='px-0' >
          <br /><br />
          <div className="clientHomePageDIV">
            <p className="clientHomePageTitle">{(selectedInterval === 0 ? 'Daily' : selectedInterval === 1 ? 'Monthly' : selectedInterval === 2 ? 'Last 3 Months' : 'Custom Period') + ' Revenue'}</p>
            {!dataLoading ?
              <PeriodDropDown
                dropDownValues={dropDownValues}
                dropDownTitle={dropDownTitle}
                setDropDownTitle={setDropDownTitle}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                tempStartDate={tempStartDate}
                setTempStartDate={setTempStartDate}
                tempEndDate={tempEndDate}
                setTempEndDate={setTempEndDate} /> : null}
          </div>
          {dataLoading ?
            <div style={{ textAlign: 'center', marginBottom: '46px' }}>
              <Spinner animation='border' style={{ color: 'white' }} />
            </div> :
            <>
              <br /><br />
              <Row xs={1} sm={2} md={2} lg={3} className="g-4">
                {dashboardData ? dashboardData.map((_item, idx) => (
                  <Col key={idx}>
                    <RevenueCard hideType={true} item={_item} interval={selectedInterval} tempEndDate={tempEndDate} tempStartDate={tempStartDate} data2Array={data2Array} setData2Array={setData2Array} id={idx} />
                  </Col>
                )) : null}
              </Row>
              <div className="separatorLine" />
            </>}

          <Row className="row-custom-dasboard session-communitcation-dashboard">
            <Col lg={8}>
              <SessionAndPricing chatPrice={profilePrices[1]} callPrice={profilePrices[0]} videoPrice={profilePrices[2]} />
            </Col>

            <Col lg={4} className="promotions-rating">
              <Promotions freeMin={profilePromotions[0]} freeMinStatus={profilePromotions[1]} saleOffer={profilePromotions[2]} saleOfferStatus={profilePromotions[3]} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default AdminHomePage;


