import React, { useState, useEffect } from "react";
import { Row, Col, Spinner, Button } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import * as Icon from "react-feather";

import "../../styles/videoChatComponent.scss";
import * as myAppConfig from "../../constants/AppConstants";
import icon_logo from "../../assets/img/user.png";
import * as utils from "../../Utilities/Utils";
import VideoCallParticipant from "./VideoCallParticipant";
import FreeMinutesTooltip from "./FreeMinutesTooltip";

const { connect, createLocalTracks, isSupported } = require("twilio-video");

const VideoChatComponent = (props) => {
  const userType = localStorage.getItem("userType");
  const isClient = Number(userType) === myAppConfig.USER_TYPES.CLIENT;

  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);

  const [isMicOn, setIsMicOn] = useState(true);
  const [isVideoOn, setIsVideoOn] = useState(true);

  const otherTalkerCategory = utils.getCategoriesFromList(
    props.otherTalkerCategory
  );

  var timer = 0;
  const [elapsedTime, setElapsedTime] = useState("0:00:00");
  const [freeMinutesExpired, setFreeMinutesExpired] = useState(false);

  var countUpInterval;

  const startCountingUp = () => {
    console.log("start counting up");
    clearInterval(countUpInterval);
    countUpInterval = setInterval(countUp, 1000);
  };

  const countUp = () => {
    timer = timer + 1;
    setElapsedTime(utils.secondsToHms(timer));

    if (
      sessionStorage.getItem("free-minutes") !== undefined &&
      sessionStorage.getItem("free-minutes") > 0 &&
      sessionStorage.getItem("free-minutes") * 60 === timer
    ) {
      setFreeMinutesExpired(true);
    }
  };

  useEffect(() => {
    if (room) {
      room.localParticipant.audioTracks.forEach((track) => {
        if (isMicOn) track.track.enable();
        else track.track.disable();
      });
    }
  }, [isMicOn]);

  useEffect(() => {
    if (room) {
      room.localParticipant.videoTracks.forEach((track) => {
        if (isVideoOn) track.track.enable();
        else track.track.disable();
      });
    }
  }, [isVideoOn]);

  useEffect(() => {
    const participantConnected = (participant) => {
      console.log("participantConnected ", participants.length);
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
      props.onParticipantConnected();
      startCountingUp();
    };

    if (props.token !== "") {
      console.log("createLocalTracks");
      createLocalTracks({
        audio: true,
        video: { facingMode: "user" },
      })
        .then((localTracks) => {
          console.log(
            "connecting to roomName ",
            props.roomName,
            props.token !== ""
          );
          return connect(props.token, {
            name: props.roomName,
            tracks: localTracks,
            preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
          });
        })
        .then((room) => {
          console.log("Video room connected ", room);
          setRoom(room);
          props.setVideoRoom(room);

          room.on("participantConnected", participantConnected);
          room.on("participantDisconnected", (participant) => {
            setParticipants((prevParticipants) =>
              prevParticipants.filter((p) => p !== participant)
            );
            room.disconnect();
            props.onParticipantDisconnected();
          });

          room.participants.forEach(participantConnected);

          room.on("disconnected", (room) => {
            console.log("room disconnected");
            // Detach the local media elements
            room.localParticipant.tracks.forEach(function (trackPublication) {
              trackPublication.track.stop();
            });

            room.disconnect();
          });
        })
        .catch((error) => {
          alert("Unable to connect to Room \n" + error.message);
          if (isClient) {
            props.onCancelVideo();
          } else {
            props.onParticipantDisconnected();
          }
        });
    }

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom) {
          console.log("CurrentRoom state ", currentRoom.localParticipant.state);
        }
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [props.roomName, props.token]);

  const remoteParticipants = participants.map((participant) => (
    <VideoCallParticipant key={participant.sid} participant={participant} />
  ));

  return (
    <div className="video-chat-container">
      <FreeMinutesTooltip
        tooltipText={
          isClient
            ? "Your " + sessionStorage.getItem("free-minutes")
            : props.otherTalkerName + "'s"
        }
        freeMinutesExpired={freeMinutesExpired}
        setFreeMinutesExpired={() => setFreeMinutesExpired(false)}
        isCallOrVideo={true}
      />
      <div className="video-chat-header">
        <Row className="video-chat-top">
          <Col lg={4} />
          <Col lg={4} className="video-chat-header-title">
            Video
          </Col>

          {participants.length === 0 ? null : (
            <Col
              lg={4}
              className="end"
              onClick={() => props.showModalEndSession()}
            >
              <p>End session</p>
            </Col>
          )}
        </Row>
        <img
          src={
            props.otherTalkerLogoImage === ""
              ? icon_logo
              : myAppConfig.imagesApiURL + props.otherTalkerLogoImage
          }
          alt="other talker logo"
          className="user-logo"
        />

        <div>
          <p className="name">{props.otherTalkerName}</p>
          <p className="category">{otherTalkerCategory}</p>
        </div>
      </div>

      <div className="video-chat-bottom">
        {isClient && participants.length === 0 ? (
          <div
            className={
              "spinner-container " +
              (isMobile ? "spinner-container-mobile" : "")
            }
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <div className="infoText">
              <p className="infoTitle">Starting Video...</p>
              <p>We're connecting you now, please wait a few seconds</p>
            </div>
            <Button onClick={() => props.onCancelVideo()}>Cancel</Button>
            <hr />
          </div>
        ) : (
          <>
            <div className={"d-flex justify-content-end "}>
              <div className="time">{elapsedTime}</div>
            </div>
            <div className="video-call-container">
              {room ? (
                <div className="me-participant">
                  <VideoCallParticipant
                    key={room.localParticipant.sid}
                    participant={room.localParticipant}
                  />
                </div>
              ) : (
                ""
              )}
              {remoteParticipants}

              <div className="btns-container">
                <div
                  className={
                    "btns " + (isMicOn ? "microphone-on" : "microphone-off")
                  }
                  onClick={() => {
                    setIsMicOn(!isMicOn);
                  }}
                ></div>
                <div
                  className="video-btn btns"
                  onClick={() => setIsVideoOn(!isVideoOn)}
                >
                  {isVideoOn ? (
                    <Icon.Video width={17} />
                  ) : (
                    <Icon.VideoOff width={17} />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default VideoChatComponent;
