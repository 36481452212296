import React from "react";
import logo from "../assets/icons/logo.png";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../styles/rateAdvisor.scss";
import bg from "../assets/img/bg/banner.png";
import ClientNavbar from "../components/navbar/ClientNavbar";
import Login from "../views/pages/Login";
import Footer from "../components/footer/Footer";

const SimpleClientLayout = (props) => {
  const {
    children,
    setLoginModalShow,
    userToken,
    setUserToken,
    setUserType,
    loginModalShow,
    categories,
    hasSimpleNavbar,
    check403Error,
    hasSimpleFooter,
  } = props;

  const footerLayout = (hasSimpleFooter) => {
    return hasSimpleFooter ? (
      <div className="footer">
        <div className="footer-bottom">
          <div className="container-fluid">
            <div className="copyright">
              <div className="copyright-text">
                <p>©2021 Advia. All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <Footer categories={categories} />
    );
  };
  return hasSimpleNavbar ? (
    <>
      <div
        className={hasSimpleFooter ? "rateAdvisor" : ""}
        style={{ backgroundImage: `url(${bg})` }}
      >
        <Navbar expand="lg" className="navbar-dark simple">
          <Navbar.Brand as={Link} to="/">
            <img src={logo} alt="logo" />
          </Navbar.Brand>
        </Navbar>
        {children}

        {footerLayout(hasSimpleFooter)}
      </div>
    </>
  ) : (
    <>
      <div
        className={hasSimpleFooter ? "rateAdvisor" : ""}
        style={{ backgroundImage: `url(${bg})` }}
      >
        <ClientNavbar
          setLoginModalShow={setLoginModalShow}
          userToken={userToken}
          categories={categories}
          check403Error={check403Error}
        />
        <Login
          setUserToken={setUserToken}
          setLoginModalShow={setLoginModalShow}
          setUserType={setUserType}
          loginModalShow={loginModalShow}
          check403Error={check403Error}
        />
        {children}
        {footerLayout(hasSimpleFooter)}
      </div>
    </>
  );
};

export { SimpleClientLayout };
