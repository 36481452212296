import { DropdownButton, Dropdown, Image } from "react-bootstrap"
import { Link } from "react-router-dom"
import { imagesApiURL } from "../../../constants/AppConstants"
import axios from "axios"
import * as myAppConfig from '../../../constants/AppConstants'
import internalProfileIcon from '../../../assets/icons/internal_profile_icon.png'
import permissionsIcon from '../../../assets/icons/permissions_icon.png'

import * as Icon from 'react-feather'
import logOutIcon from '../../../assets/icons/logout_icon.png'

import user from '../../../assets/img/user.png';
import { useState, useRef } from "react"
import { useOnClickOutside, logOutUser } from "../../../Utilities/Utils";

const SuperAdminDropDown = (props) => {
    const { userEmail, check403Error } = props;
    const logoImg = localStorage.getItem('userLogoImg')
    const [show, setShow] = useState(false);
    const ref = useRef();

    return (
        <div className="user_logged user-logged-admin d-flex align-items-center" onClick={() => setShow(!show)} ref={ref}>
            <div className="usr_img">
                <img src={logoImg ? imagesApiURL + logoImg : user} alt="user" />
                <div className="notify"></div>
            </div>
            <DropdownButton
                align="up"
                id="dropdown-menu-align-right"
                title=""
                show={show}
            >
                <span className="user-email">{userEmail}</span>
                <Dropdown.Item eventKey="1" as={Link} to="/profile-settings"><Image src={internalProfileIcon} className="admin_loggedin_images" /><span>Internal profile</span></Dropdown.Item>
                <Dropdown.Item eventKey="2"><Image src={permissionsIcon} className="admin_loggedin_images" /><span>Permissions</span></Dropdown.Item>
                <Dropdown.Item eventKey="3" as={Link} to="/change-password"><Icon.Key className="admin_loggedin_images" /><span>Change password</span></Dropdown.Item>
                <hr />
                <Dropdown.Item eventKey="4" onClick={() => logOutUser("/", check403Error)}><Image src={logOutIcon} className="admin_loggedin_images" /><span>Log Out</span></Dropdown.Item>

            </DropdownButton>
        </div>
    )
}

export default SuperAdminDropDown