import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "../../styles/endSession.scss";

const EndSessionModal = (props) => {
  var title = "End session";
  var subTitle = "Are you sure you want to end this conversation?";
  var btnCancel = "No";
  var btnOk = "Yes";
  if (props.isNotResponding && props.isNotResponding === true) {
    title = "Session";
    subTitle =
      "Are you still there? If you do not respond within the next minute, the session will be ended";
    btnCancel = "I'm still here";
    btnOk = "Log out";
  }

  return (
    <Modal
      show={props.show}
      onHide={() => props.setShowEndSession()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="end-session-action-modal"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        <div className="title">{title}</div>

        <div className="px-5">
          <div className="subtitle">{subTitle}</div>

          <div className="btns-container">
            <Button
              type="submit"
              className="button btnGreen"
              onClick={() =>
                props.isNotResponding && props.isNotResponding === true
                  ? props.btnContinueConversation()
                  : props.setShowEndSession()
              }
            >
              {btnCancel}
            </Button>

            <Button
              type="submit"
              className="button btnDarkBlue"
              onClick={() => props.btnEndSessionClick()}
            >
              {btnOk}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EndSessionModal;
