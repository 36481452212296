
import { Modal } from 'react-bootstrap'

const AlertMessage = (props) => {
    const { showAlertMessage, setShowAlertMessage, description, title } = props;
    return (
        <Modal
            show={showAlertMessage}
            onHide={() => { setShowAlertMessage(false); }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="loginModal successUpdate"
            backdrop='static'
            keyboard={false}
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="registerMessage title">{title}</div>
                <div className="registerMessage">{description}</div>)
            </Modal.Body>
        </Modal>
    )
}

export default AlertMessage