import { Row, Col, Table } from 'react-bootstrap';
// import PaymentPeriodDropDown from './PaymentPeriodDropDown';
import moment from 'moment';
import PaymentPeriodDropDownDays from './PaymentPeriodDropDownDays';

const PaymentsContainer = (props) => {
    const { paymentsData, lastUpdate, startDate, setStartDate, endDate, setEndDate } = props;

    const updated = moment(new Date(lastUpdate)).format('LL').replace(',', '');

    return (
        <>
            <Row>
                <Col lg={6} className='p-0'>
                    <p className="title">Payments and Financial Reports</p>
                </Col>
                <Col lg={6} className='p-0'>
                    <Row>
                        <Col lg={6}>
                        </Col>
                        <Col lg={6}>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br />
            <Row>
                <Col lg={6}>
                    <Row>
                        <Col lg={4} className="border-right">
                            <p className="amount">${paymentsData.processingPayments}</p>
                            <p className="custom-text-16-20">Processing Payments</p>
                        </Col>
                        <Col lg={4} className="border-right">
                            <p className="amount">${paymentsData.paymentMade}</p>
                            <p className="custom-text-16-20">Payment made</p>
                        </Col>
                        <Col lg={4}>
                            <p className="amount">{paymentsData.totalSessions}</p>
                            <p className="custom-text-16-20">Total Sessions</p>
                        </Col>
                    </Row>
                </Col>
                <Col lg={6} className='pe-0'>
                    <PaymentPeriodDropDownDays startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                </Col>
            </Row>
            <br />
            <p className="custom-text-16-20">Updated {updated}</p>
            <Row className="payments">
                <Table responsive className="payments-table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Description</th>
                            <th>Type</th>
                            <th>Total amount</th>
                            <th>Session ID</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paymentsData.payments && paymentsData.payments.length > 0 ?
                            paymentsData.payments.map((payment, idx) =>
                            <tr>
                                <td>{new Date(payment.date).toLocaleDateString()}</td>
                                <td>{(parseInt(payment.type) === 1 ? 'Chat' : parseInt(payment.type) === 2 ? 'Voice' : 'Video') + ' session with ' + payment.client_name}</td>
                                <td>{parseInt(payment.type) === 1 ? 'Chat' : parseInt(payment.type) === 2 ? 'Voice' : 'Video'}</td>
                                    <td>${payment.advisor_amount ? payment.advisor_amount : 0}</td>
                                <td>{payment.session_id}</td>
                                <td className={parseInt(payment.status) === 1 ? "created" : parseInt(payment.status) === 2 ? 'paid' : parseInt(payment.status) === 3 ? 'refund' : 'with_error'}>{parseInt(payment.status) === 1 ? "Created" : parseInt(payment.status) === 2 ? 'Paid' : parseInt(payment.status) === 3 ? 'Refund' : 'With Error'}</td>
                            </tr>
                            )
                            : null
                        }
                    </tbody>
                </Table>
            </Row>
        </>
    )
}

export default PaymentsContainer