import React from 'react'
import {Col} from 'react-bootstrap';
import '../../styles/_layouts.scss';
import { Link } from 'react-router-dom';

function FooterCategories(categories) {
    const categories_items = Array.from(categories.categories).map((category, idx) =>
        <li key={category.id}><Link to={"/category/" + category.title} >{category.title}</Link></li>
    );
    
    return (
        <Col xs="6" sm="6" md="6" lg="3">
            <div className="footer-widget footer-menu">
                <h2 className="footer-title">Categories</h2>
                {categories_items}
            </div>
        </Col>
    )
}

export default FooterCategories
