import React from 'react';
import Footer from '../components/footer/Footer'
import SuperAdminSidebar from '../components/navbar/SuperAdminSidebar';
import SuperAdminNavbar from '../components/navbar/SuperAdminNavbar';
import {Col} from 'react-bootstrap';
import '../styles/superAdmin_sidebar.scss';
import bg from '../assets/img/bg/bannerSuperAdmin.png';

const SuperAdminLayout = (props) => {
  const {children, categories, userEmail, check403Error} = props;
    return (
  <>
      <div className='d-flex flex-wrap' style={{ backgroundImage: `url(${bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: 'inherit' }}>
    <Col className="superadmin_sidebar col-12 col-sm-12 col-md-2 col-lg-2">
    <SuperAdminSidebar />
    </Col>
        <Col className="px-lg-5 px-md-5 px-sm-3 px-3 py-lg-5 py-md-5 py-sm-2 pt-2 pb-5 superadmin-page col-12 col-sm-12 col-md-10 col-lg-10">
      <SuperAdminNavbar userEmail={userEmail} check403Error={check403Error}/>
    {children}
    </Col>
    <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
    <Footer categories = {categories}/>
    </Col>
    </div>
  </>
    )
}
export {SuperAdminLayout}