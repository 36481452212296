import { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import parse from "html-react-parser";

const PaymentMethodsModal = (props) => {
    const [selectedCard, setSelectedCard] = useState(0);

    return (
        <Modal
            show={props.show}
            onHide={() => props.setShow(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="end-session-action-modal"
            backdrop='static'
            keyboard={false}
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <div className="title m-3" style={{ borderBottom: '0' }}>{props.title}</div>
                <Form className="p-3" onSubmit={(e) => { e.preventDefault(); }}>
                    <Form.Group className="mb-4 cards_form" controlId="formCards">
                        {props.cardsList.map((card, idx) => (
                            <Form.Check
                                label={parse(card.label)}
                                name={card.value}
                                type='radio'
                                value={card.value}
                                id={`inline-${idx}`}
                                className={'mb-3 pb-3 paymentMethods-container ' + (card.value === selectedCard ? 'active' : '')}
                                onChange={(ev) => setSelectedCard(parseInt(ev.target.value))}
                                checked={card.value === selectedCard}
                            />
                        ))}
                    </Form.Group>
                    <Button
                        type="submit"
                        className="button btnGreen m-0"
                        onClick={(e) => { e.preventDefault(); props.onCardSelected(selectedCard); }}
                    >
                        Select
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default PaymentMethodsModal;