import React from 'react'
import {Button} from 'react-bootstrap';
import '../../styles/wizzard.scss';
import { isMobileOnly } from 'react-device-detect';

function Wizzard() {
    const handleClick = () => {
        window.location.href = "/wizard";
    }
    const isUserLoggedIn = localStorage.getItem('userToken');
    return (
        <div className="wizzard text-center d-flex flex-column align-items-center justify-content-center">
            {isUserLoggedIn ?
                <div className="title">What can we help you with, {localStorage.getItem('userName')}?
                </div>
                :
            <div className="title">
                Get amazing <span>insights</span><br/> from our top experts
                </div>}
            <div className="desc mt-5">
                Advia is the leading psychic advisor service. Bringing you{isMobileOnly ? null : <br />} clarity, insights, and answers to all your questions
            </div>
            <Button className="tryBtn mt-5" onClick={handleClick.bind()}>Try our Wizard AI</Button>
    </div>
    )
}

export default Wizzard
