import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  Card,
  Row,
  Form,
  FormControl,
  Dropdown,
  DropdownButton,
  Spinner
} from "react-bootstrap";
import axios from "axios";
import "../../../styles/superAdminAdvisors.scss";
import "../../../styles/categoryPage.scss";
import icon_dashboard_dropdown_arrow from "../../../assets/icons/arrows/dashboard_dropdown_arrow.png";
import icon_filter from "../../../assets/icons/icon_sort.png";
import NewAdvisorRequestCard from "./NewAdvisorRequestCard";
import * as myAppConfig from "../../../constants/AppConstants";
import ExpertCard from "./ExpertCard";
import MobileModalFilter from "../../../components/modals/MobileModalFilter";
import AccountManageActionModal from "../../../components/modals/AccountManageActionModal";

const sortByDropDownValues = [
  { id: -1, title: "Sort by" },
  { id: 0, title: "All" },
  { id: 1, title: "Revenue: Low to High" },
  { id: 2, title: "Revenue: High to Low" },
  { id: 3, title: "Reviews: Low to High" },
  { id: 4, title: "Reviews: High to Low" },
  { id: 5, title: "Last Update: Near to Far" },
  { id: 6, title: "Last Update: Far to Near" },
];

const newRequestsDropDownValues = [
  { id: 4, title: "New Requests" },
  { id: 0, title: "Rejected" },
];

const filtersValues = ["All", "Call", "Chat", "Video"];

const Advisors = (props) => {
  const { check403Error } = props;
  const [dropDownTitle, setDropDownTitle] = useState(
    sortByDropDownValues[0].title
  );
  const [selectedFilter, setSelectedFilter] = useState(filtersValues[0]);
  const [searchText, setSearchText] = useState();
  const [filterIdx, setFilterIdx] = useState(0);
  const [sortIndex, setSortIndex] = useState(0);
  const [show, setShow] = useState(false);
  const [showAccountManageModal, setShowAccountActionModal] = useState(false);
  const [accountActionType, setAccountActionType] = useState("");

  const [advisors_revenue, set_advisors_revenue] = useState([]);
  const [newRequestList, setNewRequestList] = useState([]);

  const maxToDisplay = 7;
  const [noToDisplay, setNoToDisplay] = useState(maxToDisplay);

  const [selectedAdvisorsList, setSelectedAdvisorsList] = useState([]);
  const [selectedNewRequestList, setSelectedNewRequestsList] = useState([]);

  const [statusDropDownTitle, setStatusDropDownTitle] = useState(
    newRequestsDropDownValues[0].title
  );
  const [statusRequestID, setStatusRequestID] = useState(
    newRequestsDropDownValues[0].id
  );

  const [dataLoading, setDataLoading] = useState(false);
  const [dataLoadingNew, setDataLoadingNew] = useState(false);
  const [shouldReload, setShouldReload] = useState(false);
  const [shouldReloadNew, setShouldReloadNew] = useState(false);

  useEffect(() => {
    const style2 = document.querySelector(
      ".new-adv-dropdown > .dropdown-toggle"
    ).style;
    style2.setProperty(
      "--arrowURL",
      "url(" + icon_dashboard_dropdown_arrow + ")"
    );

    const style = document.querySelector(
      ".advisors-dropdown > .dropdown-toggle"
    ).style;
    style.setProperty(
      "--arrowURL",
      "url(" + icon_dashboard_dropdown_arrow + ")"
    );

    let isMounted = true;

    const fetchDataFilteredByCategory = async () => {
      axios
        .get(myAppConfig.GET_LIST_OF_ADVISORS, {
          params: {
            category: "",
            session_method: filterIdx,
            sort_by: sortIndex,
            search: searchText ? searchText : "",
            status_request: statusRequestID,
          },
          headers: {
            token: localStorage.getItem("userToken"),
          },
        })
        .then((response) => {
          if (searchText === undefined || shouldReload) {
            setDataLoading(false);
            setShouldReload(false);
          }
          if (searchText === undefined || shouldReloadNew) {
            setDataLoadingNew(false);
            setShouldReloadNew(false);
          }
          //debugger;
          if (response.data.status === "success") {
            if (isMounted) {
              setSelectedAdvisorsList([]);
              setSelectedNewRequestsList([]);
              set_advisors_revenue(
                response.data ? response.data.list_of_advisors : []
              );
              setNewRequestList(
                response.data ? response.data.list_of_new_advisors : []
              );
            }
          }
        })
        .catch((err) => {
          //debugger;
          check403Error(err);
        });
    };

    if (!dataLoading) {
      if (searchText === undefined || shouldReload) {
        setDataLoading(true);
      }
      if (searchText === undefined || shouldReloadNew) {
        setDataLoadingNew(true)
      }
      fetchDataFilteredByCategory();
    }

    return () => {
      isMounted = false;
    };
  }, [filterIdx, sortIndex, searchText, statusRequestID, check403Error]);

  const handleSelect = (index) => {
    if (!isNaN(index)) {
      const indexNo = Number(index);
      if (
        indexNo < sortByDropDownValues.length &&
        dropDownTitle !== sortByDropDownValues[indexNo].title
      ) {
        setDropDownTitle(sortByDropDownValues[indexNo].title);
        setSortIndex(sortByDropDownValues[indexNo].id);
        setShouldReload(true);
      }
    }
  };

  const handleNewAdvRequestSelect = (index) => {
    if (!isNaN(index)) {
      const indexNo = Number(index);
      if (
        indexNo < newRequestsDropDownValues.length &&
        statusDropDownTitle !== newRequestsDropDownValues[indexNo].title
      ) {
        setStatusDropDownTitle(newRequestsDropDownValues[indexNo].title);
        setStatusRequestID(newRequestsDropDownValues[indexNo].id);
        setShouldReloadNew(true);
      }
    }
  };

  const handleFilterSelect = (index) => {
    setFilterIdx(index);
    setSelectedFilter(filtersValues[index]);
    setShouldReload(true)
  };

  const showMobileFilter = () => {
    setShow(true);
  };

  const applyFilter = (index) => {
    console.log("applyFilter " + index);
    if (index !== undefined) {
      setSortIndex(parseInt(index));
    }
    setShow(false);
    setShouldReload(true);
  };

  const onChangeSearchText = (ev) => {
    let value = ev.target.value;
    setSearchText(value);
  };

  const selectExpert = (id) => {
    let list = selectedAdvisorsList;
    let index = list.indexOf(id);
    if (index > -1) {
      list.splice(index, 1);
    } else {
      list.push(id);
    }

    setSelectedAdvisorsList([...list]);
  };

  const selectExpertForUpdateRequest = (id) => {
    let list = selectedNewRequestList;
    let index = list.indexOf(id);
    if (index > -1) {
      list.splice(index, 1);
    } else {
      list.push(id);
    }

    setSelectedNewRequestsList([...list]);
  };

  const updateAccountStatus = async (disable) => {
    let list_of_profiles = "";

    selectedAdvisorsList.forEach((idx) => {
      let currentUser = advisors_revenue[idx];

      list_of_profiles += currentUser.user_id + ",";
      list_of_profiles += currentUser.id + ",";
      list_of_profiles +=
        (disable
          ? myAppConfig.ACCOUNT_STATUS.FREEZE.id
          : myAppConfig.ACCOUNT_STATUS.ACTIVE.id) + ";";
    });

    list_of_profiles = list_of_profiles.slice(0, -1);

    var formData = new FormData();
    formData.append("list_of_profiles", list_of_profiles);

    axios
      .post(myAppConfig.FREEZE_REACTIVATE_ACCOUNT, formData, {
        headers: {
          token: localStorage.getItem("userToken"),
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          selectedAdvisorsList.forEach((idx) => {
            advisors_revenue[idx].status_account = disable
              ? myAppConfig.ACCOUNT_STATUS.FREEZE.id
              : myAppConfig.ACCOUNT_STATUS.ACTIVE.id;
          });
          set_advisors_revenue([...advisors_revenue]);
          setSelectedAdvisorsList([]);
        }
      })
      .catch((err) => {
        check403Error(err);
      });
  };

  const acceptRejectAdvisor = async (shouldDelete) => {
    let list_of_profiles = "";

    selectedNewRequestList.forEach((idx) => {
      let currentUser = newRequestList[idx];

      list_of_profiles += currentUser.user_id + ",";
      list_of_profiles += currentUser.id + ",";
      list_of_profiles +=
        (shouldDelete
          ? myAppConfig.ACCOUNT_STATUS.INACTIVE.id
          : myAppConfig.ACCOUNT_STATUS.ACTIVE.id) /*accepted*/ + ";";
    });

    list_of_profiles = list_of_profiles.slice(0, -1);

    var formData = new FormData();
    formData.append("list_of_profiles", list_of_profiles);

    axios
      .post(myAppConfig.APPROVE_REJECT_ADVISOR_ACCOUNT, formData, {
        headers: {
          token: localStorage.getItem("userToken"),
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          selectedNewRequestList.forEach((idx) => {
            newRequestList.splice(idx, 1);
          });
          setNewRequestList([...newRequestList]);
          setSelectedNewRequestsList([]);
        }
      })
      .catch((err) => {
        check403Error(err);
      });
  };

  const accountManageAction = (actionType) => {
    let showPopup = false;
    if (
      actionType === myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.FREEZE_ACCOUNT ||
      actionType === myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.REACTIVATE_ACCOUNT
    ) {
      if (selectedAdvisorsList.length > 0) {
        showPopup = true;
      }
    } else if (
      actionType === myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.ACTIVATE_ACCOUNT ||
      actionType === myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.DELETE_ACCOUNT
    ) {
      if (selectedNewRequestList.length > 0) {
        showPopup = true;
      }
    }

    if (showPopup) {
      setAccountActionType(actionType);
      setShowAccountActionModal(true);
    }
  };

  const onAccountActionBtnClick = () => {
    if (
      accountActionType ===
      myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.FREEZE_ACCOUNT
    ) {
      updateAccountStatus(true);
    } else if (
      accountActionType ===
      myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.REACTIVATE_ACCOUNT
    ) {
      updateAccountStatus(false);
    } else if (
      accountActionType ===
      myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.ACTIVATE_ACCOUNT
    ) {
      acceptRejectAdvisor(false);
    } else if (
      accountActionType ===
      myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.DELETE_ACCOUNT
    ) {
      acceptRejectAdvisor(true);
    }
    setShowAccountActionModal(false);
  };

  return (
    <div className="advisors-main-container">
      <Container fluid className='p-0'>
        <div className="new-adv-request-container">
          <div className="header-container">
            <p className="title">New Advisor Requests</p>

            <div className="btns-approve-delete-container">
              <Row className="advisors-container new-adv-top-btns">
                <DropdownButton
                  className="row new-adv-dropdown"
                  alignRight
                  title={statusDropDownTitle}
                  id="new-advisors-dropdown-menu-align-right"
                  onSelect={handleNewAdvRequestSelect}
                >
                  {newRequestsDropDownValues.map((_item, idx) => (
                    <Dropdown.Item
                      key={_item.id}
                      className={
                        statusDropDownTitle === _item.title
                          ? "advisors-dropdown-menu-item advisors-dropdown-menu-item-selected-color"
                          : "advisors-dropdown-menu-item advisors-dropdown-menu-item-unselected-color"
                      }
                      eventKey={idx}
                    //disabled={idx === 0 ? true : false}
                    >
                      {_item.title}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>

                <div style={{ width: "fit-content" }}>
                  <Button
                    className="button"
                    onClick={() =>
                      accountManageAction(
                        myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.ACTIVATE_ACCOUNT
                      )
                    }
                  >
                    Approve
                  </Button>
                  <Button
                    className="button secondBtn"
                    onClick={() =>
                      accountManageAction(
                        myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.DELETE_ACCOUNT
                      )
                    }
                  >
                    Delete
                  </Button>
                </div>
              </Row>
            </div>
          </div>
          {
            dataLoadingNew ?
              <div style={{ textAlign: 'center' }}>
                <Spinner animation='border' style={{ color: 'white' }} />
              </div>
              :
              newRequestList.length > 0 ? (
                <Card>
                  {newRequestList.map((info, idx) => (
                    <NewAdvisorRequestCard
                      key={idx}
                      data={info}
                      checkbox={true}
                      isChecked={
                        selectedNewRequestList.indexOf(idx) > -1 ? true : false
                      }
                      onClick={() => selectExpertForUpdateRequest(idx)}
                    />
                  ))}
                </Card>
              ) : (
                <div className="no-new-requests">
                  <p>No new requests yet</p>
                </div>
              )}
        </div>

        <div className="advisors-list-container">
          <div className="header-container">
            <p className="title">Advisors List</p>

            <div className="btns-approve-delete-container">
              <Button
                className="button"
                onClick={() =>
                  accountManageAction(
                    myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.FREEZE_ACCOUNT
                  )
                }
              >
                Freeze Account
              </Button>
              <Button
                className="button secondBtn"
                onClick={() =>
                  accountManageAction(
                    myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.REACTIVATE_ACCOUNT
                  )
                }
              >
                Reactivate
              </Button>
            </div>
          </div>
        </div>
        <div className="advisors-container" style={dataLoading ? { display: 'none' } : {}}>
          <div className="advisors-filters">
            <Row className="cols-filters mx-0">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-filter">
                <Card className="custom-card-filter custom-card-filter-unselected card-sort">
                  <img
                    src={icon_filter}
                    onClick={() => showMobileFilter()}
                    alt="filter"
                  />
                </Card>

                {filtersValues.map((_filterValue, idx) => (
                  <Card
                    key={idx}
                    className={
                      selectedFilter === _filterValue
                        ? "custom-card-filter custom-card-filter-selected"
                        : "custom-card-filter custom-card-filter-unselected"
                    }
                  >
                    <Button
                      className="custom-card-filter-text"
                      onClick={() => handleFilterSelect(idx)}
                    >
                      {_filterValue}
                    </Button>
                  </Card>
                ))}
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-filter-justify">
                <DropdownButton
                  className="advisor-drop-down-container advisors-dropdown row"
                  alignRight
                  title={dropDownTitle}
                  id="advisors-dropdown-menu-align-right"
                  onSelect={handleSelect}
                >
                  {sortByDropDownValues.map((_item, idx) => (
                    <Dropdown.Item
                      key={_item.id}
                      className={
                        dropDownTitle === _item.title || idx === 0
                          ? "advisors-dropdown-menu-item advisors-dropdown-menu-item-selected-color"
                          : "advisors-dropdown-menu-item advisors-dropdown-menu-item-unselected-color"
                      }
                      eventKey={idx}
                      disabled={idx === 0 ? true : false}
                    >
                      {_item.title}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>

                <Form className="search-form">
                  <FormControl
                    value={searchText}
                    onChange={onChangeSearchText}
                    type="search"
                    placeholder="Search for Advisor..."
                    className={"searchInput search-input-advisors"}
                    aria-label="Search"
                  />
                </Form>
              </div>
            </Row>
          </div>
        </div>
        {dataLoading ?
          <div style={{ textAlign: 'center' }}>
            <Spinner animation='border' style={{ color: 'white' }} />
          </div>
          :
          <Row className="advisors-list-container mx-0">
            <Card>
              <div className="card-container">
                <p className="expert-title">Experts List</p>

                {advisors_revenue.length > 0
                  ? advisors_revenue.map((advisor, idx) =>
                    idx < noToDisplay ? (
                      <ExpertCard
                        key={idx}
                        advisor_revenue={advisor}
                        checkbox={true}
                        isChecked={
                          selectedAdvisorsList.indexOf(idx) > -1 ? true : false
                        }
                        onClick={() => selectExpert(idx)}
                      />
                    ) : null
                  )
                  : null}
              </div>

              <div
                className="show-more-advisors"
                style={{
                  display:
                    advisors_revenue !== undefined &&
                      advisors_revenue.length > noToDisplay
                      ? "flex"
                      : "none",
                  cursor: "pointer",
                }}
                onClick={() => setNoToDisplay(advisors_revenue.length)}
              >
                <p>Show more</p>
              </div>
            </Card>
          </Row>
        }
      </Container>
      <MobileModalFilter
        show={show}
        setMobileModalFilterShow={() => setShow(false)}
        sortByDropDownValues={sortByDropDownValues}
        applyFilter={applyFilter}
      />

      <AccountManageActionModal
        show={showAccountManageModal}
        setShowAccountActionModal={() => setShowAccountActionModal(false)}
        actionType={accountActionType}
        onAccountAction={onAccountActionBtnClick}
      />
    </div>
  );
};

export default Advisors;
