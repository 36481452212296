import React from "react";
import { Modal } from "react-bootstrap";

const MobileChatComponentLayout = (props) => {
  const { children, show, setShow } =
    props;
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="editModal mobile chat component"
        dialogClassName="modal-fullscreen modal-backdrop mobileSearch"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
};

export { MobileChatComponentLayout };
