import Card from "../Card";
import advisorImage from '../../assets/img/advisor.png';
import { imagesApiURL } from '../../constants/AppConstants';
import { useKeenSlider } from "keen-slider/react";
import React from "react";
import * as myAppConfig from '../../constants/AppConstants'

const AdvisorsMobileSlider = (props) => {

    const { advisors } = props

    const [sliderRef] = useKeenSlider({
        initial: 0,
        loop: false,
        breakpoints: {
            '(max-width: 550px)': {
                slidesPerView: 1
            }
        },
        slidesPerView: 2,

    });

    const minUsersReviewing = 2;
    const maxUsersReviewing = 10;
    const cards = advisors.filter(card => (card.full_name !== null)).map((card, idx) => {
        const usersReviewing = Math.floor(Math.random() * (maxUsersReviewing - minUsersReviewing + 1)) + minUsersReviewing;
        let audioCall = 0;
        let chat = 0;
        let videoCall = 0;
        let mins = [];
        let minprice = 0;
        if (card.profile_session_pricings) {
            card.profile_session_pricings.map((price, idx) => {
                const p = price.price;
                if (p > 0) {
                    mins.push(p);
                    switch (price.session_method_type) {
                        case 1:
                            audioCall = 1;
                            break;
                        case 2:
                            chat = 1;
                            break;
                        case 3:
                            videoCall = 1;
                            break;
                        default:
                            break;
                    }
                }
                minprice = Math.min(...mins);
                return null;
            })
        }
        return (
            <div className={`keen-slider__slide number-slide${idx + 1}`} key={card.id} style={{ paddingRight: '10px', paddingLeft: '10px', background: 'transparent' }}>
                <Card
                    key={card.id}
                    cardId={card.id}
                    image={card.logo_image ? imagesApiURL + card.logo_image : advisorImage}
                    name={card.full_name ? card.full_name : (card.first_name + ' ' + card.last_name)}
                    categ={card.category[0] ? card.category[0].title : ''}
                    stars={card.score_average ? card.score_average : 0}
                    reviewsNo={card.number_of_reviews ? card.number_of_reviews : 0}
                    price={'$' + minprice + '/min'}
                    usersReviewing={usersReviewing}
                    free={(card.freeminutes_status === 1 && card.freeminutes_value) ? ('First ' + card.freeminutes_value + ' min FREE') : (card.saleoffer_status === 1 && card.saleoffer_value) ? (card.saleoffer_value + '% sale offer') : ''}
                    audio={audioCall}
                    video={videoCall}
                    chat={chat}
                    readings={card.number_of_readings ? card.number_of_readings : 0}
                    status={(Number(card.status_account) === myAppConfig.ACCOUNT_STATUS.FREEZE.id) ? 2 : (card.offline_online ? card.status ? card.status : 0 : 2)}
                />
            </div>
        )
    })

    return (
        <div style={{ marginRight: '20px' }}>
            <div ref={sliderRef} className="keen-slider" style={{ overflow: 'visible', marginLeft: "-10px", marginRight: '-10px' }}>
                {cards}
            </div>
        </div>
    )
}

export default AdvisorsMobileSlider;