import { isMobile, isMobileOnly } from 'react-device-detect'
import { Button } from 'react-bootstrap'
import banner from '../../assets/img/bg/banner.png'

const WhyAdvia = (props) => {
    const { role, setLoginModalShow, setHideSignUp } = props
    const isAffiliate = role === 'affiliate';

    const specialities = !isAffiliate ?
        [
            {
                title: 'Integrity',
                text: 'Our main goal is to build long-term relationships with our advisors and the keywords are trust and integrity! We strive to provide you with a 100% transparent platform with our clients and advisors. We’ll share some insights with you about your activity to improve your performance and upgrade your business.​'
            },
            {
                title: 'Purpose',
                text: '“With great power comes great responsibility!” With your gifts and skills, you can help and give guidance to people and make a real difference in their lives.'
            },
            {
                title: 'Reputation',
                text: 'Advia’s growing advisor community consists of some of the most professional, veteran, talented, top-rated, and gifted advisors in the world. Each advisor is chosen using several key factors. By being part of Advia’s advisor community you will enjoy the benefit of a good reputation and prestige.'
            }
        ]
        :
        [
            {
                title: 'Integrity',
                text: 'Our main goal is to build a long term relationship with our affiliates and the keyword is trust and integrity! We work with Radius impact, a third party tracking platform so you will have 100% transparency and, you will be able to track on real time your campaigns’ erformances.​'
            },
            {
                title: 'Compability and Benefits ',
                text: 'We reward our Affiliates with an hansome prise of 120$ for each new customer. Processing payment every 30 days. Our clients are highly educated in the ages of 21-55, who are looking for guidance in relationships, romance and, career issues . They come to us with their questions. So if your traffic suitable to ours this partnership can be great benfit for us.​'
            },
            {
                title: 'Reputation',
                text: 'Advia’s growing advisors community contain wide the most professional, veterans, talented, top-rated, and gifted advisors in the world. Each advisor is chosen from several key factors. By being part of Advia’s advisor’s community you will enjoy the benefit of a good reputation and prestige.'
            }
        ]

    return (
        <div style={isMobileOnly ? (isAffiliate ? { padding: '66px 9.4vw 0', position: 'relative' } : { padding: '0 9.4vw', position: 'relative' }) : (isAffiliate ? { padding: '66px 4.4vw 0 10.7vw', position: 'relative' } : { padding: '0 4.4vw 0 10.7vw', position: 'relative' })}>
            {isAffiliate ?
                <img src={banner} style={styles.bg} alt='background' />
                : null}
            <p style={styles.title(isAffiliate)}>Why Advia?</p>
            <div className='d-flex flex-wrap'>
                {specialities.map((special, id) => {
                    return (
                        <div key={id} className='col-lg-4 col-md-4 col-sm-12 col-12 mb-5 pb-2' style={isMobileOnly ? { paddingRight: '7.2vw' } : { paddingRight: '6.3vw' }}>
                            <div style={styles.itemTitle(isAffiliate)}>{special.title}</div>
                            <div style={styles.itemText}>{special.text}</div>
                        </div>
                    )
                })}
                {
                    isAffiliate ?
                        <div className='slideContent' style={isMobile ? { textAlign: 'center', marginBottom: '25px', height: 'fit-content' } : { textAlign: 'center', marginBottom: '130px' }}>
                            <Button className="buttonGreen mr-5" style={isMobileOnly ? styles.greenBtnMobile : styles.greenBtn} onClick={() => { setHideSignUp(false); setLoginModalShow(true) }}>Apply Now</Button>
                        </div>
                        : null
                }
            </div>
        </div >
    )
}

export default WhyAdvia;

const styles = {
    bg: {
        position: "absolute",
        width: "100%",
        left: '0',
        top: "0",
        height: "100%",
        zIndex: "-1",
        objectFit: 'cover'
    },
    title: (isAffiliate) => ({
        fontSize: '32px',
        lineHeight: '34px',
        textAlign: 'center',
        color: 'white',
        marginTop: !isAffiliate ? '8px' : '0px',
        marginBottom: '52px',
        fontWeight: '600',
        marginRight: isMobileOnly ? '0' : '6.3vw'
    }),
    desc: {
        fontSize: "16px",
        lineHeight: "34px",
        textAlign: 'center',
        color: 'white',
        marginBottom: '44px'
    },
    itemText: {
        fontSize: isMobileOnly ? '18px' : '16px',
        color: 'white',
        lineHeight: '34px',
        fontWeight: '400'
    },
    itemTitle: (isAffiliate) => ({
        fontSize: '20px',
        lineHeight: '34px',
        color: !isAffiliate ? 'white' : '#01C8C2',
        fontWeight: '600',
        marginBottom: '10px'
    }),
    greenBtnMobile: {
        width: '-webkit-fill-available',
        marginLeft: "16.25px",
        marginRight: "16.25px",
        fontSize: "20px",
        lineHeight: "20px",
        padding: "17px 0.5rem"
    },
    greenBtn: {
        fontSize: "18px",
        lineHeight: "20px",
        padding: '1rem 3.43rem'
    },
}