// const imagesApiURL = 'https://e4bd-86-123-69-91.ngrok.io/'
// const apiURL = 'https://e4bd-86-123-69-91.ngrok.io/v1/api/'
// const imagesApiURL = 'http://192.168.11.135:8001/'
// const apiURL = 'http://192.168.11.135:8001/v1/api/'
// const imagesApiURL = 'https://api1.advia.io/'
const apiURL = "https://adviaapi.omtechmasters.net/public/v1/api/";
const imagesApiURL = "https://adviaapi.omtechmasters.net/public/";
// const apiURL = 'https://api.advia.io/v1/api/'
const prodApiURL = "https://adviaapi.omtechmasters.net/public/v1/api/";

export const IS_PROD_VERSION = apiURL === prodApiURL;
console.log("PROD_VERSION ", IS_PROD_VERSION);

const minChatPrice = 1.99;
const minCallPrice = 1.99;
const minVideoPrice = 1.99;

export const VERSION_FOR_PRODUCTION = false;

// Homepage info
export const GET_HOMEPAGE_DETAILS = apiURL + "homepage_details";
export const GET_HERO_BANNER = apiURL + "get_hero_banner";
export const REMOVE_HERRO_BANNER = apiURL + "remove_hero_banner";
export const ADD_HERO_BANNER = apiURL + "add_hero_banner";

// Login & Co.
export const LOG_IN = apiURL + "login";
export const REGISTER = apiURL + "register";
export const LOG_OUT = apiURL + "logout";
export const SWITCH_ACCOUNT = apiURL + "switch_account";
export const SEND_FORGOT_PASS_EMAIL = apiURL + "send_email_forgot_password";
export const FORGOT_PASSWORD = apiURL + "forgot_password";
export const CHANGE_PASSWORD = apiURL + "change_password";
export const GET_CHAT_TOKEN = apiURL + "getToken";

// Get profiles
export const GET_ADVISOR_PROFILE = apiURL + "advisor_profile";
export const GET_CLIENT_PROFILE = apiURL + "client_profile";
export const GET_MY_PROFILE = apiURL + "get_profile";

// Update profiles
export const UPDATE_PROFILE_ADVISOR = apiURL + "update_profile_advisor";
export const UPDATE_PROFILE_CLIENT = apiURL + "update_profile_client";
export const UPDATE_PROFILE_SUPER_ADMIN = apiURL + "update_profile_superadmin";
export const UPDATE_ADVISOR_PROFILE_AS_SUPERADMIN =
  apiURL + "update_advisor_profile_from_superadmin";

// Get lists
export const GET_LIST_OF_CLIENTS = apiURL + "list_of_clients";
export const GET_LIST_OF_ADVISORS = apiURL + "list_of_advisors";
export const GET_ADVISOR_DASHBOARD = apiURL + "advisor_admin_dashboard";
export const GET_DASHBOARD_GRAPHS = apiURL + "dashboardGraph";

export const GET_REGISTERED_CLIENTS = apiURL + "";
export const GET_SAVEDS = apiURL + "my_saveds";
export const GET_LIST_OF_REGISTERED_CLIENTS = apiURL + "registered_users";
export const GET_PAYMENTS_LIST = apiURL + "payments";
export const GET_SESSION_HISTORY = apiURL + "sessionsHistory";

export const GET_CARDS_LIST = apiURL + "list_of_cards";
export const SET_CARD_AS_DEFAULT = apiURL + "set_card_as_default";
export const DELETE_CARD = apiURL + "delete_card";
export const ADD_CARD = apiURL + "addcard";

export const GET_SESSIONS_LIST = apiURL + "sessionsList";

// Block, Freeze, Change status
export const BLOCK_CLIENT = apiURL + "block_client";
export const UNBLOCK_CLIENT = apiURL + "unblock_client";
export const FREEZE_REACTIVATE_ACCOUNT = apiURL + "freeze_reactivate";
export const CHANGE_ADVISOR_STATUS = apiURL + "offline_online";
export const APPROVE_REJECT_ADVISOR_ACCOUNT =
  apiURL + "accept_reject_advisor_account";

// Search & Filter
export const FILTER_BY_CATEGORY = apiURL + "filter_by_category";
export const CUSTOM_SEARCH = apiURL + "custom_search";

// Other URLs
export const NOTIFY_ME_URL = apiURL + "notify_me";
export const REMOVE_NOTIFY_ME_URL = apiURL + "remove_notify_me";
export const ADD_TO_SAVED_URL = apiURL + "add_to_saved";
export const REMOVE_FROM_SAVED_URL = apiURL + "remove_from_saved";
export const ADD_REVIEW = apiURL + "add_rating_and_review";
export const SEND_SUPPORT_MESSAGE_URL =
  apiURL + "custommer_support_send_message";
export const APPROVE_REJECT_REVIEW_URL = apiURL + "approve_reject_review";

export const SA_ADD_ADVISOR_TO_TOP = apiURL + "admin_settings_add";
export const SA_REMOVE_ADVISOR_FROM_TOP = apiURL + "admin_settings_remove";

//CONVERSATIONS
export const CREATE_CONVERSATION = apiURL + "createConversation";
export const GET_ACCOUNTS_INFO_FOR_CHAT_CONVERSATIONS =
  apiURL + "getAccountsInfo";
export const START_END_CONVERSATION = apiURL + "startendConversation";
export const CHECK_ACTIVE_CONVERSATION = apiURL + "checkActiveConversation";
export const CANCEL_CONVERSATION = apiURL + "cancelConversation";
export const CHECK_STATUS_CONVERSATION = apiURL + "checkstatusConversation";

//CALL
export const GET_CALL_LIST = apiURL + "getCallsList";

//NOTES
export const GET_NOTE = apiURL + "getNote";
export const UPDATE_NOTE = apiURL + "addupdateNote";

//NOTIFICATIONS
export const GET_MY_NOTIFICATIONS = apiURL + "getNotification";
export const ACCEPT_REJECT_NOTIFICATIONS = apiURL + "acceptRejectNotification";

export const SUBMIT_FOR_REVIEW = apiURL + "request_for_approve";
export const ADVISOR_STATUS_STATES = {
  busy: { id: 0, title: "Busy" },
  available: { id: 1, title: "Available" },
  away: { id: 2, title: "Away" },
};

export const ACCOUNT_MANAGE_ACTION_STATE = {
  DELETE_ACCOUNT: {
    id: 0,
    title: "Delete Invitation",
    subtitle: "Are you sure you want to delete this invitation?",
    btnActiveState: false,
  },
  ACTIVATE_ACCOUNT: {
    id: 1,
    title: "Activate account",
    subtitle: "Are you sure you want to activate this account?",
    btnActiveState: true,
  },
  FREEZE_ACCOUNT: {
    id: 2,
    title: "Freeze account",
    subtitle: "Are you sure you want to freeze this account?",
    btnActiveState: false,
  },
  REACTIVATE_ACCOUNT: {
    id: 1,
    title: "Reactivate account",
    subtitle: "Are you sure you want to reactivate this account?",
    btnActiveState: true,
  },
  REMOVE_ADVISOR_FROM_LIST: {
    title: "Remove advisor",
    subtitle: "Are you sure you want to remove this advisor from list?",
    btnActiveState: false,
  },
  ADD_ADVISOR_TO_LIST: {
    title: "Add advisor",
    subtitle: "Are you sure you want to add this advisor to list?",
    btnActiveState: true,
  },
  SWITCH_TO_OFFLINE: {
    title: "Switch to Offline",
    subtitle: "Are you sure you want to switch to Offline status?",
    btnActiveState: false,
  },
  SWITCH_TO_ONLINE: {
    title: "Switch to Online",
    subtitle: "Are you sure you want to switch to Online status?",
    btnActiveState: true,
  },
};

export const ACCOUNT_STATUS = {
  INACTIVE: { id: 0 },
  ACTIVE: { id: 1 },
  AWAIT: { id: 2 },
  FREEZE: { id: 3 },
  PENDING_APPROVE: { id: 4 },
};

export const USER_TYPES = {
  SUPERADMIN: 1,
  ADVISOR: 2,
  CLIENT: 3,
};
export const LOCAL_STORAGE_VALUES = {
  SHOW_COMPLETE_PROFILE: {
    Title: "showCompleteProfile",
    Values: {
      YES: "1",
      NO: "0",
    },
  },
};

export const FINAL_THANK_YOU_MESSAGES = {
  FEEDBACK: {
    icon: "changed_icon.png",
    title: "Thank you for your feedback!",
    subtitle:
      "Your feedback is valueable to us! %s appreciates your review & honesty",
    btnYes: "Ok",
    btnNo: null,
  },
  CONVERSATION_ENDED: {
    icon: "changed_icon.png",
    title: "Conversation ended",
    subtitle:
      "Thank you, %s. We hope you received clarity & insights during this session. \n\nWould you mind taking a second to rate our advisor?",
    btnYes: "Sure",
    btnNo: "Not Now",
  },
  TIME_IS_UP: {
    icon: "time_is_up.png",
    title: "Oh no, your time is up :(",
    subtitle:
      "Thank you %s, we hope you received clarity & insights that you were searching for. \n\nIf you still want to continue this chat, you can reload payments in the wallet section",
    btnYes: "Reload",
    btnNo: "Done",
  },
  CONNECTION_ISSUE: {
    icon: "no_internet.png",
    title: "Connection Issues",
    subtitle:
      "Your conversation has ended due to connectivity problems. Please be sure to check your connection and try again",
    btnYes: "Ok",
    btnNo: null,
  },
};

export const EMPTY_POPUP_DETAILS = {
  CHAT: {
    description: "You haven’t any conversations yet",
    btnText: "Explore",
  },

  SAVED: {
    description: "You haven't added any experts yet",
    btnText: "Find Top Rated Experts",
  },
};

export const NOTIFICATION_TYPE = {
  chat: 0,
  voice: 1,
  video: 2,
  available_advisor: 3,
  accept_request: 4,
  reject_request: 5,
  cancel_conversation: 6,
};

export const ACCEPT_REJECT_NOTIF_TYPE = {
  reject: 0,
  accept: 1,
};

export const CONVERSATION_TYPE = {
  CHAT: { id: 1, name: "chat" },
  VOICE: { id: 2, name: "voice" },
  VIDEO: { id: 3, name: "video" },
};

//times
export const check_active_conversation_time = 10 * 1000; //10seconds
export const final_waiting_time_for_conversation_response = 3 * 60 * 1000; //2 minutes;

export { imagesApiURL, apiURL, minChatPrice, minCallPrice, minVideoPrice };

export const current_conversation_accepted = "current_conversation_accepted";

export const LOGIN_REGISTER_TYPE = {
  DEFAULT: 0,
  GOOGLE: 1,
  FACEBOOK: 2,
};

export const GOOGLE_CLIENT_ID =
  "189045064216-vnn2ps14956k5ccr3nnqsha21rm5j7av.apps.googleusercontent.com";

export const isConversationCancelled = "isConversationCancelled";
