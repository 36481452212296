import free_min_icon from '../../assets/icons/free_3_minutes_icon_green.png'
import secured_icon from '../../assets/icons/secured_icon.png'
import satisfaction_icon from '../../assets/icons/satisfaction_guaranteed_icon_green.png'
import personalized_icon from '../../assets/icons/advisors_icon_green.png'
import twenty_four_icon from '../../assets/icons/24_7_icon.png'
import trusted_icon from '../../assets/icons/trusted_icon.png'
import banner from '../../assets/img/bg/about_advia_wizzard_banner.png'
import bannerMobile from '../../assets/img/bg/about_advia_wizzard_banner_mobile.png'
import { isMobileOnly } from 'react-device-detect'
import { aboutUs } from "../../constants"

const IconsList = (props) => {
    const iconsList = [
        {
            icon: secured_icon,
            titleFirstLine: 'Secured',
            titleSecondLine: 'platform'
        },
        {
            icon: free_min_icon,
            titleFirstLine: 'First 3 minutes',
            titleSecondLine: 'free'
        },
        {
            icon: satisfaction_icon,
            titleFirstLine: 'Satisfaction',
            titleSecondLine: 'guaranteed'
        },
        {
            icon: personalized_icon,
            titleFirstLine: 'Personalized',
            titleSecondLine: 'Wizard'
        },
        {
            icon: twenty_four_icon,
            titleFirstLine: '24/7 available',
            titleSecondLine: 'psychic advisors'
        },
        {
            icon: trusted_icon,
            titleFirstLine: 'Trusted psychic',
            titleSecondLine: 'readers'
        },
    ]
    return (

        <div style={{ position: 'relative' }}>
            <img src={isMobileOnly ? bannerMobile : banner} style={styles.banner} alt='banner' />
            <div className={isMobileOnly ? 'd-flex flex-column' : ' d-flex justify-content-evenly flex-wrap'} style={isMobileOnly ? { padding: '19px 33px' } : { padding: '33px 80px 80px' }}>
                {iconsList.map((icon, id) => {
                    return (
                        <div key={id} className={'d-flex'} style={isMobileOnly ? { marginBottom: '49px' } : (id === (iconsList.length - 1) ? { marginRight: '0', marginBottom: '20px' } : { marginRight: '60px', marginBottom: '20px' })}>
                            <img src={icon.icon} style={{ height: 'fit-content', marginRight: '12px' }} alt='icon' />
                            <div style={styles.text}>{icon.titleFirstLine}<br />{icon.titleSecondLine}</div>
                        </div>
                    )
                })}
            </div>
            <div style={isMobileOnly ? styles.descMobile : styles.desc}>
                {aboutUs[2].text}
            </div>
        </div >
    )
}

export default IconsList

const styles = {
    banner: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: '-1',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    text: {
        color: 'white',
        fontSize: isMobileOnly ? '18px' : '16px',
        lineHeight: '22px',
        fontWeight: 'normal',
        display: 'contents',
        // maxWidth: 'fit-content'
    },
    desc: {
        color: 'white',
        fontSize: '24px',
        lineHeight: '34px',
        fontWeight: '600',
        textAlign: 'center',
        padding: '25px 130px 72px'
    },
    descMobile: {
        color: 'white',
        fontSize: '16px',
        lineHeight: '27.5px',
        fontWeight: '600',
        textAlign: 'center',
        padding: '27px 33px 43px'
    }
}