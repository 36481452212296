import React, { useState } from 'react';
import { Navbar, Nav, Image } from 'react-bootstrap';
import '../../styles/_layouts.scss';
import logo from '../../assets/icons/logo.png';
import axios from 'axios';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import dashboardIcon from '../../assets/icons/dashboard_icon.png'
import advisorsIcon from '../../assets/icons/advisors_icon.png'
import archiveIcon from '../../assets/icons/archive_icon.png'
import paymentsIcon from '../../assets/icons/payments_icon.png'

import * as myAppConfig from '../../constants/AppConstants'
import AccountManageActionModal from '../modals/AccountManageActionModal';
import AdvisorDropDown from './dropDowns/AdvisorDropDown';

function AdminNavbar({ userEmail, check403Error }) {

  const [isOff, setIsOff] = useState(localStorage.getItem('userStatus') === '0' ? true : false);
  const [showAccountManageModal, setShowAccountActionModal] = useState(false);
  const [accountActionType, setAccountActionType] = useState("");

  let checkUrl = window.location.pathname;

  const urlStrings = window.location.pathname.split("/");

  async function changeStatus() {
    axios.post(myAppConfig.CHANGE_ADVISOR_STATUS, '', {
      headers: { 'token': localStorage.getItem('userToken') }
    }).then(response => {
      console.log(response.data)
    }).catch(err => check403Error(err));
  }

  const setOnline = async e => {
    e.preventDefault();
    await changeStatus();
    setIsOff(false);
    localStorage.setItem('userStatus', 1);
  }

  const setOffline = async e => {
    e.preventDefault();
    await changeStatus();
    setIsOff(true);
    localStorage.setItem('userStatus', 0);
  }

  const onAccountActionBtnClick = async (e) => {
    e.preventDefault();
    if (accountActionType === myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.SWITCH_TO_OFFLINE) {
      setOffline(e);
    } else {
      setOnline(e);
    }
    setShowAccountActionModal(false);
  };

  const accountManageAction = (actionType) => {
    setAccountActionType(actionType);
    setShowAccountActionModal(true);
  };

  return (
    <>
      <AccountManageActionModal
        show={showAccountManageModal}
        setShowAccountActionModal={() => setShowAccountActionModal(false)}
        actionType={accountActionType}
        onAccountAction={onAccountActionBtnClick}
      />

      <Navbar expand="lg" className="navbar-dark adminNavbar" style={urlStrings.includes('category') ? { background: 'transparent' } : {}}>
        <Navbar.Brand as={Link} to="/" className='col-2 me-0 pe-0'><img src={logo} alt="logo" /></Navbar.Brand>

        <Navbar.Toggle aria-controls="navbarScroll" className='col-lg-3 col-sm-4 col-3 px-0' style={{ textAlign: 'right' }} />

        {window.innerWidth > 1000 ?

          <Navbar.Collapse id="navbarScroll" className='col-10'>
            <Nav
              className="me-auto my-2 my-lg-0"
              // style={{ maxHeight: '100px' }}
              navbarScroll
              defaultActiveKey={checkUrl === 'station' ? "link-2" : checkUrl === '/registered-users' ? "link-3" : checkUrl === '/payments' ? "link-4" : "link-1"}
            >
              <Nav.Link as={Link} to="/" eventKey="link-1"><Image src={dashboardIcon} className="admin_menu_images" />Dashboard</Nav.Link>
              <Nav.Link as={Link} to="/station" id="advisors-station" eventKey="link-2"><Image src={advisorsIcon} className="admin_menu_images" />Station</Nav.Link>
              <Nav.Link as={Link} to="/registered-users" eventKey="link-3"><Image src={archiveIcon} className="admin_menu_images" />Archive</Nav.Link>
              <Nav.Link as={Link} to="/payments" eventKey="link-4"><Image src={paymentsIcon} className="admin_menu_images" />Payments</Nav.Link>

            </Nav>
            <Nav>
              <div style={{ display: 'table' }}>
                <div className="toogle-on-off">
                  <label className="switch"><input type="checkbox" id="togBtn" checked={isOff} onChange={(ev) => {
                    if (isOff)
                      accountManageAction(myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.SWITCH_TO_ONLINE)
                    else
                      accountManageAction(myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.SWITCH_TO_OFFLINE)
                  }} />
                    <div className="slider round">
                      <span className="on">Online</span>
                      <span className="off">Offline</span>
                    </div>
                  </label>
                </div>
                <AdvisorDropDown userEmail={userEmail} check403Error={check403Error} />
              </div>

            </Nav>

          </Navbar.Collapse>
          :
          <>
            <Nav className='col-sm-6 col-7 justify-content-end'>
              <div style={{ display: 'table' }}>
                <div className="toogle-on-off">
                  <label className="switch"><input type="checkbox" id="togBtn" checked={isOff} onChange={(ev) => {
                    if (isOff)
                      accountManageAction(myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.SWITCH_TO_ONLINE)
                    else
                      accountManageAction(myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.SWITCH_TO_OFFLINE)
                  }} />
                    <div className="slider round">
                      <span className="on">Online</span>
                      <span className="off">Offline</span>
                    </div>
                  </label>
                </div>
                <AdvisorDropDown userEmail={userEmail} check403Error={check403Error} />
              </div>
            </Nav>

            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="mx-auto mt-2 my-lg-0"
                style={styles.nav}
                defaultActiveKey={checkUrl === 'station' ? "link-2" : checkUrl === '/registered-users' ? "link-3" : checkUrl === '/payments' ? "link-4" : "link-1"}
              >
                <Nav.Link as={Link} to="/" eventKey="link-1"><Image src={dashboardIcon} className="admin_menu_images" />Dashboard</Nav.Link>
                <Nav.Link as={Link} to="/station" eventKey="link-2"><Image src={advisorsIcon} className="admin_menu_images" />Station</Nav.Link>
                <Nav.Link as={Link} to="/registered-users" eventKey="link-3"><Image src={archiveIcon} className="admin_menu_images" />Archive</Nav.Link>
                <Nav.Link as={Link} to="/payments" eventKey="link-4"><Image src={paymentsIcon} className="admin_menu_images" />Payments</Nav.Link>

              </Nav>


            </Navbar.Collapse>
          </>}
      </Navbar>
    </>

  );
}

export default AdminNavbar;

AdminNavbar.propTypes = {
  userEmail: PropTypes.string,
  check403Error: PropTypes.func
}

const styles = {
  nav: {
    alignItems: "flex-start",
    width: 'fit-content'
  }
}