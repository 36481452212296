import React, { useState, useEffect } from "react";
import axios from "axios";

import * as myAppConfig from "../../../constants/AppConstants";
import "../../../styles/chat.scss";
import EndSessionModal from "../../../components/modals/EndSessionModal";
import ThankYouScreen from "../../../components/ThankYouScreen";
import ChatComponent from "../../../components/chat/ChatComponent";
import AccountManageActionModal from "../../../components/modals/AccountManageActionModal";
import { useTimer } from "reactjs-countdown-hook";
import * as utils from "../../../Utilities/Utils";
import * as gaEcommerce from "../../../Utilities/GAEcommerce";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Div100vh from "react-div-100vh";
//import ReactGA from "react-ga";

const Chat = require("twilio-chat");

const ChatScreen = (props) => {
  const { check403Error } = props;

  const userType = parseInt(localStorage.getItem("userType"));
  let history = useHistory();

  const isMounted = React.useRef(true);
  //const [showLoader, setShowLoader] = useState(true);

  const [chatMessages, setChatMessages] = useState([]);
  const [newChatMessages, setNewChatMessages] = useState([]);
  const [joinedChannel, setJoinedChannel] = useState(false);

  const [channel, setChannel] = useState();
  const [identity, setIdentity] = useState("");
  const [typing, setTyping] = useState(false);
  const [token, setToken] = useState("");

  const [chatInfo, setChatInfo] = useState({
    uniqueName: "",
    friendlyName: "",
    advisorfriendlyName: "",
  });

  const [otherJoined, setOtherJoined] = useState(false);

  const [thankYouPopupMessage, setThankYouPopupMessage] = useState(
    myAppConfig.FINAL_THANK_YOU_MESSAGES.CONVERSATION_ENDED
  );

  const otherTalkerInfo = sessionStorage.getItem("otherTalker")
    ? JSON.parse(sessionStorage.getItem("otherTalker"))
    : [];
  const otherTalkerId = otherTalkerInfo["id"] ? otherTalkerInfo["id"] : "";
  const otherTalkerName = otherTalkerInfo["name"]
    ? otherTalkerInfo["name"]
    : "";
  const otherTalkerLogoImage = otherTalkerInfo["logo_image"]
    ? otherTalkerInfo["logo_image"]
    : "";
  const otherTalkerCategory = otherTalkerInfo["category"]
    ? otherTalkerInfo["category"]
    : "";

  const [showEndSession, setShowEndSession] = useState(false);
  const [isConversationDone, setConversationDone] = useState(false);

  const [isSessionEnded, setSessionEnded] = useState(true);

  const [showAccountManageModal, setShowAccountActionModal] = useState(false);
  const [accountActionType, setAccountActionType] = useState("");

  var timerForCheckAdvisorResponse;
  var timerForActiveConversation;
  var timerForContinueWithConversation;

  const [isTimerForContinueConversation, setIsTimerForContinueConversation] =
    useState(false);

  const sendMessage = (text) => {
    if (text) {
      channel.sendMessage(String(text).trim());
    }
  };

  var timerForNotResponding, timerForIsInConversation;
  const [isNotResponding, setIsNotResponding] = useState(false);
  const [isInConversation, setIsInConversation] = useState(false);

  useEffect(() => {
    if (isNotResponding) {
      timerForNotResponding = setTimeout(() => btnEndSessionClick(), 60 * 1000);
    } else {
      clearTimeout(timerForNotResponding);
    }
    return () => clearTimeout(timerForNotResponding);
  }, [isNotResponding]);

  useEffect(() => {
    if (isInConversation) {
      timerForIsInConversation = setTimeout(() => {
        setIsNotResponding(true);
        setShowEndSession(true);
      }, myAppConfig.final_waiting_time_for_conversation_response);
    } else {
      clearTimeout(timerForIsInConversation);
    }
    return () => clearTimeout(timerForIsInConversation);
  }, [isInConversation]);

  const conversationResponseSeconds = 60;
  useTimer(conversationResponseSeconds, clearWaitingTimeForAdvisorResponse);

  function clearWaitingTimeForAdvisorResponse() {
    let chatInvitation = sessionStorage.getItem("chat-invitation");

    if (utils.invitationResponse(chatInvitation)) {
      setIsTimerForContinueConversation(true);

      setAccountActionType(
        utils.getAdvisorIsNotRespondingMessage(otherTalkerName)
      );
      setShowAccountActionModal(true);
    }
  }

  useEffect(() => {
    if (isTimerForContinueConversation) {
      timerForContinueWithConversation = setTimeout(
        () => onCancelChat(),
        60 * 1000
      );
    } else {
      clearTimeout(timerForContinueWithConversation);
    }

    return () => clearTimeout(timerForContinueWithConversation);
  }, [isTimerForContinueConversation]);

  const checkChatInvitationResponse = (_channel) => {
    let isConversationCancelled = sessionStorage.getItem(
      myAppConfig.isConversationCancelled
    );
    console.log("isConversationCancelled: ", isConversationCancelled);
    if (
      isConversationCancelled === true ||
      isConversationCancelled === "true"
    ) {
      return;
    }

    if (window.location.pathname === "/chat") {
      let invitation = sessionStorage.getItem("chat-invitation");

      if (utils.invitationResponse(invitation)) {
        console.log("Waiting chat invitation response: " + invitation);
        clearTimeout(timerForCheckAdvisorResponse);
        timerForCheckAdvisorResponse = setTimeout(
          () => checkChatInvitationResponse(_channel),
          1000
        );
      } else if (
        Number(invitation) ===
        Number(myAppConfig.NOTIFICATION_TYPE.accept_request)
      ) {
        setShowEndSession(false);
        setShowAccountActionModal(false);
        setIsTimerForContinueConversation(false);

        console.log("Accepted Chat invitation: " + invitation);

        sessionStorage.setItem("free-minutes", 0);
        startOrEndConversation(
          true,
          _channel.channelState.uniqueName,
          _channel
        );
      } else if (
        Number(invitation) ===
        Number(myAppConfig.NOTIFICATION_TYPE.reject_request)
      ) {
        let message = utils.displayErrorMessage(
          "Sorry",
          utils.getAdvisorIsBusyMessage(otherTalkerName)
        );
        setAccountActionType(message);
        setShowAccountActionModal(true);
      } else {
        //console.log("aici vreau sa intru");
      }
    } else {
      clearTimeout(timerForCheckAdvisorResponse);
    }
  };

  const checkActiveConversation = (conversation_id) => {
    if (window.location.pathname === "/chat") {
      axios
        .get(myAppConfig.CHECK_ACTIVE_CONVERSATION, {
          params: {
            conversation_id: conversation_id,
            from: 2, //client
          },
          headers: {
            token: localStorage.getItem("userToken"),
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            console.log("response get active conversation ", 2);

            clearTimeout(timerForActiveConversation);
            timerForActiveConversation = setTimeout(
              () => checkActiveConversation(conversation_id),
              myAppConfig.check_active_conversation_time
            );
          } else {
            clearTimeout(timerForActiveConversation);

            let message = utils.displayErrorMessage(
              "Error",
              response.data.message !== ""
                ? response.data.message
                : "An error has occured!"
            );
            setAccountActionType(message);
            setShowAccountActionModal(true);
          }
        });
    } else {
      clearTimeout(timerForActiveConversation);
    }
  };

  const handleEndSession = () => {
    if (!isSessionEnded && channel !== null && channel !== undefined) {
      startOrEndConversation(false, channel.channelState.uniqueName);
    }
  };

  useEffect(() => {
    const unlockStory = (e) => {
      if (!isSessionEnded) {
        //handleEndSession();
        sessionStorage.setItem("close-chat", true);
        setTimeout(() => log(1), 1000);
        e.preventDefault();
        e.returnValue = "Are you sure you want to close the session?";
      }
    };

    const log = (index) => {
      console.log("asas", index);

      if (index === 1) {
        setTimeout(() => log(2), 1000);
      } else {
        sessionStorage.removeItem("close-chat");
      }
    };

    // Add event listener to run code before window closes
    window.addEventListener("beforeunload", unlockStory);
    window.addEventListener("pagehide", unlockStory);

    return () => {
      window.removeEventListener("beforeunload", unlockStory);
      window.removeEventListener("pagehide", unlockStory);
    };
  }, [isSessionEnded, channel]);

  useEffect(() => {
    async function createConversation() {
      console.log("create conversation ");
      sessionStorage.setItem("chat-invitation", undefined);
      sessionStorage.setItem(myAppConfig.isConversationCancelled, false);

      let convFormData = utils.getCreateConversationParameters(
        otherTalkerId,
        myAppConfig.CONVERSATION_TYPE.CHAT.id
      );

      console.log("calling create conversation");
      axios
        .post(myAppConfig.CREATE_CONVERSATION, convFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: localStorage.getItem("userToken"),
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            if (response.data.payUrl) {
              utils.redirectToAddCard(
                response.data,
                myAppConfig.CONVERSATION_TYPE.CHAT.name
              );
            } else {
              console.log("conversation_id: " + response.data.conversation_id);
              const uniqueName = response.data.conversation_id;
              const friendlyName = response.data.channelfriendlyName;
              const advisorfriendlyName = response.data.advisorfriendlyName;

              setChatInfo({
                uniqueName: uniqueName,
                friendlyName: friendlyName,
                advisorfriendlyName: advisorfriendlyName,
              });

              const fetchTasks = utils.getChatToken(
                myAppConfig.CONVERSATION_TYPE.CHAT.name
              );

              Promise.all([fetchTasks])
                .then(function (res) {
                  const response = res[0];
                  if (response.data.status === "success") {
                    setIdentity(response.data.identity);
                    setToken(response.data.token);
                  } else {
                    console.log(response.data);
                  }
                })
                .catch((error) => {
                  console.log("error on fetchTasks: " + error);
                  check403Error(error);
                });
            }
          } else {
            console.log(response.data);
          }
        })
        .catch((err) => {
          console.log("err create conv");
          check403Error(err);
        });
    }

    if (channel !== null && channel !== undefined) {
      channel.removeAllListeners();
    }

    if (isMounted.current) {
      //ReactGA.plugin.require("ecommerce");
      createConversation();
    }

    return () => {
      isMounted.current = false;
      console.log("return isMounted: " + isMounted.current);
    };
  }, []);

  useEffect(() => {
    const createChannelForChat = () => {
      if (token !== "") {
        Chat.Client.create(token).then((client) => {
          const uniqueName = chatInfo.uniqueName;
          const friendlyName = chatInfo.friendlyName;
          const advisorfriendlyName = chatInfo.advisorfriendlyName;

          client.on("tokenAboutToExpire", () => {
            client.updateToken(token);
          });

          client.on("tokenExpired", () => {
            client.updateToken(token);
          });

          client.on("channelJoined", (channel) => {
            if (
              parseInt(channel.channelState.uniqueName) === parseInt(uniqueName)
            ) {
              setJoinedChannel(true);
              console.log("channel joined ", channel);
              checkChatInvitationResponse(channel);

              channel.getMessages().then((messages) => {
                console.log("channel get messages " + messages.items.length);
                setChatMessages([...messages.items]);
              });

              if (advisorfriendlyName !== "") {
                channel.invite(advisorfriendlyName).then(function () {
                  console.log(
                    "1. Your friend has been invited! ",
                    advisorfriendlyName,
                    channel
                  );
                });
              }
            }
          });

          client.on("memberJoined", function (member) {
            console.log(member.identity + " has joined the channel. ");
          });

          client.on("connectionStateChanged", (connection) => {
            console.log("connectionStateChanged: ", connection);
          });

          client
            .getChannelByUniqueName(uniqueName.toString())
            .then((channel) => {
              /*channel.delete().then(function(channel) {
                console.log('Deleted channel: ' + channel.sid);
              });
              return;*/

              setChannel(channel);
              console.log("1. after set channel");
              if (
                channel.members.size === 0 &&
                identity !== advisorfriendlyName
              ) {
                // Invite another member to your channel
                channel
                  .invite(advisorfriendlyName)
                  .then(function () {
                    console.log(
                      "Friend invited: ",
                      advisorfriendlyName,
                      channel
                    );
                  })
                  .catch((err) => {
                    console.log("Error on invite to channel " + err);
                  });
              }

              if (channel.channelState.status !== "joined") {
                channel.join();
              }

              client.on("memberJoined", function (member) {
                console.log(member.identity + " has joined the channel.");
              });

              channel.on("messageAdded", (message) => {
                console.log("messages added ", message.body);
                onNewMessageAdded(channel, message);
              });
              channel.on("typingStarted", function (member) {
                setTyping(true);
              });
              channel.on("typingEnded", function (member) {
                setTyping(false);
              });

              channel.on("memberLeft", function (member) {
                console.log(member.identity + " has left the channel.");
                startOrEndConversation(false, channel.channelState.uniqueName);
              });
            })
            .catch((err) => {
              console.log("Create new channel ", uniqueName.toString());
              client
                .createChannel({
                  uniqueName: uniqueName.toString(),
                  friendlyName: friendlyName,
                  isPrivate: true,
                })
                .then((channel) => {
                  console.log("channel joined after create");
                  setChannel(channel);
                  checkChatInvitationResponse(channel);

                  if (channel.channelState.status !== "joined") {
                    channel.join();
                  }
                  channel.on("messageAdded", (message) => {
                    onNewMessageAdded(channel, message);
                  });
                  channel.on("typingStarted", function (member) {
                    setTyping(true);
                  });
                  channel.on("typingEnded", function (member) {
                    setTyping(false);
                  });
                })
                .catch((err) => {
                  /*throw new Error("Unable to connect to channel, please reload this page");*/
                  alert("Unable to connect to channel \n" + err.body.message);
                  onCancelChat();
                });
            });
        });
      }
    };

    if (token !== "" && !joinedChannel) {
      createChannelForChat();
    }
  }, [token]);

  function onNewMessageAdded(_channel, message) {
    setNewChatMessages((newChatMessages) => [...newChatMessages, message]);

    if (
      (newChatMessages.length === 0 || newChatMessages.length === 1) &&
      message.author === identity &&
      utils.IsJsonSessionStart(message.body)
    ) {
      setNewChatMessages((newChatMessages) => [
        ...newChatMessages,
        "You joined the chat",
      ]);
      setNewChatMessages((newChatMessages) => [
        ...newChatMessages,
        otherTalkerName + " joined the chat",
      ]);
    }

    if (message.author !== identity && utils.IsJsonString(message.body)) {
      clearTimeout(timerForActiveConversation);
      setConversationDone(true);
      setIsInConversation(false);
      setIsNotResponding(false);
      if (_channel !== undefined) {
        startOrEndConversation(false, _channel.channelState.uniqueName);
      }
    }

    if (message.author === identity && !utils.IsJsonString(message.body)) {
      setIsInConversation(false);
      setIsInConversation(true);
    }
  }

  const imageChange = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    channel.sendMessage(formData);
  };

  const showModalEndSession = () => {
    setShowEndSession(true);
  };

  const btnEndSessionClick = () => {
    setThankYouPopupMessage(
      myAppConfig.FINAL_THANK_YOU_MESSAGES.CONVERSATION_ENDED
    );
    setConversationDone(true);
    setShowEndSession(false);

    if (channel !== undefined) {
      startOrEndConversation(false, channel.channelState.uniqueName);
    }
  };

  const btnContinueConversation = () => {
    setIsNotResponding(false);
    setShowEndSession(false);
  };

  const startOrEndConversation = (isStart, conv_id, _channel) => {
    console.log("startOrEndConversation ", isStart, conv_id, _channel);

    let formData = utils.getStartOrEndConversationParameters(
      isStart,
      conv_id,
      myAppConfig.CONVERSATION_TYPE.CHAT.id
    );
    if (isStart) {
      setSessionEnded(false);
    } else {
      setSessionEnded(true);

      if (channel !== undefined) {
        console.log("send message on end session btn click");
        channel.sendMessage(
          '{"msg":"' + localStorage.getItem("userFullName") + ' left the chat"}'
        );
      }

      var last_message = utils.getLastMessage(
        newChatMessages.length > 0 ? newChatMessages : chatMessages
      );
      formData.append("last_message", last_message);
    }

    const fetchStartEndConv = utils.callStartOrEndConversation(
      isStart,
      formData
    );

    Promise.all([fetchStartEndConv])
      .then(function (res) {
        const response = res[0];
        if (response.data.status === "success") {
          if (isStart) {
            const currentDate = utils.getCurrentDateUTC();
            if (_channel !== undefined) {
              console.log(
                "sessionStartDate ",
                currentDate,
                response.data.session_id
              );
              _channel.sendMessage(
                '{"sessionStartDate":"' +
                  currentDate +
                  '", "session_id":"' +
                  response.data.session_id +
                  '"}'
              );
            }
            checkActiveConversation(conv_id);

            setOtherJoined(true);

            sessionStorage.setItem("free-minutes", response.data.free_minutes);
            console.log("free minutes ", response.data.free_minutes);
          } else {
            if (response.data.end_Details) {
              gaEcommerce.gaAddTransaction(response.data.end_Details);
            }
          }
          console.log("success " + (isStart ? " started conv" : " ended conv"));
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log("error on fetchStartEndConv: " + error);
        check403Error(error);
      });
  };

  const onAccountActionBtnClick = () => {
    const showHomepage = isTimerForContinueConversation;
    setIsTimerForContinueConversation(false);
    setShowAccountActionModal(false);

    if (!showHomepage) {
      history.replace("/");
    }
  };

  function onCancelChat() {
    sessionStorage.setItem(myAppConfig.isConversationCancelled, true);
    console.log("cancel the chat conv_id:", chatInfo.uniqueName);
    if (chatInfo.uniqueName !== "") {
      const fetchCancelConversationTask = utils.cancelConversation(
        chatInfo.uniqueName
      );
      Promise.all([fetchCancelConversationTask])
        .then(function (res) {
          cancel();
        })
        .catch((error) => {
          cancel();
        });
    } else {
      cancel();
    }

    clearTimeout(timerForActiveConversation);
    clearTimeout(timerForCheckAdvisorResponse);
  }

  function cancel() {
    history.goBack();
  }

  const renderChatComponent = () => {
    return (
      <>
        {otherJoined ? (
          <ChatComponent
            isNewChat={true}
            chatMessages={chatMessages}
            setChatMessages={setChatMessages}
            newChatMessages={newChatMessages}
            showModalEndSession={showModalEndSession}
            identity={identity}
            typing={typing}
            channel={channel}
            imageChange={imageChange}
            sendMessage={sendMessage}
            otherTalkerName={otherTalkerName}
            otherTalkerLogoImage={otherTalkerLogoImage}
            otherTalkerCategory={otherTalkerCategory}
            joinedChannel={true}
            otherJoined={otherJoined}
            showEndSessionButton={true}
          />
        ) : (
          <ChatComponent
            otherTalkerName={otherTalkerName}
            otherTalkerLogoImage={otherTalkerLogoImage}
            otherTalkerCategory={otherTalkerCategory}
            showLoader={true}
            onCancelChat={onCancelChat}
          />
        )}
      </>
    );
  };

  const renderThankYouScreen = () => {
    return (
      <ThankYouScreen
        data={thankYouPopupMessage}
        advisorName={localStorage.getItem("userFullName")}
        advisorId={otherTalkerId}
      />
    );
  };

  return (
    <>
      <AccountManageActionModal
        show={showAccountManageModal}
        setShowAccountActionModal={() => onAccountActionBtnClick()}
        actionType={accountActionType}
        onAccountAction={onAccountActionBtnClick}
        btnText={isTimerForContinueConversation ? "Continue" : "Ok"}
        hideClose={true}
      />

      <EndSessionModal
        show={showEndSession}
        setShowEndSession={() => setShowEndSession(false)}
        btnEndSessionClick={() => btnEndSessionClick()}
        isNotResponding={isNotResponding}
        btnContinueConversation={() => btnContinueConversation()}
      />
      {isConversationDone ? (
        isMobile ? (
          <Div100vh style={{ display: "flex", justifyContent: "center" }}>
            {renderThankYouScreen()}
          </Div100vh>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "70vh",
            }}
          >
            {renderThankYouScreen()}
          </div>
        )
      ) : isMobile ? (
        <Div100vh>{renderChatComponent()}</Div100vh>
      ) : (
        <div className="chat-screen-container">
          <div className="chatScreen mb-5 ">{renderChatComponent()}</div>
        </div>
      )}
    </>
  );
};

export default ChatScreen;
