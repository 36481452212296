
import { useState } from "react";
import { Card, InputGroup, FormControl  } from "react-bootstrap";

const Communication = (props) => {

    const { phoneNumber, setPhoneNumber, saveUserDetails } = props;
    
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
    async function changePhoneNumber(ev) {
        if (ev.target.value && !((/^\d{7,}$/).test(ev.target.value.replace(/[\s()+\-\.]|ext/gi, '')))) {
            alert('Invalid Phone Number Format!');
          setInvalidPhoneNumber(true)
        }
        else{ 
            setInvalidPhoneNumber(false);
            setPhoneNumber(ev.target.value);
            if (saveUserDetails) {
              const formData = new FormData();
                formData.append("phone_number", ev.target.value ? ev.target.value : ' ');
              await saveUserDetails(formData);
            }
        }
    }
    return (
        <Card className="card-custom custom-padding">
            <p className="custom-text-style-24-34">Communication</p>
            <p className="custom-text-style-16-22">Your Phone Number: (Optional)</p>
            <InputGroup className="mb-3 communication-username">
                <FormControl className={invalidPhoneNumber ? 'invalid' : ''} defaultValue={phoneNumber ? phoneNumber : ""} aria-describedby="basic-addon1" onBlur={changePhoneNumber} inputMode='numeric' />
            </InputGroup>
            <p className="info-communication">This number will be use for the advisor success team only for direct communications. Your phone number will not be shared or display to others.</p>
        </Card>
    )
}

export default Communication