import pdf from '../../../constants/best_practices.pdf'

const BestPractices = (props) => {

    return (
        <>
            <div style={{ position: 'absolute', width: '4vw', height: '4vw', top: '2vw', left: 'calc(50% - 8px)', transform: 'translate(-50%, 0)', cursor: 'pointer' }} onClick={() => window.location.href = '/'} />
            <iframe src={pdf + '#toolbar=0&navpanes=0&scrollbar=0'} style={{ display: 'block', width: '100%', height: '100%', background: '#04112e' }} frameBorder='0' />
        </>
    )
}

export default BestPractices