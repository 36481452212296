import { Modal, Button } from 'react-bootstrap'
import findMoreImg1 from '../../assets/img/find_out_more.png'
import findMoreImg2 from '../../assets/img/find_out_more1.png'
import findMoreImg3 from '../../assets/img/find_out_more2.png'

import pattern from '../../assets/img/bg/find_out_more_pattern.png'

import { isMobileOnly } from 'react-device-detect'
import { useSwipeable } from 'react-swipeable'

const findOutMoreData = [
    {
        title: 'Find your answers',
        subtitle: 'Advia is the leading psychic advisor service. Bringing you clarity, insights, and answers to all your questions',
        image: findMoreImg1
    },
    {
        title: 'Get amazing insights from our top experts',
        subtitle: 'Get all answers you need. From love & relationships to career choices',
        image: findMoreImg2
    },
    {
        title: 'Find your perfect match',
        subtitle: "There's hundreds of expert psychic advisors to choose from. We will help you find the best one for you",
        image: findMoreImg3
    }
]

const OnBoarding = (props) => {

    const handlers = useSwipeable({
        onSwipedLeft: (e) => onSwipeLeft(),
        onSwipedRight: (e) => onSwipeRight()
    });

    const { activeFindOutMore, setActiveFindOutMore, skipUrl, showFindMoreModal, setShowFindMoreModal } = props

    function cont() {
        if (activeFindOutMore < 2) {
            setActiveFindOutMore(activeFindOutMore + 1)
        }
        else {
            if (skipUrl) {
                window.location.pathname = skipUrl
            }
            else {
                setShowFindMoreModal(false);
                setTimeout(() => {
                    setActiveFindOutMore(0);
                }, 1000);
            }
        }
    }

    const onSwipeLeft = () => {
        if (activeFindOutMore < 2) {
            setActiveFindOutMore(activeFindOutMore + 1);
        }
    }

    const onSwipeRight = () => {
        if (activeFindOutMore > 0) {
            setActiveFindOutMore(activeFindOutMore - 1);
        }
    }

    return (
        <Modal
            show={showFindMoreModal}
            onHide={() => { setShowFindMoreModal(false); if (skipUrl) window.location.pathname = skipUrl }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="editModal account findMore"
            dialogClassName={isMobileOnly ? "modal-fullscreen modal-backdrop" : ""}
            backdrop='static'
            keyboard={false}
        >
            <div {...handlers} >
            <img src={pattern} alt='banner' className='banner' />
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <img src={findOutMoreData[activeFindOutMore].image} alt='find more tab banner' />
                <div className="title">{findOutMoreData[activeFindOutMore].title}</div>
                <div className='subtitle'>{findOutMoreData[activeFindOutMore].subtitle}</div>
                <div className='circles'>
                    {[1, 2, 3].map((item, idx) => {
                        return (
                            <div key={idx} className={activeFindOutMore === idx ? 'active circle' : 'circle'} onClick={() => setActiveFindOutMore(idx)} />
                        )
                    })}
                </div>
                <Button className="saveButton" onClick={() => cont()}>Continue</Button>
                {skipUrl ? <a href={skipUrl}>Skip</a> : null}
            </Modal.Body>
            </div>
        </Modal>
    )
}

export default OnBoarding