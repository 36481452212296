import React from "react";
import { Button, Row, Card, Table, Form } from "react-bootstrap";
import "../../../styles/expertCard.scss";
import { imagesApiURL } from "../../../constants/AppConstants";
import * as myAppConfig from "../../../constants/AppConstants";
import user from '../../../assets/img/user.png'
import { Link } from "react-router-dom";

const ExpertCard = (props) => {
  const VIEW = "View";
  const PROJECTION = "Projection";
  const CURRENCY_SYMBOL = "$";

  //debugger;
  return (
    <div>
      <Row className="expert-card-row-container">
        <Table responsive key={props.advisor_revenue.id}>
          <thead>
            <tr>
              <th className="expert-header-label" style={{ width: "20%" }}>
                <p>Advisor Name</p>
              </th>
              <th className="expert-header-label" style={{ width: "10%" }}>
                <p>Advisor ID</p>
              </th>
              <th className="expert-header-label" style={{ width: "20%" }}>
                <p>Last Update</p>
              </th>
              <th className="expert-header-label" style={{ width: "20%" }}>
                <p>Daily Revenue</p>
              </th>
              <th className="expert-header-label" style={{ width: "8%" }}>
                <p>Reviews</p>
              </th>
              <th className="expert-header-label" style={{ width: "8%" }}>
                <p>NPC</p>
              </th>
              <th className="expert-header-label" style={{ width: "8%" }}>
                <p>PDAU</p>
              </th>
              <th style={{ width: "20%" }}>
                <Button className="viewButton" onClick={() => window.location.pathname = ('/advisor-profile/' + props.advisor_revenue.full_name)}>{VIEW}</Button>
              </th>
              {props.checkbox !== undefined && props.checkbox ? (
                <th style={{ display: "flex" }}>
                  <Form.Check
                    className="adv-checkbox"
                    type="checkbox"
                    checked={props.isChecked}
                    onChange={() => props.onClick()}
                  />
                </th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="expert-label" style={{ width: "20%" }}>
                <div className="expert-name-flex-row">
                  <div style={{ display: "flex" }}>
                    <img
                      className="expert-user-img"
                      src={props.advisor_revenue.logo_image ? imagesApiURL + props.advisor_revenue.logo_image : user}
                      alt="expert-user-img"
                    />
                    {Number(props.advisor_revenue.status) === 0 ? (
                      <Card.Img className="dot busyDot" />
                    ) : Number(props.advisor_revenue.status) === 1 ? (
                      <Card.Img className="dot availableDot" />
                    ) : (
                      <Card.Img className="dot awayDot" />
                    )}
                  </div>
                  <div>
                    <p className={"expert-user-name " + (!props.advisor_revenue.full_name ? "empty" : "")}>
                      {props.advisor_revenue.full_name ? props.advisor_revenue.full_name : 'Empty'}
                    </p>

                    <p className="expert-user-status">
                      {Number(props.advisor_revenue.status_account) ===
                      myAppConfig.ACCOUNT_STATUS.FREEZE.id
                        ? "Freeze Account"
                        : "Active"}
                    </p>
                  </div>
                </div>
              </td>
              <td className="expert-label" style={{ width: "10%" }}>
                <p>{props.advisor_revenue.id}</p>
              </td>
              <td className="expert-label" style={{ width: "20%" }}>
                <p>{props.advisor_revenue.last_update}</p>
              </td>
              <td className="expert-label" style={{ width: "20%" }}>
                <p>{CURRENCY_SYMBOL + props.advisor_revenue.daily_revenue}</p>
              </td>
              <td className="expert-label" style={{ width: "8%" }}>
                <p>{props.advisor_revenue.score_average.toFixed(1)}</p>
              </td>
              <td className="expert-label" style={{ width: "8%" }}>
                <p>{props.advisor_revenue.npc}</p>
              </td>
              <td className="expert-label" style={{ width: "8%" }}>
                <p>{props.advisor_revenue.pdau}</p>
              </td>

              <td className="expert-label" style={{ width: "20%" }}>
                <Link className="viewButton btn-primary" to={{ pathname: "/advisor-projection/" + props.advisor_revenue.full_name, state: props.advisor_revenue.id }}>{PROJECTION}</Link>
              </td>
            </tr>
          </tbody>
        </Table>
      </Row>
      <hr />
    </div>
  );
};

export default ExpertCard;
