import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {  Button, Row, Col,  Modal, ProgressBar } from 'react-bootstrap';

import * as myAppConfig from "../../../../constants/AppConstants";

import CustomCategoryCard from "../Wizard/CategoryCard";
import CustomFeelingCard from "../Wizard/FeelingCard";
import ButtonCard from './ButtonCard';

import logo from '../../../../assets/icons/logo.png';
import iconOk from '../../../../assets/icons/feelings/icon_ok.png';
import iconGreat from '../../../../assets/icons/feelings/icon_great.png';
import iconNoGreat from '../../../../assets/icons/feelings/icon_not_so_great.png';
import iconBack from '../../../../assets/icons/arrows/arrow_left_icon.png';

import styles from '../../../../styles/wizard.module.scss';
import '../../../../styles/_layouts.scss';
import FirstStep from './Wizard_step_first';
import LastStep from './Wizard_step_last';

const BACK = "Back";
const CONTINUE = "Continue";

const MIN_STEP = 0;
const MAX_STEP = 5;
const VALUE_UNSET = -1;

export default function WizardAI( props ) {

    const {check403Error} = props
    const categories = props.homePageDetails.categories ? props.homePageDetails.categories : [];

    const feelingArray = [{ id: 0, title: "Great", icon: iconGreat }, { id: 1, title: "Ok", icon: iconOk }, { id: 2, title: "Not so great", icon: iconNoGreat }];
    const mindArray = [{ id: 0, title: "Divorce & Breakups" }, { id: 1, title: "Career & Work" }, { id: 2, title: "Life Path & Destiny" }, { id: 3, title: "Family & Friends" }, { id: 4, title: "Money & Prosperity" }];
    const skillsArray = [{ id: 0, title: "Clairvoyancy" }, { id: 1, title: "Tarot Reader" }, { id: 2, title: "Angel Reader" }, { id: 3, title: "Dream Analyzer" }, { id: 4, title: "Numerologist" }, { id: 5, title: "I'm not sure yet" }];
    const adviceModeArray = [{ id: 0, title: "Be Direct. I dont like sugar coating!" }, { id: 1, title: "Tell me everything - but in a gentler way" }];
 
    const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
    const [userName, setUserName] = useState(null);
    const [showFirst, setShow] = useState(true);
    const [showStepsModal, setShowStepsModal] = useState(false);
   
    const [stepModalIndex, setModalIndex] = useState(1);
    const [continueBtnDisabled, setContinueBtnDisabled] = useState(true);

    const [areaSelected, setArea] = useState(VALUE_UNSET);
    const [feelingSelected, setFeeling] = useState(VALUE_UNSET);
    const [mindSelected, setMind] = useState(VALUE_UNSET);
    const [skillSelected, setSkill] = useState(VALUE_UNSET);
    const [adviceModeSelected, setAdviceMode] = useState(VALUE_UNSET);

    useEffect(() => {
        let userIsLoggedIn = localStorage.getItem("userToken") === null ? false : true;
        setUserIsLoggedIn(userIsLoggedIn);

        if (userIsLoggedIn) {
     
            let userName = localStorage.getItem("userName");
            setUserName(userName);
        }
    }, []);

    const handleCloseStepsModal = () => setShowStepsModal(false);
    const handleShowStepsModal = () => setShowStepsModal(true);

    const handleFindExpert = () => {

        setModalIndex(1);
        handleShowStepsModal();
        setContinueBtnDisabled(!RequiredValueIsSet(1));
    }

    const handleViewAdvisors = () => {
        //debugger;
        saveUserCategory();
    }

    const handleBack = () => {

        let index = stepModalIndex;
        if (index > MIN_STEP) {
            index -= 1;
            setModalIndex(index);
            setContinueBtnDisabled(!RequiredValueIsSet(index));
        }

        if (index === MIN_STEP) {
            setShow(true);
            handleCloseStepsModal();
        }

    }

    function RequiredValueIsSet(index) {
        // let index = stepModalIndex;
        var isSet = false;
        switch (index) {
            case 1:
                isSet = areaSelected !== -1;
                break;
            case 2:
                isSet = feelingSelected !== -1;
                break;
            case 3:
                isSet = mindSelected !== -1;
                break;
            case 4:
                isSet = skillSelected !== -1;
                break;
            case 5:
                isSet = adviceModeSelected !== -1;
                break;
            default:
                break;
        }
        console.log("Index is: " + index + " value isSet: " + isSet);
        return isSet;
    }



    const handleContinue = () => {

        let index = stepModalIndex;

        if (index < MAX_STEP + 1) {
            index += 1;
            setModalIndex(index);
            setContinueBtnDisabled(!RequiredValueIsSet(index));
        }

        if (index === MAX_STEP + 1) {
            handleCloseStepsModal();
            setShow(false);
        }

    }

    const handleSelectArea = (index) => {
        console.log("item clicked");
        console.log(index);
        setArea(index);
        setContinueBtnDisabled(false);
    }

    const handleSelectFeeling = (index) => {
        console.log(index);
        setFeeling(index);
        setContinueBtnDisabled(false);
    }

    const handleSelectMind = (index) => {
        console.log(index);
        setMind(index);
        setContinueBtnDisabled(false);
    }

    const handleSelectSkill = (index) => {

        console.log(index);
        setSkill(index);
        setContinueBtnDisabled(false);
    }

    const handleSelectAdviceMode = (index) => {
        console.log(index);
        setAdviceMode(index);
        setContinueBtnDisabled(false);
    }

    const getTitleByModalStep = () => {
        let index = stepModalIndex;
        switch (index) {
            case 1:
                return "What area are you looking for advice?";
            case 2:
                return "How are you feeling today?";
            case 3:
                return "What's on your mind? What would you like to speak to an advisor about?";
            case 4:
                return "Are you looking for a psychic advisor with a specific set of skills?";
            case 5:
                return "How do you like to hear your advice?";
            default:
                return "";
        }
    }

    async function saveUserCategory() {
        console.log(props);
        let categoryIdSelected = areaSelected;
       
        if (categories !== null && categories !== undefined && categories.length > 0 && categories.length > categoryIdSelected) {
            let categoryTitle = categories !== null && categories !== undefined && categories.length > 0 ? categories[categoryIdSelected].title : "";
            
            //debugger;

            axios
                .get(myAppConfig.FILTER_BY_CATEGORY,
                {
                    params: {
                        "category": categoryTitle,
                        "session_method": 0,
                        "sort_by": 0
                    }
                })

            .then(response => {
               // debugger;
                if (response.data.status === 'success') {
                    console.log(response.data);
                        window.location.href = "/wizard-results/" + categoryTitle
                } else {
                    alert("No advisors found");
                }
            }
            ).catch(err => check403Error(err));
                //debugger;
        }else{
            alert("Data missing");
        }
    }

    return (

        <div className="container-fluid" style={{marginTop: '7%'}}>

            {showFirst ?
                <div>
                    <FirstStep
                        userIsLoggedIn={userIsLoggedIn}
                        userName={userName}
                        onClick={() => handleFindExpert()}
                    />
                </div>
                :
                <div>
                    <LastStep
                        onClick={() => handleViewAdvisors()} />
                </div>
            }

            <Modal
                dialogClassName={"modal-fullscreen "}
                show={showStepsModal}
                onHide={handleCloseStepsModal}
                className="wizzard-modal"
                backdrop='static'
                keyboard={false}
                >

                <Modal.Header className="px-0">
                    <Col className={styles.containerTopWizardStep}>
                        <Col className={styles.containerTop}>
                            <Col className={"col-xs-12 col-md-4 col-lg-4"} >
                                <label className={styles.labelTitleSmallWizard}>Find my expert.<span className={styles.spanTitle}>Step {stepModalIndex}</span> out of 5 </label>
                            </Col>
                            <Col className={"col-md-4 col-lg-4 " + styles.containerImageSteps} >
                                <img src={logo} alt="logo" className={styles.imgLogoWizardSteps}  onClick={() => window.location.pathname = '/'}/>
                            </Col>
                            <Col className={"col-md-4 col-lg-4 " + styles.containerEmpty} />
                        </Col>
                        <ProgressBar
                            className={styles.modalHeaderProgressBar}
                            min={MIN_STEP}
                            max={MAX_STEP}
                            now={stepModalIndex}
                            variant={"YOU_PICK_A_NAME"}
                        />

                    </Col>
                </Modal.Header>

                <Modal.Body >
                    <p className={styles.modalStepsSubtitle}>{getTitleByModalStep()}</p>
                    {(() => {
                        let value = "";
                        switch (stepModalIndex) {
                            case 1:
                                value = <div
                                    className={styles.modalCategoriesContainer}>
                                    <Row xs={2} md={4} >
                                        {
                                            categories !== null ? categories.map((category, idx) => (

                                                <CustomCategoryCard
                                                    id={idx}
                                                    key={idx}
                                                    onClick={() => handleSelectArea(idx)}
                                                    background_image={category.background_image}
                                                    title={category.title}
                                                    icon={category.icon}
                                                    areaSelected={areaSelected}
                                                />


                                            )) : ""}
                                    </Row>
                                </div>
                                break;
                            case 2:
                                value = <div className={styles.modalFeelingsContainer}>
                                    <Row xs={3} md={3} >
                                        {
                                            feelingArray !== null ? feelingArray.map((item, idx) => (

                                                <CustomFeelingCard
                                                    key={idx}
                                                    id={idx}
                                                    onClick={() => handleSelectFeeling(idx)}
                                                    feelingSelected={feelingSelected}
                                                    title={item.title}
                                                    icon={item.icon}
                                                />

                                            )) : ""}
                                    </Row>
                                </div>
                                break;

                            case 3:
                                value = <div className={styles.modalMindContainer}>
                                    <Row xs={1} md={1} lg={1} >
                                        {
                                            mindArray !== null ? mindArray.map((item, idx) => (

                                                <ButtonCard
                                                    key = {idx}
                                                    id={idx}
                                                    onClick={() => handleSelectMind(idx)}
                                                    title={item.title}
                                                    itemSelected={mindSelected}
                                                />

                                            )) : ""}
                                    </Row>
                                </div>;
                                break;

                            case 4:
                                value = <div className={styles.modalSkilsContainer}>
                                    <Row xs={1} md={1} lg={1} >
                                        {
                                            skillsArray !== null ? skillsArray.map((item, idx) => (

                                                <ButtonCard
                                                    key={idx}
                                                    id={idx}
                                                    onClick={() => handleSelectSkill(idx)}
                                                    title={item.title}
                                                    itemSelected={skillSelected}
                                                />

                                            )) : ""}
                                    </Row>
                                </div>;
                                break;
                            case 5:
                                value = <div className={styles.modalAdviceModeContainer}>
                                    <Row xs={1} md={1} lg={1} >
                                        {
                                            adviceModeArray !== null ? adviceModeArray.map((item, idx) => (

                                                <ButtonCard
                                                    key={idx}
                                                    id={idx}
                                                    onClick={() => handleSelectAdviceMode(idx)}
                                                    title={item.title}
                                                    itemSelected={adviceModeSelected}
                                                />

                                            )) : ""}
                                    </Row>
                                </div>;
                                break;
                            default:
                                value = <div></div>
                                break;
                        }

                        return value;
                    })()}

                </Modal.Body>

                <Modal.Footer className={styles.footerFullWidth}>
                    <Row className={styles.footerStepsModal + " " + styles.footerWeb}>
                        <Button
                            variant="primary"
                            className={styles.footerBackBtn}
                            onClick={handleBack}
                        >
                            {BACK}</Button>
                        <Button
                            variant="primary"
                            className={styles.footerContinueBtn}
                            onClick={handleContinue}
                            disabled={continueBtnDisabled}>
                            {CONTINUE}
                        </Button>
                    </Row>
                    <Row className={styles.footerStepsModal + " " + styles.footerMobile}>
                        <div className="col-2 col-sm-2 col-md-2 col-lg-2">
                            <Button
                                style={{ backgroundImage: "url(" + iconBack + ")", backgroundRepeat: "no-repeat" }}
                                variant="primary"
                                className={styles.footerBackBtn}
                                onClick={handleBack}
                            >
                            </Button>
                        </div>
                        <div className="col-10 col-sm-10 col-md-10 col-lg-10">
                            <Button
                                variant="primary"
                                className={styles.footerContinueBtnMobile}
                                onClick={handleContinue}
                                disabled={continueBtnDisabled}>
                                {CONTINUE}
                            </Button>
                        </div>
                    </Row>
                </Modal.Footer>
            </Modal>
        
        </div>
    )
}
