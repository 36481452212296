import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown, Row, Col, Button } from 'react-bootstrap';
import '../../styles/_layouts.scss';
import '../../styles/mobileNavbar.scss'
import logo from '../../assets/icons/logo.png';
import { isMobileOnly } from 'react-device-detect'
import * as myAppConfig from '../../constants/AppConstants'

import PropTypes from 'prop-types';
import { imagesApiURL } from '../../constants/AppConstants';
import { Link } from 'react-router-dom';
import MobileNavbar from './MobileNavbar';
import AdvisorDropDown from './dropDowns/AdvisorDropDown';
import SuperAdminDropDown from './dropDowns/SuperAdminDropDown';
import ClientDropDown from './dropDowns/ClientDropDown';
import SimpleAdvisorDropDown from './dropDowns/SimpleAdvisorDropDown';
import SimpleClientDropDown from './dropDowns/SimpleClientDropDown';

function GeneralNavbar({ categories, setLoginModalShow, userToken, check403Error, userType, userEmail }) {

  const [show, setShow] = useState(false);
  const navDropdownTitle = (<span> Categories</span>);
  const forProduction = myAppConfig.VERSION_FOR_PRODUCTION;

  return (
    <Navbar expand="lg" className="navbar-dark" style={isMobileOnly ? styles.mobileNavbar : styles.webNavbar}>
      <div className='special-mobile general-nav' style={forProduction ? { justifyContent: 'flex-end' } : {}}>
        <Navbar.Brand href="/" style={isMobileOnly ? styles.mobileLogo : { marginRight: '5%' }}><img src={logo} alt="logo" /></Navbar.Brand>
        <Navbar.Toggle onClick={() => setShow(true)} style={isMobileOnly && !forProduction ? { display: 'block' } : { display: 'none' }} />
        {isMobileOnly ?
          (!forProduction ?
            <MobileNavbar categories={categories} show={show} setShow={setShow} navDropdownTitle={navDropdownTitle} logo={logo} />
            : null)
          :
          <Nav className={forProduction ? "me-auto my-2 my-lg-0 justify-content-end d-flex w-100" : "me-auto my-2 my-lg-0 justify-content-between d-flex w-100"}>
            {
              !forProduction ?
                <div className='d-flex'>
                  <NavDropdown title={navDropdownTitle} className='categories'>
                    <Row>
                      <Col lg={6}>
                        {categories.slice(0, 4).map((category, idx) => {
                          let urlToOpen = "/category/" + category.title;
                          return (
                            <NavDropdown.Item as={Link} to={urlToOpen} key={idx}><img src={imagesApiURL + category.icon} alt="category icon" /> {category.title}</NavDropdown.Item>
                          )
                        })}
                      </Col>
                      <Col lg={6}>
                        {categories.slice(4).map((category, idx) => {
                          let urlToOpen = "/category/" + category.title;
                          return (
                            <NavDropdown.Item as={Link} to={urlToOpen} key={idx}><img src={imagesApiURL + category.icon} alt="category icon" /> {category.title}</NavDropdown.Item>
                          )
                        })}
                      </Col>
                    </Row>
                  </NavDropdown>
                  <Nav.Link href="https://horoscope.advia.io/">Horoscope</Nav.Link>
                  <Nav.Link href="https://horoscope.advia.io/articles/">Articles</Nav.Link>
                </div> : null}
            {userToken == null ?
              <Button className="loginButton px-5" onClick={() => { setLoginModalShow(true) }}>Sign in </Button>
              : parseInt(userType) === myAppConfig.USER_TYPES.ADVISOR ?
                myAppConfig.VERSION_FOR_PRODUCTION ?
                  <SimpleAdvisorDropDown userEmail={userEmail} check403Error={check403Error} />
                  : <AdvisorDropDown userEmail={userEmail} check403Error={check403Error} />
                : parseInt(userType) === myAppConfig.USER_TYPES.SUPERADMIN ?
                  <SuperAdminDropDown userEmail={userEmail} check403Error={check403Error} />
                  : myAppConfig.VERSION_FOR_PRODUCTION ?
                    <SimpleClientDropDown check403Error={check403Error} />
                    : <ClientDropDown check403Error={check403Error} />
            }
          </Nav>
        }
        {
          isMobileOnly ?
            userToken == null ?
              <Button className="loginButton px-5" onClick={() => { setLoginModalShow(true) }}>Sign in </Button>
              : parseInt(userType) === myAppConfig.USER_TYPES.ADVISOR ?
                myAppConfig.VERSION_FOR_PRODUCTION ?
                  <SimpleAdvisorDropDown userEmail={userEmail} check403Error={check403Error} />
                  : <AdvisorDropDown userEmail={userEmail} check403Error={check403Error} />
                : parseInt(userType) === myAppConfig.USER_TYPES.SUPERADMIN ?
                  <SuperAdminDropDown userEmail={userEmail} check403Error={check403Error} />
                  : myAppConfig.VERSION_FOR_PRODUCTION ?
                    <SimpleClientDropDown check403Error={check403Error} />
                    : <ClientDropDown check403Error={check403Error} />
            : null
        }
      </div>

    </Navbar>



  );
}

export default GeneralNavbar;

GeneralNavbar.propTypes = {
  categories: PropTypes.array,
  setLoginModalShow: PropTypes.func,
  userToken: PropTypes.string,
  check403Error: PropTypes.func,
  userEmail: PropTypes.string
}

const styles = {
  mobileNavbar: {
    position: 'relative',
    background: '#162543',
    padding: '28px 19px',
    top: '0'
  },
  webNavbar: {
    background: 'transparent',
    position: 'absolute',
    width: '100%',
    top: '0'
  },
  mobileLogo: {
    position: 'absolute',
    right: '50%',
    marginRight: '0'
  }
}