
import { Modal, Form, Button } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { isMobileOnly } from "react-device-detect";

const EditBackground = (props) => {
    const {
        showEditInternship, setShowEditInternship,
        onInternshipEdited,
        tempSelectedSpecializations, setTempSelectedSpecializations,
        tempSelectedLanguages, setTempSelectedLanguages,
        tempSelectedSkills, setTempSelectedSkills,
        homePageDetails } = props

    return (
        <Modal
    show={showEditInternship}
    onHide={() => setShowEditInternship(false)}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className="editModal"
            dialogClassName={isMobileOnly ? "modal-fullscreen modal-backdrop" : ""}
            backdrop='static'
            keyboard={false}
    >
    <Modal.Header closeButton>
    </Modal.Header>
    <Modal.Body>
    <div className="title">Edit Internship</div>
    <Form className="px-lg-5 px-md-5 px-sm-3 px-3" onSubmit={onInternshipEdited}>

    <Form.Group controlId="formSpecialization">
        <Form.Label>Specializes In</Form.Label>
            <MultiSelect
            hasSelectAll = {false}
            className="custom_input"
            disableSearch = {true}
            value={tempSelectedSpecializations}
            options={[{label : 'Specialties', value: 'Specialties', disabled: true}].concat(
                homePageDetails.specializations.map((specialization, idx) => {
                var special =  {};
                special.label = specialization.title;
                special.value = specialization.id;
                return special;
                })
            )}
            onChange={setTempSelectedSpecializations}
            labelledBy="Specialization"
            overrideStrings = {{
                "selectSomeItems": "Choose from the list",
                "allItemsAreSelected": "All specializations selected."
            }}
             />
        </Form.Group>

        <Form.Group controlId="formSkills">
        <Form.Label>Skills & Methods</Form.Label>
            <MultiSelect
            hasSelectAll = {false}
            className="custom_input"
            disableSearch = {true}
            value={tempSelectedSkills}
            options={[{label : 'Skills and Methods', value: 'Skills and Methods', disabled: true}].concat(
                homePageDetails.skillsAndMethods.map((skill, idx) => {
                var sk =  {};
                sk.label = skill.title;
                sk.value = skill.id;
                return sk;
                })
            )}
            onChange={setTempSelectedSkills}
            labelledBy="Skill"
            overrideStrings = {{
                "selectSomeItems": "Choose from the list",
                "allItemsAreSelected": "All skills selected."
            }}
             />
        </Form.Group>

        <Form.Group controlId="formLanguages">
        <Form.Label>Languages</Form.Label>
            <MultiSelect
            hasSelectAll = {false}
            className="custom_input"
            disableSearch = {true}
            value={tempSelectedLanguages}
            options={[{label : 'Languages', value: 'Languages', disabled: true}].concat(
                homePageDetails.languages.map((language, idx) => {
                var lang =  {};
                lang.label = language.title;
                lang.value = language.id;
                return lang;
                })
            )}
            onChange={setTempSelectedLanguages}
            labelledBy="Language"
            overrideStrings = {{
                "selectSomeItems": "Choose from the list",
                "allItemsAreSelected": "All languages selected."
            }}
             />
        </Form.Group>

        <Button type="submit" className="saveButton mb-5">Save</Button>
    </Form>
        </Modal.Body>
    </Modal> 
    )
}
export default EditBackground