import { isMobileOnly } from 'react-device-detect'
import { imagesApiURL } from '../../constants/AppConstants'
import banner from '../../assets/img/bg/banner.png'
import { useEffect, useState } from 'react'
import axios from 'axios'
import * as myAppConfig from '../../constants/AppConstants'

const TypesOfAdvisors = (props) => {
    const { categories, setHomePageDetails, check403Error } = props;
    const [landscape, setLandscape] = useState(window.innerWidth > 550);
    const [dataLoading, setDataLoading] = useState(false);
    useEffect(() => {
        if (!dataLoading) {
            setDataLoading(true);
            if (categories.length === 0) {
                axios
                    .get(myAppConfig.GET_HOMEPAGE_DETAILS)
                    .then((response) => {
                        setDataLoading(false);
                        if (response.data.status === "success") {
                            const hpData = {
                                categories: response.data.categories,
                                specializations: response.data.specializations,
                                languages: response.data.languages,
                                skillsAndMethods: response.data.skillsAndMethods,
                            };
                            setHomePageDetails(hpData);
                            localStorage.setItem("homePageDetails", JSON.stringify(hpData))
                        }
                    })
                    .catch((err) => check403Error(err));
            }
        }
    }, [check403Error])

    useEffect(() => {
        const setScreenOrientation = () => {
            if (window.matchMedia("(orientation: portrait)").matches) {
                console.log('orientation: was portrait');
                setLandscape(true);
            }

            if (window.matchMedia("(orientation: landscape)").matches) {
                console.log('orientation: was landscape');
                setLandscape(false);
            }
        }

        window.addEventListener("orientationchange", setScreenOrientation);
        return () => {
            window.removeEventListener("orientationchange", setScreenOrientation)
        }
    })
    const webOrder = [
        'Love & Relationship', 'Psychic Readings', 'Career Advisor', 'Dream Analyzer', 'Tarot Readings', 'Fortune Telling', 'Personal Astrologer', 'Psychic Medium'
    ]
    const mobileOrder = [
        'Love & Relationship', 'Psychic Readings', 'Tarot Readings', 'Fortune Telling', 'Career Advisor', 'Dream Analyzer', 'Personal Astrologer', 'Psychic Medium'
    ]
    return (
        <div style={isMobileOnly ? { padding: '71px 12vw 39px', position: 'relative' } : { padding: '71px 17.36vw 104px', position: 'relative' }}>
            <img src={banner} style={styles.bg} alt='background' />
            <p style={styles.title}>Types of advisors</p>
            <div className='d-flex flex-wrap'>
                {categories.map((category, id) => {
                    return (
                        <div key={id} className='col-lg-3 col-md-3 col-sm-6 col-6 d-flex mb-5 pb-2' style={isMobileOnly ? { order: mobileOrder.indexOf(category.title), alignItems: 'center' } : { order: webOrder.indexOf(category.title), alignItems: 'center' }}>
                            <img src={imagesApiURL + category.icon} style={styles.itemImage(landscape)} alt='category icon' />
                            <div style={styles.itemText(landscape)}>{category.title === 'Dream Analyzer' ? 'Dream Analysis' : category.title}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default TypesOfAdvisors;

const styles = {
    bg: {
        position: "absolute",
        width: "100%",
        left: '0',
        top: "0",
        height: "100%",
        zIndex: "-1",
        objectFit: 'cover'
    },
    title: {
        fontSize: '32px',
        lineHeight: '34px',
        textAlign: 'center',
        color: 'white',
        marginBottom: '67px',
        fontWeight: '600'
    },
    itemImage: (landscape) => ({
        marginRight: '15px',
        width: '32px',
        height: '32px',
        marginLeft: (isMobileOnly && !landscape) ? '0' : '2.25vw'
    }),
    itemText: (landscape) => ({
        fontSize: '16px',
        lineHeight: '22px',
        color: 'white',
        marginRight: (isMobileOnly && !landscape) ? '0' : '2.25vw'
    })
}