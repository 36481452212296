import React, { useState, useEffect } from "react"
import { Row, Col, Form, Button, Container, Nav, Modal } from 'react-bootstrap';
import * as Icon from "react-feather";
import "react-datepicker/dist/react-datepicker.css";
import '../../../styles/profile.scss';
import axios from 'axios'
import { imagesApiURL } from "../../../constants/AppConstants";
import * as myAppConfig from "../../../constants/AppConstants";
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";

import shareIcon from '../../../assets/icons/share_icon.png';
import arrowRightIcon from '../../../assets/icons/arrows/arrow_right_icon.png';
import arrowLeftIcon from '../../../assets/icons/arrows/arrow_left_icon.png';
import askIcon from '../../../assets/icons/ask_icon.png';
import feedbackIcon from '../../../assets/icons/feedback_icon.png';
import bugIcon from '../../../assets/icons/bug_icon.png';
import { isIOS, isMobile, isMobileOnly } from "react-device-detect";

import ProfileUpdated from "../../../components/advisorProfile/ProfileUpdated";
import ShareModal from "../../../components/ShareModal";
import ProfileSettingsForm from "../../../components/clientProfile/ProfileSettingsForm";
import { MobileUserProfileTabsLayout } from "../../../layouts/MobileUserProfileTabsLayout";
import AccountSettingsForm from "../../../components/clientProfile/AccountSettingsForm";
import Help from "../../../components/clientProfile/Help";

import ImageCropper from "../../../components/ImageCropper";
import OnBoarding from '../../../components/clientProfile/OnBoarding'

import arrow from '../../../assets/icons/arrows/arrow_left_icon.png'
import ThankYouScreen from "../../../components/ThankYouScreen";
import moment from 'moment'
import user from '../../../assets/img/user.png'
import SessionHistoryForm from "../../../components/clientProfile/SessionHistoryForm";

import { aboutUs } from "../../../constants"
import parse from 'html-react-parser';
import { contentClientNoTitle } from '../../../constants/TermsAndConditionsData'
import { contentNoTitle } from '../../../constants/PrivacyPolicyData'
import UploadingModal from "../../../components/modals/UploadingModal";

import { logOutUser } from "../../../Utilities/Utils";
export default function UserProfile({ check403Error }) {

  const shareUrl = 'https://advia.justplus.eu/'

  const MALE = "Male";
  const FEMALE = "Female";
  const OTHER = "Other";

  const TYPE_QUESTION = 1;
  const TYPE_FEEDBACK = 2;
  const TYPE_PROBLEM = 3;

  const EMPTY_VALUE = "";

  const [checkbox, setCheckbox] = useState();
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [timeOfBirth, setTimeOfBirth] = useState(null);
  const [fname, setFname] = useState('');
  const [dataLoading, setDataLoading] = useState(false);
  const [lname, setLname] = useState('');
  const [image, setImage] = useState('');
  const [activeTab, setActiveTab] = useState(1);
  const [showEditPassword, setShowEditPassword] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showCustomerSupportModal, setShowCustomerSupportModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showProfileUpdatedMessage, setShowProfileUpdatedMessage] = useState(false);
  const [showAskModal, setShowAskModal] = useState(false);
  const [showBugModal, setShowBugModal] = useState(false);

  const [showFeedbackSend, setShowFeedbackSend] = useState(false);

  const [wrongConfirmPassword, setWrongConfirmPassword] = useState(false);
  const wrongConfirmPasswordMessage = 'New password and confirm password do not match'

  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();
  const [ask, setAsk] = useState('');
  const [feedback, setFeedback] = useState('');
  const [bug, setBug] = useState('');

  const [helpTabSelected, setHelpTabSelected] = useState(0);

  const [email, setEmail] = useState('');

  const [showMobileModal, setShowMobileModal] = useState(false);

  const [showCropper, setShowCropper] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const [sessionsHistory, setSessionsHistory] = useState([]);

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  const changeOldPassword = (e) => {
    setOldPassword(e.target.value);
  }

  const changeNewPassword = (e) => {
    setNewPassword(e.target.value);
  }

  const changeConfirmNewPassword = (e) => {
    setConfirmNewPassword(e.target.value)
  }

  const changeAsk = (e) => {
    setAsk(e.target.value);
  }

  const changeFeedback = (e) => {
    setFeedback(e.target.value);
  }

  const changeBug = (e) => {
    setBug(e.target.value);
  }

  useEffect(() => {
    const script = document.createElement('script');

    if(document.getElementById('wrapperCookies') !== null){
        script.src = "https://consent.cookiebot.com/c0728bbf-2551-4cda-a732-9a1e4cf0d70b/cd.js";
        script.async = true;
        document.getElementById('wrapperCookies').appendChild(script);
    }
   
    return () => {
      if(document.getElementById('wrapperCookies') !== null && document.getElementById('wrapperCookies').childElementCount > 0){
        document.getElementById('wrapperCookies').removeChild(script);
      }
   }
  }, [helpTabSelected]);

  useEffect(() => {
    if (!dataLoading) {
      setDataLoading(true);
      axios.get(myAppConfig.GET_MY_PROFILE, {
        headers: { 'Content-Type': 'multipart/form-data', 'token': localStorage.getItem('userToken') }
      }).then(response => {
        if (response.data.status === 'success' && response.data.profile_details.length !== 0) {
          let profileDetails = response.data.profile_details[0];
          let first_name = profileDetails.first_name;
          let last_name = profileDetails.last_name;
          let gender = profileDetails.gender;
          let date_of_birth = profileDetails.date_of_birth;
          let time_of_birth = profileDetails.time_of_birth;
          let date = date_of_birth ? new Date(date_of_birth) : null;
          let email = response.data.user[0].email;
          let image = profileDetails.logo_image;
          setFname(first_name);
          setLname(last_name);
          setCheckbox(setGenderValue(gender));
          setDateOfBirth(date);
          setImage(image);

          setEmail(email);

          if (date_of_birth && time_of_birth) {
            setTimeOfBirth(new Date(date_of_birth + " " + time_of_birth));
          }
          else {
            if (time_of_birth) {
              setTimeOfBirth(new Date(new Date(null).toLocaleDateString() + " " + time_of_birth));
            }
            else {
              setTimeOfBirth(null);
            }
          }
          console.log(response.data);

        }
        else {
          console.log(response.data);
        }
      }).catch(err => check403Error(err));


      axios.get(myAppConfig.GET_SESSION_HISTORY, {
        headers: { 'Content-Type': 'multipart/form-data', 'token': localStorage.getItem('userToken') }
      }).then(response => {
        if (response.data.status === 'success') {
          setSessionsHistory(response.data.sessionsHistory);
        }
        else {
          console.log(response.data);
        }
      }).catch(err => check403Error(err));
    }
  }, [dataLoading, check403Error]);

  const fileChange = async e => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file)

      setImageSrc(imageDataUrl);
      if (!isIOS) {
      setShowCropper(true);
      }
      else {
        setImage({
          preview: URL.createObjectURL(file),
          raw: file
        });
        const formData = new FormData();
        formData.append("logo_image", file);
        await saveUserImage(formData)
      }
    }
  };

  async function changePassword(credentials) {
    axios.post(myAppConfig.CHANGE_PASSWORD, credentials, {
      headers: { 'Content-Type': 'multipart/form-data', 'token': localStorage.getItem('userToken') }
    })
      .then(response => {
        if (response.data.status === 'success') {
          logOutUser("/?logged_out=true", check403Error);
          console.log(response.data);
        }
        else {
          console.log(response.data);
        }
      }
      ).catch(err => check403Error(err));
  }

  const [loaded, setLoaded] = useState(null);
  async function saveUserImage(credentials) {
    setLoaded(null);
    axios.post(myAppConfig.UPDATE_PROFILE_CLIENT, credentials, {
      headers: { 'Content-Type': 'multipart/form-data', 'token': localStorage.getItem('userToken') },
      onUploadProgress: ProgressEvent => {
        setLoaded(Math.round(
          (ProgressEvent.loaded * 100) / ProgressEvent.total
        ))
      }
    })
      .then(response => {
        if (response.data.status === 'success') {
          console.log(response.data);
          if (response.data.logo_image) {
            localStorage.setItem('userLogoImg', response.data.logo_image)
          }
        }
        else {
          console.log(response.data);
        }
      }
      ).catch(err => check403Error(err));
  }

  async function saveProfileDetails(credentials) {
    axios.post(myAppConfig.UPDATE_PROFILE_CLIENT, credentials, {
      headers: { 'Content-Type': 'multipart/form-data', 'token': localStorage.getItem('userToken') }
    })
      .then(response => {
        if (response.data.status === 'success') {
          console.log(response.data);
          setShowProfileUpdatedMessage(true);
        }
        else {
          console.log(response.data);
        }
      }
      ).catch(err => check403Error(err));
  }

  function setGenderValue(gender) {
    if (gender === 0) {
      return OTHER;
    } else if (gender === 1) {
      return MALE;
    } else if (gender === 2) {
      return FEMALE;
    } else {
      return '';
    }
  }

  function firstName(ev) {
    setFname(ev.target.value);
  }

  function lastName(ev) {
    setLname(ev.target.value);
  }

  function handleChange(ev) {
    setCheckbox(ev.target.value);
  }

  const changeProfileSettings = async e => {
    e.preventDefault()
    var detailsFormData = new FormData();
    detailsFormData.append('first_name', fname);
    detailsFormData.append('last_name', lname);
    detailsFormData.append('gender', checkbox === 'Female' ? 2 : checkbox === 'Male' ? 1 : 0);
    if (dateOfBirth) {
      detailsFormData.append('date_of_birth', dateOfBirth.getDate() + "-" + parseInt(dateOfBirth.getMonth() + 1) + "-" + dateOfBirth.getFullYear());
    }
    else {
      detailsFormData.append('date_of_birth', '')
    }
    if (timeOfBirth && (typeof timeOfBirth.getHours !== 'function')) detailsFormData.append('time_of_birth', timeOfBirth);
    else {
      if (timeOfBirth) {
        detailsFormData.append('time_of_birth', moment(timeOfBirth).format('HH:mm'));
      }
      else {
        detailsFormData.append('time_of_birth', '');
      }
    }
    await saveProfileDetails(detailsFormData);
  }

  const changeUserPassword = async e => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      setWrongConfirmPassword(true);
      return
    }
    else setWrongConfirmPassword(false);
    var passwordFormData = new FormData();
    passwordFormData.append('old_password', oldPassword);
    passwordFormData.append('password', newPassword);
    passwordFormData.append('confirm_password', confirmNewPassword);
    await changePassword(passwordFormData);

  }

  const customerSupport = [
    {
      icon: askIcon,
      title: 'Ask a question'
    },
    {
      icon: feedbackIcon,
      title: 'Send feedback'
    },
    {
      icon: bugIcon,
      title: 'Report a problem'
    }
  ]

  const helpContent = [
    {
      title: 'About us',
      content: aboutUs.map((item, idx) => ('<div key=' + idx + '>' + (item.text ? item.text : item.description) + '</div>')).join('<br/>')
    },
    {
      title: 'Tutorials',
      content: 'Advia values your privacy.  This privacy policy explains what personal data we collect from you and how we use it.  This privacy policy applies if you visit or use Advia�s websites or mobile applications (collectively, the �Advisor Platform�).  This privacy policy also applies if you visit Advia�s social media or other web pages located on third-party websites'
    },
    {
      title: 'Customer Support',
      content: ''
    },
    {
      title: 'Privacy Policy',
      content: contentNoTitle
    },
    {
      title: 'Terms of service',
      content: contentClientNoTitle
    }
  ]

  const handleQuestion = (type, message) => {
    sendQuestion(type, message);
  }

  async function sendQuestion(type, message) {

    let url = myAppConfig.SEND_SUPPORT_MESSAGE_URL;
    let token = localStorage.getItem('userToken');

    var paramsData = new FormData();
    paramsData.append('type', type);
    paramsData.append('message', message);

    axios.post(url, paramsData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'token': token
      }
    }).then(response => {

      if (response.data.status === 'success') {
        console.log(response.data);
        switch (type) {
          case TYPE_QUESTION:
            setShowAskModal(false);
            setAsk(EMPTY_VALUE);
            break;
          case TYPE_PROBLEM:
            setShowBugModal(false);
            setBug(EMPTY_VALUE);
            break;
          case TYPE_FEEDBACK:
            setShowFeedbackModal(false);
            setFeedback(EMPTY_VALUE);
            break;
          default:
            break;
        }
        setShowFeedbackSend(true);
      }
      else {
        console.log(response.data);
      }
    }
    ).catch(err => check403Error(err));

  }

  const profileSettingsProps = {
    changeProfileSettings: changeProfileSettings,
    fname: fname, firstName: firstName,
    lname: lname, lastName: lastName,
    checkbox: checkbox,
    handleChange: handleChange,
    dateOfBirth: dateOfBirth, setDateOfBirth: setDateOfBirth,
    timeOfBirth: timeOfBirth, setTimeOfBirth: setTimeOfBirth,
    title: 'Profile Settings'
  }

  const [showFindMoreModal, setShowFindMoreModal] = useState(false);
  const [activeFindOutMore, setActiveFindOutMore] = useState(0);
  return (
    <>
      <OnBoarding activeFindOutMore={activeFindOutMore} setActiveFindOutMore={setActiveFindOutMore} showFindMoreModal={showFindMoreModal} setShowFindMoreModal={setShowFindMoreModal} />
      {!isIOS ?
      <ImageCropper show={showCropper} setShow={setShowCropper} imageSrc={imageSrc} setImage={setImage} saveUserDetails={saveUserImage} />
        : null}
      <ProfileUpdated
        showProfileUpdatedMessage={showProfileUpdatedMessage}
        setShowProfileUpdatedMessage={setShowProfileUpdatedMessage}
        title='Profile updated'
        description="You've successfully updated your profile." />
      <UploadingModal show={loaded && (loaded < 100)} />
      {showFeedbackSend ? (
        <ThankYouScreen
          data={myAppConfig.FINAL_THANK_YOU_MESSAGES.FEEDBACK}
          advisorName=''
        />
      ) : (
        <>
          <Modal
            show={showEditPassword}
            onHide={() => setShowEditPassword(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="editModal account"
            backdrop='static'
            keyboard={false}
          >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              <div className="title">Change password</div>
              <Form onSubmit={changeUserPassword}>
                <Form.Group controlId="formName">
                  <Form.Label>Current password</Form.Label>
                  <Form.Control name='old_password' type="password" className="custom_input" onChange={changeOldPassword} />
                  <Link to="/forgot-password" className="mt-3 d-block">Forgot password?</Link>
                </Form.Group>
                <Form.Group controlId="formName">
                  <Form.Label>Create new password</Form.Label>
                  <Form.Control name='new_password' type="password" className="custom_input" onChange={changeNewPassword} />
                </Form.Group>
                <Form.Group controlId="formName" className="mt-4">
                  <Form.Label>Confirm new password</Form.Label>
                  <Form.Control name='confirm_password' type="password" className="custom_input" onChange={changeConfirmNewPassword} required={true} isInvalid={wrongConfirmPassword} />
                  <Form.Control.Feedback type='invalid'> {wrongConfirmPasswordMessage} </Form.Control.Feedback>
                </Form.Group>

                <Button type="submit" className="saveButton">Save new password</Button>
              </Form>
            </Modal.Body>
          </Modal>
          <ShareModal showShareModal={showShareModal} setShowShareModal={setShowShareModal} shareUrl={shareUrl} />
          <Modal
            show={showCustomerSupportModal}
            onHide={() => setShowCustomerSupportModal(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="editModal account share"
            backdrop='static'
            keyboard={false}
          >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              <div className="title" >Customer Support</div>
              {customerSupport.map((site, idx) => {
                return (
                  <Button key={idx} onClick={() => idx === 0 ? setShowAskModal(true) : idx === 1 ? setShowFeedbackModal(true) : setShowBugModal(true)}><img src={site.icon} alt='custommer support' />{site.title}</Button>
                )
              })}
            </Modal.Body>
          </Modal>
          <Modal
            show={showAskModal}
            onHide={() => setShowAskModal(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={isMobileOnly ? "editModal account findMore profile help" : "editModal account"}
            dialogClassName={isMobileOnly ? "modal-fullscreen modal-backdrop mobileSearch" : ""}
            backdrop='static'
            keyboard={false}
          >
            {isMobileOnly ?
              <Modal.Header>
                <Button className="modal-cancel-button-custom"
                  onClick={() => setShowAskModal(false)}
                >
                  <img src={arrow} alt='arrow' />
                </Button>
              </Modal.Header>
              :
              <Modal.Header closeButton>
              </Modal.Header>
            }
            <Modal.Body>
              <div className="title mb-2">Ask a question</div>
              <div className='subtitle mb-4'>Send us a message</div>
              <Form onSubmit={(e) => { e.preventDefault(); }}>

                <Form.Group controlId="formBackground">
                  <Form.Control name="background_description" as="textarea" style={{ resize: 'none' }} rows={11} className="custom_input" onChange={changeAsk} maxLength={150} defaultValue={ask} />
                  <span className={ask.length === 150 ? 'over' : ''}>{ask.length + '/150'}</span>
                </Form.Group>

                <Button type="submit" className="saveButton" onClick={() => handleQuestion(TYPE_QUESTION, ask)}>Ask a question</Button>
              </Form>
            </Modal.Body>
          </Modal>
          <Modal
            show={showFeedbackModal}
            onHide={() => setShowFeedbackModal(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={isMobileOnly ? "editModal account findMore profile help" : "editModal account"}
            dialogClassName={isMobileOnly ? "modal-fullscreen modal-backdrop mobileSearch" : ""}
            backdrop='static'
            keyboard={false}
          >
            {isMobileOnly ?
              <Modal.Header>
                <Button className="modal-cancel-button-custom"
                  onClick={() => setShowFeedbackModal(false)}
                >
                  <img src={arrow} alt='arrow' />
                </Button>
              </Modal.Header>
              :
              <Modal.Header closeButton>
              </Modal.Header>
            }
            <Modal.Body>
              <div className="title mb-2">Share with us your feedback</div>
              <div className='subtitle mb-4'>Feel free to share with us what went well or we could be improve</div>
              <Form onSubmit={(e) => { e.preventDefault(); }}>
                <Form.Group controlId="formBackground">
                  <Form.Control name="background_description" as="textarea" style={{ resize: 'none' }} rows={11} className="custom_input" onChange={changeFeedback} maxLength={150} defaultValue={feedback} />
                  <span className={feedback.length === 150 ? 'over' : ''}>{feedback.length + '/150'}</span>
                </Form.Group>

                <Button type="submit" className="saveButton" onClick={() => handleQuestion(TYPE_FEEDBACK, feedback)}>Send feedback</Button>
              </Form>
            </Modal.Body>
          </Modal>
          <Modal
            show={showBugModal}
            onHide={() => setShowBugModal(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={isMobileOnly ? "editModal account findMore profile help" : "editModal account"}
            dialogClassName={isMobileOnly ? "modal-fullscreen modal-backdrop mobileSearch" : ""}
            backdrop='static'
            keyboard={false}
          >
            {isMobileOnly ?
              <Modal.Header>
                <Button className="modal-cancel-button-custom"
                  onClick={() => setShowBugModal(false)}
                >
                  <img src={arrow} alt='arrow' />
                </Button>
              </Modal.Header>
              :
              <Modal.Header closeButton>
              </Modal.Header>
            }
            <Modal.Body>
              <div className="title mb-2">Report a bug</div>
              <div className='subtitle mb-4'>What could we improve</div>
              <Form onSubmit={(e) => { e.preventDefault(); }}>
                <Form.Group controlId="formBackground">
                  <Form.Control name="background_description" as="textarea" style={{ resize: 'none' }} rows={11} className="custom_input" onChange={changeBug} maxLength={150} defaultValue={bug} />
                  <span className={bug.length === 150 ? 'over' : ''}>{bug.length + '/150'}</span>
                </Form.Group>

                <Button type="submit" className="saveButton" onClick={() => handleQuestion(TYPE_PROBLEM, bug)}>Report a bug</Button>
              </Form>
            </Modal.Body>
          </Modal>
          <div className="userProfile py-lg-5 py-md-5 py-sm-3 py-3" id="userProfilePage">
            <Container>
              <div className="form_side mt-lg-5">
                <Row className="m-0">
                  <Col lg={4} className="d-flex align-items-center flex-column">
                    <div className="leftSide w-100 d-flex align-items-center row">
                      <Col lg={4} md={2} sm={3} className="p-lg-4 p-3 pe-sm-4 pe-0 col-4">
                        <label htmlFor="upload-button" className="upldLabel">
                          <div className="uploadPhoto">
                            {!image ? <img src={user} alt="userImage" className="w-100 h-100" /> :
                              image.preview ?
                                <img src={image.preview} alt="userImage" className="w-100 h-100" /> :
                                <img src={imagesApiURL + image} alt="userImage" className="w-100 h-100" />
                            }
                          </div>
                        </label>
                        <input
                          type="file"
                          id="upload-button"
                          style={{ display: "none" }}
                          onChange={fileChange}
                        />
                      </Col>

                      <Col lg={8} md={10} sm={9} className="details col-8 ps-0">
                        <div className="name mb-2">{fname + ' ' + lname}</div>
                        <div className="email">{email}</div>
                        <div className="birth d-flex align-items-center">
                          {dateOfBirth || timeOfBirth ?
                            <Icon.Calendar width={15} className="me-2" />
                            : null}
                          {dateOfBirth ?
                            <div className="date me-2">{("0" + dateOfBirth.getDate()).slice(-2) + "." + ("0" + (dateOfBirth.getMonth() + 1)).slice(-2) + "." + dateOfBirth.getFullYear()}</div>
                            : null}
                          {timeOfBirth && (typeof timeOfBirth.getHours === 'function') && (typeof timeOfBirth.getHours() === 'number') && (typeof timeOfBirth.getMinutes === 'function') && (typeof timeOfBirth.getMinutes() === 'number') ?
                            <div className="hour">at {timeOfBirth.getHours() + ':' + timeOfBirth.getMinutes()}</div> :
                            timeOfBirth ?
                              <div className="hour">at {timeOfBirth}</div> :
                              null}
                        </div>
                      </Col>

                      <div className="toogleButtons pb-lg-3">
                        <Nav defaultActiveKey={"link-1"} className="flex-column pb-lg-3">
                          <Nav.Link as={Link} onClick={() => { setActiveTab(1); setShowMobileModal(true) }} to="#" eventKey="link-1" className="text-white border-custom-color">Profile settings {isMobile ? <img src={arrowRightIcon} alt='arrow' /> : null}</Nav.Link>
                          <Nav.Link as={Link} onClick={() => { setActiveTab(2); setShowMobileModal(true) }} to='#' eventKey="link-2" className="text-white border-custom-color">Account settings {isMobile ? <img src={arrowRightIcon} alt='arrow' /> : null}</Nav.Link>
                          <Nav.Link as={Link} onClick={() => { setActiveTab(3); setShowMobileModal(true) }} to="#" eventKey="link-3" className="text-white border-custom-color">Session history {isMobile ? <img src={arrowRightIcon} alt='arrow' /> : null}</Nav.Link>
                          <Nav.Link as={Link} onClick={() => { setActiveTab(4); setShowMobileModal(true) }} to="#" eventKey="link-4" className={isMobile ? "text-white border-custom-color" : "text-white border-custom-color border-bottom-custom-color"}>Help {isMobile ? <img src={arrowRightIcon} alt='arrow' /> : null}</Nav.Link>
                          {/* <Nav.Link eventKey="/" className=""></Nav.Link> */}
                        </Nav>
                      </div>
                    </div>
                    <br />
                    <div className="leftSide w-100 d-flex align-items-center row got-a-suggestion text-center p-lg-4 py-3 px-1">
                      <Col lg={9} className="details">
                        <div className="name mb-2">Got a suggestion?</div>
                        <div className="email p-lg-0 px-3">We're always keen to heart your ideas for how we could improve Advia</div>
                        <Button type="submit" className="saveButton my-3 col-12 col-md-6 col-lg-12" onClick={() => setShowFeedbackModal(true)}>Provide feedback</Button>
                      </Col>
                    </div>
                    <br />
                    <div className="leftSide w-100 d-flex align-items-center row got-a-suggestion text-center p-lg-4 p-3">
                      <Col lg={9} className="details">
                        <div className="share" onClick={() => setShowShareModal(true)}><img src={shareIcon} alt='share icon' />Share with friends</div>
                      </Col>
                    </div>
                  </Col>
                  <Col lg={8}>

                    {activeTab === 1 ?
                      !isMobile ?
                        <div className="rightSide w-100">
                          <ProfileSettingsForm {...profileSettingsProps} />
                        </div>
                        :
                        <MobileUserProfileTabsLayout show={showMobileModal} setShow={setShowMobileModal}>
                          <ProfileSettingsForm {...profileSettingsProps} />
                        </MobileUserProfileTabsLayout>
                      : activeTab === 2 ?
                        !isMobile ?
                          <div className="rightSide w-100 pb-3">
                            <AccountSettingsForm setShowEditPassword={setShowEditPassword} />
                          </div>
                          : <MobileUserProfileTabsLayout show={showMobileModal} setShow={setShowMobileModal}>
                            <AccountSettingsForm setShowEditPassword={setShowEditPassword} title='Account Settings' />
                          </MobileUserProfileTabsLayout>
                      : activeTab === 3 ? 
                        !isMobile ? 
                        <div className="rightSide w-100 pb-3"> 
                          <SessionHistoryForm sessionsHistory = {sessionsHistory}/>
                        </div> 
                          : <MobileUserProfileTabsLayout show={showMobileModal} setShow={setShowMobileModal}>
                              <SessionHistoryForm title="Session history" sessionsHistory = {sessionsHistory}/>
                            </MobileUserProfileTabsLayout>
                      : !isMobile ?
                          helpTabSelected === 0 ?
                            <div className="rightSide w-100 pb-3">
                                <Help setShowFindMoreModal={setShowFindMoreModal} setHelpTabSelected={setHelpTabSelected} setShowCustomerSupportModal={setShowCustomerSupportModal} helpTabSelected={helpTabSelected} />
                            </div>
                            :
                            <div className="rightSide w-100 pb-3">
                              <div className='account pb-3 help' >
                                <div onClick={() => setHelpTabSelected(0)}>
                                  <img src={arrowLeftIcon} alt='arrow left' />
                                </div>
                                <h4 className="custom">{helpContent[helpTabSelected - 1].title}</h4>
                                  <div className='label'>{parse(helpContent[helpTabSelected - 1].content)}</div>
                              </div>
                            </div>
                          :
                          <MobileUserProfileTabsLayout show={showMobileModal} setShow={setShowMobileModal} helpTabSelected={helpTabSelected} setHelpTabSelected={setHelpTabSelected}>
                              <Help setShowFindMoreModal={setShowFindMoreModal} setHelpTabSelected={setHelpTabSelected} setShowCustomerSupportModal={setShowCustomerSupportModal} title='Help' helpTabSelected={helpTabSelected} helpContent={helpContent} />
                          </MobileUserProfileTabsLayout>
                    }
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </>
      )}
    </>
  )
}

UserProfile.propTypes = {
  check403Error: PropTypes.func
}
