import React from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "../../styles/accountManageActionModal.scss";

const AccountManageActionModal = (props) => {
  return props.actionType !== "" ? (
    <Modal
      show={props.show}
      onHide={() => props.setShowAccountActionModal()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="account-manage-action-modal"
      backdrop='static'
      keyboard={false}
    >
      {props.hideClose ? null :
        <Modal.Header closeButton></Modal.Header>}
      <Modal.Body>
        <div className="title">{props.actionType.title}</div>
        <Form className="px-5" onSubmit={(e)=>{e.preventDefault(); }}>
          <div className="subtitle">{props.actionType.subtitle}</div>
          <Button
            type="submit"
            className={
              props.actionType.btnActiveState
                ? "button btnGreen"
                : "button btnRed"
            }
            onClick={(event) => props.onAccountAction(event)}
          >
            {props.btnText ? props.btnText : "Yes"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  ) : null;
};

export default AccountManageActionModal;
