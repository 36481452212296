import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import '../../styles/_layouts.scss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import logo from '../../assets/icons/sidebar-logo.png';
import SuperAdminDropDown from './dropDowns/SuperAdminDropDown';

function SuperAdminNavbar2({ userEmail, check403Error }) {

  return (
    <>
      <Navbar expand="lg" className="navbar-dark superAdmin">
        <Navbar.Brand as={Link} to="/" className='me-0' ><img src={logo} alt="logo" /></Navbar.Brand>
        <Navbar.Text>Advia Administrator</Navbar.Text>
        <Nav>
          <div style={{ display: 'table' }}>
            <SuperAdminDropDown userEmail={userEmail} check403Error={check403Error} />
          </div>
        </Nav>
      </Navbar>
    </>

  );
}

export default SuperAdminNavbar2;

SuperAdminNavbar2.propTypes = {
  userEmail: PropTypes.string,
  check403Error: PropTypes.func
}