import { Card } from 'react-bootstrap'

import editProfileImage from '../../assets/icons/edit_profile_icon.png';

const Specializations = (props) => {

    const {
        selectedSpecializations, selectedSkills, selectedLanguages,
        setShowEditInternship,
        setTempSelectedLanguages, setTempSelectedSkills, setTempSelectedSpecializations,
        inline
    } = props


    return (
        <Card className={inline ? "card-custom custom-padding custom-specialize mt-0" : "card-custom custom-padding custom-specialize"}>
            <p className="custom-text-style-24-34">Specializes In</p>
            {window.location.pathname !== '/' || selectedSpecializations.length > 0 ?
                selectedSpecializations.length > 0 ?
                    selectedSpecializations.map((specialization, idx) => {
                        return (
                            <p className="specializes-in" key={idx}>{specialization.label}</p>
                        )
                    })
                    :
                    null
                :
                <p className="custom-text-style-16-22" style={{ color: '#C1D1F0' }}>Add specialties</p>
            }
            <div className="space" />
            <p className="custom-text-style-24-34">Skills & Methods</p>
            {window.location.pathname !== '/' || selectedSkills.length > 0 ?
                selectedSkills.length > 0 ?
                    selectedSkills.map((skill, idx) => {
                        return (
                            <p className="specializes-in" key={idx}>{skill.label}</p>
                        )
                    })
                    :
                    null
                :
                <p className="custom-text-style-16-22" style={{ color: '#C1D1F0' }}>Add skills & methods</p>
            }
            <div className="space" />
            <p className="custom-text-style-24-34">Languages</p>
            {window.location.pathname !== '/' || selectedLanguages.length > 0 ?
                selectedLanguages.length > 0 ?
                    selectedLanguages.map((language, idx) => {
                        return (
                            <p className="specializes-in" key={idx}>{language.label}</p>
                        )
                    })
                    :
                    null
                :
                <p className="custom-text-style-16-22" style={{ color: '#C1D1F0' }}>Add languages</p>
            }
            <div className='edit-profile' onClick={() => { setShowEditInternship(true); setTempSelectedLanguages(selectedLanguages); setTempSelectedSkills(selectedSkills); setTempSelectedSpecializations(selectedSpecializations) }}> <img src={editProfileImage} alt='' /> </div>

        </Card>
    )
}
export default Specializations