import { useState } from 'react'
import banner from '../../../assets/img/bg/faq_banner.png'
import bannerMobile from '../../../assets/img/bg/faq_banner_mobile.png'
import { Tab, Tabs, Accordion, Card } from 'react-bootstrap';
import clientIcon from '../../../assets/icons/faq_client_icon.png'
import advisorIcon from '../../../assets/icons/faq_advisor_icon.png'
import '../../../styles/faq.scss'
import arrow from '../../../assets/icons/arrows/dashboard_dropdown_arrow.png'

import { faqData } from '../../../constants';
import { isMobileOnly } from 'react-device-detect';

const FAQ = (props) => {
    const clientGeneralQuestions = faqData.clientGeneralQuestions;
    const clientPaymentQuestions = faqData.clientPaymentQuestions;
    const advisorGeneralQuestions = faqData.advisorGeneralQuestions;
    const advisorPaymentQuestions = faqData.advisorPaymentQuestions;

    const [open, setOpen] = useState(new Array(clientGeneralQuestions.length).fill(false))
    const [paymentOpen, setPaymentOpen] = useState(new Array(clientPaymentQuestions.length).fill(false))

    const [openA, setOpenA] = useState(new Array(advisorGeneralQuestions.length).fill(false))
    const [paymentOpenA, setPaymentOpenA] = useState(new Array(advisorPaymentQuestions.length).fill(false))
    const changeOpen = (id) => {
        const newOpen = open.map((item, idx) => {
            return (
                id === idx ? !item : false
            )
        })
        setOpen(newOpen)
    }

    const changePaymentOpen = (id) => {
        const newOpen = paymentOpen.map((item, idx) => {
            return (
                id === idx ? !item : false
            )
        })
        setPaymentOpen(newOpen)
    }
    const changeOpenA = (id) => {
        const newOpen = openA.map((item, idx) => {
            return (
                id === idx ? !item : false
            )
        })
        setOpenA(newOpen)
    }
    const changePaymentOpenA = (id) => {
        const newOpen = paymentOpenA.map((item, idx) => {
            return (
                id === idx ? !item : false
            )
        })
        setPaymentOpenA(newOpen)
    }

    return (
        <>
            <img src={isMobileOnly ? bannerMobile : banner} style={isMobileOnly ? styles.bannerMobile : styles.banner} alt='banner' />
            <div style={styles.title}>Advia Help Desk</div>
            <div style={styles.subTitle}>Find all the answers you need</div>
            <div className='d-flex' style={styles.tabs} id='faq'>
                <Tabs defaultActiveKey="client" id="faqTabs" >
                    <Tab eventKey="client" title={<span className='d-flex flex-column'><img src={clientIcon} alt='client icon' />Client</span>}>
                        <div className='general'>General</div>
                        <Accordion style={{ marginBottom: '45px' }}>
                            {clientGeneralQuestions.map((question, id) => {
                                return (
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey={id === 0 ? '0' : id} onClick={() => changeOpen(id)} style={open[id] ? { color: '#19aaa1' } : {}}>
                                            <img src={arrow} style={open[id] ? styles.arrowSelected : styles.arrow} alt='arrow' />
                                            {question.title}
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={id === 0 ? '0' : id}>
                                            <Card.Body className={question.answer.length === 0 ? 'p-0' : ''}>{question.answer}</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )
                            })}
                        </Accordion>
                        <div className='general'>Payments</div>
                        <Accordion>
                            {clientPaymentQuestions.map((question, id) => {
                                return (
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey={id === 0 ? '0' : id} onClick={() => changePaymentOpen(id)} style={paymentOpen[id] ? { color: '#19aaa1' } : {}}>
                                            <img src={arrow} style={paymentOpen[id] ? styles.arrowSelected : styles.arrow} alt='arrow' />
                                            {question.title}
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={id === 0 ? '0' : id}>
                                            <Card.Body className={question.answer.length === 0 ? 'p-0' : ''}>{question.answer}</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )
                            })}
                        </Accordion>

                    </Tab>
                    <Tab eventKey="advisor" title={<span className='d-flex flex-column'><img src={advisorIcon} alt='advisor icon' />Advisor</span>}>
                        <div className='general'>General</div>
                        <Accordion style={{ marginBottom: '45px' }}>
                            {advisorGeneralQuestions.map((question, id) => {
                                return (
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey={id === 0 ? '0' : id} onClick={() => changeOpenA(id)} style={openA[id] ? { color: '#19aaa1' } : {}}>
                                            <img src={arrow} style={openA[id] ? styles.arrowSelected : styles.arrow} alt='arrow' />
                                            {question.title}
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={id === 0 ? '0' : id}>
                                            <Card.Body className={question.answer.length === 0 ? 'p-0' : ''}>{question.answer}</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )
                            })}
                        </Accordion>
                        <div className='general'>Payments</div>
                        <Accordion>
                            {advisorPaymentQuestions.map((question, id) => {
                                return (
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey={id === 0 ? '0' : id} onClick={() => changePaymentOpenA(id)} style={paymentOpenA[id] ? { color: '#19aaa1' } : {}}>
                                            <img src={arrow} style={paymentOpenA[id] ? styles.arrowSelected : styles.arrow} alt='arrow' />
                                            {question.title}
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={id === 0 ? '0' : id}>
                                            <Card.Body className={question.answer.length === 0 ? 'p-0' : ''}>{question.answer}</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )
                            })}
                        </Accordion>
                    </Tab>
                </Tabs>
            </div>
        </>
    )
}

export default FAQ

const styles = {
    banner: {
        position: "absolute",
        top: 0,
        left: 0,
        width: '100%',
        objectFit: 'cover',
        zIndex: '-1'
    },
    bannerMobile: {
        width: '100%',
        objectFit: 'cover',
    },
    tabs: {
        width: '100%',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    title: {
        fontWeight: "600",
        fontSize: isMobileOnly ? "32px" : "48px",
        lineHeight: "56px",
        marginTop: isMobileOnly ? '10px' : '170px',
        textAlign: 'center',
        width: '100%',
        color: 'white',
        marginBottom: '12px'
    },
    subTitle: {
        fontWeight: "500",
        fontSize: isMobileOnly ? "20px" : "24px",
        lineHeight: "24px",
        textAlign: 'center',
        width: '100%',
        color: 'white',
        marginBottom: isMobileOnly ? '59px' : '100px'
    },
    arrow: {
        width: '9px',
        marginRight: '9px',
        marginLeft: '-18px'
    },
    arrowSelected: {
        filter: 'invert(50%) sepia(90%) saturate(276%) hue-rotate(127deg)',
        width: '9px',
        marginRight: '9px',
        marginLeft: '-18px'
    }
}