import React from 'react';
import Login from '../views/pages/Login';
import Footer from '../components/footer/Footer'
import ClientNavbar from '../components/navbar/ClientNavbar';

const ClientLayout = (props) =>{
    const {children, setLoginModalShow, userToken, setUserToken, setUserType, loginModalShow, categories, check403Error} = props;
    return (
  <>
    <ClientNavbar setLoginModalShow = {setLoginModalShow}  userToken = {userToken} categories = {categories} check403Error={check403Error}/>
    <Login setUserToken = {setUserToken} setLoginModalShow = {setLoginModalShow} setUserType = {setUserType} loginModalShow = {loginModalShow} check403Error={check403Error}/>
    {children}
    <Footer categories = {categories}/>
  </>
    )
}

export {ClientLayout}

