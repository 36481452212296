import React, { useEffect } from "react"
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import "../../styles/testimonial.scss";
import user from '../../assets/img/user.png';
import { isMobileOnly } from "react-device-detect";

const testimonialSlidesData = [
  {
    id: 0,
    userImage: user,
    txt: '"At the beginning, I was a bit skeptical but, after few minutes in the call I was very surprised by the advisor\'s insights.\n No doubt that I will continue meeting him."',
    name: "Stephie J."
  },
  {
    id: 1,
    userImage: user,
    txt: '"I had my first tarot session with Suzi and she was amazing! \n Very kind, patient, and thorough in explaining what is the meaning of every card. We continued having several calls and she opens my eyes to many things about my workplace."',
    name: "Keren B."
  },
  {
    id: 2,
    userImage: user,
    txt: '"I\'ve been to several different psychics before, but Terry the psychic reader was the first one to really get me. He knew all about my doubts in life and gave me guidance on how to deal with some personal issues I couldn\'t find the answers for."',
    name: "Kenny V."
  }
]

const Testimonial = (props) => {

  const width = window.innerWidth < 975;

  const [slideNo, setSlideNo] = React.useState(3);

  const [currentSlide, setCurrentSlide] = React.useState(0);

  useEffect(() => {
    if (width) setSlideNo(1);
  }, [width]);

  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    loop: true,
    slidesPerView: slideNo,
    spacing: 20,
    centered: true,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
  })




  const testimonial = testimonialSlidesData.map((item) =>
    <div className={`keen-slider__slide testimonial number-slide${item.id} ` + (currentSlide === item.id ? " active" : "")} key={item.id}>
      <div className="testimonial_container p-5">
        <div className="overlay"></div>
        <img className="d-block mx-auto" src={item.userImage} alt="user" />
        <div className="text text-center mt-4">{item.txt.split('\n').map(str => <>{str}<br /></>)}</div>
        <div className="name text-center mt-4">{item.name}</div>
      </div>
    </div>
  );

  return (
    <>
      <div className="center_title text-center my-5">What our <span>clients</span> are saying</div>
      <div className="navigation-wrapper testimonial_slider mb-5 pb-5" style={{ overflow: 'hidden' }}>
        <div ref={sliderRef} className="keen-slider" style={{ overflow: 'visible' }}>
          {testimonial}
        </div>
        {isMobileOnly ?
          (
            slider && (
              <div className="dots">
                {[...Array(slider.details().size).keys()].map((idx) => {
                  return (
                    <button
                      key={idx}
                      onClick={() => {
                        slider.moveToSlideRelative(idx)
                      }}
                      className={"dot" + (currentSlide === idx ? " active" : "")}
                    />
                  )
                })}
              </div>
            )
          )
          :
          (
            slider && (
              <>
                <ArrowLeft
                  onClick={(e) => e.stopPropagation() || slider.prev()}
                  disabled={currentSlide === 0}
                />
                <ArrowRight
                  onClick={(e) => e.stopPropagation() || slider.next()}
                  disabled={currentSlide === slider.details().size - 1}
                />
              </>
            ))}
      </div>

    </>
  )
}

function ArrowLeft(props) {
  const disabeld = props.disabled ? " arrow--disabled" : ""
  return (
    <svg
      onClick={props.onClick}
      className={"arrow arrow--left" + disabeld}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
    </svg>
  )
}

function ArrowRight(props) {
  const disabeld = props.disabled ? " arrow--disabled" : ""
  return (
    <svg
      onClick={props.onClick}
      className={"arrow arrow--right" + disabeld}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
    </svg>
  )
}

export default Testimonial;
