
import React from 'react'
import { Col } from 'react-bootstrap';
import styles from '../../../../styles/wizard.module.scss';

const FeelingCard = (props) => {
    const BACKGROUND_DEFAULT = "#233558";
    const BACKGROUND_SELECTED = "#1DA1F2";
 
    return (
        <Col key={props.id} className="mb-3">

            <div
                onClick={() => props.onClick()}
                className={styles.feeling_item}
                style={{ background: props.feelingSelected === props.id ? BACKGROUND_SELECTED : BACKGROUND_DEFAULT }}
            >
                <img src={props.icon} alt="feeling_icon" className={styles.icon_feeling} />
                <div className={styles.title_feeling}>{props.title}</div>
            </div>

        </Col>
    );
}

export default FeelingCard
