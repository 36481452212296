import React, { useState } from 'react';
import axios from 'axios';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import changed from '../../../assets/icons/changed_icon.png';
import '../../../styles/forgotPass.scss';
import * as myAppConfig from '../../../constants/AppConstants'
import PropTypes from 'prop-types'
import { isMobileOnly } from 'react-device-detect';
import pattern from '../../../assets/img/bg/forgot_password_pattern.png'
import validator from 'validator'
import * as Icon from 'react-feather'

const emailNotFoundMessage = 'User not found. Please check your email address';
const wrongPasswordMessage = "Passwords don't match";

function ForgotPassword({ check403Error }) {

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [step1, setStep1] = useState(true);
    const [step2, setStep2] = useState(false);

    const [step4, setStep4] = useState(false);

    const [emailNotFound, setEmailNotFound] = useState(false);
    const [wrongPassword, setWrongPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState();
    const [showPass, setShowPass] = useState(false);
    const [showPass2, setShowPass2] = useState(false)

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const tkn = params.get('token');

    const [invalidPassword, setInvalidPassword] = useState(false);
    const invalidPasswordMessage = 'The password has to be a minimum of 8 characters long and to include both uppercase and lowercase characters and at least one digit. '

    const handleForgotPassword = async e => {
        e.preventDefault();
        var forgotPassFormData = new FormData();
        forgotPassFormData.append('email', email);
        await forgotPassword(forgotPassFormData);
        setStep1(false);

    }


    const handleChangePassword = async e => {
        e.preventDefault();
        if (!validatePassword(password)) {
            setInvalidPassword(true);
            return;
        }
        var changePassFormData = new FormData();
        changePassFormData.append('password', password);
        changePassFormData.append('confirm_password', confirmPassword);
        await changePassword(changePassFormData);
        localStorage.clear();
        sessionStorage.clear();
    }


    async function forgotPassword(credentials) {
        axios
            .post(myAppConfig.SEND_FORGOT_PASS_EMAIL, credentials, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            })
            .then(response => {
                if (response.data.status === 'success') {
                    console.log(response.data);
                    setStep1(false);
                    setStep2(true);
                }
                else {
                    console.log(response.data);
                    setEmailNotFound(true);
                }
            }
            ).catch(err => check403Error(err));
    }


    async function changePassword(credentials) {
        axios
            .post(myAppConfig.FORGOT_PASSWORD, credentials, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'token': tkn }
            })

            .then(response => {
                if (response.data.status === 'success') {
                    console.log(response.data);
                    setStep4(true);
                }
                else {
                    console.log(response.data);
                    setWrongPassword(true);
                }
            }
            ).catch(err => check403Error(err));
    }

    const validatePassword = (password) => {
        if (validator.isStrongPassword(password, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 0
        })) {
            return true;
        }
        return false;
    }

    return (
        <>
            <Container>
                <Row>
                    {step1 && tkn == null ?
                        <Col lg={5} className="mx-auto">
                            <div className="forgot d-flex flex-column justify-content-center step-1">
                                <h1>Forgot password?</h1>
                                <p className="mt-3">Enter the email you signed up with and we will{isMobileOnly ? null : <br />} send you password reset instructions</p>

                                <Form className="px-lg-5 px-md-5 px-sm-3 px-3" onSubmit={handleForgotPassword}>
                                    <Form.Group className="mb-4 mt-4" controlId="formBasicEmail1">
                                        <Form.Label>Enter email address</Form.Label>
                                        <Form.Control className="custom_input" type="email" onChange={e => setEmail(e.target.value)} required={true} isInvalid={emailNotFound} />
                                        <Form.Control.Feedback type='invalid'> {emailNotFoundMessage}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Button variant="primary" type="submit" className="signbutton my-2">Send</Button>
                                </Form>
                            </div>
                        </Col>
                        :
                        null
                    }
                    {step2 ?
                        <>
                            <img src={pattern} alt='pattern' className='pattern step-2' />
                            <Col lg={6} className="mx-auto">
                                <div className="forgot d-flex flex-column justify-content-center">
                                    <h1>Please check your email</h1>
                                    <p className="mt-3">We've sent you an email with password reset instructions. Should you not receive this email, please contact customer Care<br /> <a href="mailto:support@advia.io" target="_blank" rel="noreferrer">support@advia.io</a></p>
                                </div>
                            </Col>
                        </>
                        :
                        null
                    }
                    {tkn != null && step4 === false ?
                        <Col lg={5} className="mx-auto">
                            <div className="forgot d-flex flex-column justify-content-center step-1 step-3">
                                <h1>Forgot password?</h1>
                                <p className="mt-3">Enter the email you signed up with and we will send you a new password</p>

                                <Form className="px-lg-5 px-md-5 px-sm-3 px-3" onSubmit={handleChangePassword}>

                                    <Form.Group className="mb-3" controlId="formBasicPassword1" style={{ position: 'relative' }}>
                                        <Form.Label>Create new password</Form.Label>
                                        {!invalidPassword ?
                                            showPass ?
                                                <Icon.EyeOff className='eyeIcon' onClick={() => setShowPass(false)} /> :
                                                <Icon.Eye className='eyeIcon' onClick={() => setShowPass(true)} /> :
                                            null
                                        }
                                        <Form.Control className="custom_input" type={showPass ? "text" : "password"} onChange={e => { setPassword(e.target.value); if (invalidPassword) setInvalidPassword(false) }} required={true} isInvalid={invalidPassword} />
                                        <Form.Control.Feedback type='invalid'> {invalidPasswordMessage}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicPassword2" style={{ position: 'relative' }}>
                                        <Form.Label>Confirm new password</Form.Label>
                                        {!wrongPassword ?
                                            showPass2 ?
                                                <Icon.EyeOff className='eyeIcon' onClick={() => setShowPass2(false)} /> :
                                                <Icon.Eye className='eyeIcon' onClick={() => setShowPass2(true)} /> :
                                            null
                                        }
                                        <Form.Control className="custom_input" type={showPass2 ? "text" : "password"} onChange={e => { setConfirmPassword(e.target.value); if (wrongPassword) setWrongPassword(false) }} required={true} isInvalid={wrongPassword} />
                                        <Form.Control.Feedback type='invalid'> {wrongPasswordMessage}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Button variant="primary" type="submit" className="signbutton my-2">Confirm &amp; Continue</Button>
                                </Form>
                            </div>
                        </Col>
                        :
                        null
                    }
                    {step4 ?
                        <>
                            <img src={pattern} alt='pattern' className='pattern step-2' />
                            <Col lg={6} className="mx-auto">
                                <div className="forgot d-flex flex-column justify-content-center step-4">
                                    <img src={changed} alt="icon_green" className="my-4 mx-auto" />
                                    <h1>Your password has been changed</h1>
                                    <p className="mt-3">You can now go back to our sign in page to enter your new details</p>
                                    <Button className="goBack my-4" onClick={() => window.location.href = '/?logged_out=true'}>Go back to Sign In</Button>
                                </div>
                            </Col>
                        </>
                        :
                        null
                    }
                </Row>
            </Container>
        </>
    )
}

export default ForgotPassword

ForgotPassword.propTypes = {
    check403Error: PropTypes.func
}
