import React from "react"
import "keen-slider/keen-slider.min.css";
import { Button } from "react-bootstrap";

import { isMobileOnly } from 'react-device-detect'

const BackgroundText = (props) => {
    const { backgroundImg, mobileBackgroundImg, title, subtitle, description } = props

    return (
        <>
            <div className="navigation-wrapper">
                <div className="keen-slider">
                    <div className={`keen-slider__slide number-slide`} style={{ borderRadius: 0, background: '#04122e', display: 'contents' }}>
                        <div className="slideContent" style={{ height: 'fit-content' }}>
                            <div className="backroundImage" style={!isMobileOnly ? styles.bg : (title ? { position: 'relative' } : styles.bg)}>
                                <img src={isMobileOnly ? mobileBackgroundImg : backgroundImg} alt="ico" style={isMobileOnly ? (title ? { aspectRatio: '2.3', height: 'auto' } : { aspectRatio: 'unset', objectFit: 'cover', height: '100%', width: '100%' }) : { minHeight: '72%', objectFit: 'cover' }} />
                                <div style={styles.overlay} />
                            </div>

                            <div className="bottomItems d-flex flex-column align-items-center" style={isMobileOnly ? styles.bottomItemsMobile(title) : styles.bottomItems(title)}>
                                {title ?
                                    <>
                                        <div className="imageTitle" style={isMobileOnly ? styles.imageTitleMobile : styles.imageTitle}>{title}</div>
                                        <div style={isMobileOnly ? styles.imageSubTitleMobile : styles.imageSubTitle}>{subtitle}</div>
                                        <Button className="buttonGreen mr-5" style={isMobileOnly ? styles.greenBtnMobile(title) : styles.greenBtn(title)} onClick={() => window.location.href = '/category/'}>Find a psychic</Button>
                                        <div style={styles.imagedescription(title)}>{description}</div>
                                    </>
                                    :
                                    <>
                                        <div style={styles.imagedescription(title)}>{description}</div>
                                        <Button className="buttonGreen mr-5" style={isMobileOnly ? styles.greenBtnMobile(title) : styles.greenBtn(title)} onClick={() => window.location.href = '/category/'}>Find a psychic</Button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default BackgroundText

const styles = {
    bg: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%'
    },
    bottomItems: (title) => ({
        marginTop: !title ? "132px" : "170px",
        top: "0",
        position: "relative",
        left: "0"
    }),
    bottomItemsMobile: (title) => ({
        top: '0',
        position: "relative",
        marginLeft: '34px',
        left: '0',
        marginRight: '34px',
        marginTop: title ? '14px' : '72px',
        padding: '0',
        width: '-webkit-fill-available'
    }),
    imageTitle: {
        fontSize: '48px',
        lineHeight: "56px",
        textAlign: 'center',
        marginBottom: '12px',
        fontWeight: '600'
    },
    imageTitleMobile: {
        fontSize: "32px",
        lineHeight: "56px",
        textAlign: 'center',
        marginBottom: '2px',
        fontWeight: '600'
    },
    imageSubTitle: {
        fontSize: '24px',
        lineHeight: "24px",
        textAlign: 'center',
        marginBottom: '51px',
        fontWeight: "500"
    },
    imageSubTitleMobile: {
        fontSize: "20px",
        lineHeight: "24px",
        textAlign: 'center',
        marginBottom: '47px',
        fontWeight: "400"
    },
    greenBtnMobile: (title) => ({
        width: '-webkit-fill-available',
        marginLeft: "60px",
        marginRight: "60px",
        fontSize: "18px",
        lineHeight: "20px",
        padding: "15px 21px",
        marginBottom: title ? '50px' : '53px',
        fontWeight: '600'
    }),
    greenBtn: (title) => ({
        fontSize: "18px",
        lineHeight: "20px",
        padding: "15px 21px",
        marginBottom: !title ? "95px" : '104px',
        fontWeight: '600'
    }),
    imagedescription: (title) => ({
        fontSize: isMobileOnly ? (title ? '16px' : '14px') : '20px',
        lineHeight: isMobileOnly ? (title ? '24px' : '20px') : '34px',
        marginLeft: isMobileOnly ? '0' : (!title ? "10%" : "17.3%"),
        marginRight: isMobileOnly ? '0' : (!title ? "8%" : "17.3%"),
        textAlign: 'center',
        marginBottom: title ? '42px' : (isMobileOnly ? "60px" : "66px"),
        fontWeight: '600'
    }),
    overlay: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        background: 'rgba(0,0,0,0.2)'
    }

};
