import React from "react";
import * as Icon from "react-feather";
import "../../styles/freeMinutesTooltip.scss";

const FreeMinutesTooltip = (props) => {
  return (
    <div
      className={
        "free-minutes-tooltip " +
        (props.isCallOrVideo ? "free-minutes-tooltip-call-video" : "")
      }
      style={{ display: props.freeMinutesExpired ? "" : "none" }}
    >
      <p>{props.tooltipText} free minutes has ended!</p>
      <Icon.XCircle
        className="close-img"
        onClick={() => props.setFreeMinutesExpired()}
      />
    </div>
  );
};

export default FreeMinutesTooltip;
