import React from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "../../styles/accountManageActionModal.scss";

const PromotionsModal = (props) => {
  const { wantToSelect, show, setShow, saveUserDetails, setSaleOfferStatus, setFreeMinStatus } = props;

  const changeOffer = async () => {
    if (wantToSelect === 0) {
      setFreeMinStatus(1);
      setSaleOfferStatus(0);
      if (saveUserDetails) {
        const formData = new FormData();
        formData.append("saleoffer_status", 0);
        formData.append("freeminutes_status", 1)
        await saveUserDetails(formData);
      }
    }
    else {
      setFreeMinStatus(0);
      setSaleOfferStatus(1);
      if (saveUserDetails) {
        const formData = new FormData();
        formData.append("saleoffer_status", 1);
        formData.append("freeminutes_status", 0)
        await saveUserDetails(formData);
      }
    }
    setShow(false);
  }

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="account-manage-action-modal"
      backdrop='static'
      keyboard={false}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="title">Set the {wantToSelect === 0 ? 'Free Minutes' : 'Discount'} offer</div>
        <Form className="px-5" onSubmit={(e) => { e.preventDefault(); }}>
          <div className="subtitle">You cannot have 2 promotions available at the same time. Since the {wantToSelect === 1 ? 'Free Minutes' : 'Discount'} offer is already active, you can only set the {wantToSelect === 0 ? 'Free Minutes' : 'Discount'} offer if you choose to disable the other one.</div>
          <Button
            type="submit"
            className="button btnGreen mb-3"
            onClick={changeOffer}
          >
            Set the {wantToSelect === 0 ? 'Free Minutes' : 'Discount'} offer
          </Button>
          <Button
            type="submit"
            className="button btnRed mb-3"
            onClick={() => { setShow(false) }}
          >
            Keep the {wantToSelect === 1 ? 'Free Minutes' : 'Discount'} offer
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PromotionsModal;
