import React, { useState } from "react"
import { Row, Col, Form, Button } from 'react-bootstrap';
import TimePicker from "react-time-picker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../../../styles/profile.scss';
import noImage from '../../../assets/img/no_image.png';
import axios from 'axios'
import * as myAppConfig from '../../../constants/AppConstants'

import { isDesktop, isIOS, isAndroid } from "react-device-detect";
import PropTypes from 'prop-types'
import ImageCropper from "../../../components/ImageCropper";
import moment from 'moment'
import UploadingModal from "../../../components/modals/UploadingModal";
import OnBoarding from "../../../components/clientProfile/OnBoarding";

export default function CompleteProfile({ check403Error }) {

  const FIRST_NAME_REQUIRED = "Please provide your first name";
  const LAST_NAME_REQUIRED = "Please provide your last name";

  const [validated, setValidated] = useState(false);
  const [checkbox, setCheckbox] = useState();
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [timeOfBirth, setTimeOfBirth] = useState(null);
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [image, setImage] = useState(null);
  const [showFindMoreModal, setShowFindMoreModal] = useState(false);
  const [activeFindOutMore, setActiveFindOutMore] = useState(0);
  const [showCropper, setShowCropper] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const skipUrl = '/'

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  const fileChange = async e => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file)

      setImageSrc(imageDataUrl);
      if (!isIOS) {
        setShowCropper(true);
      }
      else {
        setImage({
          preview: URL.createObjectURL(file),
          raw: file
        });
      }
    }
  };

  const [loaded, setLoaded] = useState(null);

  async function saveUserDetails(credentials) {
    setLoaded(null);
    axios
      .post(myAppConfig.UPDATE_PROFILE_CLIENT, credentials, {
        headers: { 'Content-Type': 'multipart/form-data', 'token': localStorage.getItem('userToken') },
        onUploadProgress: ProgressEvent => {
          setLoaded(Math.round(
            (ProgressEvent.loaded * 100) / ProgressEvent.total
          ))
        }
      })
      .then(response => {
        if (response.data.status === 'success') {
          localStorage.setItem("userFullName", fname + ' ' + lname);
          localStorage.setItem("userName", fname);
          if (response.data.logo_image) {
            localStorage.setItem('userLogoImg', response.data.logo_image)
          }
          localStorage.setItem(myAppConfig.LOCAL_STORAGE_VALUES.SHOW_COMPLETE_PROFILE.Title, myAppConfig.LOCAL_STORAGE_VALUES.SHOW_COMPLETE_PROFILE.Values.NO);
          setShowFindMoreModal(true);
        }
        else {
          console.log(response.data);
        }
      }
      ).catch(err => check403Error(err));
    // }
    // )
  }


  function firstName(ev) {
    setFname(ev.target.value);
  }

  function lastName(ev) {
    setLname(ev.target.value);
  }

  function handleChange(ev) {
    setCheckbox(ev.target.value);
  }

  const onFormSubmit = async e => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() !== false) {
      var detailsFormData = new FormData();
      detailsFormData.append('first_name', fname);
      detailsFormData.append('last_name', lname);
      if (checkbox) detailsFormData.append('gender', checkbox === 'Female' ? 2 : checkbox === 'Male' ? 1 : 0);
      if (dateOfBirth) {
        detailsFormData.append('date_of_birth', dateOfBirth.getDate() + "-" + parseInt(dateOfBirth.getMonth() + 1) + "-" + dateOfBirth.getFullYear());
      }
      else {
        detailsFormData.append('date_of_birth', '')
      }
      if (timeOfBirth && (typeof timeOfBirth.getHours !== 'function')) detailsFormData.append('time_of_birth', timeOfBirth);
      else {
        if (timeOfBirth) {
          detailsFormData.append('time_of_birth', moment(timeOfBirth).format('HH:mm'));
        }
        else {
          detailsFormData.append('time_of_birth', '');
        }
      }
      if (image && image.raw) detailsFormData.append('logo_image', image.raw);
      detailsFormData.append('device_type', isDesktop ? 'DT' : isIOS ? 'iOS' : isAndroid ? 'Android' : 'Mobile Web');
      await saveUserDetails(detailsFormData);
    }

    setValidated(true);
  }

  return (
    <>{!isIOS ?
      <ImageCropper show={showCropper} setShow={setShowCropper} imageSrc={imageSrc} setImage={setImage} />
      : null}
      <OnBoarding activeFindOutMore={activeFindOutMore} setActiveFindOutMore={setActiveFindOutMore} skipUrl={skipUrl} showFindMoreModal={showFindMoreModal} setShowFindMoreModal={setShowFindMoreModal} />
      <UploadingModal show={loaded && (loaded < 100)} />
      <div className="userProfile">

        <div className="top_header">
          <h1 className="text-center">Complete the profile</h1>
          <h4 className="text-center mt-4">Provide us with a few details about yourself in order for us to give you more<br /> accurate recommendations</h4>
        </div>

        <div className="form_side mt-5">
          <Row className="m-0">
            <Col lg={4} className="d-flex align-items-lg-end align-items-center flex-column leftSide mb-4 mb-lg-0">
              <div>
                <label htmlFor="upload-button">
                  {image ? (
                    <>
                      <div className="uploadPhoto">
                        <img src={image.preview} alt="default" className="w-100" />
                      </div>
                      <div className="upload text-center mt-3">
                        Upload a photo<br />(optional)
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="uploadPhoto">
                        <img src={noImage} alt="default" />
                      </div>
                      <div className="upload text-center mt-3">
                        Upload a photo<br />(optional)
                      </div>
                    </>
                  )}
                </label>
                <input
                  type="file"
                  id="upload-button"
                  style={{ display: "none" }}
                  onChange={fileChange}
                />
              </div>

            </Col>
            <Col lg={8}>
              <Form onSubmit={onFormSubmit} noValidate validated={validated} >
                <Form.Group className="mb-3" controlId="formFirstName">

                  <Form.Label>Enter first name</Form.Label>
                  <Form.Control type="text" className="custom_input" value={fname} onChange={firstName} required pattern=".*\S+.*" title="This field cannot be empty" />
                  <Form.Control.Feedback type="invalid">
                    {FIRST_NAME_REQUIRED}
                  </Form.Control.Feedback>

                </Form.Group>
                <Form.Group className="mb-3" controlId="formLastName">
                  <Form.Label>Enter last name</Form.Label>
                  <Form.Control type="text" className="custom_input" value={lname} onChange={lastName} required pattern=".*\S+.*" title="This field cannot be empty" />
                  <Form.Control.Feedback type="invalid">
                    {LAST_NAME_REQUIRED}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3 gender_form" controlId="formLastName">
                  <Form.Label>Choose your gender</Form.Label>

                  {['radio'].map((type) => (
                    <div key={`inline-${type}`} className="mb-3 d-flex">
                      <Form.Check
                        inline
                        label="Male"
                        name="group1"
                        type={type}
                        value="Male"
                        id={`inline-${type}-1`}
                        className={"customGender male " + (checkbox === "Male" ? 'active' : '')}
                        onChange={handleChange}
                        checked={checkbox === "Male"}
                      />

                      <Form.Check
                        inline
                        label="Female"
                        name="group1"
                        value="Female"
                        type={type}
                        id={`inline-${type}-2`}
                        className={"customGender female " + (checkbox === "Female" ? 'active' : '')}
                        onChange={handleChange}
                        checked={checkbox === "Female"}
                      />

                      <Form.Check
                        inline
                        label="Other"
                        name="group1"
                        type={type}
                        value="Other"
                        id={`inline-${type}-3`}
                        className={"customGender other me-0 " + (checkbox === "Other" ? 'active' : '')}
                        onChange={handleChange}
                        checked={checkbox === "Other"}
                      />
                    </div>
                  ))}
                </Form.Group>

                <Row>
                  <Col lg={6} >
                    <Form.Group className="mb-3 gender_form" controlId="formBirthDate">
                      <Form.Label>Date of Birth (Optional)</Form.Label>
                      <DatePicker
                        selected={dateOfBirth}
                        onChange={(date) => setDateOfBirth(date)}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        className="dateSelect"
                        timeInputLabel="Time:"
                        dateFormat="yyyy-MM-dd"
                        maxDate={new Date()}
                      // showTimeInput
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={6} >
                    <Form.Group className="mb-3 gender_form" controlId="formBirthHour">
                      <Form.Label>Time of Birth (Optional)</Form.Label>
                      <TimePicker
                        value={timeOfBirth}
                        onChange={(date) => setTimeOfBirth(date)}
                        open={false}// peekNextMonth
                        disableClock={true}
                        closeClock={true}
                        className="hourSelect customTimeBorder"
                        timeInputLabel="Time:"
                        dateFormat="HH:mm:ss"
                        clearIcon={null}
                        showTimeInput
                      />
                    </Form.Group>
                  </Col>

                  <div className="msg">*By providing more details the easier it will be for the advisor to provide better answers. Your details will always remain confidential.</div>
                </Row>
                <Button type="submit" className="saveButton my-5">Save changes</Button>
              </Form>

            </Col>
          </Row>
        </div>
      </div>
    </>
  )


}

CompleteProfile.propTypes = {
  check403Error: PropTypes.func
}

