
import React from "react";
import { Link } from "react-router-dom";
import logo from '../../assets/icons/sidebar-logo.png';
import { Nav, Image, Navbar } from 'react-bootstrap'
import dashboard from '../../assets/icons/dashboard_icon.png'
import advisors from '../../assets/icons/advisors_icon.png'
import archive from '../../assets/icons/archive_icon.png'
import settings from '../../assets/icons/settings_icon.png'


export default function SuperAdminSidebar() {

    let checkUrl = window.location.pathname;

    return (
    <Navbar expand="lg" className="superAdminSidebar">
      <Navbar.Brand as={Link} to="/" className='logo mb-5' ><img src={logo} alt="logo" /></Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse id="navbarScroll">
        <Nav
            defaultActiveKey={ checkUrl === '/advisors' ? "link-2" : checkUrl === '/archive' ? "link-3" : checkUrl === '/clients' ? "link-4" : checkUrl === '/settings' ? "link-5" : "link-1" }
          >
          <Nav.Link as={Link} to="/" eventKey="link-1"><Image src={dashboard} className="admin_menu_images"/>Dashboard</Nav.Link>
            <Nav.Link as={Link} to="/advisors" eventKey="link-2" id="advisors-link"><Image src={advisors} className="admin_menu_images"/>Advisors</Nav.Link>
            <Nav.Link as={Link} to="/archive" eventKey="link-3"><Image src={archive} className="admin_menu_images"/>Archive</Nav.Link>
            <Nav.Link as={Link} to="/clients" eventKey="link-4"><Image src={advisors} className="admin_menu_images"/>Clients</Nav.Link>
            <Nav.Link as={Link} to="/settings" eventKey="link-5"><Image src={settings} className="admin_menu_images"/>Settings</Nav.Link>
          </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}
