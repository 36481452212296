import React from "react";
import "../../styles/chatMyReview.scss";
import ReactStars from "react-rating-stars-component";

const ChatMyReview = (props) => {
  const last_review =
    props.last_review && props.last_review.length > 0 ? props.last_review[0] : null;

  return last_review === null ? null : (
    <div className="main-my-review-container">
      <div className="my-review-container">
        <p className="header-title">My Review</p>

        <div className="rating-container">
          <ReactStars
            classNames="advisor-custom-react-stars"
            edit={false}
            size={20}
            value={Number(last_review.score.toFixed(1))}
            isHalf={true}
            color="#162543"
            activeColor="#19AAA1"
          />
          <p>{last_review.score.toFixed(1)}</p>
        </div>

        <p className="review-title">{last_review.review_title}</p>
        <p className="review-description">{last_review.review_description}</p>
      </div>
    </div>
  );
};

export default ChatMyReview;
