import React, { useState, useEffect } from "react";
import { Row, Col, Button, Container, Card } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ReactStars from "react-rating-stars-component";
import axios from "axios";
import * as Icon from "react-feather";
import "../../../styles/advisorProfile.scss";
import { Player, ControlBar, BigPlayButton } from "video-react";
import { imagesApiURL } from "../../../constants/AppConstants";
import * as myAppConfig from "../../../constants/AppConstants";
import icon_add_to_saved from "../../../assets/icons/icon_add_to_saved.png";
import icon_add_to_savedM from "../../../assets/icons/icon_add_to_saved_mobile.png";
import icon_share from "../../../assets/icons/share_icon.png";
import icon_shareM from "../../../assets/icons/share_icon_mobile.png";
import icon_ellipse from "../../../assets/icons/icon_ellipse.png";
import icon_changed from "../../../assets/icons/changed_icon.png";
import RatingAndReviewCell from "../../../components/RatingAndReviewCell";
import ShareModal from "../../../components/ShareModal";
import { isMobile, isMobileOnly } from "react-device-detect";
import starsBg from '../../../assets/img/bg/stars_bg.png'

import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import AddFirstCardModal from "../../../components/modals/AddFirstCardModal";

export default function AdvisorProfile({ check403Error, setLoginModalShow }) {
  const [dataLoading, setDataLoading] = useState(false);
  const [showNotifyMe, setShowNotifyMe] = useState(false);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [showMoreReviews, setShowMoreReviews] = useState(false);
  const [showMoreBgInfo, setShowMoreBgInfo] = useState(false);

  const [willBeNotified, setWillBeNotified] = useState(false);
  const [isAdvisorSaved, setAdvisorSaved] = useState(0);

  const [currentAdvisorProfileId, setCurrentAdvisorProfileId] = useState("");
  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [image, setImage] = useState("");
  const [category, setCategory] = useState("");
  const [describe, setDescribe] = useState();
  const [summarize, setSummarize] = useState("");
  const [status, setStatus] = useState(1); //0
  const [readingsNo, setReadingsNo] = useState(0);
  const [scoreAverage, setScoreAverage] = useState(null);
  const [showFreeMinutes, setShowFreeMinutes] = useState(0);
  const [freeMinutesNo, setFreeMinutesNo] = useState("");
  const [numberOfPrices, setNumberOfPrices] = useState(0);
  const [statusAccount, setStatusAccount] = useState(
    myAppConfig.ACCOUNT_STATUS.ACTIVE.id
  );

  const [chatPrice, setChatPrice] = useState("");
  const [callPrice, setCallPrice] = useState("");
  const [videoPrice, setVideoPrice] = useState("");

  const [backgroundDescription, setBackgroundDescription] = useState("");
  const [backgroundVideo, setBackgroundVideo] = useState(null);

  const [specializations, setSpecializations] = useState([]);
  const [skills, setSkills] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [ratingsAndReviews, setRatingsAndReviews] = useState([]);

  const [showShareModal, setShowShareModal] = useState(false);

  const columnsWidth = numberOfPrices !== 0 ? 12 / numberOfPrices : 0;

  let urlStrings = window.location.pathname.split("/advisor-profile/");
  const advisorFullName = urlStrings[urlStrings.length - 1].replace(new RegExp("%20", 'g'), " ");
  const userToken = localStorage.getItem("userToken");
  const shareUrl =
    "https://advia.io/advisor-profile/" + advisorFullName;
  let history = useHistory();

  const [linkToProceed, setLinkToProceed] = useState();
  useEffect(() => {
    setDataLoading(false);
  }, [advisorFullName]);
  const [clientCards, setClientCards] = useState();

  const userLogged = localStorage.getItem('userToken');
  const have3Mins = ((parseInt(localStorage.getItem('has3mins')) === 1) || !userLogged) ? 1 : 0;

  async function getClientCards(link) {
    axios.get(myAppConfig.GET_CARDS_LIST, {
      headers: { 'token': localStorage.getItem('userToken') }
    }).then(response => {
      if (response.data.status === 'success') {
        const list_of_cards = response.data.list_of_cards;
        if (list_of_cards.length === 0) {
          setShowAddCardModal(true);
        }
        else {
          setClientCards(response.data.list_of_cards);
          window.location.pathname = link;
        }
      }
    }).catch((err) => check403Error(err));
  }
  const checkHasCard = async (link) => {
    await getClientCards(link);
  }

  useEffect(() => {
    if (!dataLoading) {
      setDataLoading(true);
      axios
        .get(myAppConfig.GET_ADVISOR_PROFILE, {
          params: { full_name: advisorFullName },
          headers: {
            "Content-Type": "multipart/form-data",
            token: localStorage.getItem("userToken"),
          },
        })
        .then((response) => {
          //debugger;
          if (
            response.data.status === "success" &&
            response.data.profile_details.length !== 0
          ) {
            let profileDetails = response.data.profile_details[0];
            let advisorInfo = {};
            setCurrentAdvisorProfileId(profileDetails.id);
            advisorInfo["id"] = profileDetails.id;
            setFullName(
              profileDetails.full_name === ""
                ? profileDetails.first_name + " " + profileDetails.last_name
                : profileDetails.full_name
            );
            advisorInfo["name"] =
              profileDetails.full_name === ""
                ? profileDetails.first_name + " " + profileDetails.last_name
                : profileDetails.full_name;
            setFirstName(profileDetails.first_name);
            setImage(profileDetails.logo_image);
            advisorInfo["logo_image"] = profileDetails.logo_image;

            setBackgroundVideo(profileDetails.background_video);
            setDescribe(profileDetails.describe_your_services);
            setSummarize(profileDetails.summarize_your_qualifications);
            setReadingsNo(
              Number(response.data.profile_details.number_of_readings)
            );
            setScoreAverage(
              Number(response.data.profile_details.score_average.toFixed(1))
            );
            setWillBeNotified(parseInt(response.data.profile_details.is_notified) === 0 ? false : true);
            setAdvisorSaved(response.data.profile_details.is_saved);

            setStatusAccount(profileDetails.status_account);
            setStatus(profileDetails.status);
            if (
              Number(profileDetails.is_blocked) === 1 ||
              Number(profileDetails.status_account) === myAppConfig.ACCOUNT_STATUS.FREEZE.id ||
              Number(profileDetails.offline_online) === 0
            ) {
              setStatus(myAppConfig.ADVISOR_STATUS_STATES.away.id); //away
            }
            setBackgroundDescription(profileDetails.background_description);
            if (backgroundVideo !== null) {
              setShowMoreBgInfo(false);
            }

            setSpecializations(
              response.data.profile_details.profile_specializations
            );
            setSkills(response.data.profile_details.profile_skills);
            setLanguages(response.data.profile_details.profile_languages);
            setRatingsAndReviews(
              response.data.profile_details.rating_and_reviews
            );
            setNumberOfPrices(response.data.profile_details.profile_sessionprices.filter(price => price.price > 0).length);

            response.data.profile_details.profile_sessionprices.forEach(
              (session_method) => {
                if (session_method.session_method_type === 1) {
                  setCallPrice(session_method.price);
                } else if (session_method.session_method_type === 2) {
                  setChatPrice(session_method.price);
                } else if (session_method.session_method_type === 3) {
                  setVideoPrice(session_method.price);
                }
              }
            );

            if(have3Mins === 1){
              setShowFreeMinutes(1);
              setFreeMinutesNo(3);
            }else{
              setShowFreeMinutes(profileDetails.freeminutes_status);
              setFreeMinutesNo(profileDetails.freeminutes_value);
            }

            let category = "";
            if (
              response.data.profile_details.profile_categories[0] !== undefined
            ) {
              category =
                response.data.profile_details.profile_categories[0].title;
              setCategory(category);
              advisorInfo["category"] = category;
            }
            sessionStorage.setItem("otherTalker", JSON.stringify(advisorInfo));
          }
        })
        .catch((err) => check403Error(err));
    }
  }, [dataLoading, advisorFullName, backgroundVideo, check403Error]);

  const renderStatus = (statusValue) => {
    return statusValue === myAppConfig.ADVISOR_STATUS_STATES.busy.id ? (
      <div className="busy">
        {myAppConfig.ADVISOR_STATUS_STATES.busy.title} now
      </div>
    ) : statusValue === myAppConfig.ADVISOR_STATUS_STATES.available.id ? (
      <div className="available">
        {myAppConfig.ADVISOR_STATUS_STATES.available.title} now
      </div>
    ) : (
      <div className="away">{myAppConfig.ADVISOR_STATUS_STATES.away.title}</div>
    );
  };

  const closeNotifMePopup = () => {
    setShowNotifyMe(false);
  };

  const closeMessageBoxPopup = () => {
    setShowMessageBox(false);
  };

  const callRemoveNotifyMe = () => {
    if (localStorage.getItem("userToken") === null) {
      document.querySelector(".loginButton").click();
    } else {
      if (statusAccount !== myAppConfig.ACCOUNT_STATUS.FREEZE.id) {
        var notifyMeFormData = new FormData();
        notifyMeFormData.append("client_id", localStorage.getItem("profileId"));
        notifyMeFormData.append("advisor_id", currentAdvisorProfileId);

        axios
          .post(myAppConfig.REMOVE_NOTIFY_ME_URL, notifyMeFormData, {
            headers: {
              token: localStorage.getItem("userToken"),
            },
          })
          .then((response) => {
            if (response.data.status === "success") {
              setWillBeNotified(false);
            } else {
            }
          })
          .catch((err) => check403Error(err));
      }
    }
  };

  const callNotifyMe = () => {
    if (localStorage.getItem("userToken") === null) {
      document.querySelector(".loginButton").click();
    } else {
      if (statusAccount !== myAppConfig.ACCOUNT_STATUS.FREEZE.id) {
        var notifyMeFormData = new FormData();
        notifyMeFormData.append("client_id", localStorage.getItem("profileId"));
        notifyMeFormData.append("advisor_id", currentAdvisorProfileId);

        axios
          .post(myAppConfig.NOTIFY_ME_URL, notifyMeFormData, {
            headers: {
              token: localStorage.getItem("userToken"),
            },
          })
          .then((response) => {
            if (response.data.status === "success") {
              setWillBeNotified(true);
            } else {
            }
          })
          .catch((err) => check403Error(err));
      }
    }
  };

  const sendMessageToAdvisor = () => {
    setShowMessageBox(false);
  }

  const addAdvisorToSaved = () => {
    if (localStorage.getItem("userToken") === null) {
      document.querySelector(".loginButton").click();
    } else {
      if (statusAccount !== myAppConfig.ACCOUNT_STATUS.FREEZE.id) {
        var formData = new FormData();
        formData.append("client_id", localStorage.getItem("profileId"));
        formData.append("advisor_id", currentAdvisorProfileId);

        axios
          .post(
            isAdvisorSaved === 0
              ? myAppConfig.ADD_TO_SAVED_URL
              : myAppConfig.REMOVE_FROM_SAVED_URL,
            formData,
            {
              headers: {
                token: localStorage.getItem("userToken"),
              },
            }
          )
          .then((response) => {
            if (response.data.status === "success") {
              setAdvisorSaved(isAdvisorSaved === 0 ? 1 : 0);
            }
          })
          .catch((err) => check403Error(err));
      }
    }
  };

  const [showAddCardModal, setShowAddCardModal] = useState(false);
  return (
    <>
      <ShareModal
        showShareModal={showShareModal}
        setShowShareModal={setShowShareModal}
        shareUrl={shareUrl}
      />
      <AddFirstCardModal
        advisorName={fullName}
        advisorImage={image}
        show={showAddCardModal}
        setShow={setShowAddCardModal}
        onProceed={() => window.location.pathname = linkToProceed ? linkToProceed : ''}
      />
      <img src={starsBg} style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: 'auto', objectFit: 'cover', zIndex: '-1' }} />
      <div className="main-advisor-div my-5" id="adminProfilePage">
        <Container
          fluid
          style={isMobileOnly ? { width: "91.5%" } : { width: "88.88%" }}
          className="main-advisor-container p-0"
        >
          <Row>
            <Col lg={6} sm={6} className="col-top-info-container">
              <div className="top-info-container">
                <Col lg={6} sm={8}>
                  <div className="back" onClick={history.goBack} style={{ cursor: 'pointer' }}>
                    <Card.Img
                      src={imagesApiURL + "images/custom/back.png"}
                      className="back-image"
                    />
                    Back
                  </div>
                </Col>
              </div>
            </Col>
          </Row>
          <br /><br /><br />
          <Row className="top-details-row">
            <Col lg={4} className="advisor-profile-mobile">
              {image ?
              <Card.Img
                  src={imagesApiURL + image}
                className="advisor-profile-img"
              />
                : <div className="advisor-profile-img" />}
              <div className="advisor-profile-status advisor-profile-status-mobile">
                {renderStatus(status)}
              </div>
            </Col>
            <Col lg={8}>
              <Card className="advisor-custom-card">
                <Row className="advisor-personal-info">
                  <Col lg={8} className="personal-info-left-container">
                    <div className="advisor-personal-info-2">
                      <p className="advisor-name">{fullName}</p>
                      <div className="advisor-profile-status advisor-profile-status-web">
                        {renderStatus(status)}
                      </div>
                    </div>
                    <p className="advisor-category">{category}</p>
                    <p className="advisor-description advisor-description-mobile">
                      {describe}
                      <br />
                      {summarize}
                    </p>
                    {scoreAverage !== null ? (
                      <div className="stars d-flex align-items-center">
                        <ReactStars
                          edit={false}
                          size={14}
                          value={scoreAverage}
                          isHalf={true}
                          color="#233558"
                          activeColor="#19AAA1"
                        />
                        <div className="score ml-3">
                          {scoreAverage}&nbsp;&nbsp;&nbsp;
                          <img src={icon_ellipse} alt="score icon" />
                          &nbsp;&nbsp;&nbsp;Readings {readingsNo}
                        </div>
                      </div>
                    ) : null}
                  </Col>
                  <Col lg={4} className="personal-info-right-container">
                    <div
                      className={
                        "add-to-saved-container " +
                        (statusAccount === myAppConfig.ACCOUNT_STATUS.FREEZE.id
                          ? "disable-button-container"
                          : "")
                      }
                      onClick={() => addAdvisorToSaved()}
                    >
                      <img src={isMobile ? icon_add_to_savedM : icon_add_to_saved} alt="save icon" />
                      {isAdvisorSaved === 0 ? (
                        <p>Add to Saved</p>
                      ) : (
                        <p>Remove from Saved</p>
                      )}
                    </div>

                    <div
                      className={
                        "add-to-saved-container share-container " +
                        (statusAccount === myAppConfig.ACCOUNT_STATUS.FREEZE.id
                          ? "disable-button-container"
                          : "")
                      }
                      onClick={() => setShowShareModal(true)}
                    >
                      <img src={isMobile ? icon_shareM : icon_share} alt="share icon" />
                      <p>Share</p>
                    </div>
                  </Col>
                </Row>
                <Row className="advisor-description-card">
                  <Col lg={10}>
                    <p className="advisor-description">
                      {describe}
                      <br />
                      {summarize}
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row className='mb-5 pb-5'>
            <Col lg={4}>
              <Card style={{ display: (status === 1 && numberOfPrices > 0) ? "block" : "none" }}>
                <div className="advisor-session-container">
                  <p className="advisor-start-session">Start your Session</p>
                  {/*<p className="advisor-session-description">
                  Your current balance allow $50.00 of conversation
                </p> */}
                  <Row className="advisor-call-chat-container">
                    {callPrice !== "" && parseInt(callPrice) > 0 ? (
                      <Col
                        lg={columnsWidth}
                        sm={columnsWidth}
                        md={columnsWidth}
                        col={columnsWidth}
                        xs={columnsWidth}
                        className="advisor-col"
                      >
                        <div
                          onClick={userToken ? (() => { setLinkToProceed('/call'); checkHasCard('/call') })
                            : (() => setLoginModalShow(true))}
                          className='h-100'
                        >
                          <div className="advisor-call advisor-buttons" >
                            <Icon.Phone width={17} />
                            <p>Call</p>
                          </div>
                        </div>
                      </Col>
                    ) : null}
                    {chatPrice !== "" && parseInt(chatPrice) > 0 ? (
                      <Col
                        lg={columnsWidth}
                        sm={columnsWidth}
                        md={columnsWidth}
                        col={columnsWidth}
                        xs={columnsWidth}
                        className="advisor-col"
                      >
                        <div
                          onClick={userToken ? (() => { setLinkToProceed('/chat'); checkHasCard('/chat') })
                            : (() => setLoginModalShow(true))}
                          className='h-100'
                        >
                          <div className="advisor-chat advisor-buttons float-right-custom">
                            <Icon.MessageSquare width={17} />
                            <p>Chat</p>
                          </div>
                        </div>
                      </Col>
                    ) : null}
                    {videoPrice !== "" && parseInt(videoPrice) > 0 ? (
                      <Col
                        lg={columnsWidth}
                        sm={columnsWidth}
                        md={columnsWidth}
                        col={columnsWidth}
                        xs={columnsWidth}
                        className="advisor-col"
                      >
                        <div
                          onClick={userToken ? (() => { setLinkToProceed('/video-chat'); checkHasCard('/video-chat') })
                            : (() => setLoginModalShow(true))}
                          className='h-100'
                        >
                          <div className="advisor-call advisor-buttons float-right-custom ">
                            <Icon.Video width={17} />
                            <p>Video</p>
                          </div>
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                  <Row>
                    {callPrice !== "" && parseInt(callPrice) > 0 ? (
                      <Col
                        lg={columnsWidth}
                        sm={columnsWidth}
                        md={columnsWidth}
                        col={columnsWidth}
                        xs={columnsWidth}
                        className="advisor-price advisor-col"
                      >
                        <p>from&nbsp;</p>
                        <p className="price-call">${callPrice}/min</p>
                      </Col>
                    ) : null}
                    {chatPrice !== "" && parseInt(chatPrice) > 0 ? (
                      <Col
                        lg={columnsWidth}
                        sm={columnsWidth}
                        md={columnsWidth}
                        col={columnsWidth}
                        xs={columnsWidth}
                        className="advisor-price advisor-col"
                      >
                        <p>from&nbsp;</p>
                        <p className="price-chat">${chatPrice}/min</p>
                      </Col>
                    ) : null}
                    {videoPrice !== "" && parseInt(videoPrice) > 0 ? (
                      <Col
                        lg={columnsWidth}
                        sm={columnsWidth}
                        md={columnsWidth}
                        col={columnsWidth}
                        xs={columnsWidth}
                        className="advisor-price advisor-col"
                      >
                        <p>from&nbsp;</p>
                        <p className="price-call">${videoPrice}/min</p>
                      </Col>
                    ) : null}
                  </Row>
                </div>

                {showFreeMinutes === 1 ? (
                  <Row>
                    <div className="advisor-free-minutes">
                      <p>Free {freeMinutesNo} minutes</p>
                    </div>
                  </Row>
                ) : null}
              </Card>

              <Card
                style={{
                  display:
                    status === 1
                      ? "none"
                      : status !== 1 && !willBeNotified
                        ? "block"
                        : "none",
                }}
              >
                <div className="advisor-session-container advisor-container-top">
                  <p className="advisor-notifyme">
                    Notify me when the advisor is available
                  </p>
                  <Row className="advisor-call-chat-container empty">
                    {/*<Col lg={6} sm={6} md={6} col={6} className="advisor-col">*/}
                    <Col lg={12} sm={12} md={12} col={12} className="advisor-col">
                      <div
                        className={
                          "advisor-chat advisor-buttons " +
                          (statusAccount ===
                            myAppConfig.ACCOUNT_STATUS.FREEZE.id
                            ? "disable-button-container"
                            : "")
                        }
                        onClick={() => callNotifyMe()}
                      >
                        <p>Notify me</p>
                      </div>
                    </Col>
                    <Col lg={6} sm={6} md={6} col={6} className="advisor-col" style={{ display: "none" }}>
                      <div
                        className={
                          "advisor-call advisor-buttons " +
                          (statusAccount ===
                            myAppConfig.ACCOUNT_STATUS.FREEZE.id
                            ? "disable-button-container"
                            : "")
                        }
                        onClick={() => setShowMessageBox(true)}
                      >
                        <p>Send Message</p>
                      </div>
                    </Col>
                  </Row>
                  <br></br>
                </div>
              </Card>

              <Card style={{ display: (willBeNotified && status !== 1) ? "block" : "none" }}>
                <div className='stopNotify' style={{ position: 'absolute', right: '5px', top: '5px', cursor: 'pointer' }}
                  onClick={callRemoveNotifyMe}
                ><Icon.X width={32} height={32} color='#b5c4e1' /></div>
                <div className="advisor-session-container advisor-container-top will-be-notified">
                  <img
                    src={icon_changed}
                    className="img-changed"
                    alt="icon changed"
                  />
                  <p>You will be notified when the advisor is available</p>
                  <br></br>
                </div>
              </Card>

              {specializations.length === 0 &&
                skills.length === 0 &&
                languages.length === 0 ? (
                <div style={{ marginBottom: 24 }} />
              ) : (
                <Card className="advisor-info-card">
                  <div className="advisor-container-top">
                    {specializations.length > 0 ? (
                      <div className="advisor-option">
                        <p className="advisor-section-title">Specializes In</p>

                        <div className="advisor-cells-container">
                          {specializations.map((specialization, idx) => (
                            <div key={idx} className="advisor-custom-cell">
                              <p>{specialization.title}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : null}

                    {skills.length > 0 ? (
                      <div className="advisor-option">
                        <p className="advisor-section-title">
                          Skills & Methods
                        </p>

                        <div className="advisor-cells-container">
                          {skills.map((skill, idx) => (
                            <div className="advisor-custom-cell" key={idx}>
                              <p>{skill.title}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : null}

                    {languages.length > 0 ? (
                      <div className="advisor-option">
                        <p className="advisor-section-title">Languages</p>

                        <div className="advisor-cells-container">
                          {languages.map((language, idx) => (
                            <div className="advisor-custom-cell" key={idx}>
                              <p>{language.title}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Card>
              )}
            </Col>
            <Col lg={8}>
              {backgroundVideo === null ? null :
                <Card
                  className="advisor-card-margin"
                >
                  <div className="advisor-promotion-video-container">
                    <p className="advisor-section-title">Promotion video</p>
                    {backgroundDescription === null ? null : (
                      <p className="advisor-section-subtitle">
                        {backgroundDescription.split(".")[0]}.
                      </p>
                    )}
                    <div className="video-container">
                      <Player
                        playsInline
                        key={imagesApiURL + backgroundVideo}
                      >
                        <source
                          src={imagesApiURL + backgroundVideo}
                        />
                        <BigPlayButton position='center' />
                        <ControlBar autoHide={false}></ControlBar>
                      </Player>
                    </div>
                  </div>
                </Card>}

              <Card
                className="advisor-card-margin"
                style={{
                  display: backgroundDescription === null ? "none" : "block",
                }}
              >
                <div className="advisor-background-container">
                  <p className="advisor-section-title">Background</p>
                  <p className="advisor-section-subtitle">
                    {backgroundDescription}
                  </p>

                  {/* <p
                    className="read-more"
                    style={{
                      display:
                        !showMoreBgInfo && backgroundVideo !== null
                          ? "block"
                          : "none",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowMoreBgInfo(true)}
                  >
                    Read more
                  </p> */}
                </div>
              </Card>

              <Card
                className="advisor-card-margin"
                style={{
                  display: ratingsAndReviews.length > 0 ? "block" : "none",
                }}
              >
                <div className="advisor-background-container">
                  <p className="advisor-section-title">
                    Rating & Reviews({ratingsAndReviews.length})
                  </p>

                  <div>
                    {ratingsAndReviews.map((review, idx) =>
                      idx < 2 || showMoreReviews ? (
                        <RatingAndReviewCell key={idx} review={review} />
                      ) : null
                    )}

                    <div
                      className="show-more-reviews"
                      style={{
                        display:
                          showMoreReviews || ratingsAndReviews.length < 3
                            ? "none"
                            : "flex",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowMoreReviews(true)}
                    >
                      <p>Show more</p>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          dialogClassName="advisor-profile-modal"
          transparent
          show={showNotifyMe}
          onHide={() => { callRemoveNotifyMe(); setShowNotifyMe(false) }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body>
            <div className="modal-notifyme-body-custom">
              <img
                src={imagesApiURL + image}
                className="modal-user-image"
                alt="modal user logo"
              />
              <p className="modal-notifyme-title-custom">
                {firstName} is available
              </p>
              <p className="modal-notifyme-subtitle-custom">
                {" "}
                We wanted to notify you that your advsior is available. Would
                you like to start a session with him now?
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Row className="modal-notifyme-footer-btns">
              <Col lg={6}>
                <Button
                  className="btn-close-notifyme-modal-custom"
                  onClick={() => { callRemoveNotifyMe(); closeNotifMePopup() }}
                >
                  No
                </Button>
              </Col>
              <Col lg={6}>
                <Button className="btn-start-session-custom" onClick={() => { callRemoveNotifyMe(); }}>Yes</Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>

        <Modal
          dialogClassName="message-box-popup-height advisor-profile-modal"
          transparent
          show={showMessageBox}
          onHide={() => setShowMessageBox(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body>
            <div className="modal-messagebox-body-custom">
              <div className="modal-message-box-top-container">
                <p className="modal-notifyme-title-custom messagebox-title">
                  Write your message
                </p>
                <p
                  className="modal-messagebox-close"
                  onClick={() => closeMessageBoxPopup()}
                >
                  x
                </p>
              </div>
              <textarea className="message-box-inputgroup"> </textarea>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Row className="modal-messagebox-footer-btns">
              <Button
                className="btn-send-message-modal-custom"
                onClick={() => sendMessageToAdvisor()}
              >
                Send message
              </Button>
            </Row>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

AdvisorProfile.propTypes = {
  check403Error: PropTypes.func,
  setLoginModalShow: PropTypes.func
};
