import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";

import HomeSlider from "../../../components/home/slider/HomeSlider";
import TopAdvisors from "../../../components/home/TopAdvisors";
import Categories from "../../../components/home/Categories";
import PsychicReadingsAdvisors from "../../../components/home/PsychicReadingsAdvisors";
import Wizzard from "../../../components/home/Wizzard";
import Testimonial from "../../../components/home/Testimonial";
import LoveRelationshipAdvisors from "../../../components/home/LoveRelationshipAdvisors";
import CareerAdvisors from "../../../components/home/CareerAdvisors";
import Add from "../../../components/home/Add";
import axios from "axios";
import * as myAppConfig from "../../../constants/AppConstants";

const ClientHomePage = (props) => {
  const isMounted = React.useRef(true);

  const { homePageDetails, setHomePageDetails, check403Error } = props;
  const [carouselSlidesData, setCarouselSlidesData] = useState();

  const [advisorsUserReviews, setAdvisorsUsersReviews] = useState([]);
  useEffect(() => {
    if (isMounted.current) {
      axios
        .get(myAppConfig.GET_HOMEPAGE_DETAILS)
        .then((response) => {
          if (response.data.status === "success") {
            const hpData = {
              categories: response.data.categories,
              specializations: response.data.specializations,
              languages: response.data.languages,
              skillsAndMethods: response.data.skillsAndMethods,
            };
            localStorage.setItem("homePageDetails", JSON.stringify(hpData));
            if (!homePageDetails || !homePageDetails.top_advisors) {
              setHomePageDetails(response.data);
            }
          }
        })
        .catch((err) => check403Error(err));

      const getHeroBanner = () => {
        axios
        .get(myAppConfig.GET_HERO_BANNER)
        .then((response) => {
          if (response.data.status === "success") {
            console.log("GET_HERO_BANNER success ", response.data);
            setCarouselSlidesData(response.data.hero_banners);
            localStorage.setItem("carouselSlidesData", JSON.stringify(response.data.hero_banners));
          } else {
            console.log("GET_HERO_BANNER ", response.data);
          }
        })
        .catch((err) => check403Error(err));
      }  

      if(localStorage.getItem("carouselSlidesData") !== undefined && localStorage.getItem("carouselSlidesData") !== null){
        try{
          setCarouselSlidesData(JSON.parse(localStorage.getItem("carouselSlidesData")));
          getHeroBanner();
        }catch(error) {
          getHeroBanner();
        }
      } else if (!carouselSlidesData) {
        getHeroBanner();
      }
    }

    return () => {
      isMounted.current = false;
    };
  }, []); //[check403Error]);
  return (
    <>
      <HomeSlider carouselSlidesData={carouselSlidesData ? carouselSlidesData : []} />
      <TopAdvisors advisors={homePageDetails?.top_advisors ?? []} advisorsUserReviews={advisorsUserReviews} setAdvisorsUsersReviews={setAdvisorsUsersReviews} />
      <Categories categories={homePageDetails?.categories ?? []} />
      <PsychicReadingsAdvisors advisors={homePageDetails?.psychic_readings ?? []} advisorsUserReviews={advisorsUserReviews} setAdvisorsUsersReviews={setAdvisorsUsersReviews} />
      <Wizzard/>
      <LoveRelationshipAdvisors advisors={homePageDetails?.love_and_relationships ?? []} advisorsUserReviews={advisorsUserReviews} setAdvisorsUsersReviews={setAdvisorsUsersReviews} />
      <CareerAdvisors advisors={homePageDetails?.career_advisor ?? []} advisorsUserReviews={advisorsUserReviews} setAdvisorsUsersReviews={setAdvisorsUsersReviews} />
      <Testimonial/>
      <Add/>
    </>
    )
}

export default ClientHomePage;
