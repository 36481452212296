
import { useState } from 'react';
import { Col, Card, Row, InputGroup, FormControl } from 'react-bootstrap'
import callIcon from '../../assets/icons/call_icon.png';
import chatIcon from '../../assets/icons/chat_icon.png';
import videoIcon from '../../assets/icons/video_icon.png';

import { minChatPrice, minCallPrice, minVideoPrice } from '../../constants/AppConstants'
const SessionAndPricing = (props) => {

    const { callPrice, setCallPrice,
        chatPrice, setChatPrice,
        videoPrice, setVideoPrice, saveUserDetails } = props

    const [invalidCallPrice, setInvalidCallPrice] = useState(false);
    const [invalidChatPrice, setInvalidChatPrice] = useState(false);
    const [invalidVideoPrice, setInvalidVideoPrice] = useState(false);

    const [invalidCallPrice1, setInvalidCallPrice1] = useState(false);
    const [invalidChatPrice1, setInvalidChatPrice1] = useState(false);
    const [invalidVideoPrice1, setInvalidVideoPrice1] = useState(false);
    const platformFee = 0;
    const commission = 0.45;

    async function changeCallPrice(ev) {
        let decimalNumber = /^([0]|([1-9][0-9]*))(\.[0-9]+)?$/;
        if (ev.target.value && !decimalNumber.test(ev.target.value)) {
            setInvalidCallPrice(true)
        }
        else {
            setInvalidCallPrice(false);
            if (ev.target.value && ev.target.value < minCallPrice) {
                setInvalidCallPrice1(true)
            }
            else {
                setInvalidCallPrice1(false);
                setCallPrice(ev.target.value);
            }
        }
    }

    async function changeChatPrice(ev) {
        let decimalNumber = /^([0]|([1-9][0-9]*))(\.[0-9]+)?$/;
        if (ev.target.value && !decimalNumber.test(ev.target.value)) {
            setInvalidChatPrice(true)
        }
        else {
            setInvalidChatPrice(false)
            if (ev.target.value && ev.target.value < minChatPrice) {
                setInvalidChatPrice1(true)
            }
            else {
                setInvalidChatPrice1(false);
                setChatPrice(ev.target.value);
            }
        }
    }

    async function changeVideoPrice(ev) {
        let decimalNumber = /^([0]|([1-9][0-9]*))(\.[0-9]+)?$/;
        if (ev.target.value && !decimalNumber.test(ev.target.value)) {
            setInvalidVideoPrice(true)
        }
        else {
            setInvalidVideoPrice(false)
            if (ev.target.value && ev.target.value < minVideoPrice) {
                setInvalidVideoPrice1(true)
            }
            else {
                setInvalidVideoPrice1(false);
                setVideoPrice(ev.target.value);
            }
        }
    }
    const saveData = async e => {
                    const formData = new FormData();
                    if (callPrice) formData.append("call_price", callPrice);
                    else formData.append("call_price", 0);
                    if (chatPrice) formData.append("chat_price", chatPrice);
                    else formData.append("chat_price", 0);
        if (videoPrice) formData.append("video_price", videoPrice);
        else formData.append("video_price", 0);
                    await saveUserDetails(formData);
    }

    return (
        <Card className="card-custom custom-padding session">
            <Col lg={6} className="border-right">
                <p className="custom-text-style-24-34">Session & Pricing</p>
                <p className="custom-text-style-16-22">Choose your session method and pricing</p>
                <Row>
                    <Col className='col-4'><div className={'icon_wrapper' + (callPrice ? ' green' : '')}><Card.Img src={callIcon} /></div><p className="chatText custom-text-style-16-22">Call</p></Col>
                    <Col className='col-8'>
                        <p className="currency-sign custom-text-style-16-22">$</p>
                        <div className="currency-value custom-text-style-16-22">
                            <InputGroup onKeyUp={setCallPrice ? changeCallPrice : null} onBlur={saveUserDetails ? saveData : null}>
                                <FormControl disabled={setCallPrice ? false : true} className={(invalidCallPrice || invalidCallPrice1) ? 'invalid' : ''} defaultValue={callPrice ? callPrice : ''} aria-label="Username" aria-describedby="basic-addon1" />
                            </InputGroup>
                        </div>
                        <p className="currency-time custom-text-style-16-22">Per Minute</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='col-4'><div className={'icon_wrapper' + (chatPrice ? ' green' : '')}><Card.Img src={chatIcon} /></div><p className="chatText custom-text-style-16-22">Chat</p></Col>
                    <Col className='col-8'>
                        <p className="currency-sign custom-text-style-16-22">$</p>
                        <div className="currency-value custom-text-style-16-22">
                            <InputGroup onKeyUp={setChatPrice ? changeChatPrice : null} onBlur={saveUserDetails ? saveData : null}>
                                <FormControl disabled={setChatPrice ? false : true} className={(invalidChatPrice || invalidChatPrice1) ? 'invalid' : ''} defaultValue={chatPrice ? chatPrice : ''} aria-label="Username" aria-describedby="basic-addon1" />
                            </InputGroup>
                        </div>
                        <p className="currency-time custom-text-style-16-22">Per Minute</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='col-4'><div className={'icon_wrapper' + (videoPrice ? ' green' : '')}><Card.Img src={videoIcon} /></div><p className="chatText custom-text-style-16-22">Video</p></Col>
                    <Col className='col-8'>
                        <p className="currency-sign custom-text-style-16-22">$</p>
                        <div className="currency-value custom-text-style-16-22">
                            <InputGroup onKeyUp={setVideoPrice ? changeVideoPrice : null} onBlur={saveUserDetails ? saveData : null}>
                                <FormControl disabled={setVideoPrice ? false : true} className={(invalidVideoPrice || invalidVideoPrice1) ? 'invalid' : ''} defaultValue={videoPrice ? videoPrice : ''} aria-label="Username" aria-describedby="basic-addon1" />
                            </InputGroup>
                        </div>
                        <p className="currency-time custom-text-style-16-22">Per Minute</p>
                    </Col>
                </Row>
                <Row className='invalid mb-0'>
                    {(invalidCallPrice || invalidChatPrice || invalidVideoPrice) ? '* Invalid price format. Required format example: 3.99.' : ''}
                    {(invalidCallPrice || invalidChatPrice || invalidVideoPrice) ? <br /> : null}
                    {invalidCallPrice1 ? ('* Call price has to be at least $' + minCallPrice) : ''}
                    {invalidCallPrice1 ? <br /> : null}
                    {invalidChatPrice1 ? ('* Chat price has to be at least $' + minChatPrice) : ''}
                    {invalidChatPrice1 ? <br /> : null}
                    {invalidVideoPrice1 ? ('* Video price has to be at least $' + minVideoPrice) : ''}
                </Row>
            </Col>
            <Col lg={6}>
                {setCallPrice && setChatPrice && setVideoPrice ?
                    <>
                        <br />
                        <br />
                    </>
                    :
                    <p className="homepage-edit-btn" onClick={() => window.location.pathname = "/profile-settings"}>Edit</p>
                }
                <p className="custom-text-right custom-text-style-16-22">Price per minute customers will see: ${callPrice ? callPrice : chatPrice ? chatPrice : videoPrice ? videoPrice : 0}</p>
                <p className="custom-text-right custom-text-style-16-22">Platform Fee: ${(callPrice || chatPrice || videoPrice) ? platformFee : 0}</p>
                <p className="custom-text-right custom-text-style-16-22">Commission: ${callPrice ? (callPrice * commission) : chatPrice ? (chatPrice * commission) : videoPrice ? (videoPrice * commission) : 0}</p>
                <hr />
                <p className="custom-text-right custom-text-style-16-22">You will earn (per minute): ${(callPrice ? (callPrice - platformFee - callPrice * commission) : chatPrice ? (chatPrice - platformFee - chatPrice * commission) : videoPrice ? (videoPrice - platformFee - videoPrice * commission) : 0).toFixed(2)}</p>
                <p className="how-calculated">How Your Earnings are Calculated</p>
            </Col>
        </Card>
    )
}

export default SessionAndPricing