import PaymentsContainer from "../../../components/PaymentsContainer"
import { Container, Col, Row, Card, Spinner } from 'react-bootstrap'
import { isMobileOnly } from "react-device-detect"
import { imagesApiURL } from "../../../constants/AppConstants"
import { useHistory } from "react-router"
import { useState, useEffect } from "react"
import moment from "moment"
import axios from "axios"
import * as myAppConfig from "../../../constants/AppConstants"
import leftArrow from '../../../assets/icons/arrows/arrow_left_icon.png'
import firstArrow from '../../../assets/icons/arrows/arrow_first_icon.png'

const Projection = (props) => {
    let history = useHistory();
    const { advisorId, check403Error } = props;

    const itemsPerPageValues = [
        {
            label: '10',
            value: 10
        },
        {
            label: '25',
            value: 25
        },
        {
            label: '50',
            value: 50
        },
        {
            label: '100',
            value: 100
        }
    ]

    const [dataLoading, setDataLoading] = useState(false);

    const [paymentsList, setPaymentsList] = useState([]);
    const [processingPayments, setProcessingPayments] = useState(0);
    const [paymentMade, setPaymentMade] = useState(0);
    const [totalSessions, setTotalSessions] = useState(0);

    const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageValues[0].value);
    const [currentPage, setCurrentPage] = useState(1);
    const numberOfEntries = totalSessions;
    const numberOfPages = Math.ceil(numberOfEntries / itemsPerPage);
    const from = (currentPage - 1) * itemsPerPage + 1;
    const to = Math.min(numberOfEntries, currentPage * itemsPerPage)
    const [lastUpdate, setLastUpdate] = useState('');
    const [startDate, setStartDate] = useState(new Date(Date.now() - 6 * 24 * 60 * 60 * 1000))
    const [endDate, setEndDate] = useState(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000))

    const paymentsData = {
        processingPayments: processingPayments,
        paymentMade: paymentMade,
        totalSessions: totalSessions,
        payments: paymentsList
    }

    const changeCurrentPage = (page) => {
        setCurrentPage(page);
    }

    useEffect(() => {
        let sDate = moment(startDate).format('YYYY-MM-DD');
        let eDate = moment(endDate).format('YYYY-MM-DD');
        if (!dataLoading) {
            setDataLoading(true);
            axios
                .get(myAppConfig.GET_PAYMENTS_LIST, {
                    params: {
                        items_per_page: itemsPerPage,
                        current_page: currentPage,
                        from: sDate,
                        to: eDate,
                        advisor_id: advisorId
                    },
                    headers: {
                        token: localStorage.getItem("userToken"),
                    },
                })
                .then((response) => {
                    console.log(response.data)
                    setDataLoading(false);
                    if (response.data.status === "success") {
                        setPaymentsList(response.data.pays);

                        setProcessingPayments(response.data.processingPayments);
                        setPaymentMade(response.data.paymentMade);
                        setTotalSessions(response.data.totalSessions);
                        setLastUpdate(response.data.lastUpdate);
                    }
                })
                .catch((err) => {
                    check403Error(err);
                });
        }
    }, [check403Error, itemsPerPage, currentPage, startDate, endDate]);

    return (
        <div className="adminProfile my-5" id="adminProfilePage">
            <Container
                fluid
                style={isMobileOnly ? { width: "91.5%" } : { width: "88.88%" }}
            >
                <Row>
                    <Col lg={6} sm={6} className="col-top-info-container ps-0 pb-3 mb-1">
                        <div className="top-info-container">
                            <Col lg={6} sm={8}>
                                <div className="back" onClick={history.goBack} style={{ cursor: 'pointer' }}>
                                    <Card.Img
                                        src={imagesApiURL + "images/custom/back.png"}
                                        className="back-image"
                                    />
                                    Back
                                </div>
                            </Col>
                        </div>
                    </Col>
                </Row>
                {dataLoading ?
                    <div style={{ textAlign: 'center' }}>
                        <Spinner animation='border' style={{ color: 'white' }} />
                    </div>
                    :
                    <>
                        <PaymentsContainer paymentsData={paymentsData} lastUpdate={lastUpdate} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                        <div className='archivePagination mt-4 mb-4'>
                            <div>
                                {'Showing ' + from + ' to ' + to + ' of ' + numberOfEntries + ' entries'}
                            </div>
                            <div className='d-flex'>
                                <div className='itemsPerPage me-4'>
                                    <span className='me-2'>Items per page: </span>
                                    <select name="items" id="items" onChange={(e) => { setCurrentPage(1); setItemsPerPage(parseInt(e.target.value)); }} defaultValue={itemsPerPage}>
                                        {itemsPerPageValues.map((item, id) =>
                                            <option value={item.label} key={id}>{item.value}</option>
                                        )}
                                    </select>
                                </div>
                                <div className='pages'>
                                    <div className={'pageNumber first ' + (currentPage === 1 ? 'disabled' : '')} onClick={() => changeCurrentPage(1)}><img src={firstArrow} alt='arrow' /></div>
                                    <div className={'pageNumber prev ' + (currentPage === 1 ? 'disabled' : '')} onClick={() => changeCurrentPage(currentPage - 1)}><img src={leftArrow} alt='arrow' /></div>
                                    {currentPage > 1 ?
                                        <>
                                            {currentPage > 2 ?
                                                <div className='pageNumber dots'>...</div>
                                                : null
                                            }
                                            <div className='pageNumber' onClick={() => changeCurrentPage(currentPage - 1)}>{currentPage - 1}</div>
                                        </>
                                        : null}
                                    <div className='pageNumber active'>{currentPage}</div>
                                    {currentPage < numberOfPages ?
                                        <>
                                            <div className='pageNumber' onClick={() => changeCurrentPage(currentPage + 1)}>{currentPage + 1}</div>
                                            {numberOfPages > currentPage + 1 ?
                                                <div className='pageNumber dots'>...</div>
                                                : null
                                            }
                                        </>
                                        : null}
                                    <div className={'pageNumber next ' + (currentPage === numberOfPages ? 'disabled' : '')} onClick={() => changeCurrentPage(currentPage + 1)}><img alt='arrow' src={leftArrow} /></div>
                                    <div className={'pageNumber last ' + (currentPage === numberOfPages ? 'disabled' : '')} onClick={() => changeCurrentPage(numberOfPages)}><img alt='arrow' src={firstArrow} /></div>
                                </div>
                            </div>
                        </div>
                    </>}
            </Container>
        </div>
    )
}

export default Projection