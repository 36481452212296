import ReactGA from "react-ga";
import * as myAppConfig from "../constants/AppConstants";

export function initializeGA() {
  ReactGA.initialize(myAppConfig.IS_PROD_VERSION ? "UA-209945338-1" : "UA-209945338-2");
  ReactGA.plugin.require("ecommerce");
  console.log("Initialized React Google Analytics");
}

export function gaAddTransaction(end_Details) {
  let friendlyName = end_Details.friendly_name;
  let price = end_Details.price;
  let service_name = end_Details.service_name;
  let session_id = end_Details.session_id;
  let total = end_Details.total;
  let total_quantity = end_Details.total_quantity;

  ReactGA.plugin.execute("ecommerce", "addItem", {
    id: session_id,
    name: friendlyName,
    sku: "--", // seems to be required
    price: price,
    category: service_name,
    quantity: total_quantity,
  });

  ReactGA.plugin.execute("ecommerce", "addTransaction", {
    id: session_id, // the same as for addItem to connect them
    revenue: total, // it's price * quantity
  });
  ReactGA.plugin.execute("ecommerce", "send");
  ReactGA.plugin.execute("ecommerce", "clear");

  console.log("ga ecommerce send: ", end_Details);
}
