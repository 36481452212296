import { isMobile, isMobileOnly } from 'react-device-detect'
import horospoce_circle from '../../assets/icons/horospoce_circle_icon.png'
import { aboutUs } from "../../constants"

const TextImage = (props) => {
    return (
        <div className={isMobileOnly ? 'd-flex flex-column-reverse' : 'd-flex'} style={styles.flex}>
            <div style={isMobileOnly ? styles.textMobile : styles.text}>{aboutUs[1].text}</div>
            <img src={horospoce_circle} style={isMobileOnly ? { marginBottom: '49px', width: '100%', height: 'auto' } : styles.img} alt='horoscope icon' />
        </div>
    )
}

export default TextImage

const styles = {
    img: {
        paddingLeft: '14.12%',
        width: '46.5%',
        height: 'fit-content',
        alignSelf: 'center'
    },
    flex: {
        padding: isMobile ? "0 34px" : "32px 176px 45px 81px"
    },
    text: {
        width: '53.5%',
        color: 'white',
        fontSize: '16px',
        lineHeight: '34px',
        fontWeight: 'normal',
        display: 'flex',
        alignItems: 'center'
    },
    textMobile: {
        color: 'white',
        fontSize: '14px',
        lineHeight: '26px',
        fontWeight: '400',
        marginBottom: '19px'
    }
}