import React from 'react';
import parse from 'html-react-parser';
import { Player, ControlBar } from 'video-react';
import "../../../../node_modules/video-react/dist/video-react.css";
import {
    Button
} from 'react-bootstrap';

const Slide = React.forwardRef((props, player) => {
    return (
        <div className="slideContent">
            <div className="backroundImage">
                {!props.isVideo ?
                    <img src={props.background} alt="ico" />
                    :
                    <Player
                        playsInline
                        ref={player}
                        key={1}
                        // fluid
                        autoPlay
                        muted
                        startTime={0}
                    // preload="auto"
                    >
                        <source src={props.background} />
                        <ControlBar disableCompletely={true} />
                    </Player>
                }
            </div>
            {!props.isVideo ?
                <div className="bottomItems">
                    <div className="imageTitle  mb-5">{parse(props.imageTitle)}</div>

                    <div className="d-flex align-items-center">
                        <Button className="buttonGreen mr-5" onClick={() => window.location.href = '/category/'}>{props.button}</Button>
                        {props.icon1 !== '' ?
                            <div className="iconText d-flex align-items-center justify-content-center">
                                <img src={props.icon1} alt="ico" />
                                <div className="iconDesc">{parse(props.iconDesc1)}</div>
                            </div> : null}

                        <div className="iconText d-flex align-items-center justify-content-center">
                            <img src={props.icon2} alt="ico" />
                            <div className="iconDesc">{parse(props.iconDesc2)}</div>
                        </div>
                    </div>
                </div>
                : null}

        </div>
    )
});

export default Slide
