import React from "react";
import "../../styles/chatHistoryItemCell.scss";
import { Row, Col } from "react-bootstrap";
import icon_logo from "../../assets/img/user.png";
import { imagesApiURL } from "../../constants/AppConstants";
import * as myAppConfig from "../../constants/AppConstants";

const ChatHistoryItemCell = (props) => {
  let msgDate = "";
  const myChannel = props.subscribedChannels.find(
    (obj) =>
      props.conversation !== undefined &&
      Number(obj.channelState.uniqueName) ===
        Number(props.conversation.conversation_id)
  );

  if (
    props.conversationType === myAppConfig.CONVERSATION_TYPE.CHAT &&
    myChannel !== undefined &&
    myChannel.channelState.lastMessage !== undefined
  ) {
    msgDate =
      myChannel.channelState.lastMessage.dateCreated.getDate() +
      "/" +
      (myChannel.channelState.lastMessage.dateCreated.getMonth() + 1) +
      "/" +
      myChannel.channelState.lastMessage.dateCreated.getFullYear();
  }

  const lastMessageDate = msgDate;

  let lastMsg = "";
  if (props.conversation !== undefined &&
    props.conversation.last_message &&
    props.conversation.last_message.length > 0
  ) {
    lastMsg = props.conversation.last_message[0].last_message;
  }
  const last_message = lastMsg;

  var username = "";
  var userLogo = "";
  var isChat = false;
  var undefinedProfile = false;

  if (
    props.conversation.conversation_type &&
    (props.conversation.conversation_type ===
      myAppConfig.CONVERSATION_TYPE.VOICE.id ||
      props.conversation.conversation_type ===
        myAppConfig.CONVERSATION_TYPE.VIDEO.id)
  ) {
    isChat = false;

    const clientProfile = props.conversation.clientProfile[0];
    if (clientProfile === undefined) {
      undefinedProfile = true;
    } else {
      userLogo = clientProfile.logo_image;
      username = clientProfile.first_name + " " + clientProfile.last_name;
    }
  } else {
    isChat = true;

    userLogo = props.isClient
      ? props.conversation.advisorLogo
      : props.conversation.clientLogo;
    username = props.isClient
      ? props.conversation.advisorName
      : props.conversation.clientName;
  }

  const logo =
    userLogo === "" || userLogo === null ? icon_logo : imagesApiURL + userLogo;

  return props.conversation.length === 0 ? null : undefinedProfile ? null : (
    <Row
      className="chat-history-item"
      onClick={() =>
        isChat
          ? props.handleChatCellSelect(props.index)
          : props.handleCallVideoCellSelect(props.index)
      }
    >
      <hr />
      <div
        className={
          "chat-history-item-container " +
          (props.currentConversationIndex === props.index
            ? "border-left-selected"
            : "")
        }
      >
        <Col lg={9} className="info">
          <img src={logo} className="user-image" alt="user logo" />

          <div className="info-data">
            <p
              className="user-name"
              style={{ marginTop: isChat ? "0px" : "10px" }}
            >
              {username}
            </p>
            <p
              className="user-last-msg"
              style={{ display: isChat ? "inline-block" : "none" }}
            >
              {last_message}
            </p>
          </div>
        </Col>
        <Col lg={3} className="info" style={{ flexDirection: "column" }}>
          <p className="msg-date">{lastMessageDate}</p>
          {/*
          <p className="new-msg-count" style={{display: props.newMsgCount === undefined || props.newMsgCount === 0 ? "none" : ""}}>{props.newMsgCount}</p>
          */}
        </Col>
      </div>
    </Row>
  );
};

export default ChatHistoryItemCell;
