import { Dropdown, DropdownButton } from "react-bootstrap"
import * as Icon from 'react-feather'
import { Link } from "react-router-dom"
import { imagesApiURL } from "../../../constants/AppConstants"

import user from '../../../assets/img/user.png'
import axios from 'axios'
import * as myAppConfig from '../../../constants/AppConstants'

import { useState, useRef } from "react"
import { useOnClickOutside, logOutUser } from "../../../Utilities/Utils";

const ClientDropDown = (props) => {
    const { check403Error } = props;
    const haveAdminProfile = parseInt(localStorage.getItem('haveAdvisorProfile')) === 1;
    const logoImg = localStorage.getItem("userLogoImg")
    const [show, setShow] = useState(false);
    const ref = useRef();

    async function switchAccount() {
        axios.get(myAppConfig.SWITCH_ACCOUNT, {
            headers: { 'token': localStorage.getItem('userToken') }
        }).then(response => {
            console.log(response.data)
        }).catch(err => check403Error(err));
    }

    const switchUserType = async e => {
        e.preventDefault();
        await switchAccount();
        logOutUser("/?logged_out=true", check403Error);
    }

    useOnClickOutside(ref, () => setShow(false));

    return (
        <div className="user_logged d-flex align-items-center" onClick={() => setShow(!show)} ref={ref}>
            <div className="usr_img">
                <img src={logoImg ? imagesApiURL + logoImg : user} alt="user" />
                <div className="notify"></div>
            </div>
            <DropdownButton
                align="up"
                id="dropdown-menu-align-right"
                title=""
                show={show}
            >
                <Dropdown.Item eventKey="1" as={Link} to="/saved-advisors"><Icon.Heart /><span>Saved</span></Dropdown.Item>
                <Dropdown.Item eventKey="2" as={Link} to="/payment-methods"><Icon.Sidebar /><span>Payment Methods</span></Dropdown.Item>
                <Dropdown.Item eventKey="3" as={Link} to="/all-chats"><Icon.MessageSquare /><span>Chat</span></Dropdown.Item>
                <Dropdown.Item eventKey="4" as={Link} to="/profile-settings"><Icon.User /><span>My Profile</span></Dropdown.Item>
                {haveAdminProfile ? <Dropdown.Item eventKey="5" onClick={switchUserType}><Icon.Repeat /><span>Switch to advisor</span></Dropdown.Item> : null}
                <Dropdown.Item eventKey={haveAdminProfile ? "6" : "5"} onClick={() => logOutUser("/", check403Error)}><Icon.LogOut /><span>Log Out</span></Dropdown.Item>

            </DropdownButton>
        </div>

    )
}

export default ClientDropDown