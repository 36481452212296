import { Modal, Button } from "react-bootstrap"
import facebookIcon from '../assets/icons/socialMedia/facebook_icon.png';
import emailIcon from '../assets/icons/socialMedia/email_icon.png';
import twitterIcon from '../assets/icons/socialMedia/twitter_icon.png';
import copyLinkIcon from '../assets/icons/socialMedia/copy_link_icon.png';
import messengerIcon from '../assets/icons/socialMedia/messenger_icon.png';

import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    FacebookMessengerShareButton,
} from 'react-share'

const ShareModal = (props) => {
    const { showShareModal, setShowShareModal, shareUrl } = props

    const socialMedia = [
        {
            icon: facebookIcon,
            title: 'Facebook',
            component: FacebookShareButton
        },
        {
            icon: twitterIcon,
            title: 'Twitter',
            component: TwitterShareButton
        },
        // {
        //   icon: instagramIcon,
        //   title: 'Instagram',
        //   component: null
        // },
        {
            icon: messengerIcon,
            title: 'Messenger',
            component: FacebookMessengerShareButton
        },
        {
            icon: emailIcon,
            title: 'Email',
            component: EmailShareButton
        },
        {
            icon: copyLinkIcon,
            title: 'Copy link',
            component: null
        },
        // {
        //   icon: moreIcon,
        //   title: 'More',
        //   component: null
        // }
    ]
    return (
        <Modal
            show={showShareModal}
            onHide={() => setShowShareModal(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="editModal account share"
            backdrop='static'
            keyboard={false}
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="title" >Share with friends</div>
                {socialMedia.map((site, idx) => {
                    return (
                        site.component ? <site.component key={idx} className="btn-primary" url={shareUrl}><img src={site.icon} alt='socialMedia' />{site.title}</site.component> :
                            <Button key={idx} onClick={() => {
                                if (site.title === 'Copy link') {
                                    navigator.clipboard.writeText(shareUrl);
                                    alert('Link copied to clipboard!')
                                }
                            }}><img src={site.icon} alt='socialMedia' />{site.title}</Button>
                    )
                })}
            </Modal.Body>
        </Modal>
    )
}

export default ShareModal