
import React from 'react'
import { Button, Col } from 'react-bootstrap';
import styles from '../../../../styles/wizard.module.scss';

const ButtonCard = (props) => {
    const BACKGROUND_DEFAULT = "#233558";
    const BACKGROUND_SELECTED = "#1DA1F2";
 
    return (
        <Col key={props.id} >

            <div
                className={styles.mind_item}
            >
                <Button
                    variant="primary"
                    type="submit"
                    className={styles.buttonMind}
                    style={{ background: props.itemSelected === props.id ? BACKGROUND_SELECTED : BACKGROUND_DEFAULT }}
                    onClick={() => props.onClick()}
                >
                    {props.title}
                </Button>
            </div>

        </Col>
    );
}

export default ButtonCard
