

import { Link } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap'
import ReactStars from "react-rating-stars-component";
import RatingAndReviewCell from '../RatingAndReviewCell';
import { isMobileOnly } from 'react-device-detect';

const RatingReviews = (props) => {

    const {
        scoreAverage, ratingsAndReviews, reviewsNo, reviewsInfo, check403Error
    } = props;

    return (
        <Card className={"card-custom custom-padding height-auto rating " + (ratingsAndReviews.length > 0 ? "" : "empty")} >
            <Row>
                <Col xs={6}>
                    <p className="custom-text-style-24-34">Rating & Reviews</p>
                </Col>
                <Col xs={6}>
                    {scoreAverage !== null ?
                        <div className="rating-images" style={isMobileOnly ? { flexWrap: 'wrap', justifyContent: 'space-between' } : {}}>
                            <ReactStars edit={false} size={14} value={scoreAverage} isHalf={true} color="#233558" activeColor="#19AAA1" className={isMobileOnly ? 'col-6' : ''} />
                            <p className={isMobileOnly ? 'col-5 rating-amount-note custom-text-style-14-20' : "rating-amount-note custom-text-style-14-20"}>{scoreAverage.toFixed(1)} ({ratingsAndReviews.length})</p>
                            <div style={isMobileOnly ? {} : { marginLeft: 42, alignSelf: 'center' }} className={isMobileOnly ? 'col-12' : ''}>
                                <p className="rating-amount-note custom-text-style-14-20" style={{ color: '#19AAA1' }}>{reviewsNo}</p>
                                <p className="rating-amount-note custom-text-style-14-20">readings</p>
                            </div>
                        </div>
                        : null}
                </Col>
            </Row>
            {ratingsAndReviews.length > 0 ?
                <>
                    {
                        ratingsAndReviews.map((review, idx) =>
                            idx < 2 ? (<RatingAndReviewCell key={idx} review={review} check403Error={check403Error} />) : null
                        )}
                    {reviewsNo > 2 ?
                        <Link to={{ pathname: "/rating-and-reviews", state: reviewsInfo, check403Error: { check403Error } }}
                            className="custom-button-text" style={{ textDecoration: 'none' }} >Show More</Link>
                        :
                        null}
                </>
                :
                <div style={{ fontSize: '16px', lineHeight: '22px', color: 'white', marginTop: '62px', minHeight: '80px' }}>No rating yet</div>
            }
        </Card>
    )
}

export default RatingReviews