import React, { useState, useEffect } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../node_modules/video-react/dist/video-react.css";
import "../../../styles/profile.scss";
import { imagesApiURL } from "../../../constants/AppConstants";

import * as myAppConfig from "../../../constants/AppConstants";
import { isDesktop, isMobileOnly } from "react-device-detect";

import AlertMessage from "../../../components/advisorProfile/AlertMessage";
import ProfileUpdated from "../../../components/advisorProfile/ProfileUpdated";
import EditIntro from "../../../components/advisorProfile/EditIntro";
import EditBackground from "../../../components/advisorProfile/EditBackground";
import EditInternship from "../../../components/advisorProfile/EditInternship";
import SessionAndPricing from "../../../components/advisorProfile/SessionAndPricing";
import Communication from "../../../components/advisorProfile/Communication";
import LogoImage from "../../../components/advisorProfile/LogoImage";
import BackgroundVideo from "../../../components/advisorProfile/BackgroundVideo";
import Promotions from "../../../components/advisorProfile/Promotions";
import Specializations from "../../../components/advisorProfile/Specializations";
import RatingReviews from "../../../components/advisorProfile/RatingReviews";
import Sessions from "../../../components/advisorProfile/Sessions";
import BackgroundDescription from "../../../components/advisorProfile/BackgroundDescription";
import AdvisorInfo from "../../../components/advisorProfile/AdvisorInfo";
import UploadingModal from "../../../components/modals/UploadingModal";

import { useHistory } from "react-router";
export default function AdminProfile(props) {
  const { homePageDetails, check403Error } = props;

  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [image, setImage] = useState("");
  const [bgVideo, setBgVideo] = useState("");
  const [category, setCategory] = useState("");
  const [describe, setDescribe] = useState();
  const [summarize, setSummarize] = useState();
  const [background, setBackground] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSpecializations, setSelectedSpecializations] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState();
  const [freeMinStatus, setFreeMinStatus] = useState();
  const [freeMin, setFreeMin] = useState();
  const [saleOfferStatus, setSaleOfferStatus] = useState();
  const [saleOffer, setSaleOffer] = useState();
  const [callPrice, setCallPrice] = useState();
  const [chatPrice, setChatPrice] = useState();
  const [videoPrice, setVideoPrice] = useState();

  const [tempSelectedCategories, setTempSelectedCategories] =
    useState(selectedCategories);
  const [tempFirstName, setTempFirstName] = useState(firstName);
  const [tempLastName, setTempLastName] = useState(lastName);
  const [tempFullName, setTempFullName] = useState(fullName);
  const [tempDescribe, setTempDescribe] = useState(describe);
  const [tempSummarize, setTempSummarize] = useState(summarize);
  const [tempBackground, setTempBackground] = useState(background);
  const [tempSelectedSpecializations, setTempSelectedSpecializations] =
    useState(selectedSpecializations);
  const [tempSelectedSkills, setTempSelectedSkills] = useState(selectedSkills);
  const [tempSelectedLanguages, setTempSelectedLanguages] =
    useState(selectedLanguages);

  const [showEditIntro, setShowEditIntro] = useState(false);
  const [showEditBackground, setShowEditBackground] = useState(false);
  const [showEditInternship, setShowEditInternship] = useState(false);

  const [reviewsNo, setReviewsNo] = useState(0);
  const [scoreAverage, setScoreAverage] = useState(null);
  const [ratingsAndReviews, setRatingsAndReviews] = useState([]);
  const [reviewsInfo, setReviewsInfo] = useState({});

  var profileFormData = new FormData();
  const [introFormData, setIntroFormData] = useState();
  const [backgroundFormData, setBackgroundFormData] = useState();
  const [internshipFormData, setInternshipFormData] = useState();

  const [showProfileUpdatedMessage, setShowProfileUpdatedMessage] =
    useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [memberId, setMemberId] = useState();

  const [sessions, setSessions] = useState([])

  useEffect(() => {
    if (!dataLoading) {
      setDataLoading(true);
      axios
        .get(myAppConfig.GET_MY_PROFILE, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: localStorage.getItem("userToken"),
          },
        })
        .then((response) => {
          if (
            response.data.status === "success" &&
            response.data.profile_details.length !== 0
          ) {
            let profileDetails = response.data.profile_details[0];
            let first_name = profileDetails.first_name;
            let last_name = profileDetails.last_name;
            let full_name = profileDetails.full_name;
            let image = profileDetails.logo_image;
            let bgVideo = profileDetails.background_video;
            let describe = profileDetails.describe_your_services;
            let summarize = profileDetails.summarize_your_qualifications;
            let background = profileDetails.background_description;
            let phone_number = profileDetails.phone_number;
            let freeMinStat = profileDetails.freeminutes_status;
            let freeMinVal = profileDetails.freeminutes_value;
            let saleOfferStat = profileDetails.saleoffer_status;
            let saleOfferVal = profileDetails.saleoffer_value;
            let prices = response.data.profile_details.profile_sessionprices;
            let memId = profileDetails.id;
            let sess = response.data.profile_details.conversations;
            if (prices) {
              prices.map((price, idx) => {
                // console.log(price.session_method_type)
                switch (price.session_method_type) {
                  case 1:
                    setCallPrice(price.price);
                    break;
                  case 2:
                    setChatPrice(price.price);
                    break;
                  case 3:
                    setVideoPrice(price.price);
                    break;
                  default:
                    break;
                }
                return null;
              });
            }

            setReviewsNo(response.data.profile_details.number_of_reviews);
            setScoreAverage(response.data.profile_details.score_average);
            setRatingsAndReviews(
              response.data.profile_details.rating_and_reviews
            );

            let ratingAndReviewsInfo = {};
            ratingAndReviewsInfo["number_of_reviews"] =
              response.data.profile_details.number_of_reviews;
            ratingAndReviewsInfo["score_average"] =
              response.data.profile_details.score_average;
            ratingAndReviewsInfo["rating_and_reviews"] =
              response.data.profile_details.rating_and_reviews;
            ratingAndReviewsInfo["is_admin_user"] = true;

            setReviewsInfo(ratingAndReviewsInfo);

            let category = "";
            let categories = response.data.profile_details.profile_categories;
            if (
              response.data.profile_details.profile_categories[0] !== undefined
            ) {
              category =
                response.data.profile_details.profile_categories[0].title;
            }

            let specializations =
              response.data.profile_details.profile_specializations;
            let skills = response.data.profile_details.profile_skills;
            let languages = response.data.profile_details.profile_languages;

            setFirstName(first_name);
            setLastName(last_name);
            setFullName(full_name);
            setTempFirstName(first_name);
            setTempLastName(last_name);
            setTempFullName(full_name);
            setCategory(category);
            setImage(image);
            setBgVideo(bgVideo);
            setSelectedCategories(
              categories
                ? categories.map((category, idx) => {
                  var categ = {};
                  categ.label = category.title;
                  categ.value = category.id;
                  return categ;
                })
                : []
            );

            setSelectedSpecializations(
              specializations
                ? specializations.map((specialization, idx) => {
                  var special = {};
                  special.label = specialization.title;
                  special.value = specialization.id;
                  return special;
                })
                : []
            );

            setSelectedSkills(
              skills
                ? skills.map((skill, idx) => {
                  var sk = {};
                  sk.label = skill.title;
                  sk.value = skill.id;
                  return sk;
                })
                : []
            );

            setSelectedLanguages(
              languages
                ? languages.map((language, idx) => {
                  var lang = {};
                  lang.label = language.title;
                  lang.value = language.id;
                  return lang;
                })
                : []
            );

            setDescribe(describe);
            setSummarize(summarize);
            setBackground(background);
            setPhoneNumber(phone_number);
            setFreeMinStatus(freeMinStat);
            setFreeMin(freeMinVal);
            setSaleOfferStatus(saleOfferStat);
            setSaleOffer(saleOfferVal);
            setMemberId(memId);
            setSessions(sess);
          } else {
            console.log(response.data);
          }
        })
        .catch((err) => check403Error(err));
    }
  }, [dataLoading, check403Error]);

  //   OnChange Events

  const onIntroEdited = (e) => {
    e.preventDefault();
    setSelectedCategories(tempSelectedCategories);
    if (tempSelectedCategories && tempSelectedCategories[0]) {
      setCategory(tempSelectedCategories[0].label)
    }
    else {
      setCategory(null);
    }
    setFirstName(tempFirstName);
    setLastName(tempLastName);
    setFullName(tempFullName.trim());
    setDescribe(tempDescribe);
    setSummarize(tempSummarize);
    const formData = new FormData(e.target);
    var selCategories = [];
    tempSelectedCategories.map((categ, idx) => {
      selCategories.push(categ.value);
      return null;
    });
    setIntroFormData(
      Object.assign({}, Object.fromEntries(formData.entries()), {
        profile_categories: selCategories.join(","),
      })
    );
    setShowEditIntro(false);
    setShowAlertMessage(true);
  };

  const onBackgroundEdited = (e) => {
    e.preventDefault();
    setBackground(tempBackground);
    const formData = new FormData(e.target);
    setBackgroundFormData(Object.fromEntries(formData.entries()));
    setShowEditBackground(false);
    setShowAlertMessage(true);
  };

  const onInternshipEdited = (e) => {
    e.preventDefault();
    setSelectedSpecializations(tempSelectedSpecializations);
    setSelectedSkills(tempSelectedSkills);
    setSelectedLanguages(tempSelectedLanguages);
    const formData = new FormData(e.target);
    var selSpecializations = [];
    tempSelectedSpecializations.map((categ, idx) => {
      selSpecializations.push(categ.value);
      return null;
    });
    var selSkills = [];
    tempSelectedSkills.map((categ, idx) => {
      selSkills.push(categ.value);
      return null;
    });
    var selLanguages = [];
    tempSelectedLanguages.map((categ, idx) => {
      selLanguages.push(categ.value);
      return null;
    });
    setInternshipFormData(
      Object.assign(
        {},
        Object.fromEntries(formData.entries()),
        { profile_specializations: selSpecializations.join(",") },
        { profile_skills: selSkills.join(",") },
        { profile_languages: selLanguages.join(",") }
      )
    );
    setShowEditInternship(false);
    setShowAlertMessage(true);
  };

  const updateProfile = async (e) => {
    if (image && image.raw) profileFormData.append("logo_image", image.raw);
    if (bgVideo && bgVideo.raw)
      profileFormData.append("background_video", bgVideo.raw);

    profileFormData.append("phone_number", phoneNumber ? phoneNumber : '');
    if (callPrice) profileFormData.append("call_price", callPrice);
    else profileFormData.append("call_price", 0);
    if (chatPrice) profileFormData.append("chat_price", chatPrice);
    else profileFormData.append("chat_price", 0);
    if (videoPrice) profileFormData.append("video_price", videoPrice);
    else profileFormData.append("video_price", 0);
    profileFormData.append("freeminutes_status", freeMinStatus);
    if (freeMin) profileFormData.append("freeminutes_value", freeMin);
    profileFormData.append("saleoffer_status", saleOfferStatus);
    if (saleOffer) profileFormData.append("saleoffer_value", saleOffer);
    if (introFormData) {
      Object.keys(introFormData).map(function (key, index) {
        profileFormData.append(key, introFormData[key]);
        return null;
      });
    }
    if (backgroundFormData) {
      Object.keys(backgroundFormData).map(function (key, index) {
        profileFormData.append(key, backgroundFormData[key]);
        return null;
      });
    }
    if (internshipFormData) {
      Object.keys(internshipFormData).map(function (key, index) {
        profileFormData.append(key, internshipFormData[key]);
        return null;
      });
    }
    console.log(profileFormData);
    await saveUserDetails(profileFormData);
  };

  const [loaded, setLoaded] = useState(null);
  async function saveUserDetails(credentials) {
    setLoaded(null);
    axios
      .post(myAppConfig.UPDATE_PROFILE_ADVISOR, credentials, {
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("userToken"),
        },
        onUploadProgress: ProgressEvent => {
          setLoaded(Math.round(
            (ProgressEvent.loaded * 100) / ProgressEvent.total
          ))
        }
      })
      .then((response) => {
        if (response.data.status === "success") {
          localStorage.setItem('profilePrices', JSON.stringify([callPrice, chatPrice, videoPrice]))
          console.log(response.data);
          if (response.data.logo_image) {
            localStorage.setItem('userLogoImg', response.data.logo_image)
          }
          setShowProfileUpdatedMessage(true);
        } else {
          console.log(response.data);
        }
      })
      .catch((err) => check403Error(err));
  }

  const editIntroProps = {
    showEditIntro: showEditIntro,
    setShowEditIntro: setShowEditIntro,
    tempFullName: tempFullName,
    setTempFullName: setTempFullName,
    tempFirstName: tempFirstName,
    setTempFirstName: setTempFirstName,
    tempLastName: tempLastName,
    setTempLastName: setTempLastName,
    tempSelectedCategories: tempSelectedCategories,
    setTempSelectedCategories: setTempSelectedCategories,
    setTempDescribe: setTempDescribe,
    setTempSummarize: setTempSummarize,
    onIntroEdited: onIntroEdited,
    homePageDetails: homePageDetails,
    fullName: fullName,
    firstName: firstName,
    lastName: lastName,
    describe: describe,
    summarize: summarize,
  };

  const editBackgroundProps = {
    showEditBackground: showEditBackground,
    setShowEditBackground: setShowEditBackground,
    onBackgroundEdited: onBackgroundEdited,
    background: background,
    setTempBackground: setTempBackground,
  };

  const editInternshipProps = {
    showEditInternship: showEditInternship,
    setShowEditInternship: setShowEditInternship,
    onInternshipEdited: onInternshipEdited,
    tempSelectedSpecializations: tempSelectedSpecializations,
    setTempSelectedSpecializations: setTempSelectedSpecializations,
    tempSelectedLanguages: tempSelectedLanguages,
    setTempSelectedLanguages: setTempSelectedLanguages,
    tempSelectedSkills: tempSelectedSkills,
    setTempSelectedSkills: setTempSelectedSkills,
    homePageDetails: homePageDetails,
  };

  const sessionAndPricingProps = {
    callPrice: callPrice,
    setCallPrice: setCallPrice,
    chatPrice: chatPrice,
    setChatPrice: setChatPrice,
    videoPrice: videoPrice,
    setVideoPrice: setVideoPrice,
  };

  const promotionsProps = {
    freeMin: freeMin,
    setFreeMin: setFreeMin,
    freeMinStatus: freeMinStatus,
    setFreeMinStatus: setFreeMinStatus,
    saleOffer: saleOffer,
    setSaleOffer: setSaleOffer,
    saleOfferStatus: saleOfferStatus,
    setSaleOfferStatus: setSaleOfferStatus,
    chatPrice: chatPrice,
    callPrice: callPrice,
    videoPrice: videoPrice,
  };

  const specializationsProps = {
    selectedSpecializations: selectedSpecializations,
    selectedSkills: selectedSkills,
    selectedLanguages: selectedLanguages,
    setShowEditInternship: setShowEditInternship,
    setTempSelectedLanguages: setTempSelectedLanguages,
    setTempSelectedSkills: setTempSelectedSkills,
    setTempSelectedSpecializations: setTempSelectedSpecializations,
  };

  const advisorInfoProps = {
    fullName: fullName,
    category: category,
    describe: describe,
    summarize: summarize,
    selectedCategories: selectedCategories,
    setShowEditIntro: setShowEditIntro,
    setTempSelectedCategories: setTempSelectedCategories,
  };

  let history = useHistory();
  return (
    <>
      <AlertMessage
        title='Attention'
        description="This is only a temporary save. If you want to secure your changes, once you're done editing, please click the Save Changes button at the top of the page."
        showAlertMessage={showAlertMessage}
        setShowAlertMessage={setShowAlertMessage}
      />
      <UploadingModal show={loaded && (loaded < 100)} />
      <ProfileUpdated
        title='Profile updated'
        description="You've successfully updated your profile."
        showProfileUpdatedMessage={showProfileUpdatedMessage}
        setShowProfileUpdatedMessage={setShowProfileUpdatedMessage}
      />
      <EditIntro {...editIntroProps} />
      <EditBackground {...editBackgroundProps} />
      <EditInternship {...editInternshipProps} />

      <div className="adminProfile my-5" id="adminProfilePage">
        <Container
          fluid
          style={isMobileOnly ? { width: "91.5%" } : { width: "88.88%" }}
          className='p-0'
        >
          <Row>
            <Col lg={6} sm={6} className="col-top-info-container col-6">
              <div className="top-info-container">
                <Col lg={6} sm={8} className='col-8'>
                  <div className="back" onClick={history.goBack} style={{ cursor: 'pointer' }}>
                    <Card.Img
                      src={imagesApiURL + "images/custom/back.png"}
                      className="back-image"
                    />
                    Back
                  </div>
                </Col>
                <Col lg={6} sm={4} className='col-4'>
                  <p className="member-id custom-text-style-16-22 col-4">
                    Member ID {memberId}
                  </p>
                </Col>
              </div>
            </Col>
            <Col lg={6} sm={6} className='col-6'>
              <button
                type="button"
                className="save-changes custom-text-style-14-20"
                onClick={updateProfile}
              >
                Save Changes
              </button>
            </Col>
          </Row>

          <br />
          {isDesktop ?
            <>
              <br />
              <br />
            </>
            : null}

          <Row className="row-custom">
            <Col lg={4} className="logo-img" style={{ height: "270px" }}>
              <LogoImage image={image} setImage={setImage} />
            </Col>

            <Col lg={8}>
              <AdvisorInfo {...advisorInfoProps} />
            </Col>
          </Row>
          <br />

          <Row className="row-custom background">
            <div className="card-custom">
              <BackgroundVideo bgVideo={bgVideo} setBgVideo={setBgVideo} />
              <BackgroundDescription
                background={background}
                setShowEditBackground={setShowEditBackground}
                setTempBackground={setTempBackground}
              />
            </div>
          </Row>
          <br />

          <Row className="row-custom session-communication">
            <Col lg={8}>
              <SessionAndPricing {...sessionAndPricingProps} />
            </Col>
            <Col lg={4}>
              <Communication
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
              />
            </Col>
          </Row>
          <br />

          <Row className="row-custom height-auto custom-top-margin promotions-rating">
            <Col lg={4}>
              <Promotions {...promotionsProps} />

              <Specializations {...specializationsProps} />
            </Col>

            <Col lg={8}>
              <RatingReviews
                scoreAverage={scoreAverage}
                ratingsAndReviews={ratingsAndReviews}
                reviewsNo={reviewsNo}
                reviewsInfo={reviewsInfo}
                check403Error={check403Error}
              />
              <Sessions sessions={sessions ? sessions : []} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
