import React from 'react';
import Login from '../views/pages/Login';
import WizzardNavbar from '../components/navbar/WizzardNavbar';
import bg from "../assets/img/bg/banner2.png";

const WizzardLayout = (props) => {
    const { children, setLoginModalShow, userToken, setUserToken, setUserType, loginModalShow, check403Error } = props;
    return (
        <>
        <div className = "wizzard_wrapper" style={{ backgroundImage: `url(${bg})`}}>
            <WizzardNavbar
                userIsLoggedIn={userToken !== null && userToken !== undefined}
                setLoginModalShow={setLoginModalShow}
                check403Error={check403Error}
            />

            <Login
                setUserToken={setUserToken}
                setLoginModalShow={setLoginModalShow}
                setUserType={setUserType}
                loginModalShow={loginModalShow}
                check403Error={check403Error} />

            {children}
            </div>
        </>
    )
}

export {WizzardLayout}