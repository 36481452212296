import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Button, Modal, Tabs, Tab, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import * as myAppConfig from '../../constants/AppConstants'
import { isDesktop, isIOS, isAndroid, isMobileOnly } from "react-device-detect";
import { useHistory } from 'react-router-dom'
import validator from 'validator'
import googleIcon from '../../assets/icons/google_login_icon.png'
import { GoogleLogin } from 'react-google-login';

let isGoogleSignIn;
let isGoogleSignUp;

export default function Login({ setUserType, setUserToken, loginModalShow, setLoginModalShow, check403Error, hideLogin, hideSignUp, setHideSignUp }) {

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const default_user_type = hideLogin ? myAppConfig.USER_TYPES.ADVISOR : myAppConfig.USER_TYPES.CLIENT;
  const [emailNotFound, setEmailNotFound] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(false);
  const emailNotFoundMessage = 'User not found. Please check your email address';
  const wrongPasswordMessage = 'Wrong password. Please check it again';
  const [showRegisteredMessage, setShowRegisteredMessage] = useState(false);
  const [registerError, setRegisterError] = useState('')
  const [showActivate, setShowActivate] = useState(false);

  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const invalidPasswordMessage = 'The password has to be a minimum of 6 characters long. '

  const [showGoogleSignInUpError, setShowGoogleSignInUpError] = useState(false);
  const history = useHistory();

  const handleLoginSubmit = async e => {
    e.preventDefault();
    isGoogleSignIn = false;
    var loginFormData = new FormData();
    loginFormData.append('email', email);
    loginFormData.append('password', password);
    loginFormData.append('auth_type', myAppConfig.LOGIN_REGISTER_TYPE.DEFAULT);
    await loginUser(loginFormData);
  }

  const handleRegisterSubmit = async e => {
    e.preventDefault();
    isGoogleSignUp = false;
    var registerFormData = new FormData();
    registerFormData.append('email', email);
    registerFormData.append('password', password);
    registerFormData.append('user_type', default_user_type);
    registerFormData.append('device_type', isDesktop ? 'DT' : isIOS ? 'iOS' : isAndroid ? 'Android' : 'Mobile Web');
    registerFormData.append('auth_type', myAppConfig.LOGIN_REGISTER_TYPE.DEFAULT);
    await registerUser(registerFormData);
  }

  async function loginUser(credentials) {
    axios
      .post(myAppConfig.LOG_IN, credentials)
      .then(response => {
        if (response.data.status === 'success') {
          var userType = response.data.user[0].user_type;
          var userToken = response.data.user[0].token;
          var showCompleteProfile;
          localStorage.setItem('userType', userType);
          localStorage.setItem('userToken', userToken);
          localStorage.setItem('profileId', response.data.profile_details[0].id)
          localStorage.setItem('userFullName', response.data.profile_details[0] ? response.data.profile_details[0].full_name ? response.data.profile_details[0].full_name : response.data.profile_details[0].first_name + ' ' + response.data.profile_details[0].last_name : '')
          localStorage.setItem('notification_id', response.data.profile_details[0] ? response.data.profile_details[0].notification_id : "");

          switch (parseInt(userType)) {
            case myAppConfig.USER_TYPES.SUPERADMIN: //superAdmin
              localStorage.setItem("userPassword", password);
              break;
            case myAppConfig.USER_TYPES.ADVISOR: //advisor
              showCompleteProfile = response.data.profile_details && response.data.profile_details[0] && !isNaN(response.data.profile_details[0].status_account) && (parseInt(response.data.profile_details[0].status_account) === myAppConfig.ACCOUNT_STATUS.AWAIT.id || parseInt(response.data.profile_details[0].status_account) === myAppConfig.ACCOUNT_STATUS.PENDING_APPROVE.id) ?
                myAppConfig.LOCAL_STORAGE_VALUES.SHOW_COMPLETE_PROFILE.Values.YES :
                myAppConfig.LOCAL_STORAGE_VALUES.SHOW_COMPLETE_PROFILE.Values.NO;
              localStorage.setItem(myAppConfig.LOCAL_STORAGE_VALUES.SHOW_COMPLETE_PROFILE.Title, showCompleteProfile);
              localStorage.setItem('profileId', response.data.profile_details.length !== 0 ? response.data.profile_details[0].id : '');
              localStorage.setItem('userStatus', response.data.profile_details && response.data.profile_details[0] ? response.data.profile_details[0].offline_online : 1);
              localStorage.setItem("statusAccount", response.data.profile_details && response.data.profile_details[0] ? response.data.profile_details[0].status_account : 0)
              let callPrice = 0, chatPrice = 0, videoPrice = 0;
              if (response.data.profile_details[0]) {
                response.data.profile_details[0].profile_sessionprices.map((price, idx) => {
                  switch (price.session_method_type) {
                    case 1:
                      callPrice = price.price;
                      break;
                    case 2:
                      chatPrice = price.price;
                      break;
                    case 3:
                      videoPrice = price.price;
                      break;
                    default:
                      break;
                  }
                  return null;
                })
              }
              localStorage.setItem('profilePrices', JSON.stringify([callPrice, chatPrice, videoPrice]))
              if (response.data.profile_details[0]) {
                localStorage.setItem('profilePromotions', JSON.stringify([
                  response.data.profile_details[0].freeminutes_value,
                  response.data.profile_details[0].freeminutes_status,
                  response.data.profile_details[0].saleoffer_value,
                  response.data.profile_details[0].saleoffer_status,
                ]))
              }
              break;
            case myAppConfig.USER_TYPES.CLIENT:
              showCompleteProfile = response.data.profile_details && response.data.profile_details[0] && !isNaN(response.data.profile_details[0].complete_profile) && parseInt(response.data.profile_details[0].complete_profile) === 0 ?
                myAppConfig.LOCAL_STORAGE_VALUES.SHOW_COMPLETE_PROFILE.Values.YES :
                myAppConfig.LOCAL_STORAGE_VALUES.SHOW_COMPLETE_PROFILE.Values.NO;
              localStorage.setItem(myAppConfig.LOCAL_STORAGE_VALUES.SHOW_COMPLETE_PROFILE.Title, showCompleteProfile);
              localStorage.setItem('haveAdvisorProfile', response.data.profile_details && response.data.profile_details[0] ? response.data.profile_details[0].have_advisor_profile : 0);
              localStorage.setItem("userName", response.data.profile_details && response.data.profile_details[0] ? response.data.profile_details[0].first_name : '');
              localStorage.setItem("has3mins", response.data.profile_details && response.data.profile_details[0] ? response.data.profile_details[0].have_three_free_minutes : 0);
              break;
            default:
              break;
          }

          if (response.data.profile_details[0].logo_image) {
            localStorage.setItem('userLogoImg', response.data.profile_details[0].logo_image)
          }

          setUserType(userType)
          setUserToken(userToken);

          localStorage.setItem('userEmail', response.data.user ? response.data.user[0].email : '');
          setLoginModalShow(false);
          if (setHideSignUp) {
            setHideSignUp(true);
          }
          if (queryParams.has('logged_out') || queryParams.has('is_activated')) {
            if (queryParams.has('logged_out')) {
              queryParams.delete('logged_out')
            }
            else {
              queryParams.delete('is_activated')
            }
            history.replace({
              search: queryParams.toString(),
            });
          }

          if(window.location.pathname === "/"){
            window.location.reload();
          }
        }
        else {
          if (!isGoogleSignIn) {
            if (response.data.message === 'The email address is wrong!') {
              setEmailNotFound(true)
            }
            else {
              setEmailNotFound(false);
            }
            if (response.data.message === 'The password is wrong!') {
              setWrongPassword(true)
            }
            else {
              setWrongPassword(false)
            }
            if (response.data.message === 'The user profile not found!') {
              setShowActivate(true);
            }
          }
          else {
            setEmailNotFound(false);
            setWrongPassword(false);
            setShowGoogleSignInUpError(true);
          }
          console.log(response.data);
        }
      }
      ).catch(err => check403Error(err));

  }

  async function registerUser(credentials) {
    if (password && !validatePassword(password)) {
      setInvalidPassword(true);
      return;
    }
    axios
      .post(myAppConfig.REGISTER, credentials, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
      .then(async (response) => {
        if (response.data.status === 'success') {
          if (!isGoogleSignUp) {
          setShowRegisteredMessage(true);
          }
          else {
            isGoogleSignIn = true;
            isGoogleSignUp = false;
            var loginFormData = new FormData();
            loginFormData.append('email', credentials.get('email'));
            loginFormData.append('accessToken', credentials.get('accessToken'));
            loginFormData.append('auth_type', myAppConfig.LOGIN_REGISTER_TYPE.GOOGLE);
            await loginUser(loginFormData);
          }
          setRegisterError('');
        }
        else {
          console.log(response.data)
          if (!isGoogleSignUp) {
            setRegisterError(response.data.message);
          }
          else {
            setRegisterError('');
            setShowGoogleSignInUpError(true);
          }
        }
      }
      ).catch(err => check403Error(err));
  }

  const validatePassword = (password) => {
    if (validator.isStrongPassword(password, {
      minLength: 6, minLowercase: 0,
      minUppercase: 0, minNumbers: 0, minSymbols: 0
    })) {
      return true;
    }
    return false;
  }

  const responseGoogle = (response) => {
    console.log(response);
  }

  const signUpWithGoogle = async (response) => {
    // console.log(response);
    isGoogleSignIn = false;
    isGoogleSignUp = true;
    var registerFormData = new FormData();
    registerFormData.append('email', response.profileObj?.email);
    registerFormData.append('accessToken', response.accessToken);
    registerFormData.append('user_type', default_user_type);
    registerFormData.append('device_type', isDesktop ? 'DT' : isIOS ? 'iOS' : isAndroid ? 'Android' : 'Mobile Web');
    registerFormData.append('auth_type', myAppConfig.LOGIN_REGISTER_TYPE.GOOGLE);
    await registerUser(registerFormData);
  }

  const loginWithGoogle = async (response) => {
    // console.log(response);
    isGoogleSignIn = true;
    isGoogleSignUp = false;
    var loginFormData = new FormData();
    loginFormData.append('email', response.profileObj?.email);
    loginFormData.append('accessToken', response.accessToken);
    loginFormData.append('auth_type', myAppConfig.LOGIN_REGISTER_TYPE.GOOGLE);
    await loginUser(loginFormData);
  }

  return (
    <>
      <Modal
        show={showActivate}
        onHide={() => setShowActivate(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="loginModal activate"
        dialogClassName={isMobileOnly ? "modal-fullscreen modal-backdrop" : ""}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="registerMessage title">Account not activated</div>
          <div className="registerMessage">Your account has not been activated yet. Please check your email for the activation link.</div>)

        </Modal.Body>
      </Modal>
      {showRegisteredMessage ?
        <Modal
          show={showRegisteredMessage}
          onHide={() => {
            setShowRegisteredMessage(false);
            // if (isGoogleSignUp) {
            //   setLoginModalShow(true);
            // }
            // else {
              setLoginModalShow(false);
            // }
            if (setHideSignUp) {
              setHideSignUp(true);
            }
            if (queryParams.has('logged_out') || queryParams.has('is_activated')) {
              if (queryParams.has('logged_out')) {
                queryParams.delete('logged_out')
              }
              else {
                queryParams.delete('is_activated')
              }
              history.replace({
                search: queryParams.toString(),
              })
            }
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="loginModal"
          dialogClassName={isMobileOnly ? "modal-fullscreen modal-backdrop" : ""}
          backdrop='static'
          keyboard={false}
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <div className="registerMessage title">Welcome to ADVIA</div>
            <div className="registerMessage">{isGoogleSignUp ? 'Please sign in to access your account.' : 'A verification link has been sent to your mail to verify your email.'}</div>
          </Modal.Body>
        </Modal>
        :
        <>
          <Modal
            show={showGoogleSignInUpError}
            onHide={() => setShowGoogleSignInUpError(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="loginModal"
            dialogClassName={isMobileOnly ? "modal-fullscreen modal-backdrop" : ""}
            backdrop='static'
            keyboard={false}
          >
            <Modal.Header closeButton style={{ background: '#233558' }}>
            </Modal.Header>
            <Modal.Body style={{ background: '#233558' }}>
              <div className="registerMessage title">{isGoogleSignIn ? 'User not found!' : 'Your user already exists inside the Advia Platform'}</div>
              <div className="registerMessage">{isGoogleSignIn ? 'If you wish to use your Google account with us, please sign up first.' : 'Please sign in to access your account. If you forgot your password use the "Forgot password?" button on the Sign In tab.'}</div>
            </Modal.Body>
          </Modal>
          <Modal
            show={loginModalShow}
            onHide={() => {
              setLoginModalShow(false);
              if (setHideSignUp) {
                setHideSignUp(true);
              }
              if (queryParams.has('logged_out') || queryParams.has('is_activated')) {
                if (queryParams.has('logged_out')) {
                  queryParams.delete('logged_out')
                }
                else {
                  queryParams.delete('is_activated')
                }
                history.replace({
                  search: queryParams.toString(),
                })
              }
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="loginModal"
            dialogClassName={isMobileOnly ? "modal-fullscreen modal-backdrop" : ""}
            backdrop='static'
            keyboard={false}
          >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className={hideLogin || hideSignUp ? 'full' : ''}>

              <Tabs defaultActiveKey={hideLogin ? "signup" : "signin"} id="signinTabs" className="mb-3">
                {!hideLogin ?
                  <Tab eventKey="signin" title="Sign In">
                    <Form className="px-lg-5 px-md-5 px-sm-3 px-3" onSubmit={handleLoginSubmit}>
                      <Form.Group className="mb-4 mt-4" controlId="formBasicEmail1">
                        <Form.Label>Enter email address</Form.Label>
                        <Form.Control type="email" onChange={e => setEmail(e.target.value)} required={true} isInvalid={emailNotFound} />
                        <Form.Control.Feedback type='invalid'> {emailNotFoundMessage}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicPassword1" style={{ position: 'relative' }}>
                        <Form.Label>Enter password</Form.Label>
                        {!wrongPassword ?
                          showPass ?
                            <Icon.EyeOff className='eyeIcon' onClick={() => setShowPass(false)} /> :
                            <Icon.Eye className='eyeIcon' onClick={() => setShowPass(true)} /> :
                          null
                        }
                        <Form.Control type={showPass ? "text" : "password"} onChange={e => { setPassword(e.target.value); if (wrongPassword) setWrongPassword(false) }} required={true} isInvalid={wrongPassword} />
                        <Form.Control.Feedback type='invalid'> {wrongPasswordMessage}
                        </Form.Control.Feedback>
                        <Link to="/forgot-password" onClick={() => {
                          setLoginModalShow(false);
                          if (setHideSignUp) {
                            setHideSignUp(true);
                          }
                          if (queryParams.has('logged_out') || queryParams.has('is_activated')) {
                            if (queryParams.has('logged_out')) {
                              queryParams.delete('logged_out')
                            }
                            else {
                              queryParams.delete('is_activated')
                            }
                            history.replace({
                              search: queryParams.toString(),
                            })
                          }
                        }} className="mt-3 d-block">Forgot password?</Link>

                      </Form.Group>
                      <Button variant="primary" type="submit" className="signbutton my-3"><span className="me-auto" style={{ float: 'left' }}><Icon.Mail width={22} /></span> Sign In</Button>
                    </Form>
                    <div className="px-lg-5 px-md-5 px-sm-3 px-3 mt-1">
                      <GoogleLogin
                        clientId={myAppConfig.GOOGLE_CLIENT_ID}
                        onSuccess={loginWithGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                        className='google-btn'
                        icon={false}
                      >
                        <img src={googleIcon} alt='google-icon' />
                        <span> Log In with Google</span>
                      </GoogleLogin>
                    </div>
                  </Tab>
                  :
                  <></>}
                {!hideSignUp ?
                  <Tab eventKey="signup" title="Sign Up">
                    <div className='my-4'>
                      <div className='title px-lg-5 px-md-5 px-sm-3 px-3'>Let's create your account</div>
                      <div className='subtitle px-lg-5 px-md-5 px-sm-3 px-3'>Satisfaction 100% Guaranteed</div>
                    </div>
                    <Form className="px-lg-5 px-md-5 px-sm-3 px-3" onSubmit={handleRegisterSubmit}>
                      <Form.Group className="mb-4 mt-4" controlId="formBasicEmail2">
                        <Form.Label>Enter email address</Form.Label>
                        <Form.Control type="email" onChange={e => setEmail(e.target.value)} required={true} isInvalid={!(registerError === '')} />
                        <Form.Control.Feedback type='invalid'>
                          {registerError}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicPassword2" style={{ position: 'relative' }}>
                        <Form.Label>Enter password</Form.Label>
                        {!invalidPassword ?
                          showPass2 ?
                            <Icon.EyeOff className='eyeIcon' onClick={() => setShowPass2(false)} /> :
                            <Icon.Eye className='eyeIcon' onClick={() => setShowPass2(true)} /> :
                          null
                        }
                        <Form.Control type={showPass2 ? "text" : "password"} onChange={e => { setPassword(e.target.value); if (invalidPassword) setInvalidPassword(false) }} required={true} isInvalid={invalidPassword} />
                        <Form.Control.Feedback type='invalid'> {invalidPasswordMessage}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3 d-flex flex-row-reverse" controlId="formBasicCheckbox">
                        <Form.Label className='ms-2'>I have already read and agree to the <a href={hideLogin ? "/terms-and-conditions/advisors" : "/terms-and-conditions/"} target='_blank' style={{ color: 'white' }}>User Agreement</a> and <a href="https://advia.io/privacy-policy/" target='_blank' style={{ color: 'white' }}>Privacy Policy</a></Form.Label>
                        <Form.Check type="checkbox" required={true} />
                      </Form.Group>

                      <Button variant="primary" type="submit" className="signbutton my-3"><span className="me-auto" style={{ float: 'left' }}><Icon.Mail width={22} /></span> Sign Up</Button>
                    </Form>
                    <div className="px-lg-5 px-md-5 px-sm-3 px-3 mt-1">
                      <GoogleLogin
                        clientId={myAppConfig.GOOGLE_CLIENT_ID}
                        onSuccess={signUpWithGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                        className='google-btn'
                        icon={false}
                      >
                        <img src={googleIcon} alt='google-icon' />
                        <span> Sign Up with Google</span>
                      </GoogleLogin>
                    </div>
                  </Tab>
                  : null}
              </Tabs>

            </Modal.Body>
          </Modal>
        </>
      }

    </>
  )

}


Login.propTypes = {
  setUserType: PropTypes.func,
  setUserToken: PropTypes.func,
  setLoginModalShow: PropTypes.func,
  loginModalShow: PropTypes.bool,
  check403Error: PropTypes.func,
  hideLogin: PropTypes.bool,
  hideSignUp: PropTypes.bool,
  setHideSignUp: PropTypes.func
}