import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown, Row, Col, Button, Form, FormControl, Dropdown } from 'react-bootstrap';
import '../../styles/_layouts.scss';
import logo from '../../assets/icons/logo.png';
import searchIcon from '../../assets/icons/search_icon.png'
import axios from 'axios';

import * as myAppConfig from '../../constants/AppConstants'

import { isMobileOnly, isMobile } from 'react-device-detect'

import PropTypes from 'prop-types';
import { imagesApiURL } from '../../constants/AppConstants';
import { Link } from 'react-router-dom';

import MobileNavbar from './MobileNavbar';
import MobileSearch from '../modals/MobileSearch';
import ClientDropDown from './dropDowns/ClientDropDown';

import user from '../../assets/img/user.png'
function ClientNavbar({ setLoginModalShow, userToken, categories, check403Error }) {

  const [searchResultAdvisors, setSearchResultAdvisors] = useState([]);
  const [searchResultCategories, setSearchResultCategories] = useState([]);
  const [showResults, setShowResults] = useState(false)
  const [show, setShow] = useState(false);
  const [showMobileSearch, setShowMobileSearch] = useState(false);

  const showPopUpSearch = e => {
    e.preventDefault();
    setShowMobileSearch(true)
  }

  const navDropdownTitle = (<span> Categories</span>);

  const urlStrings = window.location.pathname.split("/");
  const search = (e) => {
    var searchString = e.target.value;
    axios.get(myAppConfig.CUSTOM_SEARCH,
      {
        params: { "search": searchString },
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        if (response.data.status === 'success') {
          setSearchResultAdvisors(response.data.results[0] ? response.data.results[0].advisors : [])
          setSearchResultCategories(response.data.results[1] ? response.data.results[1].categories : [])
        }
      }).catch(err => check403Error(err));
  }

  const mobileSearchProps = {
    showMobileSearch: showMobileSearch,
    setShowMobileSearch: setShowMobileSearch,
    search: search,
    showResults: showResults,
    setShowResults: setShowResults,
    searchResultAdvisors: searchResultAdvisors,
    setSearchResultAdvisors: setSearchResultAdvisors,
    searchResultCategories: searchResultCategories,
    setSearchResultCategories: setSearchResultCategories
  }

  const hasTransparentNavbar =
    urlStrings.includes('category') ||
    urlStrings.includes('wizard-results') ||
    urlStrings.includes('advisor-profile') ||
    urlStrings.includes('saved-advisors') ||
    urlStrings.includes('payment-methods')

  return (
    <>
      <MobileSearch {...mobileSearchProps} />
      <Navbar expand="lg" className="navbar-dark" style={hasTransparentNavbar ? { background: 'transparent' } : {}}>
        <div className='special-mobile col-md-6 justify-content-start'>
          <Navbar.Brand as={Link} to="/" ><img src={logo} alt="logo" /></Navbar.Brand>
          <Navbar.Toggle onClick={() => setShow(true)} style={isMobileOnly ? { display: 'block' } : { display: 'none' }} />

          {isMobileOnly ?
            <MobileNavbar categories={categories} show={show} setShow={setShow} navDropdownTitle={navDropdownTitle} logo={logo} />
            :
            <Nav className="me-auto my-2 my-lg-0">
              <NavDropdown title={navDropdownTitle} className='categories'>
                <Row>
                  <Col lg={6}>
                    {categories.slice(0, 4).map((category, idx) => {
                      let urlToOpen = "/category/" + category.title;
                      return (
                        <NavDropdown.Item as={Link} to={urlToOpen} key={idx}><img src={imagesApiURL + category.icon} alt="category icon" /> {category.title}</NavDropdown.Item>
                      )
                    })}
                  </Col>
                  <Col lg={6}>
                    {categories.slice(4).map((category, idx) => {
                      let urlToOpen = "/category/" + category.title;
                      return (
                        <NavDropdown.Item as={Link} to={urlToOpen} key={idx}><img src={imagesApiURL + category.icon} alt="category icon" /> {category.title}</NavDropdown.Item>
                      )
                    })}
                  </Col>
                </Row>
              </NavDropdown>
              <Nav.Link href="https://horoscope.advia.io/">Horoscope</Nav.Link>
              <Nav.Link href="https://horoscope.advia.io/articles/">Articles</Nav.Link>
            </Nav>
          }
        </div>
        <Nav className='col-md-6 justify-content-end'>
          <div className='search' onClick={isMobile ? showPopUpSearch : null} >
            {isMobileOnly ?
              <div className="d-flex">
                <div className="searchInput" />
              </div>
              :
              <Form className="d-flex">
                <FormControl
                  type="search"
                  placeholder={isMobile ? '' : "Search for Advisor..."}
                  className="searchInput"
                  aria-label="Search"
                  onChange={search}
                  onFocus={() => setShowResults(true)}
                  onBlur={() => setTimeout(() => setShowResults(false), 500)}
                  disabled={isMobile ? true : false}
                />
              </Form>}
            {!isMobile ?
              <Dropdown.Menu show={showResults && (searchResultAdvisors.length > 0 || searchResultCategories.length > 0)}
                className="dashboard-dropdown" alignRight>
                {searchResultAdvisors.length > 0 ?
                  <>
                    <div className='title'>Advisors</div>
                    {searchResultAdvisors.map((_item, idx) => (
                      <Dropdown.Item key={idx} as={Link} to={'/advisor-profile/' + _item.full_name} className="dashboard-dropdown-menu-item dashboard-dropdown-menu-item-unselected-color" eventKey={idx}><img alt='advisor logo' src={_item.logo_image ? imagesApiURL + _item.logo_image : user} />{_item.full_name}</Dropdown.Item>
                    ))}
                  </>
                  :
                  null
                }
                {searchResultCategories.length > 0 ?
                  <>
                    <div className='title' style={searchResultAdvisors.length > 0 ? { marginTop: '32px' } : {}}>Other results</div>
                    {searchResultCategories.map((_item, idx) => (
                      <Dropdown.Item key={idx} as={Link} to={'/category/' + _item.title + '/?search=true'} className="dashboard-dropdown-menu-item dashboard-dropdown-menu-item-unselected-color" eventKey={idx}><img alt='search logo' src={searchIcon} />{_item.title}</Dropdown.Item>
                    ))}
                  </>
                  :
                  null
                }
              </Dropdown.Menu>
              :
              null
            }
          </div>
          {userToken == null ?
            <Button className="loginButton" onClick={() => { setLoginModalShow(true) }}>Sign in / Sign up</Button>
            :
            <ClientDropDown check403Error={check403Error} />
          }
        </Nav>
      </Navbar>

    </>
  );
}

export default ClientNavbar;

ClientNavbar.propTypes = {
  setLoginModalShow: PropTypes.func,
  userToken: PropTypes.string,
  categories: PropTypes.array,
  check403Error: PropTypes.func
}