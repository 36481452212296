import { Card } from "react-bootstrap"
import arrowRightIcon from '../../assets/icons/arrows/arrow_right_icon.png';
import parse from 'html-react-parser'

const Help = (props) => {
    const {
        setHelpTabSelected, setShowCustomerSupportModal, helpTabSelected, helpContent, setShowFindMoreModal
    } = props

    return (
        <div className='account pb-3 help' >
            {
                helpTabSelected === 0 ?
                    <>
                        <h4 className="pb-3 pt-5">Help</h4>
                        <div className='label'>General</div>
                        <div style={{ borderRadius: '14px', overflow: 'hidden', marginBottom: '2rem' }}>
                            <Card style={{ borderBottom: '1px solid #3a4e76' }}>
                                <div className='value' onClick={() => setHelpTabSelected(1)}>About us <img alt='arrow right' src={arrowRightIcon} /></div>
                            </Card>
                            <Card>
                                <div className='value' onClick={() => setShowFindMoreModal(true)}>Tutorials <img alt='arrow right' src={arrowRightIcon} /></div>
                            </Card>
                        </div>
                        <div className='label'>Support</div>
                        <div style={{ borderRadius: '14px', overflow: 'hidden' }}>
                            <Card style={{ borderBottom: '1px solid #3a4e76' }}>
                                <div className='value' onClick={() => setShowCustomerSupportModal(true)}>Customer Support <img alt='arrow right' src={arrowRightIcon} /></div>
                            </Card>
                            <Card style={{ borderBottom: '1px solid #3a4e76' }}>
                                <div className='value' onClick={() => setHelpTabSelected(4)}>Privacy policy <img alt='arrow right' src={arrowRightIcon} /></div>
                            </Card>
                            <Card>
                                <div className='value' onClick={() => setHelpTabSelected(5)}>Term of service <img alt='arrow right' src={arrowRightIcon} /></div>
                            </Card>
                        </div>
                    </>
                    :
                    <>
                        <h4 className="custom">{helpContent[helpTabSelected - 1].title}</h4>
                        <div className='label'>{parse(helpContent[helpTabSelected - 1].content)}</div>
                    </>
            }
        </div >
    )
}

export default Help