import React from "react";
import { Row, Col } from "react-bootstrap";
import Moment from "moment";
import "../../styles/sessionsInfo.scss";
import * as myAppConfig from "../../constants/AppConstants";
import icon_call from "../../assets/icons/call_icon.png";
import icon_chat from "../../assets/icons/chat_icon.png";
import icon_video from "../../assets/icons/video_icon.png";
import icon_logo from "../../assets/img/user.png";

const SessionsInfoComponent = (props) => {
  var var_date_of_birth =
    props.conversation.clientProfile[0] === undefined
      ? ""
      : Date.parse(props.conversation.clientProfile[0].date_of_birth);
  const date_of_birth = Moment(var_date_of_birth).format("MMM. DD YYYY");
  const time_of_birth = props.conversation.clientProfile[0].time_of_birth;

  const sessions =
    props.conversation.sessions === undefined
      ? props.conversation.sessionsHistory
      : props.conversation.sessions.slice(0).reverse();

  var last_reading = "";
  if (sessions.length > 0) {
    last_reading =
      "Last reading: " +
      Moment(Date.parse(sessions[0].start_to.replace(/-/g, "/"))).format("MMM. DD YYYY HH:mm a");
  }

  const logo_image = props.conversation.clientProfile[0].logo_image;

  const client_id =
    props.conversation.client_id === undefined
      ? props.conversation.clientProfile[0].id
      : props.conversation.client_id;

  const renderConversationTypeIcon = (conversation_type) => {
    const conversationLogo =
      conversation_type === myAppConfig.CONVERSATION_TYPE.CHAT.id
        ? icon_chat
        : conversation_type === myAppConfig.CONVERSATION_TYPE.VOICE.id
        ? icon_call
        : icon_video;

    return conversationLogo;
  };

  return (
    <div className="sessions-info-container">
      <Row className="top-info">
        <Col lg={6}></Col>
        <Col lg={6} className="client-id">
          Client ID {client_id}
        </Col>
      </Row>

      <Row>
        <div className="user-info-container">
          <img
            className="user-logo"
            src={
              logo_image === "" || logo_image === null
                ? icon_logo
                : myAppConfig.imagesApiURL + logo_image
            }
            alt="user logo"
          />

          <p className="user-name">
            {props.conversation.clientProfile[0].first_name +
              " " +
              props.conversation.clientProfile[0].last_name}
          </p>
          <p className="last-reading">{last_reading}</p>

          <p className="date-of-birth">
            Date of birth: {date_of_birth} <br />
            {time_of_birth !== isNaN && time_of_birth !== null
              ? "Time of birth: " + time_of_birth
              : ""}
          </p>
        </div>
      </Row>

      <Row className="session-container">
        <p className="client-readings">Client all reading</p>

        <hr />
        <Row>
          <Col lg={6} className="sessions-header">
            Date
          </Col>
          <Col lg={6} className="sessions-header">
            Duration
          </Col>
        </Row>

        <hr />

        {sessions.map((session, idx) => (
          <>
            <Row key={idx}>
              <Col lg={6} className="sessions-rows">
                <img
                  className="session-logo"
                  src={renderConversationTypeIcon(session.conversation_type)}
                  alt="session logo"
                />
                {Moment(Date.parse(session.start_to.replace(/-/g, "/"))).format(
                  "MMM. DD YYYY HH:mm a"
                )}
              </Col>
              <Col lg={6} className="sessions-rows">
                {session.duration}
              </Col>
            </Row>
            <hr />
          </>
        ))}
      </Row>
    </div>
  );
};

export default SessionsInfoComponent;
