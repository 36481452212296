
import React from 'react'
import { Button, Row } from 'react-bootstrap';
import styles from '../../../../styles/wizard.module.scss';

const TITLE_VALUE = "wizard will find the perfect expert for you";
const SUBTITLE_VALUE = "Answer a few simple questions and our AI-powered Wizard will work it's magic and find the best advisor for you";
const SUBTITLE_VALUE_LOGGED = "Answer a few simple questions and our Wizard will work it's magic and find the best advisor for you";
const FIND_MY_EXPERT = "Find my expert";
const FOOTER_VALUE = "©2021 Advia. All rights reserved";

const First_step = (props) => {
    function Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return (
        <div>
        <div className={styles.containerCenterWizard}>
            <Row>
                    <p className={styles.labelTitleWizard}>{Capitalize((props.userIsLoggedIn ? (props.userName + ", our ") : "our unique ") + TITLE_VALUE)}</p>
            </Row>
            <Row>
                    <p className={styles.labelSubtitleWizard}>{props.userIsLoggedIn ? SUBTITLE_VALUE_LOGGED : SUBTITLE_VALUE}</p>
            </Row>
            <Row className={styles.hideForMobile}>
                <Button
                    variant="primary"
                    type="submit"
                    className={styles.buttonFindWizard}
                    onClick={() => props.onClick()}
                >
                    {FIND_MY_EXPERT}
                </Button>
            </Row>
        </div>

        <Row className={styles.footerDiv}>
            <p className={styles.modalFooterText}>{FOOTER_VALUE}</p>
            <div className={styles.visibleForMobile}>
                <Button
                    variant="primary"
                    type="submit"
                    className={styles.buttonFindWizardMobile}
                    onClick={() => props.onClick()}
                >
                    {FIND_MY_EXPERT}
                </Button>
            </div>
        </Row>
    </div>
    );
}

export default First_step
