import { Modal, Form, Button } from "react-bootstrap";
import { MultiSelect} from "react-multi-select-component";
import { isMobileOnly } from 'react-device-detect'

const EditIntro = (props) => {
    const {
        showEditIntro, setShowEditIntro,
        tempFullName, setTempFullName,
        tempFirstName, setTempFirstName,
        tempLastName, setTempLastName,
        tempSelectedCategories, setTempSelectedCategories,
        setTempDescribe,
        setTempSummarize,
        onIntroEdited, homePageDetails,
        fullName, firstName, lastName, describe, summarize } = props

    function changeFirstName(ev) {
        if ((tempFullName === tempFirstName + ' ' + tempLastName) || (!tempFullName)) {
            setTempFullName(ev.target.value.trim() + ' ' + tempLastName)
          }
        setTempFirstName(ev.target.value.trim());
      }
  
    function changeLastName(ev) {
        if ((tempFullName === tempFirstName + ' ' + tempLastName) || (!tempFullName)) {
            setTempFullName(tempFirstName + ' ' + ev.target.value.trim())
          }
        setTempLastName(ev.target.value.trim());
      }
  
    function changeFullName(ev) {
        setTempFullName(ev.target.value);
    }

    function changeDescribe(ev){
        setTempDescribe(ev.target.value);
    }
  
    function changeSummarize(ev){
        setTempSummarize(ev.target.value);
    }

    return(
        <Modal
    show={showEditIntro}
    onHide={() => {setShowEditIntro(false); setTempFullName(fullName); setTempFirstName(firstName); setTempLastName(lastName);}}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className="editModal"
            dialogClassName={isMobileOnly ? "modal-fullscreen modal-backdrop" : ""}
            backdrop='static'
            keyboard={false}
    >
    <Modal.Header closeButton>
    </Modal.Header>
    <Modal.Body>
    <div className="title">Edit Intro</div>
    <Form className="px-lg-5 px-md-5 px-sm-3 px-3" onSubmit={onIntroEdited}>
    <Form.Group controlId="formFirstName">
            <Form.Label>First name</Form.Label>
                        <Form.Control name='first_name' type="text" className="custom_input" defaultValue={firstName} onChange={changeFirstName} required pattern=".*\S+.*" title="This field cannot be empty" />
        </Form.Group>
        <Form.Group controlId="formLastName">
            <Form.Label>Last name</Form.Label>
                        <Form.Control name='last_name' type="text" className="custom_input" defaultValue={lastName} onChange={changeLastName} required pattern=".*\S+.*" title="This field cannot be empty" />
        </Form.Group>
        <Form.Group controlId="formFullName">
            <Form.Label>Full name / Spiritual name</Form.Label>
                        <Form.Control name='full_name' type="text" className="custom_input" value={!tempFullName ? '' : tempFullName} onChange={changeFullName} required pattern=".*\S+.*" title="This field cannot be empty" />
        </Form.Group>
        <Form.Group controlId="formCategory">
            <Form.Label>Choose Category</Form.Label>
            <MultiSelect
            className="custom_input"
            disableSearch = {true}
            hasSelectAll = {false}
            value={tempSelectedCategories}
            options={[{label : 'All categories', value: 'All categories', disabled: true}].concat(homePageDetails.categories.map((category, idx) => {
                var categ =  {};
                categ.label = category.title;
                categ.value = category.id;
                return categ;
                }
            )
            )}
            onChange={setTempSelectedCategories}
            labelledBy="Category"
            overrideStrings = {{
                "selectSomeItems": "Category",
                "allItemsAreSelected": "All categories selected."
            }}
             />
        </Form.Group>
        <Form.Group controlId="formDescribe">
            <Form.Label>Describe your services.<br/>Example: Get relationship advice from a love psychic.</Form.Label>
            <Form.Control name="describe_your_services" type="text" className="custom_input" defaultValue = {describe} onChange = {changeDescribe}/>
        </Form.Group>

        <Form.Group controlId="formSummarize">
            <Form.Label>Summarize your qualifications to entice customers scrolling to select your listing.<br/><br/>Example: Powerful and accurate answers to your relationship questions. I've guided clients through difficult situations such as breakups, separation, and divorce.</Form.Label>
            <Form.Control name="summarize_your_qualifications" as="textarea" style={{resize: 'none'}} rows={7} className="custom_input" defaultValue = {summarize} onChange = {changeSummarize} />
            <Form.Label style={{marginTop: '0.5rem'}}>(Required) Listing sales pitch must be between 25 and 225 characters.</Form.Label>
        </Form.Group>

        <Button type="submit" className="saveButton mb-5">Save</Button>
    </Form>
        </Modal.Body>
    </Modal>
    )
}

export default EditIntro