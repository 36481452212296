
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import pattern from '../assets/img/bg/mobile_search_pattern.png'
import arrow from '../assets/icons/arrows/arrow_left_icon.png'

const MobileUserProfileTabsLayout = (props) => {
    const { children, show, setShow, helpTabSelected, setHelpTabSelected } = props;
    return (
        <>
            <Modal
                show={show}
                onHide={() => { setShow(false) }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="editModal account findMore profile"
                dialogClassName="modal-fullscreen modal-backdrop mobileSearch"
                backdrop='static'
                keyboard={false}
            >
                <Modal.Header>
                    <Button className="modal-cancel-button-custom"
                        onClick={() => helpTabSelected ? setHelpTabSelected(0) : setShow(false)}
                    >
                        <img src={arrow} alt='arrow' />
                    </Button>
                    <div className='title'>{children.props.title}</div>
                </Modal.Header>
                <img src={pattern} alt='banner' className='banner' />
                <Modal.Body>
                    {children}
                </Modal.Body>
            </Modal>
        </>
    )
}

export { MobileUserProfileTabsLayout }