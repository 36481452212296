import BackgroundText from "../../../components/aboutAdvia/BackgroundText"
import aboutAdvia_banner from '../../../assets/img/bg/about_advia_banner.png'
import aboutAdvia_banner2 from '../../../assets/img/bg/about_advia_banner2.png'
import aboutAdvia_banner_mobile from '../../../assets/img/bg/about_advia_banner_mobile.png'
import aboutAdvia_banner_mobile2 from '../../../assets/img/bg/about_advia_banner_mobile2.png'
import TextImage from "../../../components/aboutAdvia/TextImage"
import IconsList from "../../../components/aboutAdvia/IconsList"
import { isMobileOnly } from "react-device-detect"
import { aboutUs } from "../../../constants"

const AboutAdvia = (props) => {
    return (
        <>
            <BackgroundText backgroundImg={aboutAdvia_banner} mobileBackgroundImg={aboutAdvia_banner_mobile} title={aboutUs[0].title} subtitle={aboutUs[0].subtitle} description={aboutUs[0].description} />
            <TextImage />
            <IconsList />
            <div style={isMobileOnly ? { fontSize: '14px', lineHeight: '34px', fontWeight: '400', padding: '30px 25px', color: 'white', textAlign: 'center' } : { fontSize: '20px', fontWeight: '400', lineHeight: '34px', padding: '58px 121px 57px 139px', color: 'white', textAlign: 'center' }}>
                {aboutUs[3].text}
            </div>
            <BackgroundText backgroundImg={aboutAdvia_banner2} mobileBackgroundImg={aboutAdvia_banner_mobile2} title={aboutUs[4].title} subtitle={aboutUs[4].subtitle} description={aboutUs[4].description} />
        </>
    )
}

export default AboutAdvia