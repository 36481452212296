import becomePsychic_banner from '../../../assets/img/bg/becomePsychic_banner.png'
import becomePsychic_banner1 from '../../../assets/img/bg/becomePsychic_banner1.png'
import BackgroundText from "../../../components/becomePsychic/BackgroundText"
import AboutUs from "../../../components/becomePsychic/AboutUs"
import TypesOfAdvisors from "../../../components/becomePsychic/TypesOfAdvisors"
import WhyAdvia from "../../../components/becomePsychic/WhyAdvia"

const BecomeAPsychic = (props) => {
    const { categories, setHomePageDetails, setLoginModalShow, setHideSignUp } = props
    const list = ["Access to thousands of new users monthly", "Dedicated support from the Advisor success team", "Become a member of a growing community of top-tier, professional, dedicated, passionate, and talented advisors"]
    return (
        <>
            <BackgroundText setHideSignUp={setHideSignUp} setLoginModalShow={setLoginModalShow} backgroundImg={becomePsychic_banner} titleFirstLine="Provide your psychic" titleSecondLine="services on Advia" subtitle="The next online expert advisors platform" />
            <AboutUs />
            <BackgroundText setHideSignUp={setHideSignUp} setLoginModalShow={setLoginModalShow} backgroundImg={becomePsychic_banner1} titleFirstLine="Open an account and begin" titleSecondLine="your journey with Advia" list={list} />
            <TypesOfAdvisors setHomePageDetails={setHomePageDetails} categories={categories} />
            <WhyAdvia />
        </>
    )
}

export default BecomeAPsychic