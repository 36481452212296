import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Card, Row, Col } from "react-bootstrap";
import "../../../styles/ratingAndReviews.scss";
import { imagesApiURL } from "../../../constants/AppConstants";
import { isMobileOnly } from "react-device-detect";
import * as myAppConfig from "../../../constants/AppConstants";

export default function Sessions(props) {
  const maxToDisplay = 6;
  const [noToDisplay, setNoToDisplay] = useState(maxToDisplay);
  let history = useHistory();
  const sessions = props.reviews;

  return (
    <div className="rating-and-reviews-container sessions">
      <Container
        fluid
        className="main-container p-0"
        style={isMobileOnly ? { width: "91.5%" } : { width: "88.88%" }}
      >
        <div className="back" onClick={history.goBack}>
          <Card.Img
            src={imagesApiURL + "images/custom/back.png"}
            className="back-image"
          />{" "}
          Back
        </div>

        <Card className="card-container">
          <div className="card-margins">
            <Row>
              <Col lg={6}>
                <p className="reviews-title">Sessions</p>
              </Col>
              <Col lg={6}>
                <div className="rating-images">
                  <p
                    className="rating-amount-note custom-text-style-14-20"
                    style={{ color: "#19AAA1" }}
                  >
                    {sessions.length}
                  </p>
                  <p className="rating-amount-note custom-text-style-14-20">
                    Sessions
                  </p>
                </div>
              </Col>
            </Row>

            <div>
              {sessions.map((session, idx) =>
                idx < noToDisplay ? (
                  <>
                    <Row className="session">
                      <Col className="rating-left d-flex col-6">
                        <Card.Img src={imagesApiURL + session.clientLogo} />
                        <div className="d-flex flex-column justify-content-center">
                          <p className="review-name custom-text-style-16-22 text-white">
                            {session.clientName}
                          </p>
                          {/* <p className="review-ago custom-text-style-16-22 mb-0 text-white">{session.session[0].last_message}</p> */}
                          <p className="review-ago custom-text-style-16-22 mb-0 text-white">
                            <span style={{ textTransform: "capitalize" }}>
                              {session.conversation_type ===
                              myAppConfig.CONVERSATION_TYPE.CHAT.id
                                ? myAppConfig.CONVERSATION_TYPE.CHAT.name
                                : myAppConfig.CONVERSATION_TYPE.VOICE.id
                                ? myAppConfig.CONVERSATION_TYPE.VOICE.name
                                : myAppConfig.CONVERSATION_TYPE.VIDEO.name}
                            </span>
                            &nbsp;session
                          </p>
                        </div>
                      </Col>
                      <Col className="rating-right col-6">
                        <button
                          type="button"
                          className="custom-button-text mb-4 mt-4"
                          style={{ padding: "10px 18px" }}
                          onClick={() =>
                            (window.location.href =
                              "/station?" +
                              (session.conversation_type ===
                              myAppConfig.CONVERSATION_TYPE.CHAT.id
                                ? myAppConfig.CONVERSATION_TYPE.CHAT.name
                                : myAppConfig.CONVERSATION_TYPE.VOICE.id
                                ? myAppConfig.CONVERSATION_TYPE.VOICE.name
                                : myAppConfig.CONVERSATION_TYPE.VIDEO.name) +
                              "/" +
                              session.id)
                          }
                        >
                          Review
                        </button>
                      </Col>
                    </Row>
                    {idx !== sessions.length - 1 ? <hr /> : null}
                  </>
                ) : null
              )}

              <div
                className="show-more-reviews"
                style={{
                  display: sessions.length > noToDisplay ? "flex" : "none",
                  cursor: "pointer",
                }}
                onClick={() => setNoToDisplay(sessions.length)}
              >
                <p>Show more</p>
              </div>
            </div>
          </div>
        </Card>
      </Container>
    </div>
  );
}
