


import editProfileImage from '../../assets/icons/edit_profile_icon.png';
import { Card } from 'react-bootstrap';

const AdvisorInfo = (props) => {
    const {
        fullName, category, describe, summarize, setShowEditIntro, setTempSelectedCategories, selectedCategories
    } = props

    return (
        <Card className="card-custom custom-padding desc">
            <h4>{window.location.pathname !== '/' || fullName ? (fullName ? fullName : '') : 'Full name / Spiritual name'}</h4>
            <p className="custom-text-style-16-22">{window.location.pathname !== '/' || category ? (category ? category : '') : 'Category'}</p>
            <p className="description custom-text-style-14-20">
                {window.location.pathname !== '/' || describe ? (describe ? describe : '') : 'Describe your services'}
                <br />
                {window.location.pathname !== '/' || summarize ? (summarize ? summarize : '') : 'Summarize your qualifications to entice customers scrolling to select your listing.'}
            </p>
            <div className='edit-profile' onClick={() => { setShowEditIntro(true); setTempSelectedCategories(selectedCategories) }}> <img alt='' src={editProfileImage} /> </div>
        </Card>
    )
}

export default AdvisorInfo