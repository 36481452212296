import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import * as myAppConfig from "../../../constants/AppConstants";
import "../../../styles/videoChat.scss";
import EndSessionModal from "../../../components/modals/EndSessionModal";
import ThankYouScreen from "../../../components/ThankYouScreen";
import VideoChatComponent from "../../../components/chat/VideoChatComponent";
import AccountManageActionModal from "../../../components/modals/AccountManageActionModal";
import { useTimer } from "reactjs-countdown-hook";
import * as utils from "../../../Utilities/Utils";
import * as gaEcommerce from "../../../Utilities/GAEcommerce";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import Div100vh from "react-div-100vh";
import { MobileChatComponentLayout } from "../../../layouts/MobileChatComponentLayout";
import { Col } from "react-bootstrap";
import ClientSessionsNotes from "../../../components/chat/ClientSessionsNotes";
import ReactGA from "react-ga";
import Popup from "react-popup";

const { isSupported } = require("twilio-video");

const VideoChat = (props) => {
  const { check403Error } = props;

  const userType = parseInt(localStorage.getItem("userType"));
  const isClient = Number(userType) === myAppConfig.USER_TYPES.CLIENT;
  let history = useHistory();
  const isMounted = React.useRef(true);

  const [token, setToken] = useState("");
  const [room, setRoom] = useState(null);

  const [videoInfo, setVideoInfo] = useState({
    uniqueName: "",
    friendlyName: "",
    advisorfriendlyName: "",
  });

  const otherTalkerInfo = sessionStorage.getItem("otherTalker")
    ? JSON.parse(sessionStorage.getItem("otherTalker"))
    : [];
  const otherTalkerId = otherTalkerInfo["id"] ? otherTalkerInfo["id"] : "";
  const otherTalkerName = otherTalkerInfo["name"]
    ? otherTalkerInfo["name"]
    : "";
  const otherTalkerLogoImage = otherTalkerInfo["logo_image"]
    ? otherTalkerInfo["logo_image"]
    : "";
  const otherTalkerCategory = otherTalkerInfo["category"]
    ? otherTalkerInfo["category"]
    : "";

  const [showEndSession, setShowEndSession] = useState(false);
  const [isConversationDone, setConversationDone] = useState(false);
  const [isSessionEnded, setSessionEnded] = useState(true);

  const [showAccountManageModal, setShowAccountActionModal] = useState(false);
  const [accountActionType, setAccountActionType] = useState("");

  //Advisor sessions & notes
  const [notes, setNotes] = useState("");
  const [clientSessions, setClientSessions] = useState(null);
  const [showClientSessions, setShowClientSessions] = useState(false);

  var timerForCheckAdvisorResponse;
  var timerForContinueWithConversation;
  var timerForNotResponding;
  var timerForActiveConversation;

  const [isTimerForContinueConversation, setIsTimerForContinueConversation] =
    useState(false);

  const [outsideTarget, setOutSideTarget] = useState(null);
  const currentScreenConversation = useRef();
  const handleOutsideClick = (event) => {
    if (
      currentScreenConversation &&
      currentScreenConversation !== undefined &&
      currentScreenConversation.current !== null &&
      currentScreenConversation.current !== undefined &&
      !currentScreenConversation.current.contains(event.target)
    ) {
      if (
        document.querySelector(".modal") === null //&& event.target.pathname !== undefined
      ) {
        console.log("handleOutsideClick");
        setOutSideTarget(event.target);
        setShowEndSession(true);
      }
    }
  };

  const conversationResponseSeconds = 60;
  useTimer(conversationResponseSeconds, clearWaitingTimeForAdvisorResponse);

  function clearWaitingTimeForAdvisorResponse() {
    if (userType === myAppConfig.USER_TYPES.CLIENT) {
      let invitation = sessionStorage.getItem("video-invitation");

      if (utils.invitationResponse(invitation)) {
        setIsTimerForContinueConversation(true);

        setAccountActionType(
          utils.getAdvisorIsNotRespondingMessage(otherTalkerName)
        );
        setShowAccountActionModal(true);
      }
    }
  }

  useEffect(() => {
    if (isTimerForContinueConversation) {
      timerForContinueWithConversation = window.setTimeout(
        () => onCancelVideo(),
        60 * 1000
      );
    } else {
      clearTimeout(timerForContinueWithConversation);
    }

    return () => clearTimeout(timerForContinueWithConversation);
  }, [isTimerForContinueConversation]);

  const checkVideoInvitationResponse = () => {
    if (
      userType === myAppConfig.USER_TYPES.CLIENT &&
      window.location.pathname === "/video-chat"
    ) {
      let isConversationCancelled = sessionStorage.getItem(
        myAppConfig.isConversationCancelled
      );
      console.log("isConversationCancelled: ", isConversationCancelled);
      if (
        isConversationCancelled === true ||
        isConversationCancelled === "true"
      ) {
        return;
      }

      let invitation = sessionStorage.getItem("video-invitation");

      if (utils.invitationResponse(invitation)) {
        console.log("Waiting video invitation response: " + invitation);
        clearTimeout(timerForCheckAdvisorResponse);
        timerForCheckAdvisorResponse = setTimeout(
          () => checkVideoInvitationResponse(),
          1000
        );
      } else if (
        Number(invitation) ===
        Number(myAppConfig.NOTIFICATION_TYPE.accept_request)
      ) {
        setShowEndSession(false);
        setShowAccountActionModal(false);
        setIsTimerForContinueConversation(false);

        console.log("Accepted invitation: " + invitation);
      } else if (
        Number(invitation) ===
        Number(myAppConfig.NOTIFICATION_TYPE.reject_request)
      ) {
        let message = utils.displayErrorMessage(
          "Sorry",
          utils.getAdvisorIsBusyMessage(otherTalkerName)
        );

        setAccountActionType(message);
        setShowAccountActionModal(true);
      } else {
        console.log("here I am");
      }
    }
  };

  const getChatToken = (conversation_id) => {
    const fetchTasks = utils.getChatToken(
      myAppConfig.CONVERSATION_TYPE.VIDEO.name,
      conversation_id
    );

    Promise.all([fetchTasks])
      .then(function (res) {
        const response = res[0];
        if (response.data.status === "success") {
          setToken(response.data.token);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log("error on fetchTasks: " + error);
      });
  };

  useEffect(() => {
    
    async function createConversation() {
      console.log("create conversation");
      sessionStorage.setItem("video-invitation", undefined);
      sessionStorage.setItem(myAppConfig.isConversationCancelled, false);

      let convFormData = utils.getCreateConversationParameters(
        otherTalkerId,
        myAppConfig.CONVERSATION_TYPE.VIDEO.id
      );

      console.log(
        "Calling create conversation for video: " +
          myAppConfig.CONVERSATION_TYPE.VIDEO.id
      );
      axios
        .post(myAppConfig.CREATE_CONVERSATION, convFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: localStorage.getItem("userToken"),
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            if (response.data.payUrl) {
              utils.redirectToAddCard(
                response.data,
                myAppConfig.CONVERSATION_TYPE.VIDEO.name
              );
            } else {
              console.log("conversation_id: " + response.data.conversation_id);
              setVideoInfo({
                uniqueName: response.data.conversation_id,
                friendlyName: response.data.channelfriendlyName,
                advisorfriendlyName: response.data.advisorfriendlyName,
              });

              getChatToken(response.data.conversation_id);
            }
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log("error create conv");
          alert("Error on creating conversation");
          onCancelVideo();
          check403Error(error);
        });
    }

    if (isMounted.current) {
      if (userType === myAppConfig.USER_TYPES.CLIENT) {
        if (isSupported) {
          //ReactGA.plugin.require("ecommerce");
          createConversation();
        } else {
          alert("This browser is not supported by Twilio Video");
          onCancelVideo();
        }
      } else {
        checkConversationStatus();
        
        getClientNotes();
        getSessionsList();
      }
    }

    return () => {
      isMounted.current = false;
      console.log("return isMounted: " + isMounted.current);
      utils.setCurrentConversationAccepted("");
    };
  }, []);

  useEffect(() => {
    if (token !== "" && isClient) {
      checkVideoInvitationResponse();
    }
  }, [token]);

  const showModalEndSession = () => {
    setOutSideTarget(null);
    setShowEndSession(true);
  };

  const btnEndSessionClick = () => {
    onParticipantDisconnected(false);
    disconnectFromVideoChatRoom();
  };

  const btnContinueConversation = () => {
    setShowEndSession(false);
  };

  const startOrEndConversation = (isStart, conv_id) => {
    console.log("startOrEndConversation ", isStart, conv_id);

    if (isStart) {
      setSessionEnded(false);
    } else {
      setSessionEnded(true);
    }
    let formData = utils.getStartOrEndConversationParameters(
      isStart,
      conv_id,
      myAppConfig.CONVERSATION_TYPE.VIDEO.id
    );

    const fetchStartEndConv = utils.callStartOrEndConversation(
      isStart,
      formData
    );

    Promise.all([fetchStartEndConv])
      .then(function (res) {
        const response = res[0];
        if (response.data.status === "success") {
          if (isStart) {
            checkActiveConversation(conv_id);
            sessionStorage.setItem("free-minutes", response.data.free_minutes);
            console.log("free minutes ", response.data.free_minutes);
          } else {
            if (response.data.end_Details) {
              gaEcommerce.gaAddTransaction(response.data.end_Details);
            }
          }

          console.log("success ", isStart ? " started conv" : " ended conv");
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log("error on fetchStartEndConv: " + error);
        check403Error(error);
      });
  };

  const onAccountActionBtnClick = () => {
    const showHomepage = isTimerForContinueConversation;
    setIsTimerForContinueConversation(false);
    setShowAccountActionModal(false);

    if (!showHomepage) {
      history.replace("/");
    }
  };

  function onCancelVideo() {
    sessionStorage.setItem(myAppConfig.isConversationCancelled, true);
    console.log("Cancel the video conv_id:", videoInfo.uniqueName);

    if (videoInfo.uniqueName !== "") {
      const fetchCancelConversationTask = utils.cancelConversation(
        videoInfo.uniqueName
      );
      Promise.all([fetchCancelConversationTask])
        .then(function (res) {
          history.goBack();
        })
        .catch((error) => {
          history.goBack();
        });
    } else {
      history.goBack();
    }

    clearTimeout(timerForActiveConversation);
    clearTimeout(timerForCheckAdvisorResponse);

    disconnectFromVideoChatRoom();
  }

  const onParticipantConnected = () => {
    console.log("onParticipantConnected ", isClient);
    document.addEventListener("mousedown", handleOutsideClick);

    if (isClient) {
      sessionStorage.setItem("free-minutes", 0);
      startOrEndConversation(true, videoInfo.uniqueName);
    } else {
      checkActiveConversation(utils.getCurrentConversationAccepted());
    }
  };

  const onParticipantDisconnected = (isDisconnected) => {
    clearTimeout(timerForActiveConversation);
    utils.setCurrentConversationAccepted("");

    if (isClient) {
      clearTimeout(timerForActiveConversation);

      startOrEndConversation(false, videoInfo.uniqueName);

      setConversationDone(true);
      setShowEndSession(false);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);

      if (outsideTarget === null) {
        history.replace("/");

        if (isDisconnected === undefined) {
          Popup.create({
            title: "End session",
            content: otherTalkerName + " has left the video call",
          });
        }
      } else outsideTarget.click();
    }
  };

  const disconnectFromVideoChatRoom = () => {
    clearTimeout(timerForActiveConversation);
    utils.setCurrentConversationAccepted("");

    if (room !== undefined && room !== null) {
      if (room && room.localParticipant.state === "connected") {
        room.localParticipant.tracks.forEach(function (trackPublication) {
          trackPublication.track.stop();
        });
      }
      room.disconnect(); // Disconnect the LocalParticipant.
      console.log("Room disconnected");
    }
  };

  const checkActiveConversation = (conversation_id) => {
    if (window.location.pathname === "/video-chat") {
      console.log("checkActiveConversation ", conversation_id);
      axios
        .get(myAppConfig.CHECK_ACTIVE_CONVERSATION, {
          params: {
            conversation_id: conversation_id,
            from: userType === myAppConfig.USER_TYPES.CLIENT ? 2 : 1, //1.advisor //2 client
          },
          headers: {
            token: localStorage.getItem("userToken"),
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            console.log("response get active conversation");
            timerForActiveConversation = setTimeout(
              () => checkActiveConversation(conversation_id),
              myAppConfig.check_active_conversation_time
            );
          } else {
            clearTimeout(timerForActiveConversation);

            let message = utils.displayErrorMessage(
              "Error",
              response.data.message !== ""
                ? response.data.message
                : "An error has occured!"
            );
            setAccountActionType(message);
            setShowAccountActionModal(true);
          }
        })
        .catch((err) => {
          console.log("VideoChat error ", err);
        });
    } else {
      clearTimeout(timerForActiveConversation);
    }
  };

  const setVideoRoom = (currentRoom) => {
    setRoom(currentRoom);
    console.log("Setting video room ", currentRoom);
  };

  function getClientNotes() {
    const fetchTasks = utils.getClientNotes(otherTalkerId);

    Promise.all([fetchTasks])
      .then(function (res) {
        setNotes(res);
      })
      .catch((error) => {
        console.log("error on fetchTasks: " + error);
      });
  }

  function getSessionsList() {
    const fetchTasks = utils.getSessionsList(otherTalkerId);

    Promise.all([fetchTasks])
      .then(function (res) {
        if (res.length > 0) {
          setClientSessions(res[0]);
        }
      })
      .catch((error) => {
        console.log("error on fetchTasks: " + error);
      });
  }

  const setClientSessionsList = () => {
    setShowClientSessions(!showClientSessions);
  };

  function checkConversationStatus() {
    const fetchTasks = utils.checkConversationStatus(
      utils.getCurrentConversationAccepted(),
      myAppConfig.CONVERSATION_TYPE.VIDEO.id
    );

    Promise.all([fetchTasks])
      .then(function (res) {
        if (res.length > 0) {
          if (res[0] === false) {
            disconnectFromVideoChatRoom();
            if (document.querySelector(".modal") === null) {
              let message = utils.displayErrorMessage(
                "Sorry",
                utils.getCancelConversationMessage(otherTalkerName)
              );
              setAccountActionType(message);
              setShowAccountActionModal(true);
            }
          } else {
            getChatToken(utils.getCurrentConversationAccepted());
          }
        }
      })
      .catch((error) => {
        console.log("error on fetchTasks: " + error);
      });
  }

  const renderVideoChatComponent = () => {
    return (
      <VideoChatComponent
        roomName={
          isClient
            ? videoInfo.uniqueName.toString()
            : utils.getCurrentConversationAccepted()
        }
        token={token}
        otherTalkerName={otherTalkerName}
        otherTalkerLogoImage={otherTalkerLogoImage}
        otherTalkerCategory={otherTalkerCategory}
        showModalEndSession={showModalEndSession}
        onCancelVideo={onCancelVideo}
        onParticipantConnected={onParticipantConnected}
        onParticipantDisconnected={onParticipantDisconnected}
        setVideoRoom={setVideoRoom}
      />
    );
  };
  return (
    <>
      <AccountManageActionModal
        show={showAccountManageModal}
        setShowAccountActionModal={() => onAccountActionBtnClick()}
        actionType={accountActionType}
        onAccountAction={onAccountActionBtnClick}
        btnText={isTimerForContinueConversation ? "Continue" : "Ok"}
        hideClose={true}
      />

      <EndSessionModal
        show={showEndSession}
        setShowEndSession={() => setShowEndSession(false)}
        btnEndSessionClick={() => btnEndSessionClick()}
        btnContinueConversation={() => btnContinueConversation()}
      />

      {isConversationDone ? (
        <div>
          <ThankYouScreen
            data={myAppConfig.FINAL_THANK_YOU_MESSAGES.CONVERSATION_ENDED}
            advisorName={localStorage.getItem("userFullName")}
            advisorId={otherTalkerId}
          />
        </div>
      ) : isMobile ? (
        <Div100vh>
          <MobileChatComponentLayout show={true}>
            {renderVideoChatComponent()}
          </MobileChatComponentLayout>
        </Div100vh>
      ) : (
        <div
          className="video-chat-screen mb-5 pb-5"
          ref={currentScreenConversation}
        >
          <Col
            lg={8}
            style={{
              display: "flex",
              justifyContent: isClient ? "center" : "flex-end",
              height: "100%",
              paddingRight: isClient ? "0" : "2%",
            }}
          >
            <div className="video-chat-component-container">
              {renderVideoChatComponent()}
            </div>
          </Col>
          {isClient ? null : (
            <ClientSessionsNotes
              showClientSessions={showClientSessions}
              setClientSessionsList={setClientSessionsList}
              currentConversationIndex={0}
              clientId={otherTalkerId}
              notes={notes}
              setNotes={setNotes}
              clientSessions={clientSessions}
              check403Error={check403Error}
            />
          )}
        </div>
      )}
    </>
  );
};
export default VideoChat;
