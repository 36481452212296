import React, {useState} from 'react';
import axios from 'axios';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import changed from '../../../assets/icons/changed_icon.png';
import '../../../styles/forgotPass.scss';
import * as myAppConfig from "../../../constants/AppConstants";
import PropTypes from 'prop-types'
import validator from 'validator'
import * as Icon from 'react-feather'
import { logOutUser } from '../../../Utilities/Utils';

const wrongPasswordMessage = "Passwords don't match";

function ChangePassword({ check403Error }) {

  const [password, setPassword] = useState();

  const [wrongPassword, setWrongPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState();
  const [passChanged, setPassChanged] = useState(false);

  const [invalidPassword, setInvalidPassword] = useState(false);
  const invalidPasswordMessage = 'The password has to be a minimum of 8 characters long and to include both uppercase and lowercase characters and at least one digit. '

  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);

  const tkn = localStorage.getItem('userToken');

    const handleChangePassword = async e => {
        e.preventDefault();
    if (!validatePassword(password)) {
      setInvalidPassword(true);
      return;
    }
        if (password !== confirmPassword){
            setWrongPassword(true);
            return;
        }
        setWrongPassword(false);
        var changePassFormData = new FormData();
        changePassFormData.append('password', password);
        changePassFormData.append('confirm_password', confirmPassword);
        await changePassword(changePassFormData);
        localStorage.clear();
        sessionStorage.clear();
      }

  const validatePassword = (password) => {
    if (validator.isStrongPassword(password, {
      minLength: 8, minLowercase: 1,
      minUppercase: 1, minNumbers: 1, minSymbols: 0
    })) {
      return true;
    }
    return false;
  }

async function changePassword(credentials) {
        axios
          .post(myAppConfig.FORGOT_PASSWORD, credentials,  {
            headers: {'Content-Type': 'application/x-www-form-urlencoded', 'token': tkn}
        })
          
          .then(response => {
        if (response.data.status === 'success') {
                 console.log(response.data);
                 setPassChanged(true);
              }
              else {
                setPassChanged(true);
                  console.log(response.data);
              }
            }
          ).catch(err => check403Error(err));
       }


    return (
        <Container>
            <Row>
            {!passChanged ? 
                <Col lg={5} className="mx-auto">
                    <div className="forgot d-flex flex-column justify-content-center">
                        <h1>Want to change your password?</h1>

                        <Form className="px-lg-5 px-md-5 px-sm-3 px-3" onSubmit={handleChangePassword}>

                <Form.Group className="mb-3" controlId="formBasicPassword1" style={{ position: 'relative' }}>
                  <Form.Label>Enter new password</Form.Label>
                  {!invalidPassword ?
                    showPass ?
                      <Icon.EyeOff className='eyeIcon' onClick={() => setShowPass(false)} /> :
                      <Icon.Eye className='eyeIcon' onClick={() => setShowPass(true)} /> :
                    null
                  }
                  <Form.Control className="custom_input" type={showPass ? "text" : "password"} onChange={e => { setPassword(e.target.value); if (invalidPassword) setInvalidPassword(false) }} required={true} isInvalid={invalidPassword} />
                  <Form.Control.Feedback type='invalid'> {invalidPasswordMessage}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword2" style={{ position: 'relative' }}>
                  <Form.Label>Confirm new password</Form.Label>
                  {!wrongPassword ?
                    showPass2 ?
                      <Icon.EyeOff className='eyeIcon' onClick={() => setShowPass2(false)} /> :
                      <Icon.Eye className='eyeIcon' onClick={() => setShowPass2(true)} /> :
                    null
                  }
                  <Form.Control className="custom_input" type={showPass2 ? "text" : "password"} onChange={e => { setConfirmPassword(e.target.value); if (wrongPassword) { setWrongPassword(false) } }} required={true} isInvalid={wrongPassword} />
                  <Form.Control.Feedback type='invalid'> {wrongPasswordMessage}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button variant="primary" type="submit" className="signbutton my-2">Send</Button>
              </Form>
            </div>
                </Col>
                :
                <Col lg={6} className="mx-auto">
                    <div className="forgot d-flex flex-column justify-content-center">
                        <img src={changed} alt="icon_green" className="my-4 mx-auto"/>
                        <h1>Your password has been changed</h1>
                        <p className="mt-3">You can now go back to our sign in page to enter your new details</p>
              <Button className="goBack my-4" onClick={() => logOutUser("/logged_out=true", check403Error)}>Go back to Sign In page</Button>
                    </div>
                </Col> }
            </Row>
        </Container>
    )
}

export default ChangePassword

ChangePassword.propTypes = {
    check403Error: PropTypes.func
}
