import TextPage from "../../../components/TextPage"
import { contentClient, contentAdvisor } from '../../../constants/TermsAndConditionsData'


const TermsAndConditions = (props) => {
    let urlStrings = window.location.href.split("/");

    return (
        <TextPage content={urlStrings.includes('advisors') ? contentAdvisor : contentClient} />
    )
}

export default TermsAndConditions