import { imagesApiURL } from '../../constants/AppConstants';
import { useKeenSlider } from "keen-slider/react";
import React from "react";

const CategoriesMobileSlider = (props) => {

    const { categories } = props

    const [sliderRef] = useKeenSlider({
        initial: 0,
        loop: false,
        breakpoints: {
            '(max-width: 550px)': {
                slidesPerView: 2
            }
        },
        slidesPerView: 3,

    });

    const categories_items = categories.map((category, idx) =>
        <div className={`keen-slider__slide number-slide${idx + 1}`} key={category.id} style={{ paddingRight: '10px', paddingLeft: '10px', background: 'transparent' }}>
            <div key={category.id} className='w-100'>
                <a href={"/category/" + category.title} style={{ textDecoration: 'none' }} >
                    <div className="categ_item push-on-hover" style={{ backgroundImage: "url(" + imagesApiURL + category.background_image + ")" }}>
                        <img src={imagesApiURL + category.icon} alt="categ_icon" />
                        <div className="title_categ">{category.title}</div>
                    </div>
                </a>
            </div>
        </div>
    );

    return (
        <div style={{ marginRight: '20px' }}>
            <div ref={sliderRef} className="keen-slider" style={{ overflow: 'visible', marginLeft: "-10px", marginRight: '-10px' }}>
                {categories_items}
            </div >
        </div>
    )
}

export default CategoriesMobileSlider;