
import { Card } from "react-bootstrap"
import { imagesApiURL } from "../../constants/AppConstants"
import { Player, ControlBar } from 'video-react';
import uploadBg from '../../assets/icons/upload_bg_icon.png';

const BackgroundVideo = (props) => {

  const { bgVideo, setBgVideo, saveUserDetails } = props;

  const bgChange = async e => {
        if (e.target.files.length) {
          setBgVideo({
            preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
        name: e.target.files[0].name
          });
      if (saveUserDetails) {
        const formData = new FormData();
        formData.append("background_video", e.target.files[0]);
        await saveUserDetails(formData)
        }
    }
      };
    return (
        <div className="overlay-div" style={{height:'281px'}}>
            {!bgVideo ? <div/> : 
        (bgVideo.name && bgVideo.name.split('.').pop() === 'mp4') || ((imagesApiURL + bgVideo) && (imagesApiURL + bgVideo).split('.').pop() === 'mp4') ?
            <Player
            playsInline
            // autoPlay
            key={bgVideo.preview ? bgVideo.preview : imagesApiURL+bgVideo}
          >
              <source src = {bgVideo.preview ? bgVideo.preview : imagesApiURL+bgVideo}/>
              <ControlBar autoHide={false}>
                </ControlBar>
          </Player>
          : <img src={bgVideo.preview ? bgVideo.preview : imagesApiURL + bgVideo} className='w-100 h-100' />
        }
        <div style={{height: 0}}>
        <label htmlFor="upload-button-bg">
          <Card.Img src={uploadBg} className = "overlay-img"/>
        </label>
        <input
            type="file"
            id="upload-button-bg"
            style={{ display: "none" }}
            onChange={bgChange}
        />
        </div>
        
        </div>
        
    )
}

export default BackgroundVideo