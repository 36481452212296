import React, { useState } from "react"
import { imagesApiURL } from "../../../constants/AppConstants";
import '../../../styles/superAdminSettings.scss';
import { Col, Button, Card, Modal, Table, Form, FormControl } from 'react-bootstrap'
import axios from "axios";
import * as myAppConfig from "../../../constants/AppConstants";
import * as Icon from 'react-feather'
import AccountManageActionModal from "../../../components/modals/AccountManageActionModal";
import { isMobileOnly } from "react-device-detect";

const Settings = (props) => {
  const { homePageDetails, setHomePageDetails, check403Error } = props;
  const [slides, setSlides] = useState([]);
  const [showAddAdvisorsModal, setShowAddAdvisorsModal] = useState(false);
  const [selectedAdvisorIds, setSelectedAdvisorIds] = useState([]);
  const [otherAdvisors, setOtherAdvisors] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [showRemovePopUp, setShowRemovePopUp] = useState(false);
  const [selectedAdvisorId, setSelectedAdvisorId] = useState();
  const [numberOfAdvisorsToShow, setNumberOfAdvisorsToShow] = useState(7);
  const numberMoreAdvisors = 7;
  let advisorsShown = 0;
  const [slidesUpdated, setSlidesUpdated] = useState(true);
  const [showAccountManageModal, setShowAccountActionModal] = useState(false);
  const [accountActionType, setAccountActionType] = useState("");
  const [searchAdvisors, setSearchAdvisors] = useState([]);
  React.useEffect(() => {
    if (!homePageDetails || !homePageDetails.top_advisors) {
      axios
        .get(myAppConfig.GET_ADVISOR_DASHBOARD, {
          params: { interval: '1' },
          headers: { token: localStorage.getItem("userToken") },
        })
        .then((response) => {
          if (response.data.status === "success") {
            setHomePageDetails(response.data);
          }
        })
    }
  }, [])

  React.useEffect(() => {
    if (slidesUpdated) {
      axios
        .get(myAppConfig.GET_HERO_BANNER)
        .then(response => {
          if (response.data.status === 'success') {
            setSlides(response.data.hero_banners);
          }
          else {
            console.log(response.data);
          }
        }).catch(err => check403Error(err));
      setSlidesUpdated(false);
    }
  }, [slidesUpdated, check403Error])

  async function removeBannerSlide(slideId) {
    let ImageFormData = new FormData();
    ImageFormData.append('banner_id', slideId);
    axios.post(myAppConfig.REMOVE_HERRO_BANNER, ImageFormData, {
      headers: { 'Content-Type': 'multipart/form-data', 'token': localStorage.getItem('userToken') }
    })
      .then(response => {
        if (response.data.status === 'success') {
          setSlidesUpdated(true);
        }
        else {
          console.log(response.data)
        }
      })
  }

  const removeSlide = async (slideId) => {
    await removeBannerSlide(slideId);
  }

  async function getCategoryAdvisors(categTitle) {
    axios
      .get(myAppConfig.FILTER_BY_CATEGORY,
        {
          params: {
            "category": categTitle,
            "session_method": 0,
            "sort_by": 0
          }
        })
      .then(response => {
        if (response.data.status === 'success') {
          setOtherAdvisors(response.data.advisors);
          setSearchAdvisors(response.data.advisors);
        }
        else {
          console.log(response.data);
          setOtherAdvisors(null);
        }
      }).catch(err => check403Error(err));
  }

  const getDifference = (stDate) => {
    var dateFuture = new Date();
    var dateNow = new Date(stDate);

    var seconds = Math.floor((dateFuture - (dateNow)) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);

    hours = hours - (days * 24);
    minutes = minutes - (days * 24 * 60) - (hours * 60);
    seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
    return hours > 0 ? (hours === 1 ? '1 hour ago' : (hours + ' hours ago')) : minutes > 0 ? (minutes === 1 ? '1 minute ago' : (minutes + ' minutes ago')) : (seconds === 1 ? '1 second ago' : (seconds + 'seconds ago'));
  }

  async function saveImage(e) {
    let ImageFormData = new FormData();
    const NewImage = { preview: URL.createObjectURL(e.target.files[0]), raw: e.target.files[0] };
    ImageFormData.append('image', NewImage.raw);
    axios.post(myAppConfig.ADD_HERO_BANNER, ImageFormData, {
      headers: { 'Content-Type': 'multipart/form-data', 'token': localStorage.getItem('userToken') }
    })
      .then(response => {
        if (response.data.status === 'success') {
          setSlidesUpdated(true);
        }
      })
  }

  const imageChange = async e => {
    if (e.target.files.length) {
      await saveImage(e);
    }
  };

  async function addAdvisorToList(credentials) {
    axios.post(myAppConfig.SA_ADD_ADVISOR_TO_TOP, credentials, {
      headers: { 'Content-Type': 'multipart/form-data', 'token': localStorage.getItem('userToken') }
    })
      .then(response => {
        if (response.data.status === 'success') {
          window.location.reload();
        }
        else {
          console.log(response.data);
        }
      }).catch(err => check403Error(err));
  }

  const addAdvisor = async e => {
    e.preventDefault();
    let advisorFormData = new FormData();
    advisorFormData.append('advisor_id', selectedAdvisorId);
    advisorFormData.append('type', selectedType);
    await addAdvisorToList(advisorFormData);
  }

  async function removeAdvisorFromList(credentials) {
    axios.post(myAppConfig.SA_REMOVE_ADVISOR_FROM_TOP, credentials, {
      headers: { 'Content-Type': 'multipart/form-data', 'token': localStorage.getItem('userToken') }
    })
      .then(response => {
        if (response.data.status === 'success') {
          window.location.reload();
        }
        else {
          console.log(response.data);
        }
      }).catch(err => check403Error(err));
  }

  const removeAdvisor = async e => {
    e.preventDefault();
    let advisorFormData = new FormData();
    advisorFormData.append('advisor_id', selectedAdvisorId);
    advisorFormData.append('type', selectedType);
    await removeAdvisorFromList(advisorFormData);
  }

  const addTopAdvisors = async e => {
    e.preventDefault();
    setSelectedAdvisorIds(homePageDetails.top_advisors.map((adv) => adv.id));
    setSelectedType(2);
    advisorsShown = 0;
    await getCategoryAdvisors('');
    setTimeout(() => setShowAddAdvisorsModal(true), 400);
  }

  const addLoveAdvisors = async e => {
    e.preventDefault();
    setSelectedAdvisorIds(homePageDetails.love_and_relationships.map((adv) => adv.id));
    setSelectedType(3);
    advisorsShown = 0;
    await getCategoryAdvisors('Love & Relationship');
    setTimeout(() => setShowAddAdvisorsModal(true), 400);
  }

  const addPsychicAdvisors = async e => {
    e.preventDefault();
    setSelectedAdvisorIds(homePageDetails.psychic_readings.map((adv) => adv.id));
    setSelectedType(4);
    advisorsShown = 0;
    await getCategoryAdvisors('Psychic Readings');
    setTimeout(() => setShowAddAdvisorsModal(true), 400);
  }

  const addCareerAdvisors = async e => {
    e.preventDefault();
    setSelectedAdvisorIds(homePageDetails.career_advisor.map((adv) => adv.id));
    setSelectedType(5);
    advisorsShown = 0;
    await getCategoryAdvisors('Career Advisor');
    setTimeout(() => setShowAddAdvisorsModal(true), 400);
  }

  const onAccountActionBtnClick = async (e) => {
    e.preventDefault();
    if (accountActionType === myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.REMOVE_ADVISOR_FROM_LIST) {
      removeAdvisor(e);
    } else {
      addAdvisor(e);
    }
    setShowAccountActionModal(false);
  };

  const accountManageAction = (actionType) => {
    setAccountActionType(actionType);
    setShowAccountActionModal(true);
  };

  const searchForAdvisors = (e) => {
    var searchString = e.target.value;
    console.log(searchString)
    let searchResults = [];
    otherAdvisors.map((advisor, idx) => {
      if (advisor.full_name && (advisor.full_name.toLowerCase().includes(searchString.toLowerCase()))) {
        searchResults = searchResults.concat(advisor);
      }
      return null;
    })
    setSearchAdvisors(searchResults);
  }

  return (
    <>
      <AccountManageActionModal
        show={showAccountManageModal}
        setShowAccountActionModal={() => setShowAccountActionModal(false)}
        actionType={accountActionType}
        onAccountAction={onAccountActionBtnClick}
      />

      <Modal
        show={showRemovePopUp}
        onHide={() => setShowRemovePopUp(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="editModal archive account block"
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>

          <div className="title">Cannot add more advisors</div>
          <Form className="px-lg-5 px-md-5 px-sm-3 px-3">
            <div>You can only have 4 best advisors for each category!</div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showAddAdvisorsModal}
        onHide={() => setShowAddAdvisorsModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="editModal advisorsModal"
        dialogClassName={isMobileOnly ? "modal-fullscreen modal-backdrop" : ""}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <div className='title'>Add Advisor</div>
          <div className='searchBar'>
            <Form className="d-flex">
              <FormControl
                type="search"
                placeholder="Search for advisor..."
                className="searchInput"
                aria-label="Search"
                onChange={searchForAdvisors}
              />
            </Form>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Table responsive className="registered-users-table">
            <thead>
              <tr className='tableTitle'><th>Advisors List</th></tr>

            </thead>
            <tbody>
              {searchAdvisors.map((advisor, idx) => {
                if (advisorsShown >= numberOfAdvisorsToShow || selectedAdvisorIds.includes(advisor.id)) return null
                else {
                  advisorsShown = advisorsShown + 1;
                  return (
                    <React.Fragment key={idx}>
                      <tr>
                        <th>Advisor Name</th>
                        <th>Expert ID</th>
                        <th>Last Update</th>
                        <th>Daily Revenue</th>
                        <th>Reviews</th>
                        <th>NPC</th>
                        <th>PDAU</th>
                        <th></th>
                      </tr>
                      <tr>
                        <td><Card.Img className="user-img" src={imagesApiURL + advisor.logo_image} alt='user logo' />{advisor.full_name}</td>
                        <td>{advisor.id}</td>
                        <td>{getDifference(advisor.updated_at)}</td>
                        <td>$2,384</td>
                        <td>{advisor.score_average}</td>
                        <td>21</td>
                        <td>45</td>
                        <td className='has-btn'><button type="button" className="review-btn" onClick={() => { setSelectedAdvisorId(advisor.id); accountManageAction(myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.ADD_ADVISOR_TO_LIST) }}>Add</button></td>
                      </tr>
                    </React.Fragment>
                  )
                }
              })}
            </tbody>
          </Table>
          {searchAdvisors.length - selectedAdvisorIds.length > numberOfAdvisorsToShow ?
            <div className='d-flex justify-content-center mt-4 mb-2'>
              <Button className='showMore' onClick={() => setNumberOfAdvisorsToShow(numberOfAdvisorsToShow + numberMoreAdvisors)}>Show more</Button>
            </div>
            : null}
        </Modal.Body>
      </Modal>
      <div className="superAdminSettings">
        <h4>Top Hero Banners</h4>
        <div className="slider_upload d-flex">
          {slides.map((slide, idx) => {
            return (
              <Col className='mb-3 col-10 col-sm-10 col-md-4 col-lg-4' key={idx}>
                <div className='overlay-div'>
                  <img key={idx} src={imagesApiURL + slide.image} alt='slide bg' />
                  {/* <div className='overlay'><p className='size'></p>
              <label for='upload-button-img'>
                <p>Upload Image</p> 
              </label>
                </div> */}
                  <Icon.Trash size={20} onClick={() => removeSlide(slide.id)} />
                  {/* <input
              type="file"
              id="upload-button-img"
              style={{ display: "none" }}
              onChange={imageChange}
            /> */}
                </div>
              </Col>
            )
          })}
          {slides.length < 3 ?
            [0, 1, 2].slice(slides.length).map((idx) => {
              return (
                <Col className='mb-3 col-10 col-sm-10 col-md-4 col-lg-4' key={idx}>
                  <label className='overlay-div' htmlFor='upload-button-img'>
                    <div className='overlay'><p className='size'>1440 x 700</p><p>Upload Image</p> </div>
                  </label>
                  <input
                    type="file"
                    id="upload-button-img"
                    style={{ display: "none" }}
                    onChange={imageChange}
                  />
                </Col>
              )
            })
            :
            null
          }
        </div>
        <div className='d-flex justify-content-between advisors flex-wrap'>
          <Col col={9} lg={9} md={9} sm={9}>
            <h4>Top Advisors</h4>
          </Col>
          <Button onClick={homePageDetails && homePageDetails.top_advisors && homePageDetails.top_advisors.length === 4 ? (() => { setShowRemovePopUp(true) }) : addTopAdvisors}>Add Advisor</Button>
          <Col col={12} lg={12} md={12} sm={12}>
            <div className='wrapper d-flex flex-row mt-4'>
              {homePageDetails && homePageDetails.top_advisors ? homePageDetails.top_advisors.map((advisor, idx) => {
                return (
                  <Col col={3} lg={3} md={3} sm={3} key={idx}>
                    <div className='d-flex align-items-center'>
                      <img src={imagesApiURL + advisor.logo_image} alt='advisor logo' />
                      <div className='name'>{advisor.full_name}</div>
                    </div>
                    <Button className='remove' onClick={() => { setSelectedAdvisorId(advisor.id); setSelectedType(2); accountManageAction(myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.REMOVE_ADVISOR_FROM_LIST) }}>Remove</Button>
                  </Col>
                )
              })
                : null}
            </div>
          </Col>
        </div>

        <div className='d-flex justify-content-between advisors flex-wrap'>
          <Col col={9} lg={9} md={9} sm={9}>
            <h4>Best advisors in Love & Relationship</h4>
          </Col>
          <Button onClick={homePageDetails && homePageDetails.love_and_relationships && homePageDetails.love_and_relationships.length === 4 ? (() => { setShowRemovePopUp(true) }) : addLoveAdvisors} >Add Advisor</Button>
          <Col col={12} lg={12} md={12} sm={12}>
            <div className='wrapper d-flex flex-row mt-4'>
              {homePageDetails && homePageDetails.love_and_relationships ? homePageDetails.love_and_relationships.map((advisor, idx) => {
                return (
                  <Col col={3} lg={3} md={3} sm={3} key={idx}>
                    <div className='d-flex align-items-center'>
                      <img src={imagesApiURL + advisor.logo_image} alt='advisor logo' />
                      <div className='name'>{advisor.full_name}</div>
                    </div>
                    <Button className='remove' onClick={() => { setSelectedAdvisorId(advisor.id); setSelectedType(3); accountManageAction(myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.REMOVE_ADVISOR_FROM_LIST) }}>Remove</Button>
                  </Col>
                )
              })
                : null}
            </div>
          </Col>
        </div>

        <div className='d-flex justify-content-between advisors flex-wrap'>
          <Col col={9} lg={9} md={9} sm={9}>
            <h4>Best advisors in Psychic Readings</h4>
          </Col>
          <Button onClick={homePageDetails && homePageDetails.psychic_readings && homePageDetails.psychic_readings.length === 4 ? (() => { setShowRemovePopUp(true) }) : addPsychicAdvisors}>Add Advisor</Button>
          <Col col={12} lg={12} md={12} sm={12}>
            <div className='wrapper d-flex flex-row mt-4'>
              {homePageDetails && homePageDetails.psychic_readings ? homePageDetails.psychic_readings.map((advisor, idx) => {
                return (
                  <Col col={3} lg={3} md={3} sm={3} key={idx}>
                    <div className='d-flex align-items-center'>
                      <img src={imagesApiURL + advisor.logo_image} alt='advisor logo' />
                      <div className='name'>{advisor.full_name}</div>
                    </div>
                    <Button className='remove' onClick={() => { setSelectedAdvisorId(advisor.id); setSelectedType(4); accountManageAction(myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.REMOVE_ADVISOR_FROM_LIST) }}>Remove</Button>
                  </Col>
                )
              })
                : null}
            </div>
          </Col>
        </div>

        <div className='d-flex justify-content-between advisors flex-wrap'>
          <Col col={9} lg={9} md={9} sm={9}>
            <h4>Best advisors in Career Advisor</h4>
          </Col>
          <Button onClick={homePageDetails && homePageDetails.career_advisor && homePageDetails.career_advisor.length === 4 ? (() => { setShowRemovePopUp(true) }) : addCareerAdvisors}>Add Advisor</Button>
          <Col col={12} lg={12} md={12} sm={12}>
            <div className='wrapper d-flex flex-row mt-4'>
              {homePageDetails && homePageDetails.career_advisor ? homePageDetails.career_advisor.map((advisor, idx) => {
                return (
                  <Col col={3} lg={3} md={3} sm={3} key={idx}>
                    <div className='d-flex align-items-center'>
                      <img src={imagesApiURL + advisor.logo_image} alt='advisor logo' />
                      <div className='name'>{advisor.full_name}</div>
                    </div>
                    <Button className='remove' onClick={() => { setSelectedAdvisorId(advisor.id); setSelectedType(5); accountManageAction(myAppConfig.ACCOUNT_MANAGE_ACTION_STATE.REMOVE_ADVISOR_FROM_LIST) }}>Remove</Button>
                  </Col>
                )
              })
                : null}
            </div>
          </Col>
        </div>
      </div>
    </>
  )
}

export default Settings