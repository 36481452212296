import React from 'react'
import {Row, Col} from 'react-bootstrap';
import '../../styles/add.scss';
import bigPhone from '../../assets/img/big_phone.png';
import apple from '../../assets/img/btn_apple.png';
import google from '../../assets/img/btn_google.png'

function Add() {
    return (
       <div className="add p-5">
           <Row>
             <Col lg={6} className="justify-content-center d-flex align-items-center">
                 <div className="leftSide">
                        <div className='title mt-lg-3 mb-lg-5 mt-0 mb-3'>Coming soon...</div>
                        <div className="title">Get <span>valuable answers<br /> and clarity</span> from insightful<br /> psychic readings</div>
                        <div className="desc mt-5">Hundreds of the best advisors in the world right in your pocket.<br /> Get answers now with the Advia app!</div>

                     <div className="btns d-flex mt-5 mb-5">
                            <a style={{ pointerEvents: 'none' }} href="/" className="m-2"><img src={apple} alt="app" /></a>
                            <a style={{ pointerEvents: 'none' }} href="/" className="m-2"><img src={google} alt="google" /></a>
                     </div>
                 </div>
             </Col>
             <Col lg={6}>
                 <img src={bigPhone} alt="big_phone" className="big_phone"/>
             </Col>
           </Row>
       </div>
    )
}

export default Add
