import { DropdownButton, Dropdown, Image } from "react-bootstrap"
import { Link } from "react-router-dom"
import { imagesApiURL } from "../../../constants/AppConstants"
import axios from "axios"
import * as myAppConfig from '../../../constants/AppConstants'
import internalProfileIcon from '../../../assets/icons/internal_profile_icon.png'
import bestPracticesIcon from '../../../assets/icons/best_practices_icon.png'
import switchToUserIcon from '../../../assets/icons/switch_to_user_icon.png'
import logOutIcon from '../../../assets/icons/logout_icon.png'

import user from '../../../assets/img/user.png';
import { useState, useRef } from "react"
import { useOnClickOutside, logOutUser } from "../../../Utilities/Utils";

const AdvisorDropDown = (props) => {
    const { userEmail, check403Error } = props;
    const logoImg = localStorage.getItem('userLogoImg')
    const [show, setShow] = useState(false);
    const ref = useRef();

    const switchUserType = async e => {
        e.preventDefault();
        await switchAccount();
        logOutUser("/?logged_out=true", check403Error);
    }

    async function switchAccount() {
        axios.get(myAppConfig.SWITCH_ACCOUNT, {
            headers: { 'token': localStorage.getItem('userToken') }
        }).then(response => {
            console.log(response.data)
        }).catch(err => check403Error(err));
    }

    useOnClickOutside(ref, () => setShow(false));

    return (
        <div className="user_logged user-logged-admin d-flex align-items-center" onClick={() => setShow(!show)} style={window.innerWidth <= 1000 ? { float: 'right' } : {}} ref={ref}>
            <div className="usr_img">
                <img src={logoImg ? imagesApiURL + logoImg : user} alt="user" />
                <div className="notify"></div>
            </div>
            <DropdownButton
                align="up"
                id="dropdown-menu-align-right"
                title=""
                show={show}
            >
                <span className="user-email">{userEmail}</span>
                <Dropdown.Item eventKey="1" as={Link} to="/profile-settings"><Image src={internalProfileIcon} className="admin_loggedin_images" /><span>Internal profile</span></Dropdown.Item>
                <Dropdown.Item eventKey="2" as={Link} to="/best-practices"><Image src={bestPracticesIcon} className="admin_loggedin_images" /><span>Best Practices</span></Dropdown.Item>
                {/* <Dropdown.Item eventKey="3" onClick={switchUserType}><Image src={switchToUserIcon} className="admin_loggedin_images" /><span>Switch to user</span></Dropdown.Item> */}
                <hr />
                <Dropdown.Item eventKey="3" onClick={() => logOutUser("/", check403Error)}><Image src={logOutIcon} className="admin_loggedin_images" /><span>Log Out</span></Dropdown.Item>

            </DropdownButton>
        </div>
    )
}

export default AdvisorDropDown