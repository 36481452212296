import TextPage from "../../../components/TextPage"


const AffiliatePDF = (props) => {
    const content = `
    <div class='pdf'>
    <p style="text-align: center;" class='title terms'><u>Advia affiliate agreement</u></p>
    <p class='subtitle'><u>General Information</u></p>
    <ul>
        <li><strong>Payout Details</strong><br/>120$ for every new paying customer. </li>
        <li><strong>Payout Schedule</strong><br/>Payment will be transferred 15 days after the end of the month.</li>
        <li><strong>Qualified Referral Users</strong><br/>60 days from last click.<br/>Above the age of 18. <br/>New User conducted a first paying session with an advisor.</li>
    </ul>
    <p class='subtitle'><u>Terms Agreement</u></p>
    <ul>
        <li>This agreement is between you, as an individual or the entity which you represent and, Advia technologies Ltd., and it is 
        governed by the following terms and conditions. By filling out and submitting the sign-up form and clicking 
        on the "I agree" button at the end of this agreement, you agree to be bound by the terms and conditions of this agreement.</li>
        <li><strong>Term of Termination</strong><br/>Either party may terminate this agreement at any time, with or without cause, by giving the other party written notice of 
        termination via e-mail. All licenses granted hereunder shall automatically terminate upon the termination of this agreement. 
        None of the obligations that are under this agreement would survive termination.</li>
        <li><strong>General</strong><br/>The company may make changes to the Terms from time to time. When these changes are made, Company will make a 
        new copy of the Terms available at the Application and shall inform You of such changes via the email address You provided 
        during the Account set up. You understand and agree that if You use the Services after the date on which the Terms have 
        changed, Your use of the Service shall be construed as acceptance of the updated Terms. These Terms or the updated version 
        thereof shall constitute the entire agreement between You and Company.</li>
        <li><strong>Advertising guidelines policy</strong><br/>Affiliate agrees to follow the advertising policy instructions herein:
            <ul>
                <li>Affiliate is prohibited using any display and native ads (Taboola, Outbrain) forwarding users directly to Advia website.</li>
                <li>Affiliate is prohibited directing users by any search engine directly to advia website. </li>
                <li>Paid search advertisements must be send users to affiliate’s own website. Also, don’t send users to interstitial page, and then the affiliate website.</li>
                <li>Send only by using link according to Advia affiliate agreement herein.</li>
                <li>Advia doens’t allow to promote Advia by merchant advertisment through bidding media platform, direct media buying, affiliate platforms and, any other paid search on any media channels such as Google, Yahoo MSN, and others.</li>
                <li>Affiliate is not allowed to promote Advia directly through social media such as Facebook, Twitter, Tiktok, Instagram, etc.</li>
                <li>Any Mispelling and variations are prohibitted in an ad copy. </li>
                <li>Do not use any promises, success and guarantees for the service and results.</li>
                <li>Do not Use the word “free” for anything that relating Advia to it services.</li>
                <li>All creatives and content must be approved by Advia before launching</li>
            </ul>
        </li>
    </ul> 
    </div>`

    return (
        <TextPage content={content} pdf={true} />
    )
}

export default AffiliatePDF