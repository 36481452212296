import React from 'react';
import Footer from '../components/footer/Footer'
import AdminNavbar from '../components/navbar/AdminNavbar';

const AdminLayout = (props) =>{
    const {children, categories, userEmail, check403Error} = props;
    return (
  <>
    <AdminNavbar  userEmail = {userEmail} check403Error={check403Error}/>
    {children}
    <Footer categories = {categories}/>
  </>
    )
}

export {AdminLayout}

