import React, { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/periodDropdown.scss";

import icon_dashboard_dropdown_arrow from "../assets/icons/arrows/dashboard_dropdown_arrow.png";
import Moment from "moment";

const PeriodDropDown = (props) => {
  const { dropDownValues, dropDownTitle, setDropDownTitle, setStartDate, setEndDate, tempStartDate, setTempStartDate, tempEndDate, setTempEndDate } = props;

  const [showCalendar, setShowCalendar] = useState(false);

  const onChange = (dates) => {
    const [start, end] = dates;
    setTempStartDate(start);
    setTempEndDate(end);
  };

  const handleSelect = (index) => {
    setDropDownTitle(dropDownValues[index]);

    if (Number(index) === (dropDownValues.length - 1)) {
      setTempStartDate(null);
      setTempEndDate(null);
      setShowCalendar(true);
    } else {
      if (showCalendar) {
        setShowCalendar(false);
      }
      if (Number(index) === 0) {
        const today = new Date();
        today.setDate(today.getDate() - 1);
        const yesterday = Moment(today.toDateString()).format("YYYY-MM-DD");

        setStartDate(yesterday);
        setEndDate(Moment(new Date()).format("YYYY-MM-DD"));
      }
      else {
        if (Number(index) === 1) {
          const today = new Date();
          today.setDate(today.getDate() - 30);
          const yesterday = Moment(today.toDateString()).format("YYYY-MM-DD");

          setStartDate(yesterday);
          setEndDate(Moment(new Date()).format("YYYY-MM-DD"));
        }
        else {
          const today = new Date();
          today.setDate(today.getDate() - 90);
          const yesterday = Moment(today.toDateString()).format("YYYY-MM-DD");

          setStartDate(yesterday);
          setEndDate(Moment(new Date()).format("YYYY-MM-DD"));
        }
      }
    }
  };

  const onClearChanges = () => {
    setTempStartDate(null);
    setTempEndDate(null);
  };

  const onApplyChanges = () => {
    console.log(tempStartDate);
    console.log(tempEndDate)
    if (tempStartDate !== null && tempEndDate !== null) {
      setStartDate(Moment(tempStartDate).format("YYYY-MM-DD"));
      setEndDate(Moment(tempEndDate).format("YYYY-MM-DD"));
      setShowCalendar(false);
    }
  };

  React.useEffect(() => {
    const style = document.querySelector(
      ".dashboard-dropdown > .dropdown-toggle"
    ).style;
    style.setProperty(
      "--arrowURL",
      "url(" + icon_dashboard_dropdown_arrow + ")"
    );
  }, []);

  return (
    <div className="period-drop-down-container">
      {showCalendar ? (
        <div className="picker-date-container">
          <DatePicker
            selected={tempStartDate === null ? (new Date()) : tempStartDate}
            onChange={onChange}
            peekNextMonth
            dateFormat="yyyy-MM-DD"
            startDate={tempStartDate}
            endDate={tempEndDate}
            selectsRange
            inline
          />

          <div className="apply-changes" onClick={() => onApplyChanges()}>Apply changes</div>

          <div className="clear-changes" onClick={() => onClearChanges()}>
            <p>Clear</p>
          </div>
        </div>
      ) : null}

      <DropdownButton
        className="dashboard-dropdown period-dropdown"
        alignRight
        title={dropDownTitle}
        id="dropdown-menu-align-right"
        onSelect={handleSelect}
      >
        {dropDownValues.map((_item, idx) =>
          dropDownTitle === _item ? (
            <Dropdown.Item
              key={idx}
              className="dashboard-dropdown-menu-item dashboard-dropdown-menu-item-selected-color"
              eventKey={idx}
            >
              {_item}
            </Dropdown.Item>
          ) : (
            <Dropdown.Item
              key={idx}
              className="dashboard-dropdown-menu-item dashboard-dropdown-menu-item-unselected-color"
              eventKey={idx}
            >
              {_item}
            </Dropdown.Item>
          )
        )}
      </DropdownButton>
    </div>
  );
};

export default PeriodDropDown;
