import { Form, Col, Button, Row } from "react-bootstrap"
import DatePicker from "react-datepicker";
import { isMobile } from "react-device-detect";
import TimePicker from "react-time-picker";

const ProfileSettingsForm = (props) => {
    const {
        changeProfileSettings,
        fname, firstName,
        lname, lastName,
        checkbox,
        handleChange,
        dateOfBirth, setDateOfBirth,
        timeOfBirth, setTimeOfBirth
    } = props

    return (
        <Form onSubmit={changeProfileSettings}>
            <h4 className="pb-3 pt-5">Profile settings</h4>
            <Form.Group className="mb-3" controlId="formFirstName">
                <Form.Label>Enter first name</Form.Label>
                <Form.Control type="text" className="custom_input" value={fname} onChange={firstName} required pattern=".*\S+.*" title="This field cannot be empty" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formLastName">
                <Form.Label>Enter last name</Form.Label>
                <Form.Control type="text" className="custom_input" value={lname} onChange={lastName} required pattern=".*\S+.*" title="This field cannot be empty" />
            </Form.Group>
            <Form.Group className="mb-3 gender_form" controlId="formLastName">
                <Form.Label>Choose your gender</Form.Label>
                {['radio'].map((type) => (
                    <div key={`inline-${type}`} className={isMobile ? "justify-content-between d-flex" : "mb-3 d-flex"}>
                        <Form.Check
                            inline
                            label="Male"
                            name="group1"
                            type={type}
                            value="Male"
                            id={`inline-${type}-1`}
                            className={"customGender male " + (checkbox === "Male" ? 'active' : '')}
                            onChange={handleChange}
                            checked={checkbox === "Male"}
                        />
                        <Form.Check
                            inline
                            label="Female"
                            name="group1"
                            value="Female"
                            type={type}
                            id={`inline-${type}-2`}
                            className={"customGender female " + (checkbox === "Female" ? 'active' : '')}
                            onChange={handleChange}
                            checked={checkbox === "Female"}
                        />
                        <Form.Check
                            inline
                            label="Other"
                            name="group1"
                            type={type}
                            value="Other"
                            id={`inline-${type}-3`}
                            className={"customGender other me-0 " + (checkbox === "Other" ? 'active' : '')}
                            onChange={handleChange}
                            checked={checkbox === "Other"}
                        />
                    </div>
                ))}
            </Form.Group>
            <Row>
                <Col lg={6} >
                    <Form.Group className="mb-3 gender_form" controlId="formBirthDate">
                        <Form.Label>Date of Birth</Form.Label>
                        <DatePicker
                            selected={dateOfBirth}
                            onChange={(date) => setDateOfBirth(date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="dateSelect"
                            timeInputLabel="Time:"
                            dateFormat="yyyy-MM-dd"
                            maxDate={new Date()}
                        // showTimeInput
                        />
                    </Form.Group>
                </Col>
                <Col lg={6} >
                    <Form.Group className="mb-3 gender_form" controlId="formBirthHour">
                        <Form.Label>Time of Birth</Form.Label>
                        <TimePicker
                            value={timeOfBirth}
                            onChange={(date) => setTimeOfBirth(date)}
                            open={false}// peekNextMonth
                            disableClock={true}
                            closeClock={true}
                            className="hourSelect customTimeBorder"
                            timeInputLabel="Time:"
                            dateFormat="HH:mm:ss"
                            showTimeInput
                            clearIcon={null}
                        />
                    </Form.Group>
                </Col>
                <div className="msg">*By providing more details the easier it will be for the advisor to provide better answers. Your details will always remain confidential.</div>
            </Row>
            <Button type="submit" className="saveButton my-5">Save changes</Button>
        </Form >

    )
}

export default ProfileSettingsForm