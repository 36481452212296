import * as dataJSON from './dashboard.json';
import * as dataJSONFAQ from './faq.json'
import * as dataJSONAbout from './aboutAdvia.json'

const dashBoardData = dataJSON.dashBoardData;
const faqData = {
    clientGeneralQuestions: dataJSONFAQ.clientGeneralQuestions,
    clientPaymentQuestions: dataJSONFAQ.clientPaymentQuestions,
    advisorGeneralQuestions: dataJSONFAQ.advisorGeneralQuestions,
    advisorPaymentQuestions: dataJSONFAQ.advisorPaymentQuestions
}

const aboutUs = dataJSONAbout.aboutUs;
export { dashBoardData, faqData, aboutUs };