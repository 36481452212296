import React from "react"
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import SingleSlide from './Slide';
import { imagesApiURL } from "../../../constants/AppConstants";
import free3MinutesIcon from '../../../assets/icons/free_3_minutes_icon.png'
import SatisfactionGuaranteedIcon from '../../../assets/icons/satisfaction_guaranteed_icon.png'
import { isMobileOnly } from "react-device-detect";
import video from '../../../assets/videos/top_banner_hero_01.mp4'

let timeOut1;
let timeOut2;

const HomeSlider = (props) => {
  const { carouselSlidesData } = props
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    loop: true,
    duration: 1000,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    }
  });

  const userLogged = localStorage.getItem('userToken');
  const has3mins = parseInt(localStorage.getItem('has3mins')) === 1 || !userLogged;
  const player = React.createRef();

  React.useEffect(() => {
    if (currentSlide === 0) {
      clearTimeout(timeOut2);
      setTimeout(() => {
        if (player && player.current) {
          if (player.current.getState().player.currentTime > 0) {
            player.current.seek(0);
          }
        }
      }, 1000)
      timeOut1 = setTimeout(() => {
        if (player && player.current) {
          if (player.current.getState().player.currentTime > 0) {
            player.current.seek(0);
          }
          player.current.play();
        }
        if (slider) {
          slider.next();
        }
      }, 4000)
    }
    else {
      clearTimeout(timeOut1)
      timeOut2 = setTimeout(() => {
        if (slider) {
          slider.next();
        }
      }, 25000)
    }
  }, [slider, currentSlide])

  const slides =
    <>
      <div className='keen-slider__slide number-slide0'
        key={1}>
        {carouselSlidesData[0] ?
          <SingleSlide
            background={imagesApiURL + carouselSlidesData[0].image}
            imageTitle="Advia features the<br/><span>best psychics</span> in the world. <br/> Get guidance and clarity!"
            button="Start a Session"
            icon1={has3mins ? free3MinutesIcon : ''}
            iconDesc1={has3mins ? '3 Free<br/>Minutes' : ''}
            icon2={SatisfactionGuaranteedIcon}
            iconDesc2='Satisfaction<br />Guaranteed'
          />
          : null}
      </div>
      {
        !isMobileOnly ?
          <div className='keen-slider__slide number-slide1'
            key={2}>
            <SingleSlide
              isVideo={true}
              background={video}
              ref={player} />
          </div>
          : null
      }
    </>

  return (
    <>
      <div className="navigation-wrapper">
        <div ref={sliderRef} className="keen-slider hp">
          {slides}
        </div>
        {isMobileOnly ?
          (
            slider && (
              <div className="dots">
                {[...Array(slider.details().size).keys()].map((idx) => {
                  return (
                    <button
                      key={idx}
                      onClick={() => {
                        slider.moveToSlideRelative(idx)
                      }}
                      className={"dot" + (currentSlide === idx ? " active" : "")}
                    />
                  )
                })}
              </div>
            )
          )
          :
          (
            slider && (
              <>
                <ArrowLeft
                  onClick={(e) => e.stopPropagation() || slider.prev()}
                  disabled={currentSlide === 0}
                />
                <ArrowRight
                  onClick={(e) => e.stopPropagation() || slider.next()}
                  disabled={currentSlide === slider.details().size - 1}
                />
              </>
            )
          )}
      </div>

    </>
  )
}

function ArrowLeft(props) {
  const disabeld = props.disabled ? " arrow--disabled" : ""
  return (
    <svg
      onClick={props.onClick}
      className={"arrow arrow--left" + disabeld}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
    </svg>
  )
}

function ArrowRight(props) {
  const disabeld = props.disabled ? " arrow--disabled" : ""
  return (
    <svg
      onClick={props.onClick}
      className={"arrow arrow--right" + disabeld}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
    </svg>
  )
}

export default HomeSlider
