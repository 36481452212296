import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Container,
  Card,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import axios from "axios";
import Popup from "react-popup";
import PulseLoader from "react-spinners/PulseLoader";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../node_modules/video-react/dist/video-react.css";
import "../../../styles/allChats.scss";
import "../../../styles/custom.popup.css";

import * as myAppConfig from "../../../constants/AppConstants";

import ChatHistoryItemCell from "../../../components/chat/ChatHistoryItemCell";
import ChatComponent from "../../../components/chat/ChatComponent";
import AccountManageActionModal from "../../../components/modals/AccountManageActionModal";
import EndSessionModal from "../../../components/modals/EndSessionModal";
import * as utils from "../../../Utilities/Utils";
import SessionsInfoComponent from "../../../components/chat/SessionsInfoComponent";
import { isMobile } from "react-device-detect";
import { Modal } from "react-bootstrap";
import arrow from "../../../assets/icons/arrows/arrow_left_icon.png";
import ClientSessionsNotes from "../../../components/chat/ClientSessionsNotes";

const Chat = require("twilio-chat");

export default function AllChats(props) {
  const { check403Error } = props;

  const userType = localStorage.getItem("userType");
  const isClient = Number(userType) === myAppConfig.USER_TYPES.CLIENT;

  const [conversationsList, setConversationsList] = useState([]);
  const [conversationsInfoList, setConversationsInfoList] = useState([]);
  const [allChatConversationsList, setAllChatConversationsList] = useState([]);
  const [allChatSessionsList, setAllChatSessionsList] = useState([]);
  const [allCallConversationsList, setAllCallConversationsList] = useState([]);
  const [allVideoConversationsList, setAllVideoConversationsList] = useState(
    []
  );
  const [clientSessions, setClientSessions] = useState(null);
  const [showClientSessions, setShowClientSessions] = useState(false);
  const [notes, setNotes] = useState("");

  const [chatMessages, setChatMessages] = useState([]);
  const [newChatMessages, setNewChatMessages] = useState([]);

  const [channel, setChannel] = useState();
  const [client, setClient] = useState();
  const [identity, setIdentity] = useState("");
  const [typing, setTyping] = useState(false);
  const [otherJoined, setOtherJoined] = useState(false);

  const [showLoader, setShowLoader] = useState(true);

  const [currentConversationIndex, setCurrentConversationIndex] = useState(-1);

  const [showAccountManageModal, setShowAccountActionModal] = useState(false);
  const [accountActionType, setAccountActionType] = useState("");

  const [showEndSession, setShowEndSession] = useState(false);

  const [conversationType, setConversationType] = useState(
    myAppConfig.CONVERSATION_TYPE.CHAT
  );

  const openConversation = window.location.search.length > 0;

  const NO_CONV_ACCEPTED = "NO_CONV_ACCEPTED";
  var current_conv = NO_CONV_ACCEPTED;
  if (
    utils.getCurrentConversationAccepted() !== undefined &&
    utils.getCurrentConversationAccepted() !== null &&
    utils.getCurrentConversationAccepted() !== ""
  ) {
    current_conv = utils.getCurrentConversationAccepted();
  }

  var current_conversation_accepted = current_conv;
  var timerForActiveConversation;
  const activeConversationTimeForReload = 10 * 1000;

  const [headerTitle, setHeaderTitle] = useState("All Chats");
  const [noOfConversations, setNoOfConversations] = useState(0);

  const [showMobileChat, setShowMobileChat] = useState(false);

  const [
    currentActiveConversationOldMessages,
    setCurrentActiveConversationOldMessages,
  ] = useState();
  const [
    currentActiveConversationNewMessages,
    setCurrentActiveConversationNewMessages,
  ] = useState([]);

  const [outsideTarget, setOutSideTarget] = useState(null);
  const currentScreenConversation = useRef();
  const handleOutsideClick = (event) => {
    if (
      currentScreenConversation &&
      currentScreenConversation.current !== null &&
      !currentScreenConversation.current.contains(event.target)
    ) {
      if (
        document.querySelector(".modal") === null //&& event.target.pathname !== undefined
      ) {
        console.log("handleOutsideClick");
        setOutSideTarget(event.target);
        setShowEndSession(true);
      }
    }
  };

  var timerForNotResponding, timerForIsInConversation;
  const [isNotResponding, setIsNotResponding] = useState(false);
  const [isInConversation, setIsInConversation] = useState(false);

  useEffect(() => {
    if (isNotResponding) {
      timerForNotResponding = setTimeout(() => btnEndSessionClick(), 60 * 1000);
    } else {
      clearTimeout(timerForNotResponding);
    }
    return () => clearTimeout(timerForNotResponding);
  }, [isNotResponding]);

  useEffect(() => {
    if (isInConversation) {
      timerForIsInConversation = setTimeout(() => {
        setIsNotResponding(true);
        setShowEndSession(true);
      }, myAppConfig.final_waiting_time_for_conversation_response);
    } else {
      clearTimeout(timerForIsInConversation);
    }
    return () => clearTimeout(timerForIsInConversation);
  }, [isInConversation]);

  useEffect(() => {
    console.log(
      "showLoader ",
      showLoader,
      conversationsInfoList.length,
      conversationType,
      current_conversation_accepted
    );

    if (showLoader === false && conversationsInfoList.length > 0) {
      var foundConversationIndex = -1;
      if (
        conversationType === myAppConfig.CONVERSATION_TYPE.CHAT &&
        current_conversation_accepted !== NO_CONV_ACCEPTED
      ) {
        document.addEventListener("mousedown", handleOutsideClick);

        foundConversationIndex = findConversationIndexByConversationId(
          current_conversation_accepted
        );

        openChatConversation(foundConversationIndex);
        checkConversationStatus(foundConversationIndex);
      }

      if (
        openConversation &&
        current_conversation_accepted === NO_CONV_ACCEPTED
      ) {
        let urlStrings = window.location.search.replace("?", "").split("/");
        if (urlStrings.length > 1) {
          let type = urlStrings[0];
          let id = urlStrings[1];

          foundConversationIndex = findConversationIndexByConversationId(id);
          if (type === myAppConfig.CONVERSATION_TYPE.CHAT.name) {
            openChatConversation(foundConversationIndex);
          } else {
            if (foundConversationIndex > -1) {
              console.log("1. call handleCallVideoCellSelect");
              handleCallVideoCellSelect(foundConversationIndex);
            } else {
              console.log("2. call handleCallVideoCellSelect");
              handleCallVideoCellSelect(0);
            }
          }
        }
      }
    }

    function openChatConversation(foundConversationIndex) {
      if (foundConversationIndex > -1) {
        console.log("1. call handleChatCellSelect");
        handleChatCellSelect(foundConversationIndex);
      } else {
        console.log("2. call handleChatCellSelect");
        handleChatCellSelect(0);
      }
    }

    function findConversationIndexByConversationId(id) {
      var foundConversationIndex = -1;
      for (var i = 0; i < conversationsInfoList.length; i++) {
        if (
          conversationsInfoList[i] !== undefined &&
          ((conversationsInfoList[i].conversation_id !== undefined &&
            Number(conversationsInfoList[i].conversation_id) === Number(id)) ||
            (conversationsInfoList[i].id !== undefined &&
              Number(conversationsInfoList[i].id) === Number(id)))
        ) {
          foundConversationIndex = i;
          console.log("foundConversationIndex ", foundConversationIndex);
          break;
        }
      }
      return foundConversationIndex;
    }

    return () => {
      //on unmount
      if (!showLoader) {
        if (window.location.pathname !== "/station") {
          utils.setCurrentConversationAccepted("");

          console.log("clear timeout");
          clearTimeout(timerForActiveConversation);

          document.removeEventListener("mousedown", handleOutsideClick);
        }
      }
    };
  }, [showLoader]);

  const getSubscribedChannels = (_client, joined) => {
    _client.getSubscribedChannels().then(function (paginator) {
      console.log("Station getSubscribedChannels");
      let uniqueNameList = "";
      for (var i = 0; i < paginator.items.length; i++) {
        uniqueNameList += paginator.items[i].uniqueName + ";";
      }
      if (uniqueNameList !== "") {
        uniqueNameList = uniqueNameList.slice(0, -1);
      }

      if (
        joined &&
        current_conversation_accepted !== NO_CONV_ACCEPTED &&
        !uniqueNameList.includes(current_conversation_accepted)
      ) {
        uniqueNameList += ";" + current_conversation_accepted;
      }

      if (
        current_conversation_accepted === NO_CONV_ACCEPTED ||
        (current_conversation_accepted !== NO_CONV_ACCEPTED &&
          uniqueNameList.includes(current_conversation_accepted))
      ) {
        setConversationsList(paginator.items);
        getConversationsInfo(uniqueNameList, paginator.items);
        //getCallVideoList(myAppConfig.CONVERSATION_TYPE.CHAT);
      }
    });
  };
  const getChannels = (token) => {
    console.log("getChannels: " + conversationType.name);
    Chat.Client.create(token)
      .then((client) => {
        setClient(client);

        client.on("tokenAboutToExpire", () => {
          client.updateToken(token);
        });

        client.on("tokenExpired", () => {
          client.updateToken(token);
        });

        client.on("connectionStateChanged", (connection) => {
          console.log("ConnectionStateChanged: ", connection);
        });

        // Listen for new invitations to your Client
        client.on("channelInvited", function (channel) {
          console.log("Invited to channel " + channel.friendlyName);
          // Join the channel that you were invited to
          channel.join();

          console.log("Call getSubscribedChannels after joining channel");
          getSubscribedChannels(client, true);
        });

        getSubscribedChannels(client, false);
      })
      .catch((err) => {
        console.log("error ", err);
        alert("Error \n" + err.body.message);
      });
  };

  const getChatConversationToken = () => {
    console.log(
      "getConversationToken: " + myAppConfig.CONVERSATION_TYPE.CHAT.name
    );
    axios
      .get(myAppConfig.GET_CHAT_TOKEN, {
        params: { session_type: myAppConfig.CONVERSATION_TYPE.CHAT.name },
        headers: { token: localStorage.getItem("userToken") },
      })
      .then((response) => {
        if (response.data.status === "success") {
          const token = response.data.token;
          setIdentity(response.data.identity);

          getChannels(token);
        }
      })
      .catch((error) => check403Error(error));
  };

  const getConversationsInfo = async (uniqueNameList, listOfConversations) => {
    console.log("getConversationsInfo: ", uniqueNameList);
    axios
      .get(myAppConfig.GET_ACCOUNTS_INFO_FOR_CHAT_CONVERSATIONS, {
        params: {
          conversations_info: uniqueNameList,
        },
        headers: {
          token: localStorage.getItem("userToken"),
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          if (
            response.data.infoConversations &&
            response.data.infoConversations.length > 0
          ) {
            let infoConversations =
              listOfConversations.length > 0
                ? getSortedConversations(
                    response.data.infoConversations,
                    listOfConversations
                  )
                : [];
            setAllChatConversationsList(infoConversations);
            setNoOfConversations(infoConversations.length);
            setConversationsInfoList(infoConversations);
          }
          //setShowLoader(false);
          getCallVideoList(myAppConfig.CONVERSATION_TYPE.CHAT);
        }
      })
      .catch((error) => check403Error(error));
  };

  const getSortedConversations = (infoConversations, listOfConversations) => {
    let members = infoConversations.map((member) => {
      const channel = listOfConversations.find(
        (obj) =>
          Number(obj.channelState.uniqueName) === Number(member.conversation_id)
      );

      if (channel === undefined) return;
      return {
        ...member,
        channel: channel,
      };
    });

    let minDate = new Date(-8640000000000000);
    try {
      if (members.length > 0) {
        members = members.filter((n) => n);
        let sortedConversations = members.sort(
          (a, b) =>
            Date.parse(
              a.channel.channelState !== undefined &&
                a.channel.channelState.lastMessage !== undefined
                ? a.channel.channelState.lastMessage.dateCreated
                : new Date(minDate)
            ) -
            Date.parse(
              b.channel.channelState !== undefined &&
                b.channel.channelState.lastMessage !== undefined
                ? b.channel.channelState.lastMessage.dateCreated
                : new Date(minDate)
            )
        );

        return sortedConversations.reverse();
      } else return [];
    } catch (err) {
      console.log("error in sorting conversations");
    }
  };

  useEffect(() => {
    if (document.querySelector(".modal") === null) {
      if (
        openConversation &&
        current_conversation_accepted === NO_CONV_ACCEPTED
      ) {
        let urlStrings = window.location.search.replace("?", "").split("/");
        if (urlStrings.length > 1) {
          switch (urlStrings[0]) {
            case myAppConfig.CONVERSATION_TYPE.VOICE.name:
              setShowLoader(false);
              selectConversationType(myAppConfig.CONVERSATION_TYPE.VOICE, true);
              break;
            case myAppConfig.CONVERSATION_TYPE.VIDEO.name:
              setShowLoader(false);
              selectConversationType(myAppConfig.CONVERSATION_TYPE.VIDEO, true);
              break;
            default:
              getChatConversationToken();
              break;
          }
        } else {
          getChatConversationToken();
        }
      } else {
        getChatConversationToken();
      }
    }
  }, []);

  const checkActiveConversation = (conversation_id) => {
    console.log("checkActiveConversation", conversation_id, 1);
    var currentConv = utils.getCurrentConversationAccepted();
    if (
      currentConv !== NO_CONV_ACCEPTED &&
      currentConv !== "" &&
      currentConv === conversation_id
    ) {
      axios
        .get(myAppConfig.CHECK_ACTIVE_CONVERSATION, {
          params: {
            conversation_id: conversation_id,
            from: 1, //advisor
          },
          headers: {
            token: localStorage.getItem("userToken"),
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            console.log("response get active conversation");
            clearTimeout(timerForActiveConversation);
            timerForActiveConversation = setTimeout(
              () => checkActiveConversation(conversation_id),
              activeConversationTimeForReload
            );
          } else {
            clearTimeout(timerForActiveConversation);

            let message = utils.displayErrorMessage(
              "Error",
              response.data.message !== ""
                ? response.data.message
                : "An error has occured!"
            );
            setAccountActionType(message);
            setShowAccountActionModal(true);
          }
        })
        .catch((err) => {
          console.log("aici errror");
          check403Error(err);
        });
    }
  };

  const handleChatCellSelect = (index) => {
    setChatMessages([]);
    setNewChatMessages([]);

    setNotes("");
    setClientSessions(null);
    setShowClientSessions(false);

    if (
      channel !== null &&
      channel !== undefined &&
      channel.channelState.uniqueName !== current_conversation_accepted
    ) {
      channel.removeAllListeners();
      console.log("Channel removeAllListeners");
    }

    const myChannel = conversationsList.find(
      (obj) =>
        conversationsInfoList[index] !== undefined &&
        Number(obj.channelState.uniqueName) ===
          Number(conversationsInfoList[index].conversation_id)
    );

    setCurrentConversationIndex(index);
    if (myChannel !== undefined) {
      myChannel.getMessages().then((messages) => {
        if (messages.items.length > 0 && isCurrentActiveConversation(index)) {
          let lastMsg = messages.items[messages.items.length - 1];
          if (
            lastMsg.author !== identity &&
            utils.IsJsonSessionStart(lastMsg.body)
          ) {
            var lastMessage = messages.items.pop();
            setNewChatMessages((newChatMessages) => [
              ...newChatMessages,
              lastMessage,
            ]);

            setNewChatMessages((newChatMessages) => [
              ...newChatMessages,
              conversationsInfoList[index].clientName + " joined the chat",
            ]);

            setNewChatMessages((newChatMessages) => [
              ...newChatMessages,
              "You joined the chat",
            ]);

            setCurrentActiveConversationNewMessages((newChatMessages) => [
              ...newChatMessages,
              lastMessage,
            ]);

            setCurrentActiveConversationNewMessages((newChatMessages) => [
              ...newChatMessages,
              conversationsInfoList[index].clientName + " joined the chat",
            ]);

            setCurrentActiveConversationNewMessages((newChatMessages) => [
              ...newChatMessages,
              "You joined the chat",
            ]);
          }
        }

        if (isCurrentActiveConversation(index)) {
          if (currentActiveConversationOldMessages === undefined) {
            setCurrentActiveConversationOldMessages(messages.items);
            setChatMessages(messages.items);
          } else {
            setChatMessages(currentActiveConversationOldMessages);
            setNewChatMessages(currentActiveConversationNewMessages);
          }
        } else {
          setChatMessages(messages.items);
        }
      });

      let uniqueName = myChannel.channelState.uniqueName;

      if (
        client !== undefined &&
        isCurrentActiveConversation(index) &&
        channel === undefined
      ) {
        client
          .getChannelByUniqueName(uniqueName.toString())
          .then((channel) => {
            console.log("getChannelByUniqueName ", channel);
            setChannel(channel);

            if (
              current_conversation_accepted !== NO_CONV_ACCEPTED &&
              current_conversation_accepted !== ""
            ) {
              checkActiveConversation(current_conversation_accepted);
              setOtherJoined(true);
            } else {
              setOtherJoined(false);
            }

            if (channel.channelState.status !== "joined") {
              console.log("joined");
              channel.join();
            }

            client.on("channelJoined", (channel) => {
              console.log("channel joined");
            });

            // Listen for new invitations to your Client
            client.on("channelInvited", function (channel) {
              console.log("Invited to channel " + channel.friendlyName);
              channel.join();
            });

            channel.on("messageAdded", (message) => {
              console.log("All Chats message added ", message.body);

              if (message.author !== identity) {
                utils.playNotificationSound();
              }

              setNewChatMessages((newChatMessages) => [
                ...newChatMessages,
                message,
              ]);

              setCurrentActiveConversationNewMessages(
                (currentActiveConversationNewMessages) => [
                  ...currentActiveConversationNewMessages,
                  message,
                ]
              );

              if (
                message.author !== identity &&
                utils.IsJsonString(message.body)
              ) {
                Popup.create({
                  title: "End session",
                  content: utils.parseJson(message.body),
                });
                //setOtherJoined(false);

                utils.setCurrentConversationAccepted("");
                current_conversation_accepted = NO_CONV_ACCEPTED;
                document.removeEventListener("mousedown", handleOutsideClick);

                setIsInConversation(false);
                setIsNotResponding(false);

                channel.removeAllListeners();
              }

              if (
                message.author === identity &&
                !utils.IsJsonString(message.body)
              ) {
                setIsInConversation(false);
                setIsInConversation(true);
              }
            });

            channel.on("typingStarted", function (member) {
              setTyping(true);
            });

            channel.on("typingEnded", function (member) {
              setTyping(false);
            });

            channel.on("memberLeft", function (member) {
              console.log(member.identity + " has left the channel. D");
            });
          })
          .catch((err) => {
            //debugger;
          });
      } else {
        setChannel(myChannel);
      }
    }

    setShowMobileChat(true);
  };

  const handleCallVideoCellSelect = (index) => {
    console.log("handleCallCellSelect ", index);

    setNotes("");
    setClientSessions(null);
    setShowClientSessions(false);
    setCurrentConversationIndex(index);
  };

  const setClientSessionsList = () => {
    if (!showClientSessions) {
      if (currentConversationIndex > -1) {
        let clientID = "";
        if (conversationType === myAppConfig.CONVERSATION_TYPE.CHAT) {
          setClientSessions(
            allChatSessionsList.find(
              (obj) =>
                Number(obj.client_id) ===
                Number(conversationsInfoList[currentConversationIndex].clientId)
            )
          );
          clientID = conversationsInfoList[currentConversationIndex].clientId;
        } else {
          setClientSessions(conversationsInfoList[currentConversationIndex]);
          clientID = conversationsInfoList[currentConversationIndex].client_id;
        }
        getClientNotes(clientID);
      }
    }
    setShowClientSessions(!showClientSessions);
  };

  const sendMessage = (text) => {
    if (text) {
      channel.sendMessage(String(text).trim());
    }
  };

  const imageChange = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    channel.sendMessage(formData);
  };

  const onNewSession = () => {
    let advisorInfo = {};
    advisorInfo["id"] =
      conversationsInfoList[currentConversationIndex].advisorId;
    advisorInfo["name"] =
      conversationsInfoList[currentConversationIndex].advisorName;
    advisorInfo["logo_image"] =
      conversationsInfoList[currentConversationIndex].advisorLogo;
    advisorInfo["category"] =
      conversationsInfoList[currentConversationIndex].advisor_categories;
    sessionStorage.setItem("otherTalker", JSON.stringify(advisorInfo));
  };

  const onChangeSearchText = (ev) => {
    let value = ev.target.value;

    let filteredList = [];

    if (conversationType === myAppConfig.CONVERSATION_TYPE.CHAT) {
      allChatConversationsList.forEach((element) => {
        if (
          isClient &&
          element.advisorName.toLowerCase().includes(value.toLowerCase())
        ) {
          filteredList.push(element);
        } else if (
          !isClient &&
          element.clientName.toLowerCase().includes(value.toLowerCase())
        ) {
          filteredList.push(element);
        }
      });
    } else if (conversationType === myAppConfig.CONVERSATION_TYPE.VOICE) {
      allCallConversationsList.forEach((element) => {
        if (
          (element.clientProfile !== undefined &&
            element.clientProfile[0].first_name
              .toLowerCase()
              .includes(value.toLowerCase())) ||
          element.clientProfile[0].last_name
            .toLowerCase()
            .includes(value.toLowerCase())
        ) {
          filteredList.push(element);
        }
      });
    } else if (conversationType === myAppConfig.CONVERSATION_TYPE.VIDEO) {
      allVideoConversationsList.forEach((element) => {
        if (
          (element.clientProfile !== undefined &&
            element.clientProfile[0].first_name
              .toLowerCase()
              .includes(value.toLowerCase())) ||
          element.clientProfile[0].last_name
            .toLowerCase()
            .includes(value.toLowerCase())
        ) {
          filteredList.push(element);
        }
      });
    }

    setCurrentConversationIndex(-1);
    setConversationsInfoList([...filteredList]);
  };

  const onAccountActionBtnClick = () => {
    utils.setCurrentConversationAccepted("");
    window.location.pathname = "/";
  };

  const showModalEndSession = () => {
    setOutSideTarget(null);
    setShowEndSession(true);
  };

  const btnEndSessionClick = () => {
    setShowEndSession(false);
    console.log("Advisor end session click", channel);
    clearTimeout(timerForActiveConversation);

    if (channel !== undefined) {
      console.log("conv_id end session: " + channel.channelState.uniqueName);
      channel.sendMessage(
        '{"msg":"' + localStorage.getItem("userFullName") + ' left the chat"}'
      );
    }

    utils.setCurrentConversationAccepted("");

    if (outsideTarget === null) {
      window.location.pathname = "/";
    } else outsideTarget.click();
  };

  function selectConversationType(type, allowSelect) {
    if (type !== conversationType && (!showLoader || allowSelect)) {
      setCurrentConversationIndex(-1);
      setNotes("");
      setClientSessions(null);
      setShowClientSessions(false);

      console.log("selectConversationType ", type);

      if (type === myAppConfig.CONVERSATION_TYPE.CHAT) {
        setHeaderTitle("All Chats");
        if (allChatConversationsList.length === 0) {
          getChatConversationToken();
        } else {
          setNoOfConversations(allChatConversationsList.length);
          setConversationsInfoList([...allChatConversationsList]);
        }
      } else if (type === myAppConfig.CONVERSATION_TYPE.VOICE) {
        setHeaderTitle("All Calls");
        if (allCallConversationsList.length === 0) {
          getCallVideoList(type);
        } else {
          setConversationsInfoList([...allCallConversationsList]);
          setNoOfConversations(allCallConversationsList.length);
        }
      } else if (type === myAppConfig.CONVERSATION_TYPE.VIDEO) {
        setHeaderTitle("All Videos");
        if (allVideoConversationsList.length === 0) {
          getCallVideoList(type);
        } else {
          setConversationsInfoList([...allVideoConversationsList]);
          setNoOfConversations(allVideoConversationsList.length);
        }
      }

      setConversationType(type);
    }
  }

  function getCallVideoList(type) {
    console.log("calling get call list");
    setShowLoader(true);
    axios
      .get(myAppConfig.GET_CALL_LIST, {
        params: {
          conversation_type: type.id,
        },
        headers: {
          token: localStorage.getItem("userToken"),
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          if (type === myAppConfig.CONVERSATION_TYPE.CHAT) {
            setAllChatSessionsList(response.data.listOfConversations);
          } else {
            if (type === myAppConfig.CONVERSATION_TYPE.VOICE) {
              setAllCallConversationsList(response.data.listOfConversations);
            } else {
              setAllVideoConversationsList(response.data.listOfConversations);
            }
            setConversationsInfoList([...response.data.listOfConversations]);
            setNoOfConversations(response.data.listOfConversations.length);
          }
        }
        setShowLoader(false);
      });
  }

  const isCurrentActiveConversation = (index) => {
    if (index !== undefined && index !== -1) {
      let response =
        current_conversation_accepted !== NO_CONV_ACCEPTED &&
        current_conversation_accepted !== "" &&
        utils.getCurrentConversationAccepted() !== "" &&
        conversationsInfoList[index] !== undefined &&
        Number(current_conversation_accepted) ===
          Number(conversationsInfoList[index].conversation_id);

      //console.log("isCurrentActiveConversation ", response);
      return response;
    } else return false;
  };

  const renderChatComponent = () => {
    return (
      <>
        {currentConversationIndex > -1 ? (
          <ChatComponent
            key={currentConversationIndex}
            isNewChat={
              isClient
                ? false
                : isCurrentActiveConversation(currentConversationIndex)
            }
            chatMessages={chatMessages}
            setChatMessages={setChatMessages}
            newChatMessages={
              isCurrentActiveConversation(currentConversationIndex)
                ? newChatMessages
                : []
            }
            showModalEndSession={showModalEndSession}
            identity={identity}
            typing={typing}
            channel={channel}
            imageChange={imageChange}
            sendMessage={sendMessage}
            otherTalkerName={
              isClient
                ? conversationsInfoList[currentConversationIndex].advisorName
                : conversationsInfoList[currentConversationIndex].clientName
            }
            otherTalkerLogoImage={
              isClient
                ? conversationsInfoList[currentConversationIndex].advisorLogo
                : conversationsInfoList[currentConversationIndex].clientLogo
            }
            otherTalkerCategory={
              isClient
                ? conversationsInfoList[currentConversationIndex]
                    .advisor_categories
                : ""
            }
            last_review={
              conversationsInfoList[currentConversationIndex].last_review
            }
            allowNewSession={
              conversationsInfoList[currentConversationIndex].advisorStatus !==
                null &&
              conversationsInfoList[currentConversationIndex].advisorStatus !==
                undefined &&
              Number(
                conversationsInfoList[currentConversationIndex].advisorStatus
              ) === myAppConfig.ADVISOR_STATUS_STATES.available.id
            }
            //allowNewSession={true}
            joinedChannel={true}
            otherJoined={otherJoined}
            advisorInfo={onNewSession()}
            showEndSessionButton={isCurrentActiveConversation(
              currentConversationIndex
            )}
          />
        ) : null}
      </>
    );
  };

  function getClientNotes(client_id) {
    if (client_id !== undefined) {
      const fetchTasks = utils.getClientNotes(client_id);

      Promise.all([fetchTasks])
        .then(function (res) {
          setNotes(res);
        })
        .catch((error) => {
          console.log("error on fetchTasks: " + error);
        });
    }
  }

  function checkConversationStatus(client_conversation_index) {
    console.log("call checkConversationStatus");
    const fetchTasks = utils.checkConversationStatus(
      utils.getCurrentConversationAccepted(),
      myAppConfig.CONVERSATION_TYPE.CHAT.id
    );

    Promise.all([fetchTasks])
      .then(function (res) {
        if (res.length > 0) {
          if (res[0] === false) {
            if (document.querySelector(".modal") === null) {
              console.log("call checkConversationStatus response");

              let message = utils.displayErrorMessage(
                "Sorry",
                utils.getCancelConversationMessage(
                  conversationsInfoList[client_conversation_index].clientName
                )
              );
              setAccountActionType(message);
              setShowAccountActionModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log("error on fetchTasks: " + error);
      });
  }

  return (
    <>
      <Popup />
      <EndSessionModal
        show={showEndSession}
        setShowEndSession={() => setShowEndSession(false)}
        btnEndSessionClick={() => btnEndSessionClick()}
        isNotResponding={isNotResponding}
        btnContinueConversation={() => {
          setIsNotResponding(false);
          setShowEndSession(false);
        }}
      />

      <AccountManageActionModal
        show={showAccountManageModal}
        setShowAccountActionModal={() => setShowAccountActionModal(false)}
        actionType={accountActionType}
        onAccountAction={onAccountActionBtnClick}
        btnText={"Ok"}
        hideClose={true}
      />
      <div
        ref={currentScreenConversation}
        className={
          "all-chats-screen " + (isMobile ? "mobile-all-chats-screen" : "")
        }
      >
        <div
          className="all-chats-spinner "
          style={{ display: showLoader ? "block" : "none" }}
        >
          <PulseLoader color={"white"} size={"15px"} />
        </div>

        <Container
          fluid
          style={{
            width: isMobile
              ? "100%"
              : isClient || !showClientSessions
              ? "74%"
              : "95%",
            height: "100%",
          }}
        >
          <div
            className="conversation-type-container"
            style={{ display: isClient ? "none" : "" }}
          >
            <div
              className={
                "conversation-type-item " +
                (conversationType === myAppConfig.CONVERSATION_TYPE.CHAT
                  ? "conversation-type-item-selected"
                  : "conversation-type-item-unselected")
              }
              onClick={() =>
                selectConversationType(myAppConfig.CONVERSATION_TYPE.CHAT)
              }
            >
              Chat
            </div>
            <div
              className={
                "conversation-type-item " +
                (conversationType === myAppConfig.CONVERSATION_TYPE.VOICE
                  ? "conversation-type-item-selected"
                  : "conversation-type-item-unselected")
              }
              onClick={() =>
                selectConversationType(myAppConfig.CONVERSATION_TYPE.VOICE)
              }
            >
              Call
            </div>
            <div
              className={
                "conversation-type-item " +
                (conversationType === myAppConfig.CONVERSATION_TYPE.VIDEO
                  ? "conversation-type-item-selected"
                  : "conversation-type-item-unselected")
              }
              onClick={() =>
                selectConversationType(myAppConfig.CONVERSATION_TYPE.VIDEO)
              }
            >
              Video
            </div>
          </div>

          <Row style={{ height: "inherit" }}>
            <Col
              lg={isClient ? 12 : showClientSessions ? 8 : 11}
              style={{ height: "inherit" }}
            >
              <Card
                className={
                  "all-chats-container " +
                  (isMobile ? "all-chats-container-mobile" : "")
                }
              >
                <Row style={{ height: "100%" }}>
                  <Col lg={5} className="chat-list-container">
                    <div className="chat-list-top">
                      <p className="heading">{headerTitle}</p>
                      <p className="session-no">
                        You have {noOfConversations} completed sessions
                      </p>

                      <Form>
                        <FormControl
                          onChange={onChangeSearchText}
                          type="search"
                          placeholder="Search session..."
                          className={
                            "searchInput search-input-advisors chat-search"
                          }
                          aria-label="Search"
                        />
                      </Form>
                    </div>

                    <div className="chat-history-users-container">
                      {conversationsInfoList.map((conversation, idx) =>
                        conversation === undefined ? null : (
                          <ChatHistoryItemCell
                            key={idx}
                            index={idx}
                            conversation={conversation}
                            handleChatCellSelect={handleChatCellSelect}
                            handleCallVideoCellSelect={
                              handleCallVideoCellSelect
                            }
                            currentConversationIndex={currentConversationIndex}
                            subscribedChannels={conversationsList}
                            isClient={isClient}
                            conversationType={conversationType}
                          />
                        )
                      )}
                    </div>
                  </Col>

                  <Col
                    lg={7}
                    className="chat-container"
                    style={{ display: isMobile ? "none" : "" }}
                  >
                    {conversationType === myAppConfig.CONVERSATION_TYPE.CHAT ? (
                      isMobile ? (
                        <Modal
                          show={showMobileChat}
                          onHide={() => {
                            setShowMobileChat(false);
                          }}
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                          className="editModal mobile chat component"
                          dialogClassName="modal-fullscreen modal-backdrop mobileSearch"
                          backdrop="static"
                          keyboard={false}
                        >
                          <Modal.Header>
                            <Button
                              className="modal-cancel-button-custom"
                              onClick={() => setShowMobileChat(false)}
                            >
                              <img src={arrow} alt="arrow" />
                            </Button>
                          </Modal.Header>
                          <Modal.Body>{renderChatComponent()}</Modal.Body>
                        </Modal>
                      ) : (
                        renderChatComponent()
                      )
                    ) : (
                      <>
                        {currentConversationIndex > -1 ? (
                          <SessionsInfoComponent
                            key={currentConversationIndex}
                            conversation={
                              conversationsInfoList[currentConversationIndex]
                            }
                          />
                        ) : null}
                      </>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>

            {isClient ? null : (
              <>
                {currentConversationIndex > -1 ? (
                  <ClientSessionsNotes
                    colLg={showClientSessions ? 4 : 1}
                    showClientSessions={showClientSessions}
                    setClientSessionsList={setClientSessionsList}
                    currentConversationIndex={currentConversationIndex}
                    clientId={
                      conversationType === myAppConfig.CONVERSATION_TYPE.CHAT
                        ? conversationsInfoList[currentConversationIndex]
                            .clientId
                        : conversationsInfoList[currentConversationIndex]
                            .client_id
                    }
                    notes={notes}
                    setNotes={setNotes}
                    clientSessions={clientSessions}
                    check403Error={check403Error}
                  />
                ) : null}
              </>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
}
