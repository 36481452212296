import React, { useEffect, useState } from "react";
import Moment from "moment";

function ChatItem(props) {
  const message = props.message;
  const userMsg = message.author === props.identity;
  const [imageUrl, setImageUrl] = useState();
  //console.log(message.author)
  //console.log("message body: " + message.body);

  const isJsonParsable = (string) => {
    if (string !== null) {
      try {
        JSON.parse(String(string));
      } catch (e) {
        return "";
      }
      if (JSON.parse(string) !== null) {
        return JSON.parse(string).msg !== undefined
          ? JSON.parse(string).msg
          : JSON.parse(string).freeMinutes !== undefined
          ? JSON.parse(string).freeMinutes
          : "";
      } else return "";
    }
    return "";
  };

  const jsonMessage = isJsonParsable(message.body);

  function parseSessionStartDate(string) {
    let sessionStartDate = "";
    if (string !== null) {
      try {
        JSON.parse(String(string));
      } catch (e) {
        //sessionStartDate = "";
      }
      if (JSON.parse(string) !== null) {
        sessionStartDate =
          JSON.parse(string).sessionStartDate !== undefined
            ? JSON.parse(string).sessionStartDate
            : "";
      }
    }

    try {
      if (sessionStartDate !== "") {
        let parsedData = Moment(new Date(sessionStartDate)).format("MMMM DD, YYYY HH:mm a");
        if(parsedData === "Invalid date"){
          return "-";
        }
        return parsedData;
      }
    } catch (error) {
      debugger;
      return "";
    }
    return "";
  }

  useEffect(() => {
    if (message.type === "media") {
      message.media.getContentTemporaryUrl().then(function (url) {
        setImageUrl(url);
      });
    }
  }, [message.media, message.type, setImageUrl]);

  return (
    <>
      {imageUrl ? (
        <img
          src={imageUrl}
          style={styles.imageMessage(userMsg)}
          alt="message img"
          className={userMsg ? "me" : "other"}
        />
      ) : (
        <>
          {jsonMessage !== "" ? (
            <>
              {props.isNewMessage ? (
                <div>
                  <p className="joined">
                    <span className="name">{jsonMessage}</span>
                  </p>
                </div>
              ) : null}{" "}
            </>
          ) : (
            <>
              {message.body === undefined ? (
                <div>
                  <p className="joined">
                    <span className="name">{message}</span>
                  </p>
                </div>
              ) : (
                <>
                  {message.body !== null &&
                  message.body.indexOf("sessionStartDate") !== -1 ? (
                    <div className="date">
                      {parseSessionStartDate(message.body)}
                    </div>
                  ) : (
                    <div
                      style={styles.container(userMsg)}
                      className={userMsg ? "me" : "other"}
                    >
                      {message.body}
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default ChatItem;

const styles = {
  listItem: (userMsg) => ({
    flexDirection: "column",
    alignItems: userMsg ? "flex-end" : "flex-start",
  }),
  container: (userMsg) => ({
    padding: "12px",
    width: "fit-content",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "white",
    backgroundColor: userMsg ? "#1DA1F2" : "#233558",
    alignSelf: userMsg ? "flex-end" : "flex-start",
    borderRadius: userMsg ? "14px" : "14px 14px 14px 0px",
    marginBottom: "10px",
  }),
  imageMessage: (userMsg) => ({
    alignSelf: userMsg ? "flex-end" : "flex-start",
    marginBottom: "10px",
    maxHeight: "100px",
    borderRadius: "14px",
  }),
  author: { fontSize: 10, color: "gray" },
  timestamp: { fontSize: 8, color: "white", textAlign: "right", paddingTop: 5 },
};