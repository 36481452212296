
import React, {useState} from "react";
import { Button} from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import "../../styles/mobileModalFilter.scss";

const MobileModalFilter = (props) => {

    const [checkedRadio, setCheckedRadio] = useState(0);

    const onChangeRadioButtonValue = (e) => {
        setCheckedRadio(e);
      };

    return (
        <Modal dialogClassName="modal-fullscreen modal-backdrop mobileFilter"
            show={props.show} 
            onHide={() => props.setMobileModalFilterShow()}
            backdrop='static'
            keyboard={false}
        >
            <Modal.Header>
                <div className="modal-header-container">
                    <Button className="modal-cancel-button-custom"
                        onClick={() => props.setMobileModalFilterShow()}
                    >Cancel</Button>
                    <p className="modal-title-custom">Sort</p>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-body-custom">
                    <p className="modal-subtitle-custom">Sort</p>
                    <hr />
                    <div>
                        {props.sortByDropDownValues.map((_item, idx) => (
                        idx >= 0 ?
                            <div key={idx} >
                                <div className="radio-buttons-container" >
                                    <p className="radio-buttons-text">{_item.title}</p>
                                    <input
                                        className="radio-button-custom"
                                        type="radio"
                                        value={_item.title}
                                        checked={checkedRadio === idx}
                                        onChange={() => onChangeRadioButtonValue(idx)}
                                        />
                                </div>
                                <hr />
                            </div>
                            : null
                        
                        ))}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="apply-filter-custom" onClick={() => props.applyFilter(checkedRadio)}>Apply</Button>
            </Modal.Footer>
        </Modal>
        );
}
export default MobileModalFilter;