import React, { useEffect, useState } from "react";
import {
  Row,
  Container,
  Button,
  Card,
  Dropdown,
  DropdownButton,
  Spinner
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { imagesApiURL } from "../../../constants/AppConstants";
import "../../../styles/categoryPage.scss";
import icon_dashboard_dropdown_arrow from "../../../assets/icons/arrows/dashboard_dropdown_arrow.png";
import icon_filter from "../../../assets/icons/icon_sort.png";

import CustomCard from "../../../components/Card";
import advisorImage from "../../../assets/img/advisor.png";
import "../../../styles/card.scss";
import axios from "axios";
import * as myAppConfig from "../../../constants/AppConstants";

import { isMobileOnly } from "react-device-detect";
import MobileModalFilter from "../../../components/modals/MobileModalFilter";

import bg from "../../../assets/img/bg/banner.png";

const sortByDropDownValues = [
  { id: 0, title: "Top advisors" },
  { id: 1, title: "New advisors" },
  { id: 2, title: "Price: Low to High" },
  { id: 3, title: "Price: High to Low" },
];

const filtersValues = ["All", "Call", "Chat", "Video"];

export default function CategoryPage(props) {
  const { check403Error } = props;

  const [dataLoading, setDataLoading] = useState(false);
  const [dropDownTitle, setDropDownTitle] = useState(sortByDropDownValues[0].title);
  const [selectedFilter, setSelectedFilter] = useState(filtersValues[0]);
  const [pageDetails, setPageDetails] = useState();
  const [filterIdx, setFilterIdx] = useState(0);
  const [sortIndex, setSortIndex] = useState(0);
  const [numberOfAdvisorsToShow, setNumberOfAdvisorsToShow] = useState(12);
  const numberMoreAdvisors = 20;

  let urlStrings = window.location.href.split("/category/").length > 1 ? window.location.href.split("/category/") : window.location.href.split("/wizard-results/");
  const categTitle = decodeURI(
    urlStrings[urlStrings.length - 1].includes("?search=true")
      ? urlStrings[urlStrings.length - 1].split('/?search=true')[0].replace(new RegExp("%20", 'g'), " ")
      : urlStrings[urlStrings.length - 1] !== "" ? urlStrings[urlStrings.length - 1].replace(new RegExp("%20", 'g'), " ") : "Top");
  const query = new URLSearchParams(window.location.search).get("search");

  React.useEffect(() => {
    if (!dataLoading) {
      setDataLoading(true);
      axios
        .get(myAppConfig.FILTER_BY_CATEGORY, {
          params: {
            category: categTitle !== 'Top' ? categTitle : '',
            session_method: filterIdx,
            sort_by: sortIndex,
          },
        })
        .then((response) => {
          setDataLoading(false);
          if (response.data.status === "success") {
            setPageDetails(response.data);
          } else {
            setPageDetails(null);
          }
        })
        .catch((err) => check403Error(err));
      setNumberOfAdvisorsToShow(12);
    }
  }, [categTitle, filterIdx, sortIndex, check403Error]);

  const [show, setShow] = useState(false);

  const categories = props.homePageDetails
    ? props.homePageDetails.categories
    : [];

  const category = getCategory(categories, categTitle);
  const advisors = pageDetails ? pageDetails.advisors : [];

  const minUsersReviewing = 2;
  const maxUsersReviewing = 10;
  const cards = advisors.filter(card => (card.full_name !== null)).map((card, idx) => {
    if (idx >= numberOfAdvisorsToShow) return null;
    const usersReviewing = Math.floor(Math.random() * (maxUsersReviewing - minUsersReviewing + 1)) + minUsersReviewing;
    let audioCall = 0;
    let chat = 0;
    let videoCall = 0;
    let mins = [];
    let minprice = 0;
    if (card.profile_session_pricings) {
      card.profile_session_pricings.map((price, idx) => {
        const p = price.price;
        if (p > 0) {
          mins.push(p);
          switch (price.session_method_type) {
            case 1:
              audioCall = 1;
              break;
            case 2:
              chat = 1;
              break;
            case 3:
              videoCall = 1;
              break;
            default:
              break;
          }
        }
        minprice = Math.min(...mins);
        return null;
      })
    }
    return (
      <CustomCard
        key={card.id}
        cardId={card.id}
        image={card.logo_image ? imagesApiURL + card.logo_image : advisorImage}
        name={card.full_name ? card.full_name : (card.first_name + " " + card.last_name)}
        categ={card.category[0] ? card.category[0].title : ""}
        stars={card.score_average ? card.score_average : 0}
        reviewsNo={card.number_of_reviews ? card.number_of_reviews : 0}
        price={"$" + minprice + "/min"}
        usersReviewing={usersReviewing}
        free={
          card.freeminutes_status
            ? card.freeminutes_value
              ? "First " + card.freeminutes_value + " min FREE"
              : ""
            : ""
        }
        audio={audioCall}
        video={videoCall}
        chat={chat}
        readings={card.number_of_readings ? card.number_of_readings : 0}
        status={(Number(card.status_account) === myAppConfig.ACCOUNT_STATUS.FREEZE.id) ? 2 : (card.offline_online ? card.status ? card.status : 0 : 2)}
      />
    );
  });

  function getCategory(arrCategories, title) {
    for (var i = 0; i < arrCategories.length; i++) {
      var _item = arrCategories[i];
      if (_item.title === title) {
        return _item;
      }
    }
  }

  useEffect(() => {
    if (document.querySelector(".category-dropdown > .dropdown-toggle")) {
      const style = document.querySelector(".category-dropdown > .dropdown-toggle").style;
      style.setProperty("--arrowURL", "url(" + icon_dashboard_dropdown_arrow + ")");
    }
  });

  const handleSelect = (index) => {
    setSortIndex(parseInt(index) + 1);
    setDropDownTitle(sortByDropDownValues[index].title);
  };

  const handleFilterSelect = (index) => {
    setFilterIdx(index);
    setSelectedFilter(filtersValues[index]);
  };

  const showMobileFilter = () => {
    setShow(true);
  };

  const applyFilter = (index) => {
    if (index !== undefined) {
      setSortIndex(parseInt(index) + 1);
      setSelectedFilter(filtersValues[index]);
    }
    setShow(false);
  };

  return (
    <div>
      <img src={bg} style={{ position: 'absolute', top: '0', width: '100%', zIndex: '-1', objectFit: 'cover' }} alt='bg' />
      <Container
        fluid
        className='p-0'
        style={isMobileOnly ? { width: "91.5%" } : { width: "88.88%" }}
      >
        {categTitle !== "Top" && !category ?
          <div style={{ marginTop: '156px', marginBottom: '80px', textAlign: 'center' }}>
            <Spinner animation='border' style={{ color: 'white' }} />
          </div>
          :
          <>
            <div className="top-div">
              {
                query ?
                  <p className="title">{advisors.filter(card => (card.full_name !== null)).length} advisors found</p>
                  : categTitle !== "Top" ?
                    <>
                      <img
                        className="main-image"
                        src={imagesApiURL + category.icon}
                        alt="category icon"
                      />
                      <p className="title">{category.title}</p>
                      <p className="subtitle">
                        {" "}
                        Unravel hidden insights & answers. Find your perfect psychic
                        match amongst our hand-picked selection of gifted, top-rated
                        psychics.
                      </p>
                    </>
                    :
                    <p className="title">Top Advisors</p>
              }
            </div>
            {(categTitle === "Top" || category) && dataLoading ?
              <div style={{ marginTop: '80px', marginBottom: '80px', textAlign: 'center' }}>
                <Spinner animation='border' style={{ color: 'white' }} />
              </div>
              :
              <>
                <div className="advisors-container">
                  <p className="advisors-count">{advisors.filter(card => (card.full_name !== null)).length} advisors found</p>
                  {advisors.length > 0 ?
                    <div className="advisors-filters">
                      <Row className="cols-filters">
                        <Card className="custom-card-filter custom-card-filter-unselected card-sort">
                          <img
                            src={icon_filter}
                            onClick={() => showMobileFilter()}
                            alt="filter"
                          />
                        </Card>
                        {filtersValues.map((_filterValue, idx) =>
                          selectedFilter === _filterValue ? (
                            <Card
                              key={idx}
                              className="custom-card-filter custom-card-filter-selected"
                            >
                              <Button
                                className="custom-card-filter-text"
                                onClick={() => handleFilterSelect(idx)}
                              >
                                {_filterValue}
                              </Button>
                            </Card>
                          ) : (
                            <Card
                              key={idx}
                              className="custom-card-filter custom-card-filter-unselected"
                            >
                              <Button
                                className="custom-card-filter-text"
                                onClick={() => handleFilterSelect(idx)}
                              >
                                {_filterValue}
                              </Button>
                            </Card>
                          )
                        )}

                        <DropdownButton
                          className="category-dropdown row"
                          alignRight
                          title={"Sort by: " + dropDownTitle}
                          id="category-dropdown-menu-align-right"
                          onSelect={handleSelect}
                        >
                          {sortByDropDownValues.map((_item, idx) =>
                            dropDownTitle === _item.title ? (
                              <Dropdown.Item
                                key={idx}
                                className="category-dropdown-menu-item category-dropdown-menu-item-selected-color"
                                eventKey={idx}
                              >
                                {_item.title}
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item
                                key={idx}
                                className="category-dropdown-menu-item category-dropdown-menu-item-unselected-color"
                                eventKey={idx}
                              >
                                {_item.title}
                              </Dropdown.Item>
                            )
                          )}
                        </DropdownButton>
                      </Row>
                    </div>
                    : null}
                  <p className="advisors-count-mobile">
                    {advisors.filter(card => (card.full_name !== null)).length} advisors found
                  </p>
                </div>

                <div className="cards-container">
                  <Row>{cards}</Row>
                </div>
                {advisors.filter(card => (card.full_name !== null)).length > numberOfAdvisorsToShow ? (
                  <div
                    style={{ display: "flex", justifyContent: "center" }}
                    className="my-5 pb-5 pt-3"
                  >
                    <button
                      className="showMore"
                      onClick={() =>
                        setNumberOfAdvisorsToShow(
                          numberOfAdvisorsToShow + numberMoreAdvisors
                        )
                      }
                    >
                      Show {numberMoreAdvisors} more advisors
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </>}
          </>}
      </Container>

      <MobileModalFilter
        show={show}
        setMobileModalFilterShow={() => setShow(false)}
        sortByDropDownValues={sortByDropDownValues}
        applyFilter={applyFilter}
      />
    </div>
  );
}
