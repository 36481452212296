import React from "react";

const ChatLayout = (props) => {
  const {
    children
  } = props;
  return <>{children}</>;
};

export { ChatLayout };
