import React, { useEffect, useState } from "react";
import {
  Row,
  Container,
  Button,
  Card,
  Dropdown,
  Spinner,
  DropdownButton,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import { imagesApiURL } from "../../../constants/AppConstants";
import "../../../styles/categoryPage.scss";
import icon_dashboard_dropdown_arrow from "../../../assets/icons/arrows/dashboard_dropdown_arrow.png";
import icon_filter from "../../../assets/icons/icon_sort.png";

import CustomCard from "../../../components/Card";
import advisorImage from "../../../assets/img/advisor.png";
import "../../../styles/card.scss";
import axios from "axios";

import * as myAppConfig from "../../../constants/AppConstants";
import EmptyScreen from "../../../components/EmptyScreen";
import { isMobileOnly } from "react-device-detect";

const sortByDropDownValues = [
  "Top advisors",
  "New advisors",
  "Price: Low to High",
  "Price: High to Low",
];
const filtersValues = ["All", "Call", "Chat", "Video"];

export default function SavedAdvisors(props) {
  const { setHasSimpleNavbar, setHasSimpleFooter, check403Error } = props;
  setHasSimpleNavbar(false);

  const [dropDownTitle, setDropDownTitle] = useState(sortByDropDownValues[0]);
  const [selectedFilter, setSelectedFilter] = useState(filtersValues[0]);
  const [checkedRadio, setCheckedRadio] = useState("");
  const [filterIdx, setFilterIdx] = useState(0);
  const [sortIndex, setSortIndex] = useState(0);
  const [numberOfAdvisorsToShow, setNumberOfAdvisorsToShow] = useState(12);
  const numberMoreAdvisors = 20;
  const [showEmptyPopup, setShowEmptyPopup] = useState(false);
  const [advisors, setAdvisors] = useState([])
  const [dataLoading, setDataLoading] = useState(false);

  React.useEffect(() => {
    if (!dataLoading) {
      setDataLoading(true);
      axios
        .get(myAppConfig.GET_SAVEDS, {
          params: {
            session_method: filterIdx,
            sort_by: sortIndex,
          },
          headers: {
            token: localStorage.getItem("userToken"),
          },
        })
        .then((response) => {
          setDataLoading(false);
          if (response.data.status === "success") {
            setAdvisors(response.data.advisors);
            setHasSimpleFooter(false);
          } else {
            console.log(response.data);
          }

          setShowEmptyPopup(true);
        })
        .catch((err) => check403Error(err));
      setNumberOfAdvisorsToShow(12);
    }
  }, [filterIdx, sortIndex, check403Error, setHasSimpleFooter]);

  const [show, setShow] = useState(false);

  const minUsersReviewing = 2;
  const maxUsersReviewing = 10;
  const cards = advisors.filter(card => (card.full_name !== null)).map((card, idx) => {
    if (idx >= numberOfAdvisorsToShow) return null;
    const usersReviewing = Math.floor(Math.random() * (maxUsersReviewing - minUsersReviewing + 1)) + minUsersReviewing;
    let audioCall = 0;
    let chat = 0;
    let videoCall = 0;
    let mins = [];
    let minprice = 0;
    if (card.profile_session_pricings) {
      card.profile_session_pricings.map((price, idx) => {
        const p = price.price;
        if (p > 0) {
          mins.push(p);
          switch (price.session_method_type) {
            case 1:
              audioCall = 1;
              break;
            case 2:
              chat = 1;
              break;
            case 3:
              videoCall = 1;
              break;
            default:
              break;
          }
        }
        minprice = Math.min(...mins);
        return null;
      })
    }
    return (
      <CustomCard
        key={card.id}
        cardId={card.id}
        image={card.logo_image ? imagesApiURL + card.logo_image : advisorImage}
        name={card.full_name ? card.full_name : (card.first_name + " " + card.last_name)}
        categ={card.category[0] ? card.category[0].title : ""}
        stars={card.score_average ? card.score_average : 0}
        reviewsNo={card.number_of_reviews ? card.number_of_reviews : 0}
        price={"$" + minprice + "/min"}
        usersReviewing={usersReviewing}
        free={
          card.freeminutes_status
            ? card.freeminutes_value
              ? "First " + card.freeminutes_value + " min FREE"
              : ""
            : ""
        }
        audio={audioCall}
        video={videoCall}
        chat={chat}
        readings={card.number_of_readings ? card.number_of_readings : 0}
        status={(Number(card.status_account) === myAppConfig.ACCOUNT_STATUS.FREEZE.id) ? 2 : (card.offline_online ? card.status ? card.status : 0 : 2)}
      />
    );
  });

  useEffect(() => {
    if (advisors.length > 0) {
      const style = document.querySelector(
        ".category-dropdown > .dropdown-toggle"
      ).style;
      style.setProperty(
        "--arrowURL",
        "url(" + icon_dashboard_dropdown_arrow + ")"
      );
    }
  }, [advisors]);

  const handleSelect = (index) => {
    setSortIndex(parseInt(index) + 1);
    setDropDownTitle(sortByDropDownValues[index]);
  };

  const handleFilterSelect = (index) => {
    setFilterIdx(index);
    setSelectedFilter(filtersValues[index]);
  };

  const showMobileFilter = () => {
    setShow(true);
  };

  const onChangeRadioButtonValue = (e) => {
    setCheckedRadio(e);
  };

  const applyFilter = () => {
    if (checkedRadio !== "") {
      setSortIndex(parseInt(checkedRadio) + 1);
      setSelectedFilter(filtersValues[checkedRadio]);
    }
    setShow(false);
  };

  return (
    <div>
      <Container fluid style={isMobileOnly ? { width: "91.5%" } : { width: "88.88%" }} className='p-0'>
        <div className="top-div">
          <p className="title">Saved</p>
        </div>

        {dataLoading ?
          <div style={{ marginTop: '80px', marginBottom: '80px', textAlign: 'center' }}>
            <Spinner animation='border' style={{ color: 'white' }} />
          </div>
          :
          showEmptyPopup && advisors.length === 0 ? (
            <div className="empty-screen">
              <EmptyScreen data={myAppConfig.EMPTY_POPUP_DETAILS.SAVED} />
            </div>
          ) :
            (
              <div>
                <div className="advisors-container">
                  <p className="advisors-count">{advisors.length} advisors saved</p>
                  <div className="advisors-filters">
                    <Row className="cols-filters">
                      <Card className="custom-card-filter custom-card-filter-unselected card-sort">
                        <img
                          src={icon_filter}
                          onClick={() => showMobileFilter()}
                          alt="filter"
                        />
                      </Card>
                      {filtersValues.map((_filterValue, idx) =>
                        selectedFilter === _filterValue ? (
                          <Card
                            key={idx}
                            className="custom-card-filter custom-card-filter-selected"
                          >
                            <Button
                              className="custom-card-filter-text"
                              onClick={() => handleFilterSelect(idx)}
                            >
                              {_filterValue}
                            </Button>
                          </Card>
                        ) : (
                          <Card
                            key={idx}
                            className="custom-card-filter custom-card-filter-unselected"
                          >
                            <Button
                              className="custom-card-filter-text"
                              onClick={() => handleFilterSelect(idx)}
                            >
                              {_filterValue}
                            </Button>
                          </Card>
                        )
                      )}

                      <DropdownButton
                        className="category-dropdown row"
                        alignRight
                        title={"Sort by: " + dropDownTitle}
                        id="category-dropdown-menu-align-right"
                        onSelect={handleSelect}
                      >
                        {sortByDropDownValues.map((_item, idx) =>
                          dropDownTitle === _item ? (
                            <Dropdown.Item
                              key={idx}
                              className="category-dropdown-menu-item category-dropdown-menu-item-selected-color"
                              eventKey={idx}
                            >
                              {_item}
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item
                              key={idx}
                              className="category-dropdown-menu-item category-dropdown-menu-item-unselected-color"
                              eventKey={idx}
                            >
                              {_item}
                            </Dropdown.Item>
                          )
                        )}
                      </DropdownButton>
                    </Row>
                  </div>
                  <p className="advisors-count-mobile">
                    {advisors.length} advisors found
                  </p>
                </div>

                <div className="cards-container">
                  <Row>{cards}</Row>
                </div>
                {advisors.length > numberOfAdvisorsToShow ? (
                  <div
                    style={{ display: "flex", justifyContent: "center" }}
                    className="my-5 pb-5 pt-3"
                  >
                    <button
                      className="showMore"
                      onClick={() =>
                        setNumberOfAdvisorsToShow(
                          numberOfAdvisorsToShow + numberMoreAdvisors
                        )
                      }
                    >
                      Show {numberMoreAdvisors} more advisors
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
      </Container>

      <Modal
        dialogClassName="modal-fullscreen modal-backdrop mobileFilter"
        show={show}
        onHide={() => setShow(false)}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header>
          <div className="modal-header-container">
            <Button
              className="modal-cancel-button-custom"
              onClick={() => setShow(false)}
            >
              Cancel
            </Button>
            <p className="modal-title-custom">Sort</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body-custom">
            <p className="modal-subtitle-custom">Sort</p>
            <hr />

            <div>
              {sortByDropDownValues.map((_item, idx) => (
                <div key={idx}>
                  <div className="radio-buttons-container">
                    <p className="radio-buttons-text">{_item}</p>
                    <input
                      className="radio-button-custom"
                      type="radio"
                      value={_item}
                      checked={checkedRadio === idx}
                      onChange={() => onChangeRadioButtonValue(idx)}
                    />
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="apply-filter-custom" onClick={() => applyFilter()}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
  //}
}
//export default CategoryPage;