import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import bg from "../../../assets/img/bg/pattern.png";
import ReactStars from "react-rating-stars-component";
import { imagesApiURL } from "../../../constants/AppConstants";

import * as myAppConfig from "../../../constants/AppConstants";
import ThankYouScreen from "../../../components/ThankYouScreen";
import * as utils from "../../../Utilities/Utils";

let showThankYouPopup = false;

const RateAdvisor = (props) => {
  const { setHasSimpleNavbar, check403Error } = props;
  const advisorInfo = JSON.parse(sessionStorage.getItem("otherTalker"));
  console.log(advisorInfo);
  const advisorName = advisorInfo["name"];
  const advisorCategory = utils.getCategoriesFromList(advisorInfo["category"]);
  const advisorId = advisorInfo["id"];
  const advisorLogo = advisorInfo["logo_image"];
  const [title, setTitle] = useState("");
  const [review, setReview] = useState("");
  const [score, setScore] = useState();
  const [anonymous, setAnonymous] = useState(false);

  const changeScore = (e) => {
    setScore(e);
  };

  const changeTitle = (e) => {
    setTitle(e.target.value);
  };

  const changeReview = (e) => {
    setReview(e.target.value);
  };

  const rateAdvisor = async (e) => {
    e.preventDefault();

    if (score === undefined) {
      alert("Please add rating star to our advisor");
    } else {
      const fetchTasks = utils.add_rating_and_review(
        true,
        advisorId,
        score,
        title,
        review,
        anonymous
      );

      Promise.all([fetchTasks])
        .then(function (res) {
          if (res !== null && res.length > 0) {
            showThankYouPopup = true;
            setHasSimpleNavbar(false);
          } else {
            alert(
              "An error has occured while trying to send your review. \n Please try again!"
            );
          }
        })
        .catch((error) => {
          console.log("error on fetchTasks: " + error);
          check403Error(error());
        });
    }
  };
  const changeAnonymous = (e) => {
    setAnonymous(e.target.checked);
  };

  return (
    <>
      {showThankYouPopup ? (
        <ThankYouScreen
          data={myAppConfig.FINAL_THANK_YOU_MESSAGES.FEEDBACK}
          advisorName={advisorName}
        />
      ) : (
        <div
          className="rateWrapper"
          style={{
            backgroundImage: `url(${bg})`,
          }}
        >
          <div className="title mb-2">Rate the advisor</div>
          <div className="subtitle">Your opinion is important to us</div>
          <div className="d-flex flex-column align-items-center hasStars">
            <img
              className="advisor-logo"
              src={imagesApiURL + advisorLogo}
              alt="advisor logo"
            />
            <div className="advisor-name">{advisorName}</div>
            <div className="advisor-categ mb-2">{advisorCategory}</div>
            <ReactStars
              edit={true}
              size={49}
              isHalf={true}
              color="#233558"
              activeColor="#19AAA1"
              onChange={changeScore}
              required
            />
            <div className="advisor-name" style={{ marginTop: "12px" }}>
              {" "}
              Leave your comment <br /> (optional){" "}
            </div>
          </div>
          <Form onSubmit={rateAdvisor}>
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                name="title"
                type="text"
                onChange={changeTitle}
                className="custom_input"
              />
            </Form.Group>
            <Form.Group controlId="formReview" style={{ position: "relative" }}>
              <Form.Label>Review</Form.Label>
              <Form.Control
                name="review"
                as="textarea"
                onChange={changeReview}
                style={{ resize: "none" }}
                rows={7}
                className="custom_input"
                maxLength={500}
              />
              <span className={review.length === 550 ? "over" : ""}>
                {review.length + "/550"}
              </span>
            </Form.Group>

            <Form.Group
              className="mt-4 d-flex flex-row-reverse justify-content-end"
              controlId="formBasicCheckbox"
            >
              <Form.Label className="ms-2">
                I would like my feedback to be posted as anonymous.
              </Form.Label>
              <Form.Check type="checkbox" onChange={changeAnonymous} />
            </Form.Group>
            <Button type="submit" className="saveButton">
              Send my review
            </Button>
          </Form>
        </div>
      )}
    </>
  );
};

export default RateAdvisor;
