import React, { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";

import Pusher from "pusher-js";
import axios from "axios";
import Popup from "react-popup";
import "./styles/custom.popup.css";

import HomePage from "./views/pages/HomePage";

import * as pusherConfig from "./constants/PusherConfig";
import * as myAppConfig from "./constants/AppConstants";
import * as utils from "./Utilities/Utils";

import NotificationsPopup from "./components/modals/NotificationsPopup";
import AccountManageActionModal from "./components/modals/AccountManageActionModal";
import AdvisorIsAvailableModal from "./components/modals/AdvisorIsAvailable";
import CookieConsent from "react-cookie-consent";
// import TagManager from "react-gtm-module";
import * as gaEcommerce from "./Utilities/GAEcommerce";

const { isSupported } = require("twilio-video");

function App() {
  const [showNewNotificationModal, setShowNewNotificationModal] =
    useState(false);
  const [notificationInfo, setNotificationInfo] = useState("");

  const [showAccountManageModal, setShowAccountActionModal] = useState(false);
  const [accountActionType, setAccountActionType] = useState("");

  const [showNotifyMe, setShowNotifyMe] = useState(false);
  const [notifyMeInfo, setNotifyMeInfo] = useState("");

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: myAppConfig.IS_PROD_VERSION ? "GTM-PGRCJ4F" : "GTM-T7SRWM",
    };

    //TagManager.initialize(tagManagerArgs);
    console.log("TagManager initialize ", myAppConfig.IS_PROD_VERSION);
    gaEcommerce.initializeGA();

    sessionStorage.setItem("call-invitation", undefined);

    const getNotification = (notification_id, item_id) => {
      console.log(
        "getNotification, notification_id: " +
          notification_id +
          " ,item_id: " +
          item_id
      );
      axios
        .get(myAppConfig.GET_MY_NOTIFICATIONS, {
          params: {
            notification_id: notification_id,
            item_id: item_id,
          },
          headers: {
            token: localStorage.getItem("userToken"),
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            var info = JSON.parse(response.data.notification.data);
            console.log("getNotification response: ", info);

            var notificationType = response.data.notification.type;
            if (
              isSupported === false &&
              (notificationType === myAppConfig.NOTIFICATION_TYPE.chat ||
                notificationType === myAppConfig.NOTIFICATION_TYPE.voice ||
                notificationType === myAppConfig.NOTIFICATION_TYPE.video)
            ) {
              return;
            }
            if (notificationType === myAppConfig.NOTIFICATION_TYPE.chat) {
              console.log("Notification type chat: ", info.conversation_id);

              if (
                window.location.pathname === "/station" &&
                Number(utils.getCurrentConversationAccepted()) ===
                  Number(info.conversation_id)
              ) {
                console.log("onAcceptRejectNotification from station");
                onAcceptRejectNotification(
                  true,
                  info.conversation_id,
                  response.data.notification
                );
              } else if (userHasNoConversationActive()) {
                utils.playNotificationSound();
                setNotificationInfo(response.data.notification);
                setShowNewNotificationModal(true);
              }
            } else if (
              notificationType === myAppConfig.NOTIFICATION_TYPE.voice
            ) {
              console.log("Notification type call ", info.conversation_id);

              if (userHasNoConversationActive()) {
                utils.playNotificationSound();
                setNotificationInfo(response.data.notification);
                setShowNewNotificationModal(true);
              } else {
                console.log("Won't show the call invitation popup");
              }
            } else if (
              notificationType === myAppConfig.NOTIFICATION_TYPE.video
            ) {
              console.log("Notification type video ", info.conversation_id);

              if (userHasNoConversationActive()) {
                utils.playNotificationSound();
                setNotificationInfo(response.data.notification);
                setShowNewNotificationModal(true);
              } else {
                console.log("Won't show the video invitation popup");
              }
            } else if (
              notificationType === myAppConfig.NOTIFICATION_TYPE.reject_request
            ) {
              console.log("Notification type rejected", info.conversation_id);

              let msg = utils.displayErrorMessage("Sorry", info.message);

              if (
                window.location.pathname !== "/chat" &&
                window.location.pathname !== "/call" &&
                window.location.pathname !== "/video-chat"
              ) {
                setAccountActionType(msg);
                setShowAccountActionModal(true);
              }

              sessionStorage.setItem(
                "chat-invitation",
                myAppConfig.NOTIFICATION_TYPE.reject_request
              );
              sessionStorage.setItem(
                "call-invitation",
                myAppConfig.NOTIFICATION_TYPE.reject_request
              );

              sessionStorage.setItem(
                "video-invitation",
                myAppConfig.NOTIFICATION_TYPE.reject_request
              );
            } else if (
              notificationType === myAppConfig.NOTIFICATION_TYPE.accept_request
            ) {
              console.log("Notification type Accept ", info.conversation_id);
              sessionStorage.setItem(
                "chat-invitation",
                myAppConfig.NOTIFICATION_TYPE.accept_request
              );
              sessionStorage.setItem(
                "call-invitation",
                myAppConfig.NOTIFICATION_TYPE.accept_request
              );
              sessionStorage.setItem(
                "video-invitation",
                myAppConfig.NOTIFICATION_TYPE.accept_request
              );
            } else if (
              notificationType ===
              myAppConfig.NOTIFICATION_TYPE.available_advisor
            ) {
              console.log("Notification type Available Advisor");
              if (
                window.location.pathname !== "/chat" &&
                window.location.pathname !== "/call" &&
                window.location.pathname !== "/video-chat"
              ) {
                utils.playNotificationSound();
                setNotifyMeInfo(response.data.notification);
                setShowNotifyMe(true);
              }
            } else if (
              notificationType ===
              myAppConfig.NOTIFICATION_TYPE.cancel_conversation
            ) {
              console.log("Notification type Cancel Conversation ");

              setNotificationInfo("");
              setShowNewNotificationModal(false);

              if (
                (window.location.pathname === "/station" ||
                  window.location.pathname === "/chat" ||
                  window.location.pathname === "/call" ||
                  window.location.pathname === "/video-chat") &&
                Number(utils.getCurrentConversationAccepted()) ===
                  Number(info.conversation_id)
              ) {
                let msg = utils.displayErrorMessage("Sorry", info.message);
                setAccountActionType(msg);
                setShowAccountActionModal(true);
                utils.setCurrentConversationAccepted("");
                console.log("setCurrentConversationAccepted empty");
              }
            }
          } else {
            console.log(
              "error on getNotification api call: " +
                JSON.stringify(response.data)
            );
          }
        })
        .catch((errorMessage) => {
          if (
            errorMessage &&
            errorMessage.response &&
            errorMessage.response.status &&
            (parseInt(errorMessage.response.status) === 403 ||
              parseInt(errorMessage.response.status) === 404 ||
              parseInt(errorMessage.response.status) === 401)
          ) {
            if (
              parseInt(errorMessage.response.status) === 404 ||
              parseInt(errorMessage.response.status) === 401
            ) {
              console.log("clear all storage - 404 error");
              localStorage.clear();
              sessionStorage.clear();
              window.location.href = "/?logged_out=true";
            } else {
              //403
              if (errorMessage.response.data.status === "error") {
                Popup.create({
                  title: "Error",
                  content:
                    errorMessage.response.data.message !== ""
                      ? errorMessage.response.data.message
                      : "An error has occured!",
                });
              }
            }
          }
        });
    };

    function userHasNoConversationActive() {
      const currentConversationAccepted =
        utils.getCurrentConversationAccepted();
      return (
        currentConversationAccepted === null ||
        currentConversationAccepted === undefined ||
        currentConversationAccepted === "" ||
        (window.location.pathname !== "/station" &&
          window.location.pathname !== "/call" &&
          window.location.pathname !== "/video-chat")
      );
    }
    const pusher = new Pusher(pusherConfig.PUSHER_APP_KEY, {
      cluster: pusherConfig.PUSHER_APP_CLUSTER,
      //encrypted: true,
    });

    const channel = pusher.subscribe("my-channel");
    channel.bind("my-event", (data) => {
      const my_notification_id = localStorage.getItem("notification_id");
      if (
        data.message &&
        my_notification_id !== null &&
        my_notification_id !== ""
      ) {
        let showNotifPopup = false;
        if (Array.isArray(data.message)) {
          data.message.forEach((message) => {
            console.log(
              "1. Notification is for me? ",
              message.notification_id,
              message.notification_id === my_notification_id,
              message.item_id
            );
            if (message.notification_id === my_notification_id) {
              console.log("Api call getNotification ", data);
              if (!showNotifPopup) {
                showNotifPopup = true;
                getNotification(message.notification_id, message.item_id);
              }
            }
          });
        } else {
          console.log(
            "2. Notification is for me? ",
            data.message.notification_id,
            data.message.notification_id === my_notification_id,
            data.message.item_id
          );
          if (data.message.notification_id === my_notification_id) {
            getNotification(data.message.notification_id, data.message.item_id);
          }
        }
      }
    });

    return () => {};
  }, []);

  const onAcceptRejectNotification = (
    isAccept,
    conversation_id,
    notificationInfo
  ) => {
    utils.setCurrentConversationAccepted("");

    console.log("onAcceptRejectNotification: ", isAccept, conversation_id);

    setNotificationInfo("");
    setShowNewNotificationModal(false);

    var formData = new FormData();
    formData.append("conversation_id", conversation_id);
    formData.append(
      "type",
      isAccept
        ? myAppConfig.ACCEPT_REJECT_NOTIF_TYPE.accept
        : myAppConfig.ACCEPT_REJECT_NOTIF_TYPE.reject
    );

    let type = notificationInfo.type;
    let clientInfo = {};
    if (
      (type === myAppConfig.NOTIFICATION_TYPE.voice ||
        type === myAppConfig.NOTIFICATION_TYPE.video) &&
      isAccept
    ) {
      clientInfo["id"] = notificationInfo.client_id;
      clientInfo["name"] = notificationInfo.client_name;
      clientInfo["logo_image"] = notificationInfo.client_logo;
      clientInfo["friendly_name"] = notificationInfo.friendly_name;
    }

    axios
      .post(myAppConfig.ACCEPT_REJECT_NOTIFICATIONS, formData, {
        headers: {
          token: localStorage.getItem("userToken"),
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          if (isAccept) {
            utils.setCurrentConversationAccepted(conversation_id);
            console.log(
              "onAcceptNotification: ",
              conversation_id,
              "Free mins: ",
              response.data.free_minutes
            );

            sessionStorage.setItem("free-minutes", response.data.free_minutes);

            if (type === myAppConfig.NOTIFICATION_TYPE.voice) {
              sessionStorage.setItem("otherTalker", JSON.stringify(clientInfo));
              window.location.pathname = "/call";
            } else if (type === myAppConfig.NOTIFICATION_TYPE.video) {
              sessionStorage.setItem("otherTalker", JSON.stringify(clientInfo));
              window.location.pathname = "/video-chat";
            } else if (type === myAppConfig.NOTIFICATION_TYPE.chat) {
              if (
                window.location.pathname === "/station" &&
                window.location.search.length > 0
              ) {
                window.location.pathname = "/station";
                window.location.search = "";
              } else if (document.querySelector("#advisors-station") !== null) {
                document.querySelector("#advisors-station").click();
              } else {
                window.location.pathname = "/station";
              }
            }
          } else {
            console.log("rejected invitation ", conversation_id);
          }
        } else {
          alert("An error has occured while trying to accept the invitation");
        }
      })
      .catch((err) => {
        console.log("error onAcceptReject ", err);
      });
  };

  const onAccountActionBtnClick = () => {
    window.location.pathname = "/";
  };

  const onNewSession = (isNewSession, info) => {
    setShowNotifyMe(false);
    if (
      isNewSession &&
      info &&
      info.advisor_name !== null &&
      info.advisor_name !== ""
    ) {
      window.location.pathname = "/advisor-profile/" + info.advisor_name;
    }
  };

  return (
    <>
      {/* <CookieConsent
        location="bottom"
        buttonText="Accept All Cookies"
        cookieName="AdviaCookie"
        style={{ background: "#2B373B", fontSize: '16px', lineHeight: '22px' }}
        buttonStyle={{ padding: '15px', borderRadius: '14px', background: '#19aaa1', color: 'white', margin: '0 15px' }}
        expires={150}
        containerClasses='justify-content-center justify-content-sm-between py-3 align-items-center'
        dataBlockingMode='auto'
      >
        By clicking "Accept All Cookies", you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.
      </CookieConsent> */}
      <AdvisorIsAvailableModal
        show={showNotifyMe}
        setShowNotifyMe={() => setShowNotifyMe(false)}
        onNewSession={onNewSession}
        notifyMeInfo={notifyMeInfo}
      />
      <AccountManageActionModal
        show={showAccountManageModal}
        setShowAccountActionModal={() => setShowAccountActionModal(false)}
        actionType={accountActionType}
        onAccountAction={onAccountActionBtnClick}
        btnText={"Ok"}
        hideClose={true}
      />
      <NotificationsPopup
        show={showNewNotificationModal}
        setShowNewNotificationModal={() => setShowNewNotificationModal(false)}
        notificationInfo={notificationInfo}
        onAcceptRejectNotification={onAcceptRejectNotification}
      />
      <HomePage />
    </>
  );
}

export default App;
