import React from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "../../styles/accountManageActionModal.scss";

const UploadingModal = (props) => {

    return (
        <Modal
            show={props.show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="account-manage-action-modal"
            backdrop='static'
            keyboard={false}
        >
            <Modal.Header></Modal.Header>
            <Modal.Body>
                <div className="title px-3">Please wait until your files are uploaded</div>
                <div style={{ textAlign: 'center' }} className='my-4'>
                    <Spinner animation='border' style={{ color: 'white', width: '4rem', height: '4rem' }} />
                </div>
            </Modal.Body>
        </Modal>
    )
};

export default UploadingModal;
