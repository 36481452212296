import React, { useState, useEffect, useRef } from "react";
import "../../styles/chatComponent.scss";
import * as myAppConfig from "../../constants/AppConstants";
import icon_logo from "../../assets/img/user.png";
import ChatItem from "../../views/pages/client/ChatItem";
import { Spinner, Button } from "react-bootstrap";
//import { ProgressBarLine } from "react-progressbar-line";
import PulseLoader from "react-spinners/PulseLoader";
import ChatMyReview from "./ChatMyReview";
import * as utils from "../../Utilities/Utils";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
// import {polyfill} from "seamless-scroll-polyfill";
import * as Icon from "react-feather";
import Moment from "moment";

import Sticky from "react-sticky-el";
const ChatComponent = (props) => {
  if (navigator.userAgent.indexOf("iPhone OS") != -1) {
    const chatFooter = document.querySelector(".chatFooter");
    if (chatFooter !== null) {
      chatFooter.style.setProperty("--app-ios-15-padding-bottom", `5px`);
    }

    if (document.querySelector(".chatContents") !== null) {
      //document.querySelector(".chatContents").style.bottom = "120px";
    }
  }

  document.addEventListener("DOMContentLoaded", () => {
    setPlatformInfo();
    var inputBox = document.querySelector(".safari #inputBox");
    if (inputBox) {
      inputBox.addEventListener("focus", function (e) {
        document.body.classList.add("keyboard");
        setTimeout(function () {
          window.scrollTo(0, 0);
        }, 200);
      });

      inputBox.addEventListener("blur", function (e) {
        document.body.classList.remove("keyboard");
      });
    }
  });

  function setPlatformInfo() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") > -1) {
        document.body.classList.add("chrome");
      } else {
        document.body.classList.add("safari");
      }
    }
  }

  const userType = localStorage.getItem("userType");
  const isClient = Number(userType) === myAppConfig.USER_TYPES.CLIENT;

  const messagesEndRef = useRef();
  const oldMessagesEndRef = useRef();

  const [oldChatMessages, setOldChatMessages] = useState([]);

  const [text, setText] = useState("");
  const [shouldScrollBottom, setShouldScrollBottom] = useState(true);
  const [showLoadingMoreMessages, setShowLoadingMoreMessages] = useState(false);
  const [messagesPage, setMessagesPage] = useState();

  /*const conversationSeconds = 3 * 60; //3 seconds

  const [hours, setHours] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);*/

  const [elapsedTime, setElapsedTime] = useState("");
  const [freeMinutesExpired, setFreeMinutesExpired] = useState(false);

  const otherTalkerCategory = utils.getCategoriesFromList(
    props.otherTalkerCategory
  );

  var isTyping = props.typing;
  if (!isClient && isTyping) {
    if (props.channel !== null && props.channel !== undefined) {
      if (
        props.channel.channelState.uniqueName !==
        utils.getCurrentConversationAccepted()
      ) {
        isTyping = false;
      }
    }
  }

  const showIsTyping = isTyping;

  var sessionIsLive = true;

  if (!isClient && props.newChatMessages.length > 0) {
    let lastMessage = props.newChatMessages[props.newChatMessages.length - 1];
    if (
      lastMessage.author !== props.identity &&
      utils.IsJsonString(lastMessage.body)
    ) {
      if (
        props.channel !== null &&
        props.channel !== undefined &&
        lastMessage.channel.channelState.uniqueName ===
          props.channel.channelState.uniqueName
      ) {
        sessionIsLive = false;
      }
    }
  }

  const isSessionLive = sessionIsLive;

  const showLastReview = props.last_review && props.last_review.length > 0;

  var timer = 0;

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13 || e.charCode === 13) {
      if (text.trim().length > 0) {
        props.sendMessage(text);
        setText("");
      }
    } else if (props.channel !== null && props.channel !== undefined) {
      props.channel.typing();
    }
  };

  const handleScroll = (e) => {
    if (e.target.scrollTop === 0) {
      setShowLoadingMoreMessages(true);

      if (messagesPage === undefined) {
        props.channel.getMessages(20).then((messages) => {
          setMessagesPage(messages);
          processPage(messages);
        });
      } else {
        processPage(messagesPage);
      }
    } else {
      setShowLoadingMoreMessages(false);
      setShouldScrollBottom(true);
    }
  };

  function processPage(page) {
    if (page.hasPrevPage) {
      page.prevPage().then((oldMessages) => {
        setMessagesPage(oldMessages);
        setShouldScrollBottom(false);

        var msgOld = oldChatMessages;
        setOldChatMessages([...oldMessages.items]);

        let allOldChatMessages = [...msgOld, ...props.chatMessages];
        props.setChatMessages([...allOldChatMessages]);

        setShowLoadingMoreMessages(false);
      });
    } else {
      console.log("all messages read!");
      setShowLoadingMoreMessages(false);
    }
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });

    // polyfill();
  };

  const scrollToBottomOldMessages = () => {
    oldMessagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });

    // polyfill();
  };

  useEffect(() => {
    if (shouldScrollBottom) {
      //console.log("scrollToBottom");
      scrollToBottom();
    } else {
      console.log("can't scroll to bottom");
    }

    if (oldChatMessages.length > 0) {
      if (
        props.chatMessages.length === 0 ||
        (props.chatMessages.length > 0 &&
          oldChatMessages[0].channel.uniqueName !==
            props.chatMessages[0].channel.uniqueName)
      ) {
        setOldChatMessages([]);
        setMessagesPage();
        setShouldScrollBottom(true);
      }
    }

    return () => {};
  }, [
    props.newChatMessages,
    props.chatMessages,
    props.typing,
    props.otherJoined,
  ]);

  useEffect(() => {
    //console.log("scrollToBottomOldMessages");
    scrollToBottomOldMessages();
    return () => {};
  }, [oldChatMessages]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isClient && props.otherJoined) {
        countUp();
      }
    }, 1000);
    return () => {
      if (isClient) {
        //console.log("Client cleared interval ", interval);
        clearInterval(interval);
      }
    };
  }, [props.otherJoined]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isClient && props.isNewChat && isSessionLive) {
        var messageSessionStartDate = null;
        if (props.newChatMessages && props.newChatMessages.length > 0) {
          messageSessionStartDate = props.newChatMessages[0];
        } else if (props.chatMessages && props.chatMessages.length > 0) {
          messageSessionStartDate =
            props.chatMessages[props.chatMessages.length - 1];
        }

        if (
          messageSessionStartDate !== null &&
          messageSessionStartDate.body.indexOf("sessionStartDate") !== -1 &&
          messageSessionStartDate.body !== null
        ) {
          let string = messageSessionStartDate.body;

          if (utils.IsJsonSessionStart(string)) {
            let sessionStartDate = JSON.parse(string).sessionStartDate;

            var oldDate = Moment(sessionStartDate);
            var now = Moment.utc(new Date());
            var duration = Moment.duration(now.diff(oldDate));
            timer = duration.asSeconds();
            countUp();
          }
        }
      }
    }, 1000);
    return () => {
      if (!isClient) {
        //console.log("Advisor cleared interval ", interval);
        clearInterval(interval);
      }
    };
  }, [props.newChatMessages]);

  function countUp() {
    if (isClient) timer = timer + 1;
    setElapsedTime(utils.secondsToHms(timer));

    if (
      sessionStorage.getItem("free-minutes") !== undefined &&
      sessionStorage.getItem("free-minutes") > 0 &&
      sessionStorage.getItem("free-minutes") * 60 === Number(timer.toFixed(0))
    ) {
      if (
        isClient ||
        (!isClient && utils.getCurrentConversationAccepted() !== "")
      )
        setFreeMinutesExpired(true);
    }
  }

  const renderFreeMinutesExpiredMessage = (messageToDisplay) => {
    return (
      <div
        className="free-minutes-tooltip"
        style={{ display: freeMinutesExpired ? "" : "none" }}
      >
        <p>{messageToDisplay}</p>

        <Icon.XCircle
          className="close-img"
          onClick={() => setFreeMinutesExpired(false)}
        />
      </div>
    );
  };

  return (
    <div
      className={" " + (isMobile ? "mobile-app-container" : "app-container")}
    >
      <div className="chatContainer d-flex flex-column justify-content-between">
        {renderFreeMinutesExpiredMessage(
          isClient
            ? "Your " +
                sessionStorage.getItem("free-minutes") +
                " free minutes has ended!"
            : props.otherTalkerName + "'s free minutes has ended!"
        )}
        <div
          className="load-old-messages-spinner"
          style={{ display: showLoadingMoreMessages ? "flex" : "none" }}
        >
          <Spinner animation="border" role="status" />
        </div>
        <header className="chat-header">
          <Sticky>
            <div className="chatHeader d-flex justify-content-between">
              <div className="d-flex">
                <img
                  src={
                    props.otherTalkerLogoImage === "" ||
                    props.otherTalkerLogoImage === null
                      ? icon_logo
                      : myAppConfig.imagesApiURL + props.otherTalkerLogoImage
                  }
                  alt="other talker logo"
                />
                <div>
                  <p className="name">{props.otherTalkerName}</p>
                  <p>{otherTalkerCategory}</p>
                </div>
              </div>
              {props.showEndSessionButton && isSessionLive ? (
                <div
                  className="end"
                  onClick={() => props.showModalEndSession()}
                >
                  End session
                </div>
              ) : null}
            </div>
            {props.isNewChat && isSessionLive ? (
              <div
                className="d-flex justify-content-end"
                style={{ marginBottom: "auto" }}
              >
                <div className="time">{elapsedTime}</div>
              </div>
            ) : (
              <div />
            )}
          </Sticky>
        </header>
        <main className="chat-main">
          <div style={{ overflow: "hidden" }}>
            <div
              className={
                "chatContents d-flex flex-column align-items-center " /*+
                (props.isNewChat
                  ? isMobile
                    ? ""
                    : ""
                  : isClient
                  ? "chat-contents-station-client"
                  : "chat-contents-station-advisor")*/
              }
              id="chatContents"
              onScroll={handleScroll}
            >
              {(isClient && props.otherJoined) ||
              (!isClient && props.joinedChannel) ||
              (isClient && props.joinedChannel && !props.isNewChat) ? (
                <>
                  {oldChatMessages.map((message, id) => (
                    <ChatItem
                      key={id}
                      message={message}
                      identity={props.identity}
                      isNewMessage={false}
                    />
                  ))}

                  <div ref={oldMessagesEndRef} />

                  {props.chatMessages.map((message, id) => (
                    <ChatItem
                      key={id}
                      message={message}
                      identity={props.identity}
                      isNewMessage={false}
                    />
                  ))}

                  {showLastReview ? (
                    <ChatMyReview last_review={props.last_review} />
                  ) : null}

                  {props.newChatMessages ? (
                    <>
                      {props.newChatMessages.map((message, id) => (
                        <ChatItem
                          key={id}
                          message={message}
                          identity={props.identity}
                          isNewMessage={true}
                        />
                      ))}
                    </>
                  ) : null}

                  {showIsTyping ? (
                    <>
                      <div
                        className="d-flex typing"
                        style={{ marginTop: "10px" }}
                      >
                        <img
                          src={
                            props.otherTalkerLogoImage === "" ||
                            props.otherTalkerLogoImage === null
                              ? icon_logo
                              : myAppConfig.imagesApiURL +
                                props.otherTalkerLogoImage
                          }
                          alt="user logo"
                        />
                        <PulseLoader color={"#1DA1F2"} size={"5px"} />
                      </div>
                      <div className="typing name">
                        {props.otherTalkerName.split(" ")[0]} is typing a
                        message
                      </div>
                    </>
                  ) : null}
                  <hr />

                  {!props.isNewChat && isClient ? (
                    <div className="chat-new-session-container">
                      {props.allowNewSession ? (
                        <Link
                          to={{ pathname: "/chat" }}
                          className={"start-new-session "}
                          style={{ textDecoration: "none" }}
                        >
                          Start a new session
                        </Link>
                      ) : (
                        <div
                          className={
                            "start-new-session  start-new-session-disabled"
                          }
                        >
                          Start a new session
                        </div>
                      )}
                    </div>
                  ) : null}

                  {isClient && !showLastReview && !props.isNewChat ? (
                    <div
                      className="chat-rate-the-advisor-container"
                      onClick={() =>
                        (window.location.pathname = "/rate-advisor")
                      }
                    >
                      <p>Rate the advisor</p>
                    </div>
                  ) : null}
                  <div ref={messagesEndRef} />
                </>
              ) : (
                <div
                  className={
                    "spinner-container " +
                    (isMobile ? "spinner-container-mobile" : "")
                  }
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                  <div className="infoText">
                    <p className="infoTitle">Starting Chat...</p>
                    <p>We're connecting you now, please wait a few seconds</p>
                  </div>
                  <Button onClick={() => props.onCancelChat()}>Cancel</Button>
                  <hr />
                </div>
              )}
            </div>
          </div>
        </main>
        <footer className="chat-footer">
          <div className="chatFooter d-flex">
            <textarea
              id="inputBox"
              type="text"
              onChange={(event) => setText(event.target.value)}
              onKeyUp={handleKeypress}
              value={text}
              placeholder="Your message here"
              rows="1"
              enterKeyHint="Send"
              style={{
                display: props.isNewChat && isSessionLive ? "block" : "none",
              }}
            />
          </div>
        </footer>
      </div>
    </div>
  );
};

export default ChatComponent;
