import React, { useEffect } from 'react';
import { Modal, Nav, NavDropdown, Col, Button, Row, Container } from 'react-bootstrap'
import * as Icon from 'react-feather'
import { Link } from 'react-router-dom';
import { imagesApiURL } from '../../constants/AppConstants';

import icon_dashboard_dropdown_arrow from "../../assets/icons/arrows/dashboard_dropdown_arrow.png";

const MobileNavbar = (props) => {
    const { categories, show, setShow, logo, navDropdownTitle } = props

    useEffect(() => {
        const elem = document.querySelector(
            ".dropdown .dropdown-toggle"
        );
        if (elem) {
            elem.style.setProperty(
                "--arrowURL",
                "url(" + icon_dashboard_dropdown_arrow + ")"
            );
        }
    });


    return (
        <Modal dialogClassName="modal-fullscreen modal-backdrop mobileNavbar"
            show={show} onHide={() => setShow(false)}
            backdrop='static'
            keyboard={false}
        >
            <Modal.Header>
                <div className="modal-header-container">
                    <Button className="modal-cancel-button-custom" onClick={() => setShow(false)}>X</Button>
                    <img src={logo} alt="logo" onClick={() => { setShow(false); window.location.pathname = '/' }} />
                </div>
            </Modal.Header>
            <Modal.Body>
                <Nav className="me-auto my-2 my-lg-0">
                    <NavDropdown title={navDropdownTitle}>
                        <Row className='mx-0'>
                            <Col lg={6} className='p-0' >
                                {categories.slice(0, 4).map((category, idx) => {
                                    let urlToOpen = "/category/" + category.title;
                                    return (
                                        <NavDropdown.Item as={Link} to={urlToOpen} onClick={() => setShow(false)} key={idx}><img src={imagesApiURL + category.icon} alt="category icon" /> {category.title}</NavDropdown.Item>
                                    )
                                })}
                            </Col>
                            <Col lg={6} className='p-0'>
                                {categories.slice(4).map((category, idx) => {
                                    let urlToOpen = "/category/" + category.title;
                                    return (
                                        <NavDropdown.Item as={Link} to={urlToOpen} onClick={() => setShow(false)} key={idx}><img src={imagesApiURL + category.icon} alt="category icon" /> {category.title}</NavDropdown.Item>
                                    )
                                })}
                            </Col>
                        </Row>
                    </NavDropdown>
                    <Nav.Link href="https://horoscope.advia.io/">Horoscope</Nav.Link>
                    <Nav.Link href="https://horoscope.advia.io/articles/">Articles</Nav.Link>
                </Nav>
                <div className='footer'>
                    <div className="footer-top">
                        <Container fluid={true} className='p-0'>
                            <Row className='mx-0'>
                                <Col xs="6" md="6" lg="3" className='p-0'>
                                    <div className="footer-widget footer-contact">
                                        <h2 className="footer-title">Contact Us</h2>
                                        <div className="footer-contact-info">
                                            {/* <p>+ 1 098 654 67 86</p> */}
                                            <p><a href="mailto:advia.info@gmail.com">support@advia.io</a></p>
                                            {/* <p className="mb-0"><a href="/#">Customer support</a></p> */}
                                        </div>
                                    </div>
                                </Col>

                                <Col xs="6" md="6" lg="1" className='p-0'>
                                    <div className="footer-widget footer-contact">
                                        <h2 className="footer-title">Follow Us</h2>
                                        <div className="footer-about-content">

                                            <div className="social-icon">
                                                <ul className="d-flex">
                                                    <li>
                                                        <a href="https://www.instagram.com/advialifeadvisors/" target="_blank"><Icon.Instagram size={21} /></a>
                                                    </li>
                                                    <li>
                                                        <a href="https://www.facebook.com/Advialifeadvisors1" target="_blank"><Icon.Facebook size={21} /></a>
                                                    </li>
                                                    {/* <li>
                                                        <a href="/#" target="_blank"><Icon.Twitter size={21} /></a>
                                                    </li> */}

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="footer-bottom">
                        <div className="container-fluid p-0">
                            <div className="copyright">
                                <div className="copyright-text">
                                    <p>©2021 Advia. All rights reserved</p>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

    )
}

export default MobileNavbar