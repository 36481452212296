import React from 'react'
import { Col, Row, Container } from 'react-bootstrap';
import '../../styles/_layouts.scss';
import logo from '../../assets/icons/logo.png';
import * as Icon from "react-feather";
import FooterCategories from './FooterCategories';
import { isMobileOnly } from 'react-device-detect';
import * as myAppConfig from '../../constants/AppConstants'

function Footer(categories) {
    const userToken = localStorage.getItem('userToken');
    const userType = parseInt(localStorage.getItem('userType'));
    return (
        <div className="footer" style={isMobileOnly ? { padding: '17px 5px' } : {}}>
            <div className="footer-top">
                <Container fluid={true}>
                    <Row>
                        <Col xs='12' sm="12" md="12" lg="1" className='me-lg-5'>
                            <div className="footer-widget footer-about">
                                <div className="footer-logo">
                                    <a href="/"> <img src={logo} alt="logo" /></a>
                                </div>
                            </div>
                        </Col>
                        {!myAppConfig.VERSION_FOR_PRODUCTION ?
                            <FooterCategories categories={categories.categories} />
                            : null}

                        <Col xs="6" sm='6' md="6" lg="3">
                            <div className="footer-widget footer-menu">
                                <h2 className="footer-title">About Advia</h2>
                                <ul>
                                    <li><a href={myAppConfig.VERSION_FOR_PRODUCTION && !userToken ? "/" : "/become-a-psychic"}>Become A Psychic</a></li>
                                    <li><a href="https://horoscope.advia.io/">Horoscope</a></li>
                                    <li><a href="https://horoscope.advia.io/articles/">Articles</a></li>
                                    <li><a href="/about-advia">About Advia</a></li>
                                    <li><a href={(myAppConfig.VERSION_FOR_PRODUCTION && !userToken) || userType === 2 ? "/terms-and-conditions/advisors" : '/terms-and-conditions/'}>Terms and conditions</a></li>
                                    <li><a href="/faq">FAQ</a></li>
                                    <li><a href='/privacy-policy'>Privacy Policy</a></li>
                                </ul>
                            </div>
                        </Col>

                        <Col xs='6' sm="6" md="6" lg="3">
                            <div className="footer-widget footer-contact">
                                <h2 className="footer-title">Contact Us</h2>
                                <div className="footer-contact-info">

                                    {/* <p>
                                        <i className="fas fa-phone-alt"></i>
                                        + 1 098 654 67 86
                                    </p> */}
                                    <p className="mb-0">
                                        <i className="fas fa-envelope"></i>
                                        <a href="mailto:support@advia.io">support@advia.io</a>
                                    </p>
                                </div>
                            </div>
                        </Col>

                        <Col xs='6' sm="6" md="6" lg="1">
                            <div className="footer-widget footer-contact">
                                <h2 className="footer-title">Follow Us</h2>
                                <div className="footer-about-content">

                                    <div className="social-icon">
                                        <ul className="d-flex">
                                            <li>
                                                <a href="https://www.instagram.com/advialifeadvisors/" target="_blank"><Icon.Instagram size={21} /></a>
                                            </li>
                                            <li>
                                                <a href="https://www.facebook.com/Advialifeadvisors1" target="_blank"><Icon.Facebook size={21} /></a>
                                            </li>
                                            {/* <li>
                                                <a href="/#" target="_blank"><Icon.Twitter size={21} /></a>
                                            </li> */}

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>



            <div className="footer-bottom">
                <div className="container-fluid">
                    <div className="copyright">
                        <div className="copyright-text">
                            <p>©2021 Advia. All rights reserved</p>
                        </div>

                    </div>


                </div>
            </div>


        </div>

    )
}


export default Footer
