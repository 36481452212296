


import editProfileImage from '../../assets/icons/edit_profile_icon.png';

const BackgroundDescription = (props) => {
    const {
        background, setShowEditBackground, setTempBackground
    } = props

    return (
        <div className="info">
            <p className="custom-text-style-24-34">Background</p>
            <p className="description custom-text-style-16-22">{window.location.pathname !== '/' || background ? (background ? background.split('\n').map((item, i) => <span key={i}>{item}<br /></span>) : '') : 'Detail about your background so that other customers can be impressed by your experience'}</p>
            <br />
            {/* <a className="readMore" href='# '>Read more</a> */}
            <div className='edit-profile' onClick={() => { setShowEditBackground(true); setTempBackground(background) }}> <img src={editProfileImage} alt='' /> </div>
        </div>
    )
}

export default BackgroundDescription