import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import '../../styles/_layouts.scss';
import PropTypes from 'prop-types';
import SuperAdminDropDown from './dropDowns/SuperAdminDropDown';

function SuperAdminNavbar({ userEmail, check403Error }) {

  return (
    <>
      <Navbar expand="lg" className="navbar-dark p-0" style={{ marginBottom: '126px' }}>
        <Navbar.Text>Advia Administrator</Navbar.Text>
        <Nav>
          <div style={{ display: 'table' }}>
            <SuperAdminDropDown userEmail={userEmail} check403Error={check403Error} />
          </div>
        </Nav>
      </Navbar>
    </>
  );
}

export default SuperAdminNavbar;

SuperAdminNavbar.propTypes = {
  userEmail: PropTypes.string,
  check403Error: PropTypes.func
}