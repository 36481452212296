import React, {useState} from "react";
import { Col, Row } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import axios from "axios";
import * as myAppConfig from "../constants/AppConstants";
import "../styles/ratingAndReviewCell.scss";

const RatingAndReviewCell = (props) => {
  const {check403Error} = props
  const [isApproved, setIsApproved] = useState(props.review.is_approved);

  const userType = localStorage.getItem("userType");

  const changeReviewApprovalStatus = (publish, reviewId) => {
    if (localStorage.getItem("userToken") === null) {
      document.querySelector(".loginButton").click();
    } else {
      var formData = new FormData();
      formData.append("review_id", reviewId);
      formData.append("is_approved", publish ? 1 : 0);

      axios
        .post(myAppConfig.APPROVE_REJECT_REVIEW_URL, formData, {
          headers: {
            token: localStorage.getItem("userToken"),
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            setIsApproved(publish ? 1 : 0);
          } 
        }).catch(err => check403Error(err));
    }
  };

  return (
    <div className="rating-and-review-cell">
      <div className="rating-flex-row space-between">
        <div className="rating-flex-row">
          {/* <img
            className="rating-user-img"
            src={imagesApiURL + props.review.logo_image} alt="rating-user-img"
          /> */}
          <div>
            <p className="rating-user-name">
              {parseInt(props.review.is_anonymous) === 1 ? 'Anonymous' : (props.review.first_name + ' ' + props.review.last_name)}
            </p>
            <p className="rating-user-time">{props.review.when_was_created}</p>
          </div>
        </div>

        <div className="stars d-flex align-items-center advisor-ratings-stars">
          <div className="rating-flex-row rating-score-container">
            <p className="rating-score">{props.review.score}</p>
            <p className="rating-max-score">/5</p>
          </div>
          <ReactStars
            classNames="advisor-custom-react-stars"
            edit={false}
            size={28}
            value={Number(props.review.score)}
            isHalf={true}
            color="#233558"
            activeColor="#19AAA1"
          />
        </div>
      </div>

      <Row>
        <Col lg={8}>
          <p className="rating-title">{props.review.review_title}</p>
          <p className="rating-description">
            {props.review.review_description}
          </p>
        </Col>
        {Number(userType) === myAppConfig.USER_TYPES.SUPERADMIN ? (
          <Col lg={4} className="rating-cell-right">
            {isApproved === 1 ? (
              <button
                type="button"
                className="unpublish"
                onClick={() =>
                  changeReviewApprovalStatus(false, props.review.id)
                }
              >
                Un-publish
              </button>
            ) : (
              <button
                type="button"
                className="publish"
                onClick={() =>
                  changeReviewApprovalStatus(true, props.review.id)
                }
              >
                Publish
              </button>
            )}
          </Col>
        ) : null}
      </Row>
      <hr />
    </div>
  );
};

export default RatingAndReviewCell;
