import axios from "axios";
import Moment from "moment";

import * as myAppConfig from "../constants/AppConstants";

import popupSound from "../assets/sound/notification.mp3";
import { useEffect } from "react";
import { isDesktop, isIOS, isAndroid } from "react-device-detect";
import { gapi } from "gapi-script";

export function getCategoriesFromList(arrayList) {
  let category = "";
  if (Array.isArray(arrayList)) {
    for (var i = 0; i < arrayList.length; i++) {
      category += arrayList[i].title + ", ";
    }

    if (category !== "") {
      category = category.slice(0, -2);
    }
  } else {
    category = arrayList;
  }

  return category;
}

export function getLastMessage(chatMessages) {
  let lastMsg = "";
  if (chatMessages.length > 0) {
    let msgList = chatMessages.reverse();

    for (var i = 0; i < msgList.length; i++) {
      if (msgList[i].body !== null && !IsJsonString(msgList[i].body)) {
        return msgList[i].body;
      }
    }
  }
  return lastMsg;
}

export function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return JSON.parse(str).msg !== undefined;
}

export function IsJsonSessionStart(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return JSON.parse(str).sessionStartDate !== undefined;
}

export function IsJsonFreeMinutes(str) {
  if (str !== undefined && str !== null) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return JSON.parse(str).freeMinutes !== undefined;
  } else return false;
}

export function parseFreeMinutesExpiredMessage(string) {
  if (string !== null) {
    try {
      JSON.parse(String(string));
    } catch (e) {
      return "";
    }
    if (JSON.parse(string) !== null) {
      return JSON.parse(string).freeMinutes !== undefined
        ? JSON.parse(string).freeMinutes
        : "";
    } else return "";
  }
  return "";
}

export function parseJson(string) {
  if (JSON.parse(string) !== null) {
    return JSON.parse(string).msg !== undefined
      ? JSON.parse(string).msg
      : string;
  } else return "";
}

export async function cancelConversation(conversation_id) {
  let response = await axios.get(myAppConfig.CANCEL_CONVERSATION, {
    params: { conversation_id: conversation_id },
    headers: { token: localStorage.getItem("userToken") },
  });

  console.log("API CALL cancelConversation response", conversation_id);
  return response;
}

export function playNotificationSound() {
  var sound = new Audio(popupSound);
  sound.load();
  const promise = sound.play();
  if (promise !== undefined) {
    // On older browsers play() does not return anything, so the value would be undefined.
    promise
      .then(() => {
        // Audio is playing.
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export function getStartOrEndConversationParameters(
  isStart,
  conversation_id,
  conversation_type
) {
  let formData = new FormData();
  if (isStart) {
    formData.append("start_to", getCurrentDate());
  } else {
    formData.append("end_to", getCurrentDate());
  }

  formData.append("conversation_id", conversation_id);
  formData.append("conversation_type", conversation_type);

  formData.append(
    "device_type",
    isDesktop ? "DT" : isIOS ? "iOS" : isAndroid ? "Android" : "Mobile Web"
  );

  return formData;
}

export async function callStartOrEndConversation(isStart, formData) {
  let response = await axios.post(
    myAppConfig.START_END_CONVERSATION,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        token: localStorage.getItem("userToken"),
      },
    }
  );

  if(isStart && response.data.status === "success"){
    localStorage.setItem("has3mins", 0);
  }
  if(response.data.status === "success"){
    console.log("response callStartOrEndConversation success");
  }
  return response;
}

export function getCurrentDate() {
  return Moment(new Date()).format("yyyy-MM-DD HH:mm:ss");
}

export function getCurrentDateUTC() {
  //return Moment(new Date().toUTCString()).format("MMMM DD, YYYY HH:mm a");
  return Moment.utc(new Date()).toString();
}

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

export async function getChatToken(session_type, conversationId) {
  console.log("getChatToken: ");

  let params = { session_type: session_type };
  if (conversationId !== undefined) {
    params = { session_type: session_type, conversation_id: conversationId };
  }

  let response = await axios.get(myAppConfig.GET_CHAT_TOKEN, {
    params: params,
    headers: { token: localStorage.getItem("userToken") },
  });

  return response;
}

export async function logOut(redirectUrl, check403Error) {
  if (gapi && gapi.auth2) {
    const auth2 = gapi.auth2.getAuthInstance();
    if (auth2 != null) {
      auth2
        .signOut()
        .then(auth2.disconnect().then(console.log("LOGOUT SUCCESSFUL")));
    }
  }
  axios
    .get(myAppConfig.LOG_OUT, {
      headers: { token: localStorage.getItem("userToken") },
    })
    .then((response) => {
      if (response.data.status === "success") {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = redirectUrl;
      }
    })
    .catch((err) => check403Error(err));
}
export const logOutUser = async (redirectUrl, check403Error) => {
  await logOut(redirectUrl, check403Error);
};

export function getCreateConversationParameters(
  otherTalkerId,
  conversation_type
) {
  let convFormData = new FormData();
  convFormData.append("client_id", localStorage.getItem("profileId"));
  convFormData.append("advisor_id", otherTalkerId);

  convFormData.append("conversation_type", conversation_type);

  return convFormData;
}

export function redirectToAddCard(data, session_pay_screen) {
  sessionStorage.setItem("session-pay", data.sessionPay);
  sessionStorage.setItem("session-pay-screen", session_pay_screen);
  window.location.href = data.payUrl;
}

export function secondsToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + ":" : "";
  var mDisplay = m > 0 ? (m < 10 ? "0" : "") + (m + ":") : "00:";
  var sDisplay = s > 0 ? (s < 10 ? "0" : "") + s : "00";

  var value = hDisplay + mDisplay + sDisplay;
  return value;
}

export function getAdvisorIsNotRespondingMessage(otherTalkerName) {
  let message = {
    id: 0,
    title: "Sorry",
    subtitle:
      "We are seen that " +
      otherTalkerName +
      " are not responsive. The session will be ended in one minute",
    btnActiveState: false,
  };

  return message;
}

export function displayErrorMessage(title, messageToDisplay) {
  var message = {
    id: 0,
    title: title,
    subtitle: messageToDisplay,
    btnActiveState: false,
  };

  return message;
}

export function getAdvisorIsBusyMessage(otherTalkerName) {
  return "The advisor " + otherTalkerName + " is busy!";
}

export function getCancelConversationMessage(otherTalkerName) {
  return "The " + otherTalkerName + " cancel the conversation!";
}

export function invitationResponse(invitation) {
  return (
    invitation === undefined ||
    invitation === "undefined" ||
    invitation === null
  );
}

export function setCurrentConversationAccepted(value) {
  sessionStorage.setItem(myAppConfig.current_conversation_accepted, value);
}

export function getCurrentConversationAccepted() {
  return sessionStorage.getItem(myAppConfig.current_conversation_accepted);
}

export async function getClientNotes(clientId) {
  if (clientId !== undefined) {
    let response = await axios.get(myAppConfig.GET_NOTE, {
      params: { client_id: clientId },
      headers: { token: localStorage.getItem("userToken") },
    });
    if (response.data.status === "success" && response.data.notes.length > 0) {
      return response.data.notes[0].note;
    } else {
      return "";
    }
  }
  return "";
}

export function updateClientNotes(
  clientId,
  notes,
  currentConversationIndex,
  check403Error
) {
  if (currentConversationIndex > -1 && notes.trim().length > 0) {
    let formData = new FormData();
    formData.append("client_id", clientId);
    formData.append("note", notes);

    axios
      .post(myAppConfig.UPDATE_NOTE, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("userToken"),
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          console.log(response.data);
        }
      })
      .catch((err) => check403Error(err));
  }
}

export async function getSessionsList(clientId) {
  if (clientId !== undefined) {
    let response = await axios.get(myAppConfig.GET_SESSIONS_LIST, {
      params: { client_id: clientId },
      headers: { token: localStorage.getItem("userToken") },
    });
    if (response.data.status === "success" && response.data.sessionsHistory) {
      return response.data;
    } else {
      return [];
    }
  }
  return [];
}

export async function add_rating_and_review(
  send_a_feedback,
  advisorId,
  score,
  title,
  review,
  anonymous
) {
  let rateFormData = new FormData();
  rateFormData.append("profile_client_id", localStorage.getItem("profileId"));
  rateFormData.append("profile_advisor_id", advisorId);
  rateFormData.append("send_a_feedback", send_a_feedback ? "1" : "0");
  if (send_a_feedback) {
    rateFormData.append("score", score);
    rateFormData.append("review_title", title);
    rateFormData.append("review_description", review);
    rateFormData.append("is_anonymous", anonymous ? 1 : 0);
  }

  let response = await axios.post(myAppConfig.ADD_REVIEW, rateFormData, {
    headers: {
      token: localStorage.getItem("userToken"),
    },
  });
  if (response.data.status === "success") {
    console.log("Send review ", send_a_feedback, advisorId);
    return true;
  } else return null;
}

export async function checkConversationStatus(
  conversation_id,
  conversation_type
) {
  let response = await axios.get(myAppConfig.CHECK_STATUS_CONVERSATION, {
    params: {
      conversation_id: conversation_id,
      conversation_type: conversation_type,
    },
    headers: { token: localStorage.getItem("userToken") },
  });

  if (
    response.data.status === "success" &&
    response.data.conversationDetails &&
    response.data.conversationDetails.length > 0
  ) {
    return response.data.conversationDetails[0].status === 1; // 1 - conversation active
  } else {
    return false;
  }
}

export function getDashboardGraphsData(response) {
  return [
    {
      title: "Gross Revenue",
      percentage: response.data.gross_revenue.procent ?? 0,
      percentagePositive: (response.data.gross_revenue.procent ?? 0) > 0,
      amount: response.data.gross_revenue.total ?? 0,
      dtAmount:
        response.data.gross_revenue.device_type &&
        response.data.gross_revenue.device_type["DT"]
          ? response.data.gross_revenue.device_type["DT"].toFixed(2)
          : 0,
      mobileWebAmount:
        response.data.gross_revenue.device_type &&
        response.data.gross_revenue.device_type["Mobile Web"]
          ? response.data.gross_revenue.device_type["Mobile Web"].toFixed(2)
          : 0,
      iOSAmount:
        response.data.gross_revenue.device_type &&
        response.data.gross_revenue.device_type["iOS"]
          ? response.data.gross_revenue.device_type["iOS"].toFixed(2)
          : 0,
      androidAmount:
        response.data.gross_revenue.device_type &&
        response.data.gross_revenue.device_type["Android"]
          ? response.data.gross_revenue.device_type["Android"].toFixed(2)
          : 0,
      transactions: response.data.gross_revenue.transactions,
    },

    {
      title: "New Clients",
      percentage: response.data.new_clients.procent ?? 0,
      percentagePositive: (response.data.new_clients.procent ?? 0) > 0,
      amount: response.data.new_clients.total,
      dtAmount:
        response.data.new_clients.device_type &&
        response.data.new_clients.device_type["DT"]
          ? response.data.new_clients.device_type["DT"]
          : 0,
      mobileWebAmount:
        response.data.new_clients.device_type &&
        response.data.new_clients.device_type["Mobile Web"]
          ? response.data.new_clients.device_type["Mobile Web"]
          : 0,
      iOSAmount:
        response.data.new_clients.device_type &&
        response.data.new_clients.device_type["iOS"]
          ? response.data.new_clients.device_type["iOS"]
          : 0,
      androidAmount:
        response.data.new_clients.device_type &&
        response.data.new_clients.device_type["Android"]
          ? response.data.new_clients.device_type["Android"]
          : 0,
      transactions: response.data.new_clients.transactions,
    },

    {
      title: "Returning Clients",
      percentage: response.data.returning_clients.procent ?? 0,
      percentagePositive: (response.data.returning_clients.procent ?? 0) > 0,
      amount: response.data.returning_clients.total,
      dtAmount:
        response.data.returning_clients.device_type &&
        response.data.returning_clients.device_type["DT"]
          ? response.data.returning_clients.device_type["DT"]
          : 0,
      mobileWebAmount:
        response.data.returning_clients.device_type &&
        response.data.returning_clients.device_type["Mobile Web"]
          ? response.data.returning_clients.device_type["Mobile Web"]
          : 0,
      iOSAmount:
        response.data.returning_clients.device_type &&
        response.data.returning_clients.device_type["iOS"]
          ? response.data.returning_clients.device_type["iOS"]
          : 0,
      androidAmount:
        response.data.returning_clients.device_type &&
        response.data.returning_clients.device_type["Android"]
          ? response.data.returning_clients.device_type["Android"]
          : 0,
      transactions: response.data.returning_clients.transactions,
    },

    {
      title: "Total Sessions",
      percentage: response.data.total_sessions.procent ?? 0,
      percentagePositive: (response.data.total_sessions.procent ?? 0) > 0,
      amount: response.data.total_sessions.total ?? 0,
      dtAmount:
        response.data.total_sessions.device_type &&
        response.data.total_sessions.device_type["DT"]
          ? response.data.total_sessions.device_type["DT"]
          : 0,
      mobileWebAmount:
        response.data.total_sessions.device_type &&
        response.data.total_sessions.device_type["Mobile Web"]
          ? response.data.total_sessions.device_type["Mobile Web"]
          : 0,
      iOSAmount:
        response.data.total_sessions.device_type &&
        response.data.total_sessions.device_type["iOS"]
          ? response.data.total_sessions.device_type["iOS"]
          : 0,
      androidAmount:
        response.data.total_sessions.device_type &&
        response.data.total_sessions.device_type["Android"]
          ? response.data.total_sessions.device_type["Android"]
          : 0,
      transactions: response.data.total_sessions.transactions,
    },

    {
      title: "New Clients Revenue",
      percentage: response.data.new_clients_revenue.procent ?? 0,
      percentagePositive: (response.data.new_clients_revenue.procent ?? 0) > 0,
      amount: response.data.new_clients_revenue.total ?? 0,
      dtAmount:
        response.data.new_clients_revenue.device_type &&
        response.data.new_clients_revenue.device_type["DT"]
          ? response.data.new_clients_revenue.device_type["DT"].toFixed(2)
          : 0,
      mobileWebAmount:
        response.data.new_clients_revenue.device_type &&
        response.data.new_clients_revenue.device_type["Mobile Web"]
          ? response.data.new_clients_revenue.device_type["Mobile Web"].toFixed(2)
          : 0,
      iOSAmount:
        response.data.new_clients_revenue.device_type &&
        response.data.new_clients_revenue.device_type["iOS"]
          ? response.data.new_clients_revenue.device_type["iOS"].toFixed(2)
          : 0,
      androidAmount:
        response.data.new_clients_revenue.device_type &&
        response.data.new_clients_revenue.device_type["Android"]
          ? response.data.new_clients_revenue.device_type["Android"].toFixed(2)
          : 0,
      transactions: response.data.new_clients_revenue.transactions,
    },

    {
      title: "Return Revenue",
      percentage: response.data.return_revenue.procent ?? 0,
      percentagePositive: (response.data.return_revenue.procent ?? 0) > 0,
      amount: response.data.return_revenue.total ?? 0,
      dtAmount:
        response.data.return_revenue.device_type &&
        response.data.return_revenue.device_type["DT"]
          ? response.data.return_revenue.device_type["DT"].toFixed(2)
          : 0,
      mobileWebAmount:
        response.data.return_revenue.device_type &&
        response.data.return_revenue.device_type["Mobile Web"]
          ? response.data.return_revenue.device_type["Mobile Web"].toFixed(2)
          : 0,
      iOSAmount:
        response.data.return_revenue.device_type &&
        response.data.return_revenue.device_type["iOS"]
          ? response.data.return_revenue.device_type["iOS"].toFixed(2)
          : 0,
      androidAmount:
        response.data.return_revenue.device_type &&
        response.data.return_revenue.device_type["Android"]
          ? response.data.return_revenue.device_type["Android"].toFixed(2)
          : 0,
      transactions: response.data.return_revenue.transactions,
    }, 
  ];
}
